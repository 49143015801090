import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { isLocaleSupported, Locales } from './Locales';
import { Messages } from './Messages';

Vue.use(VueI18n);

const LOCAL_STORAGE_KEY = 'locale';

function loadLocaleForUnregistered(component, contract) {
  let locale = localStorage.getItem('sign_app_locale');

  if (!locale) {
    locale = contract?.current_user?.locale || contract?.locale || guessCurrentLocale();
  }

  // if (
  //   (component.$route.path.startsWith('/contract') || component.$route.path.startsWith('/sign')) &&
  //   component.$route.name !== 'contractReceived'
  // ) {
  //   locale = localStorage.getItem('sign_app_locale') || locale;
  // } else {
  //   locale = localStorage.getItem('sign_app_locale') || locale;
  // }

  changeLocaleWithoutSave(component, locale);
}

function getCurrentLocale(component) {
  return component && component.$i18n ? component.$i18n.locale : guessCurrentLocale();
}

function guessCurrentLocale() {
  return localStorage.getItem(LOCAL_STORAGE_KEY) || detectBrowserLocale() || Locales.DEFAULT_LOCALE;
}

function changeAndSaveLocale(component, locale) {
  const isChanged = changeLocaleWithoutSave(component, locale);
  if (isChanged) {
    localStorage.setItem(LOCAL_STORAGE_KEY, locale);
  }
}

function detectBrowserLocale() {
  const lang =
    navigator.language ||
    navigator.browserLanguage ||
    navigator.systemLanguage ||
    navigator.userLanguage;
  const browserLocale = lang && 'string' === typeof lang && lang.split('-')[0];
  return browserLocale && isLocaleSupported(browserLocale) ? browserLocale : null;
}

function changeLocaleWithoutSave(component, locale) {
  // hotfix solution that causes text blinking, maybe you could use i18n
  // TODO: cleanup when changing locale via flags is implemented
  // https://kazupon.github.io/vue-i18n/guide/locale.html
  // https://github.com/kazupon/vue-i18n/issues/253
  if (
    !component ||
    !component.$i18n ||
    String(locale) === component.$i18n.locale ||
    !isLocaleSupported(locale)
  ) {
    return false;
  }
  Messages.loadLocale(locale);
  component.$i18n.locale = locale;
  component.$root.$i18n.locale = locale;
  return true;
}

const defaultLocale = guessCurrentLocale();
const fallbackLocale = Locales.FALLBACK_LOCALE;
const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: fallbackLocale,
  messages: Messages.loadDefaultLocales([defaultLocale, fallbackLocale]),
  pluralizationRules: {
    cs: (count) => (1 === count ? 0 : count < 5 ? 1 : 2),
  },
  escapeParameterHtml: true,
});

export {
  i18n,
  getCurrentLocale,
  loadLocaleForUnregistered,
  changeAndSaveLocale,
  changeLocaleWithoutSave,
};
