<template>
  <div class="contract-title__edit">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" class="mb-1" style="max-width: 24rem">
        <v-text-field
          type="text"
          name="newContractTitle"
          class="headline font-weight-bold contract-title__edit-title pb-2 font-weight-bold"
          v-model="newContractTitle"
          append-outer-icon="mdi-pencil-outline"
          hide-details
          :rules="titleRules"
          @blur="saveNewTitle"
          @keydown.enter="saveNewTitle"
        >
          <template v-slot:default>
            <h1>
              {{ contract.title }}
            </h1>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ContractTitleEdit',
  data() {
    return {
      newContractTitle: '',
      titleRules: [
        (v) => !!v || this.$t('general.fill_required2'),
        (v) => v.length >= 2 || this.$t('general.fill_required2'),
      ],
    };
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
    }),
  },
  watch: {
    'contract.title': {
      handler: function (val) {
        this.newContractTitle = val;
      },
    },
  },
  created() {
    this.newContractTitle = this.contract.title;
  },
  methods: {
    ...mapActions({
      setContract: 'setContract',
    }),
    saveNewTitle() {
      this.contract.title = this.newContractTitle;
      this.setContract(this.contract);
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-title__edit >>> .v-input__slot::before {
  border-style: none !important;
}

.contract-title__edit {
  &::v-deep input {
    border-bottom: 1px dashed black !important;
    padding-bottom: 0.5rem !important;
  }

  &::v-deep .v-input__slot::before {
    border-style: none !important;
  }
}
</style>
