
<template lang="html">
    <svg width="29px" height="25px" viewBox="0 0 29 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="SYN001---DSKTP---Nová-smlouva---výběr-zdroje" transform="translate(-792.000000, -353.000000)">
                <rect fill="none" x="0" y="0" width="1200" height="893"></rect>
                <g id="karty-3" transform="translate(433.000000, 234.000000)">
                    <g id="ico/ico-g-drive" transform="translate(358.000000, 114.000000)">
                        <g id="Group">
                            <polygon id="Path-21" fill="#2EB672" fill-rule="nonzero" points="1.09646266 21.2053652 5.21750521 29.7795603 16.0416802 12.9563148 11.3917497 5.41029448"></polygon>
                            <polygon id="Path-21-Copy-2" fill="#587CBA" fill-rule="nonzero" transform="translate(17.006076, 25.767376) rotate(-120.000000) translate(-17.006076, -25.767376) " points="10.0336807 29.5786715 14.0236677 38.2678114 23.9784713 21.0255858 19.4512627 13.266941"></polygon>
                            <polygon id="Path-21-Copy" fill="#FED14B" fill-rule="nonzero" transform="translate(20.868285, 13.630585) rotate(-240.000000) translate(-20.868285, -13.630585) " points="13.6177809 17.7673855 18.22436 25.7462146 28.1187899 9.26891452 23.6420398 1.51495586"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>


<script>
    export default {
        name: 'IcoGoogleDrive'
    }
</script>
