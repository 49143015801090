<template>
  <div class="contract-side-previews">
    <div v-for="(image, imageIndex) in images" :key="imageIndex" class="contract-side-previews__image">
      <!--      TODO - select current image-->
      <img :src="image" :alt="`preview-${imageIndex}`" :class="{ 'active': false }" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ContractSidePreviews",
  props: {
    images: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contract-side-previews {
  &__image {
    margin: 20px 15px;

    img {
      background-color: #ffffff;
      border-radius: 6px;
      overflow: hidden;
      margin: 10px auto;
      padding: 5px;
      width: 100%;
      height: auto;
    }

    img.active {
      border: 3px solid #8141c7;
    }
  }
}
</style>
