<template lang="html">
  <ContractLayout
    :background-image="contract.settings && contract.settings.background ? contract.settings.background : `/images/contract-desktop-bg.20200622.jpg`"
    :contract="contract"
    :landscape-signing="landscapeOrientation && isMobileDevice"
  >
    <template v-slot:content>
      <div :class="{'contract-landscape': landscapeOrientation}">
        <div class="contract__data contract__data--received contract__data--full">
          <div class="contract__logo">
            <Logo
              v-if="!landscapeOrientation"
              :logo="contract.settings ? contract.settings.logo : null"
              :white="false"
              larger
            />
          </div>

          <Signature
            v-if="loaded"
            :orientation="orientation"
            :signIdentity="signIdentity"
            :contract="selectedContract"
            :cantChangeSignatureDate="cantChangeSignatureDate"
            @contractSignedOnRemoteDevice="contractSignedOnRemoteDevice"
            @save="signatureSave"
          />

          <Loader
            v-else
            class="register-loader"
            style="text-align: center; padding-left: 0"
          />
        </div>
      </div>
    </template>
  </ContractLayout>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { ContractHelper } from '@/helpers/ContractHelper'
import { ContractService } from '@/services/ContractService.js'
import FeatureFlags from '@/common/reusable/featureFlagsChecker';
import ContractLayout from '@/views/contract/components/ContractLayout'
import Loader from '@/components/Loader.vue'
import Signature from '@signature/views/Signature'

export default {
  name: 'contractSign',
  components: {
    ContractLayout,
    Loader,
    Signature
  },
  data () {
    return {
      loaded: false,
      selectedContract: null,
      orientation: 'portrait',
      landscapeOrientation: false
    }
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
    }),
    signIdentity () {
      return this.selectedContract.sign_identity
    },
    displaySizes () {
      return {
        windowWidth: this.$vuetify.breakpoint.width,
        windowHeight: this.$vuetify.breakpoint.height
      }
    },
    cantChangeSignatureDate () {
      return FeatureFlags.cantChangeSignatureDate(this.contract)
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch: {
    displaySizes (newSizes, oldSizes) {
      if (newSizes.windowWidth === oldSizes.windowWidth && newSizes.windowHeight < oldSizes.windowHeight) {
        return
      }

      this.landscapeOrientation = newSizes.windowWidth > newSizes.windowHeight
    },
    landscapeOrientation (isLandscape) {
      this.orientation = isLandscape
        ? 'landscape'
        : 'portrait'
    }
  },
  mounted () {
    this.loaded = false
    ContractService.contractFromApiToken({
      component: this,
      onSuccess: (response) => {
        const contractId = parseInt(this.$route.params.contract_id, 10)

        this.$store.commit('setContract', response)

        if (contractId && contractId !== response.id) {
          this.selectedContract = _.chain(response)
            .get('attachments')
            .find((attachment) => attachment.id === contractId)
            .value()
        } else {
          this.selectedContract = this.contract
        }

        if (this.selectedContract?.current_sign_identity?.is_signed) {
          return (() => {
            this.$notification.warning(this.$t('contract.document.signed'));
            this.checkUnsignedDocuments();
          })();
        }

        this.loaded = true
      }
    })
  },
  methods: {
    contractSignedOnRemoteDevice (signedContract) {
      this.checkUnsignedDocuments(signedContract)
    },
    signatureSave (event) {
      this.loaded = false

      ContractService.sendSignature(
        event.signature,
        event.data,
        this.$route.params.hash,
        event.contract_id,
        event.sign_identity_id,
        event.progress
      ).then(response => {
        if (response.code === 400) {
          this.$notification.error(response.title) // TODO: i18n - displaying backend error message
          this.loaded = true

          this.$router.push({ name: 'contractReceived', params: { hash: this.$route.params.hash } })
          return
        }

        if (response.code > 400) {
          this.$notification.error(this.$t('contract.errors.signature_exists'));
          this.loaded = true
          return
        }

        if (response.state === 'completed') {
          window.dataLayer.push({ event: 'contract_proposal_sign_completed' })
        }

        if (event.fromSMS) {
          this.$notification.success(this.$t('contract.completed.sms_sign'));
          this.$router.push({ name: 'success-sign-sms', params: {} })
        } else {
          this.checkUnsignedDocuments(response)
        }

        this.loaded = true
      }).catch((err) => {
        if (err === 'Sign image content is empty') {
          this.$notification.error(this.$t('contract.errors.signature_empty'));
          this.loaded = true
        }
      })
    },
    checkUnsignedDocuments(responseContract = null) {
      let contract = this.contract
      if (responseContract) contract = responseContract

      const unsignedAttachment = ContractHelper.getNextUnsigned(contract)
      if (unsignedAttachment) {
        this.$router.push({
          name: 'contractView',
          params: {
            hash: this.$route.params.hash
          },
          query: {
            contract_id: unsignedAttachment.id
          }
        })
      } else {
        this.$router.push({
          name: 'contractComplete',
          params: {
            hash: this.$route.params.hash,
            email: this.signIdentity.email
          }
        })
      }
    }
  },
};
</script>

<style lang="scss">
  .contract-landscape {
    @include md {
      padding: 0;
      background: white !important;
    }
  }
  .contract-sign-phone-container {
    background: #ffffff;
    border-radius: 6px;
    overflow: hidden;
    height: 100% !important;
  }
</style>
