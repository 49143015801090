<template>
  <div class="error-message">
    <p class="error-message__text">
      <slot></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage'
}
</script>

<style lang="scss">
.error-message {
  &__text {
    color: #CD0000;
    font-size: 1rem;
    font-weight: 300;
  }
}
</style>
