<template lang="html">
  <svg xmlns="http://www.w3.org/2000/svg" width="1200" height="600" viewBox="0 0 6 3">
    <rect fill="#436F4D" width="6" height="3" />
    <rect fill="#FFF" width="6" height="2" />
    <rect fill="#CD2A3E" width="6" height="1" />
  </svg>
</template>

<script>
export default {
  name: 'IcoFlagHU',
};
</script>
