var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('article',_vm._g(_vm._b({},'article',attrs,false),on),[_c('v-avatar',{attrs:{"size":35}},[_c('v-img',{attrs:{"src":_vm.accountAvatar,"alt":_vm.accountName,"contain":""}})],1),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(("mdi-chevron-" + (_vm.show ? 'up' : 'down')))+" ")])],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"width":"20rem"}},[_c('v-list',{staticClass:"pb-0"},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({ name: 'account' })}}},[_c('v-list-item-avatar',{staticClass:"px-2 mr-3",attrs:{"size":"55"}},[_c('img',{attrs:{"src":_vm.accountAvatar,"alt":_vm.accountName}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title font-weight-medium",staticStyle:{"color":"#606771"},domProps:{"textContent":_vm._s(_vm.accountName)}}),_c('v-list-item-subtitle',{staticClass:"subtitle-2 font-weight-regular",domProps:{"textContent":_vm._s(_vm.accountCompanyName)}})],1)],1)],1),_c('v-list',{staticClass:"pt-0"},[_vm._l((_vm.menuItems),function(menuItem){return _c('v-hover',{key:menuItem.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"user-menu__item px-0",attrs:{"link":""},on:{"click":menuItem.callback}},[_c('v-list-item-content',{staticClass:"font-weight-normal black--text"},[_c('v-list-item-title',[_vm._v(_vm._s(menuItem.title))])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":hover ? 'primary' : '#b3c2d6'}},[_vm._v(" mdi-chevron-right ")])],1)],1)]}}],null,true)})}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"logout-button",attrs:{"link":""},on:{"click":_vm.onLogout}},[_c('v-list-item-content',{staticClass:"black--text"},[_vm._v(" "+_vm._s(_vm.$t('logout'))+" ")]),_c('v-icon',{attrs:{"color":hover ? 'error' : '#b3c2d6'}},[_vm._v("mdi-power")])],1)]}}])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }