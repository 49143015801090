import { render, staticRenderFns } from "./IcoDownload.vue?vue&type=template&id=78c19c42&scoped=true&"
import script from "./IcoDownload.vue?vue&type=script&lang=js&"
export * from "./IcoDownload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78c19c42",
  null
  
)

export default component.exports