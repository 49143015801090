
<template lang="html">
    <svg width="48px" height="49px" viewBox="0 0 48 49" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="SYN001---DSKTP---Dashboard---no-workspace" transform="translate(-404.000000, -146.000000)">
                <rect x="0" y="0" width="1200" height="887"></rect>
                <g id="upozorneni" transform="translate(370.000000, 111.000000)">
                    <g id="ico/ico-team" transform="translate(24.000000, 27.000000)">
                        <g id="Group-10" transform="translate(11.289474, 9.552632)">
                            <path d="M6.36534155,3.34735531 C6.36534155,5.1434137 6.36534155,5.83262543 6.36534155,7.51799914 C6.36534155,9.20337286 9.13416979,10.6887725 10.3014936,10.6887725 C11.4688175,10.6887725 14.4088076,9.47609075 14.4088076,7.51799914 C14.4088076,5.55990754 14.4088076,5.24211703 14.4088076,3.34735531 C14.4088076,1.4525936 12.1985375,0 10.3870746,0 C8.57561161,0 6.36534155,1.55129693 6.36534155,3.34735531 Z" id="Path-46" stroke="#6DECAF" stroke-width="2" fill-rule="nonzero"></path>
                            <path d="M19.0960246,3.34735531 C19.0960246,5.1434137 19.0960246,5.83262543 19.0960246,7.51799914 C19.0960246,9.20337286 21.8648529,10.6887725 23.0321767,10.6887725 C24.1995006,10.6887725 27.1394907,9.47609075 27.1394907,7.51799914 C27.1394907,5.55990754 27.1394907,5.24211703 27.1394907,3.34735531 C27.1394907,1.4525936 24.9292206,0 23.1177577,0 C21.3062947,0 19.0960246,1.55129693 19.0960246,3.34735531 Z" id="Path-46-Copy" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                            <path d="M31.3370661,3.34735531 C31.3370661,5.1434137 31.3370661,5.83262543 31.3370661,7.51799914 C31.3370661,9.20337286 34.1058943,10.6887725 35.2732182,10.6887725 C36.440542,10.6887725 39.3805321,9.47609075 39.3805321,7.51799914 C39.3805321,5.55990754 39.3805321,5.24211703 39.3805321,3.34735531 C39.3805321,1.4525936 37.170262,0 35.3587991,0 C33.5473361,0 31.3370661,1.55129693 31.3370661,3.34735531 Z" id="Path-46-Copy-2" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                            <path d="M4.23194392,46.4656406 L4.23194392,21.0772029" id="Path-47" stroke="#6DECAF" stroke-width="2" fill-rule="nonzero"></path>
                            <path d="M10.6816078,31.3874224 L10.6816078,46.5189861" id="Path-48" stroke="#6DECAF" stroke-width="2" fill-rule="nonzero"></path>
                            <path d="M16.9187458,46.4488097 L16.9187458,20.8920628" id="Path-49" stroke="#6DECAF" stroke-width="2" fill-rule="nonzero"></path>
                            <path d="M17.1013184,32.0512451 C19.6719793,31.4378285 20.9573097,30.1388786 20.9573097,28.1543953 C20.9573097,25.1776704 20.9573097,19.4745574 20.9573097,18.1922699 C20.9573097,16.9099823 16.0372622,13.4000232 10.4742167,13.4000232 C4.91117121,13.4000232 0.3017825,17.0295103 0.3017825,18.1922699 C0.3017825,19.3550294 0.3017825,25.6177116 0.3017825,28.1543953 C0.3017825,29.8455177 1.52368795,31.1444677 3.96749886,32.0512451" id="Path-50" stroke="#6DECAF" stroke-width="2" fill-rule="nonzero"></path>
                            <path d="M21.0545913,13.2203247 C24.8631327,13.229073 27.5937348,13.69121 29.2463976,14.6067358 C31.7253917,15.9800244 32.3609728,17.5992155 32.3609728,19.6873901 C32.3609728,21.7755647 32.3609728,26.5184597 32.3609728,28.8582422 C32.3609728,30.4180972 31.0604279,31.4656078 28.4593383,32.0007738" id="Path-51" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                            <path d="M34.274916,13.2203247 C38.0834574,13.229073 40.8140595,13.69121 42.4667223,14.6067358 C44.9457165,15.9800244 45.5812975,17.5992155 45.5812975,19.6873901 C45.5812975,21.7755647 45.5812975,26.5184597 45.5812975,28.8582422 C45.5812975,30.4180972 44.2807527,31.4656078 41.6796631,32.0007738" id="Path-51-Copy" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                            <path d="M27.9095745,21.0545913 L27.9095745,46.4729823" id="Path-52" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                            <path d="M41.1298992,21.0545913 L41.1298992,46.4729823" id="Path-52-Copy" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>


<script>
    export default {
        name: 'IcoPeople'
    }
</script>
