<template>
  <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ico-N/ico-50-hotove</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-50-hotove" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="ico/ico-dokumenty-copy-3" transform="translate(10.000000, 1.000000)" fill-rule="nonzero" stroke="#B4C1D6" stroke-width="2">
        <path d="M1.98689727e-12,10.8424012 C1.94240406e-12,31.5545699 1.96465066e-12,42.3531586 2.05363709e-12,43.2381673 C2.18708629e-12,44.5656804 1.01363147,45 2.19443808,45 C3.37524468,45 27.0342286,45 28.3256035,45 C29.6169785,45 30,44.1218559 30,42.6631229 C30,41.2043898 30,3.0954183 30,1.94770563 C30,0.799992959 29.3069639,-1.23910146e-14 27.9404659,0 C27.0294673,8.26067643e-15 21.2834394,8.26067643e-15 10.7023824,0 L1.98689727e-12,10.8424012 Z" id="Path-10" fill="#FFFFFF" transform="translate(15.000000, 22.500000) scale(-1, 1) translate(-15.000000, -22.500000) "></path>
        <path d="M18,12 C24.3805337,12 28.06687,12 29.0590087,12 C30.5472168,12 30.4998301,10.3101096 30.4998301,8.09949706 C30.4998301,6.62575539 30.4998301,3.92592304 30.4998301,0" id="Path-16" transform="translate(24.250000, 6.000000) scale(-1, 1) translate(-24.250000, -6.000000) "></path>
        <g id="ico/ico-9-uzavrene-gray" transform="translate(2.000000, 13.000000)">
          <g id="ico/ico-9-uzavrene-gray-copy-3">
            <polyline id="Path-42" points="5.82844682 11.3866715 10.8264831 16.3733429 20.799826 6.4"></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IcoDocsCompleted",
};
</script>
