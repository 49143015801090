<template>
  <v-dialog v-model="showWorkspaceMemberSettingsDialog" max-width="45rem">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        id="workspaceMemberSettingsDialogActivator"
        color="primary"
        icon
        small
        :disabled="isOwner"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>mdi-pencil-outline</v-icon>
      </v-btn>
    </template>

    <v-card class="pa-5" elevation="0">
      <v-card-title>
        <h2 class="headline font-weight-bold">{{ $t('general.settings') }}</h2>
      </v-card-title>

      <v-card-text>
        <v-text-field
          type="text"
          name="email"
          v-model="memberSettings.email"
          :label="$t('general.email_address')"
          :error="$v.memberSettings.email.$error"
          outlined
        ></v-text-field>

        <v-text-field
          type="text"
          name="position"
          v-model="memberSettings.position"
          :label="$t('workspace.position')"
          list="positions"
          outlined
        ></v-text-field>

        <datalist id="positions">
          <option
            v-for="(jobPosition, index) in membersJobPositions"
            :key="index"
            :value="jobPosition.name"
          />
        </datalist>

        <v-row class="additional-config pa-0 pa-md-4" align="center" justify="start" no-gutters>
          <v-col class="col-12 col-md-6">
            <v-checkbox
              v-model="memberSettings.all_contracts"
              color="primary"
              :label="$t('workspace.team.role.every_doc')"
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="showAllContacts"
              color="primary"
              :label="$t('workspace.team.role.only_their_contacts')"
            ></v-checkbox>
          </v-col>

          <v-col class="col-12 col-md-6">
            <v-radio-group v-model="memberSettings.rights" class="pa-0 pb-3" hide-details>
              <v-radio
                :label="$t('workspace.team.role.signer')"
                value="signer"
                name="workspace-person"
              ></v-radio>

              <v-radio
                :label="$t('workspace.team.role.assistant')"
                value="assistant"
                name="workspace-person"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-row align="center" justify="space-between">
          <v-col class="col-12 col-md-auto">
            <v-btn
              color="error"
              :block="$vuetify.breakpoint.mobile"
              :min-width="$vuetify.breakpoint.mobile ? '' : '12rem'"
              @click="onCloseDialog"
            >
              {{ $t('general.cancel') }}
            </v-btn>
          </v-col>

          <v-col class="col-12 col-md-auto">
            <v-btn
              color="primary"
              :block="$vuetify.breakpoint.mobile"
              :loading="isSubmitted"
              :min-width="$vuetify.breakpoint.mobile ? '' : '12rem'"
              @click="onConfirmationAction"
            >
              {{ $t('general.save_changes') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { email, required } from 'vuelidate/lib/validators';

export default {
  name: 'WorkspaceMemberSettingsDialog',
  props: {
    value: {
      type: Object,
      required: true,
    },
    defaultConfig: {
      type: Object,
      required: true,
    },
    isSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showWorkspaceMemberSettingsDialog: false,
    };
  },
  validations: {
    memberSettings: {
      email: {
        required,
        email,
        minLength: 5,
      },
    },
  },
  computed: {
    ...mapGetters({
      membersJobPositions: 'workspace/membersJobPositions',
    }),
    memberSettings: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    showAllContacts: {
      get() {
        return !this.memberSettings.all_contacts;
      },
      set(value) {
        this.memberSettings.all_contacts = !value;
      },
    },
    isOwner() {
      return 'owner' === this.memberSettings.rights;
    },
  },
  methods: {
    onConfirmationAction() {
      this.$v.memberSettings.$touch();

      if (this.$v.memberSettings.$error || this.isSubmitted) {
        return;
      }

      this.showWorkspaceMemberSettingsDialog = false;
      this.$emit('update-settings');
    },
    onCloseDialog() {
      this.showWorkspaceMemberSettingsDialog = false;
      this.memberSettings = Object.assign({}, this.defaultConfig);
    },
  },
};
</script>

<style lang="scss" scoped>
.additional-config {
  border: solid 1px #d4dff0;
  border-radius: 6px;

  @media only screen and (max-width: 960px) {
    border: none;
  }
}
</style>
