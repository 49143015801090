import environment from '@/config/environment';

function loadSomeLocales() {
  const languages = ['cs', 'en', 'de', 'sk', 'pl', 'hu', 'nl', 'ru', 'vi', 'ua', 'bg'];
  const translationsPrefix = environment.getAppTranslationsPrefix();
  const messages = {};

  for (let language of languages) {
    messages[language] = require(`./locales/${language}_${translationsPrefix}.json`);
  }

  for (let language of languages) {
    messages[language] = Object.assign(
      messages[language],
      require(`./locales/errors/${language}.json`),
    );
  }

  return messages;
}

export const Messages = {
  loadDefaultLocales: loadSomeLocales,
  loadLocale: () => loadSomeLocales(),
};
