<template lang="html">
  <div class="logo">
    <img :src="source" class="custom-logo" :class="{ 'larger': larger, 'smaller': smaller }" alt="" />
  </div>
</template>

<script>
export default {
  name: 'Logo',
  data() {
    return {
      defaultLogo: '/images/logo-signi_com.png'
    }
  },
  props: {
    logo: {
      type: String,
      default: null
    },
    smaller: {
      type: Boolean,
      default: false
    },
    larger: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    source () {
      return !this.logo ? this.defaultLogo : this.logo
    }
  }
}
</script>

<style lang="scss">
.logo {
  margin-bottom: 40px;
}

.custom-logo {
  max-height: 31px;

  &.smaller {
    max-height: 22px;
  }

  &.larger {
    max-height: 62px;
  }
}
</style>
