<template>
  <article ref="pageSignature">
    <v-card v-if="isSignable" v-bind="attrs" elevation="0">
      <SignButton
        :identifier="identifier"
        :is-one-device-signing="isOneDeviceSigning"
        :sign-identity="signIdentity"
        :sign="sign"
      />
    </v-card>

    <v-card v-else v-bind="attrs" elevation="0">
      <div
        v-if="isSignatureHeaderVisible"
        class="page-signature__header d-flex align-center px-2 py-0 text-center"
      >
        <span class="d-inline-block text-truncate font-weight-medium">
          {{ signatureHeaderContent }}
        </span>
      </div>

      <v-card-text
        v-if="!isSigned"
        class="page-signature__content justify-center fill-height pa-0"
        :class="{
          'page-signature__content--no-header': !isSignatureHeaderVisible,
        }"
      >
        <SignByOtherPreview :participant-name="participantName" />
      </v-card-text>
    </v-card>
  </article>
</template>

<script>
import { mapGetters } from 'vuex';
import { i18n } from '@/plugins/i18n';
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions';
import SignButton from '@signing/components/SignButton';
import SignByOtherPreview from '@signing/components/SignByOtherPreview';

export default {
  name: 'PageSignature',
  components: {
    SignByOtherPreview,
    SignButton,
  },
  props: {
    identifier: {
      type: Number,
      required: true,
    },
    position: {
      type: Object,
      required: true,
    },
    signIdentity: {
      type: Object,
      required: true,
    },
    sign: {
      type: Function,
      default: undefined,
    },
  },
  data() {
    return {
      resizeObserver: null,
      signatureRatio: 0.54989126,
      pageRation: 1.41284404,
    };
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
    }),
    attrs() {
      return {
        class: [
          'page-signature',
          'ma-0',
          'pa-0',
          !this.isSigned && !this.isSignable ? 'page-signature--border' : '',
        ],
        style: [
          `background: ${this.isSigned || this.isSignable ? 'transparent' : '#ffffff'} !important`,
          `top: ${this.position?.offset_top}%`,
          `left: ${this.position?.offset_left}%`,
          `width: ${this.position?.relative_width || 45}%`,
          `height: ${(this.position?.relative_width * this.signatureRatio) / this.pageRation}%`,
        ].join('; '),
      };
    },
    isSigned() {
      return this.signIdentity?.is_signed || this.signIdentity?.is_signed_with_certificate || false;
    },
    isSignable() {
      return (!this.isSigned && this.signIdentity?.is_signable_by_current_user) || false;
    },
    isSignatureHeaderVisible() {
      return (
        this.position?.show_header && 'stamp' !== this.signIdentity.contract_role && !this.isSigned
      );
    },
    isSignatureFooterVisible() {
      return (
        this.position?.show_footer && 'stamp' !== this.signIdentity.contract_role && !this.isSigned
      );
    },
    isOneDeviceSigning() {
      return this.contract?.one_device || false;
    },
    participantName() {
      if (this.signIdentity?.variable_position) {
        return `${this.signIdentity.variable_position}`;
      }

      return `${this.signIdentity.firstname} ${this.signIdentity.lastname}`;
    },
    signatureDate() {
      return getFormattedDateByLocale({
        date: this.signIdentity?.signature_date,
        locale: this.contract?.locale || i18n.locale,
      });
    },
    signaturePlace() {
      return this.signIdentity?.signature_place;
    },
    signatureHeaderContent() {
      if (!this.isSigned) {
        return this.$t('docs.pending.generic');
      }

      return this.signIdentity?.signature_date
        ? this.$t('signature.header.place_day', this.contract.locale, {
            place: this.signaturePlace,
            day: this.signatureDate,
          })
        : this.$t('signature.header.placeholder');
    },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.onResizePage);
    this.resizeObserver?.observe(this.$refs.pageSignature?.parentElement);
  },
  beforeDestroy() {
    if (this.$refs.pageSignature?.parentElement) {
      this.resizeObserver?.unobserve(this.$refs.pageSignature.parentElement);
    }
  },
  methods: {
    onResizePage() {
      const currentPage = this.$refs.pageSignature?.parentElement || undefined;

      if (currentPage) {
        const width = currentPage.getBoundingClientRect()?.width;
        const height = currentPage.getBoundingClientRect()?.height;

        this.pageRation = height / width;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-signature {
  position: absolute;
  overflow: hidden;

  &--border {
    border: 1px solid #b5c1d7;
    border-radius: 8px;
  }

  &__header {
    border-bottom: 1px solid #b5c1d7;
    background: #f1f6fc;
    color: #2d2e2e;
    font-size: clamp(0.2rem, 0.8vw, 0.8rem);
    width: 100%;
    height: 100%;
    max-height: 20%;

    span {
      width: 100%;
      height: fit-content;
    }

    &--signed {
      border-bottom: unset !important;
      background: transparent;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    max-height: 80%;

    &--no-header {
      max-height: 100%;
    }
  }
}
</style>
