import { i18n } from '@/plugins/i18n';
import { getErrorMessage } from '@/common/variables/errors';
import VueI18n from 'vue-i18n';

const messages = {};

for (let language of ['cs', 'en', 'de', 'sk', 'pl', 'hu', 'nl', 'ru', 'vi', 'ua', 'bg']) {
  messages[language] = require(`@/plugins/i18n/locales/errors/${language}.json`);
}

const errorsI18n = new VueI18n({
  locale: i18n.locale,
  fallbackLocale: 'en',
  messages,
  pluralizationRules: {
    cs: (count) => (1 === count ? 0 : count < 5 ? 1 : 2),
  },
  escapeParameterHtml: true,
});

export const getErrorResponseMessage = (data, locale = null) => {
  if (!data?.translationKey) {
    if (data?.errorCode) {
      return getErrorMessage({
        errorCode: data.errorCode,
      });
    }

    return data?.message || i18n.t('general.error');
  }

  return errorsI18n.t(
    data?.translationKey || 'general.error',
    null === locale ? i18n.locale : locale,
    data?.values ? data?.values : {},
  );
};
