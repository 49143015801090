<template>
  <v-col cols="12">
    <v-text-field
      v-model="subjectValue"
      :label="$t(`workspace.settings.subjects.${subject.key}`)"
      :rules="[() => !!subjectValue || $t('general.fill_required2')]"
      hide-details
      @blur="updateSubject"
      @keypress.enter="updateSubject"
    ></v-text-field>
  </v-col>
</template>

<script>
import { i18n } from '@/plugins/i18n';
import { manageSubjects } from '@workspace/services/workspaceService';

export default {
  name: 'SubjectsCustomizingItem',
  data() {
    return {
      subjectValue: this.subject.value,
    };
  },
  props: {
    subject: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      default: i18n.locale,
    },
  },
  watch: {
    'subject.value': {
      handler: function () {
        this.subjectValue = this.subject.value;
      },
    },
  },
  methods: {
    updateSubject() {
      if (this.subject.value === this.subjectValue) {
        return;
      }

      if ('' === this.subjectValue) {
        this.subjectValue = this.subject.value;
        return this.$notification.error(this.$t('workspace.settings.error_subjects'));
      }

      manageSubjects(this.$route.params.workspace_id, this.locale, {
        [this.subject.key]: this.subjectValue,
      }).then(
        (resp) => {
          const currentSubject = resp.mail_subjects.find((s) => {
            return s.key === this.subject.key;
          });

          this.subjectValue = currentSubject.value;
          this.$emit('reload-subjects');
          this.$notification.success(this.$t('workspace.settings.updated_subjects'));
        },
        () => {
          this.subjectValue = this.subject.value;
          this.$notification.error(this.$t('general.error'));
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
