import * as types from './mutation_types';

export default {
  [types.SET_USER_PASSWORD](state, password) {
    state.userPassword = password;
  },
  [types.SET_REMAINING_TIME](state, seconds) {
    state.remainingTime = seconds;
  },
  [types.RESET_USER_PASSWORD](state) {
    state.userPassword = null;
  },
};
