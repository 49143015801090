<template>
  <svg width="27px" height="28px" viewBox="0 0 27 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="SYN001---DSKTP---Nová-smlouva---výběr-zdroje" transform="translate(-473.000000, -268.000000)">
        <rect x="0" y="0" width="1200" height="893"></rect>
        <g id="karty-3" transform="translate(433.000000, 234.000000)">
          <g id="ico/ico-upload-purple" transform="translate(33.000000, 26.000000)">
            <rect id="Rectangle-2" fill-rule="nonzero" x="0" y="0" width="41" height="41"></rect>
            <g id="Group-2" stroke-width="1" fill-rule="evenodd" transform="translate(8.200000, 10.086119)" stroke="#5E239E">
              <g id="Group" transform="translate(0.000000, 2.106295)" fill-rule="nonzero" stroke-width="2">
                <path d="M12.3,15.9873856 L12.3,1.14575016e-13" id="Path-5"></path>
                <path d="M-1.11910481e-13,13.7742532 C-2.45302194e-13,15.5473053 -1.61269132e-13,17.0302558 1.40188706e-13,18.2231048 C5.92589729e-13,20.0123784 1.25660652,21.9165365 3.78263902,21.9165365 C6.30867151,21.9165365 18.8663964,21.9165365 21.5512047,21.9165365 C23.3410769,21.9165365 24.3573395,20.6853926 24.5999925,18.2231048 L24.6001203,13.7742532" id="Path-4"></path>
              </g>
              <polyline id="Path-6" stroke-width="2" fill-rule="nonzero" points="5.19333333 7.12447718 12.3178105 0 19.4422877 7.12447718"></polyline>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoUpload'
}
</script>

<style lang="scss" scoped></style>
