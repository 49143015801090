<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
    <title>ico-N/ico-24-download.gray</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-24-download.gray" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M3,15 C3,16.2973551 3,17.3824409 3,18.2552573 C3,19.5644818 3.91946819,20.9577682 5.76778465,20.9577682 C7.61610111,20.9577682 16.8046803,20.9577682 18.7691742,20.9577682 C20.0788367,20.9577682 20.8224435,20.0569313 20.9999945,18.2552573 L21.000088,15" id="Path-4" stroke="#B4C1D6" stroke-width="2" fill-rule="nonzero"></path>
      <g id="Group" transform="translate(12.000000, 10.000000) scale(1, -1) translate(-12.000000, -10.000000) translate(6.000000, 3.000000)" fill-rule="nonzero" stroke="#B4C1D6" stroke-width="2">
        <path d="M6,13.6193651 L6,1.92127812" id="Path-5"></path>
        <polyline id="Path-6" points="0.8 5.593119 6.01303208 0.38008692 11.2260642 5.593119"></polyline>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IcoDownload"
}
</script>

<style lang="scss" scoped></style>
