import * as types from './mutation_types';
import { authorizedApiRequest } from '@/services/ApiService';
import { fetchNews } from '@/services/NewsService';
import { i18n } from '@/plugins/i18n';

export const fetchAppNotifications = (context) => {
  authorizedApiRequest({
    method: 'GET',
    endpoint: `/api/public/v1/notifications/${i18n.locale}`,
  }).then((resp) => {
    context.commit(types.SET_APP_NOTIFICATIONS, resp);
  });
};

export const fetchNewsMsg = ({ state, commit }) => {
  if (state.messages.length !== 0) {
    return;
  }

  return fetchNews().then((messages) => {
    commit(types.SET_APP_NEWS, messages);
  });
};
