<template lang="html">
    <svg width="47px" height="53px" viewBox="0 0 47 53" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="ico/ico-uvod-bezpecnost" transform="translate(-15.000000, -10.000000)" fill-rule="nonzero" stroke-width="2">
                <g>
                    <path d="M46.0295249,16.0621946 C43.5072259,15.2289912 41.6084401,14.5473979 40.3331676,14.0174145 C39.0578951,13.4874311 37.5749406,12.6407985 35.8843042,11.4775165 C32.9202354,13.3077028 29.6120456,14.8359289 25.9597345,16.0621946 C22.3074235,17.2884604 19.0113265,17.8141828 16.0714435,17.6393619 C16.0714435,27.7143038 16.0714435,36.0093586 16.0714435,42.5245263 C16.0714435,49.0396939 22.6757304,55.4197368 35.8843042,61.664655 C49.3896792,55.9223798 56.1423667,49.5423369 56.1423667,42.5245263 C56.1423667,35.5067156 56.1423667,31.0061946 56.1423667,29.0229632" id="Path-8" stroke="#D4DFF0"></path>
                    <path d="M42.1576221,17.5156556 L50.2075958,17.5156556 L50.2075958,37.9519015 L46.3905326,47.8730049 L42.1576221,37.970943 L42.1576221,17.5156556 Z M42.2134007,15.3854041 C42.2134007,14.1520836 42.2134007,14.1520836 42.2134007,13.267881 C42.2134007,11.7653846 43.1153384,10.1503579 44.6491979,10.1503579 C46.1214118,10.1503579 46.1214118,10.1503579 47.7086258,10.1503579 C49.3709785,10.1503579 50.1652934,12.0726501 50.1652934,13.7167662 C50.1652934,14.4371425 50.1652934,14.4371425 50.1652934,15.3854041 L50.1652934,16.3854041 L42.2134007,16.3854041 L42.2134007,15.3854041 Z" id="Combined-Shape" stroke="#6DECAF" transform="translate(46.182609, 28.182609) rotate(-315.000000) translate(-46.182609, -28.182609) "></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'IcoDashboardSecurity'
    }
</script>
