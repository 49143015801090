<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ico-N/ico-24-stitky</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-24-stitky" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect id="Rectangle-16" stroke="#B4C1D6" stroke-width="2" fill="#FFFFFF" x="3" y="6" width="15" height="10" rx="3"></rect>
      <rect id="Rectangle-16-Copy" stroke="#B4C1D6" stroke-width="2" fill="#FFFFFF" x="6" y="9" width="15" height="10" rx="3"></rect>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IcoGroups",
};
</script>
