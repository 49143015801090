<template>
  <v-card class="pa-5" elevation="0">
    <v-card-title>
      <h2 class="headline font-weight-bold">{{ $t('workspace.invitation.send2') }}</h2>
    </v-card-title>

    <v-card-subtitle>
      {{ $t('workspace.team.placeholder.email') }}
    </v-card-subtitle>

    <v-card-text>
      <v-text-field
        type="text"
        name="email"
        v-model="invitation.email"
        :label="$t('general.email_address')"
        :error="$v.invitation.email.$error"
        outlined
      ></v-text-field>

      <v-text-field
        type="text"
        name="position"
        v-model="invitation.position"
        :label="$t('workspace.position')"
        list="positions"
        outlined
      ></v-text-field>

      <datalist id="positions">
        <option
          v-for="(jobPosition, index) in membersJobPositions"
          :key="index"
          :value="jobPosition.name"
        />
      </datalist>

      <v-row class="additional-config pa-0 pa-md-4" align="center" justify="start" no-gutters>
        <v-col class="col-12 col-md-6">
          <v-checkbox
            v-model="invitation.all_contracts"
            color="primary"
            :label="$t('workspace.team.role.every_doc')"
            hide-details
          ></v-checkbox>

          <v-checkbox
            v-model="showAllContacts"
            color="primary"
            :label="$t('workspace.team.role.only_their_contacts')"
          ></v-checkbox>
        </v-col>

        <v-col class="col-12 col-md-6">
          <v-radio-group v-model="invitation.rights" class="pa-0 pb-3" hide-details>
            <v-radio
              :label="$t('workspace.team.role.signer')"
              value="signer"
              name="workspace-person"
            ></v-radio>

            <v-radio
              :label="$t('workspace.team.role.assistant')"
              value="assistant"
              name="workspace-person"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-row align="center" justify="space-between">
        <v-col class="col-12 col-md-auto">
          <v-btn
            color="error"
            :block="$vuetify.breakpoint.mobile"
            :min-width="$vuetify.breakpoint.mobile ? '' : '12rem'"
            @click="$emit('discard-changes')"
          >
            {{ $t('general.cancel') }}
          </v-btn>
        </v-col>

        <v-col class="col-12 col-md-auto">
          <v-btn
            color="primary"
            :block="$vuetify.breakpoint.mobile"
            :loading="isSubmitted"
            :min-width="$vuetify.breakpoint.mobile ? '' : '12rem'"
            @click="onConfirmationAction"
          >
            {{ $t('workspace.invitation.send') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { email, required } from 'vuelidate/lib/validators';

export default {
  name: 'InvitationForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
    isSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    invitation: {
      email: {
        required,
        email,
        minLength: 5,
      },
    },
  },
  computed: {
    ...mapGetters({
      membersJobPositions: 'workspace/membersJobPositions',
    }),
    invitation: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    showAllContacts: {
      get() {
        return !this.invitation.all_contacts;
      },
      set(value) {
        this.invitation.all_contacts = !value;
      },
    },
  },
  created() {
    this.fetchWorkspaceMembersPositions(this.$route.params.workspace_id);
  },
  methods: {
    ...mapActions({
      fetchWorkspaceMembersPositions: 'workspace/fetchWorkspaceMembersPositions',
    }),
    onConfirmationAction() {
      this.$v.invitation.$touch();

      if (this.$v.invitation.$error || this.isSubmitted) {
        return;
      }

      this.$emit('success-changes');
      this.$v.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.additional-config {
  border: solid 1px #d4dff0;
  border-radius: 6px;

  @media only screen and (max-width: 960px) {
    border: none;
  }
}
</style>
