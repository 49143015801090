import { authHeader } from '@/config/authHeader';
import { autologoutIfUnauthorized } from './helpers';
import { hostname } from '@/services/helpers/variables.js';
import router from '@/router'


export const TemplateService = {
    getWorkspaceTemplates,
    getPDF,
    detail,
};

function getPDF( template_id ) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };

    return fetch(`${hostname}/api/v1/templates/${template_id}/pdf`, requestOptions)
        .then(handleResponseRaw);
}


function getWorkspaceTemplates(workspaceId, areOnlyFavouritesLoaded) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };
    const typeFilter = areOnlyFavouritesLoaded ? '&type=favourite' : '';
    return fetch(`${hostname}/api/v1/templates?workspaceId=${workspaceId}${typeFilter}`, requestOptions)
        .then(handleResponse);
}

function detail( workspace_id, template_id ) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };

    return fetch(`${hostname}/api/v1/workspaces/${workspace_id}/templates/${template_id}`, requestOptions)
        .then(handleResponse);
}

function handleResponseRaw( response ) {
    return response;
}



function handleResponse( response ) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 404) {
                router.push({name: 'notFound'});
                return;
            }

            autologoutIfUnauthorized(response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
