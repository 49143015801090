<template>
  <div
    class="contract-tabs"
    :class="{
      'detail-view': $route.name === 'dataContractDetail',
      'small-width': smallWidth,
    }"
  >
    <v-btn
      v-if="(isMobile && hasAttachments)"
      class="contract-tab contract-tab-menu"
      :class="{'open': openMenu && showMenu}"
      icon
      min-height="60px"
      @click="openMenu = !openMenu"
    >
      <v-icon v-if="openMenu">mdi-chevron-up</v-icon>
      <v-icon v-else>mdi-chevron-down</v-icon>
    </v-btn>

    <div v-if="openMenu" class="contract-menu">
      <a
        v-for="contract in inactiveContract"
        :key="contract.id"
        class="contract-menu__item"
        :class="{ 'active': selectedContractId === contract.id, 'hidden': isMobile && childVisible[contract.id] }"
       >
        <span class="contract-tab__title" @click="contractChange($event, contract)">
          {{ contract.title }}
        </span>

        <a
          v-if="selectedContractId === contract.id && isCreateContentPage && canDeleteDocument(contract)"
          href="#"
          class="contract-tab__delete"
          :title="$t('contract.remove_document')"
          @click.prevent="showDeleteContractDocumentDialog = true"
        >
          <IcoClose />
        </a>

        <span v-if="isUnsigned(contract)" class="contract-tab__signed" :title="$t('signing.not_signed_yet')">
          <IcoUnsignedAlert />
        </span>
      </a>
    </div>

    <div
      ref="scroller"
      class="contract-tabs__scroller"
      :class="{
        'contract-tabs__scroller--with-menu': showMenu
      }"
    >
      <a
        v-for="contract in activeContracts"
        :key="contract.id"
        class="contract-tab"
        :class="{'active': selectedContractId === contract.id}"
        :data-id="contract.id"
      >
        <span class="contract-tab__title" @click="(event) => contractChange(event, contract)">
          {{contract.title}}
        </span>

        <a
          v-if="selectedContractId === contract.id && isCreateContentPage && canDeleteDocument(contract)"
          href="#"
          class="contract-tab__delete"
          :title="$t('contract.remove_document')"
          @click.prevent="showDeleteContractDocumentDialog = true"
        >
          <IcoClose />
        </a>

        <span v-if="isUnsigned(contract)" class="contract-tab__signed" :title="$t('signing.not_signed_yet')">
          <IcoUnsignedAlert />
        </span>
      </a>
    </div>

    <v-btn
      v-if="contracts.length > 0"
      class="contract-tab contract-tab--add-attachment"
      :class="{ 'hidden': hideAddAttachment }"
      text
      min-height="60px"
      @click="addAttachment"
    >
      <v-icon>mdi-plus</v-icon>
      <span style="text-decoration: underline">
        {{ $t('contract.attach_document') }}
      </span>
    </v-btn>
    <SConfirmDialog
      v-model="showDeleteContractDocumentDialog"
      :message="`${$t('docs.delete.confirm')}`"
      @confirmation-callback="deleteContractDocument(contract.id)"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { ContractHelper } from '@/helpers/ContractHelper'
import { ContractService } from '@/services/ContractService.js'
import IcoUnsignedAlert from '@/components/svg/IcoUnsignedAlert'
import IcoClose from '@/components/svg/IcoClose'
import SConfirmDialog from '@/common/components/SConfirmDialog';

export default {
  name: 'ContractTabs',
  components: {
    IcoUnsignedAlert,
    IcoClose,
    SConfirmDialog,
  },
  data () {
    return {
      showDeleteContractDocumentDialog: false,
      showMenu: false,
      openMenu: false,
      childVisible: []
    }
  },
  props: {
    mainContractId: {
      required: true
    },
    contracts: {
      required: false,
      default: null
    },
    selectedContractId: {
      required: false,
      default: null
    },
    redirectParameters: {
      required: false,
      default: () => {}
    },
    workspace_id: {
      required: true
    },
    smallWidth: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.mobile
    },
    hasAttachments () {
      return this.contracts && this.contracts.length > 1
    },
    activeContracts () {
      return this.isMobile
        ? this.contracts.filter((contract) => (contract.id === this.$route.params.contract_id))
        : this.contracts
    },
    inactiveContract () {
      return this.isMobile
        ? this.contracts.filter((contract) => (contract.id !== this.$route.params.contract_id))
        : this.contracts
    },
    hideAddAttachment () {
      return this.$route.name === 'createOwn'
    },
    counterPartySigning () {
      return this.$route.name === 'documentsDetailCounterparty'
    },
    isCreateContentPage () {
      return this.$route.name === 'createContent'
    }
  },
  mounted () {
    this.$refs.scroller.addEventListener('scroll', this.toggleMenuHandler)
    this.toggleMenuHandler()
  },
  beforeDestroy () {
    this.$refs.scroller.removeEventListener('scroll', this.toggleMenuHandler)
  },
  methods: {
    addAttachment () {
      this.$emit('addAttachment')
    },
    contractChange (event, contract) {
      if (event) {
        event.preventDefault()
      }

      if (contract.id === this.selectedContractId) {
        return
      }

      if (this.counterPartySigning) {
        this.$router.push({
          name: 'dataContractCounterparty',
          params: {
            workspace_id: this.workspace_id,
            contract_id: contract.id,
            ...this.redirectParameters
          }
        })
        return
      }

      if (this.$route.name === 'dataContractDetail') {
        this.$router.push({
          name: 'dataContractDetail',
          params: {
            workspace_id: this.workspace_id,
            contract_id: contract.id,
            ...this.redirectParameters
          }
        })
        return
      }

      this.$router.push({
        name: 'createContent',
        params: {
          workspace_id: this.workspace_id,
          contract_id: contract.id,
          ...this.redirectParameters
        }
      })
    },
    isUnsigned (contract) {
      return ContractHelper.isUnsigned(contract)
    },
    toggleMenuHandler () {
      if (!this.isMobile) {
        if (!this.tabsFitsResolution() && this.$refs.scroller) {
          this.showMenu = true
          this.childVisible = {}
          _.each(this.$refs.scroller.childNodes, (tab) => {
            this.childVisible[tab.getAttribute('data-id')] = this.tabInViewport(tab)
          })
        } else {
          this.showMenu = false
          this.openMenu = false
        }
      }
    },
    tabsFitsResolution () {
      if (typeof this.$refs.scroller === 'undefined') {
        return false
      }

      const scrollerWidth = this.$refs.scroller
      let tabsWidth = 0

      _.each(this.$refs.scroller.childNodes, (tab) => {
        tabsWidth += tab.offsetWidth
      })

      return scrollerWidth >= tabsWidth
    },
    tabInViewport (elem) {
      const bounding = elem.getBoundingClientRect()
      const scrollerBounding = this.$refs.scroller.getBoundingClientRect()

      return (scrollerBounding.left + scrollerBounding.width >= bounding.left + bounding.width) && (scrollerBounding.left <= bounding.left)
    },

    deleteContractDocument (contractId) {
      ContractService
        .delete(contractId)
        .then(
          () => {
            this.$notification.success(this.$t('docs.delete.ok'));

            const firstContract = _.chain(this.contracts)
              .find((contract) => parseInt(contract.id, 10) !== parseInt(contractId, 10))
              .value()

            this.contractChange(null, firstContract)
            location.reload()
          },
          () => {
            this.$notification.error(this.$t('docs.delete_draft.failed'));
          })
    },

    canDeleteDocument (contract) {
      return contract && contract.current_user && contract.current_user.can_delete
    }
  },
  watch: {
    '$vuetify.breakpoint.width' () {
      this.toggleMenuHandler()
    }
  }
}
</script>

<style lang="scss">
.contract-tabs {
  max-width: 1380px;
  margin: auto;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 1;
  overflow-y: inherit;

  @include display(960px) {
    padding-left: 15px;
    margin-top: 20px;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    padding-left: 0;
  }

  &.detail-view {
    padding: 0 15px;
  }

  &.small-width {
    max-width: 770px;
  }

  &__scroller {
    &--with-menu {
      .contract-tab {
        &:first-of-type {
          border-radius: 0;
        }
      }
    }
  }
}

.contract-tab {
  font-size: 15px;
  color: #000;
  letter-spacing: -0.03px;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  text-decoration: none;

  background: #ECF0F6;
  padding: 0 30px;
  border: 1px solid #D4DFF0;
  border-right: 0 none;
  border-bottom: 0 none;
  cursor: pointer;
  position: relative;

  @include display(960px) {
    padding: 0 10px;
    border-radius: 0 6px 0 0 !important;
  }

  &:first-of-type {
    border-radius: 6px 0 0 0;
  }

  &:nth-last-of-type(1):not(.active):not(.contract-tab--add-attachment) {
    border-radius: 0 6px 0 0;
    border-right: 1px solid #D4DFF0;
  }

  &__title {
    padding: 17px 0 18px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include display(960px) {
      padding: 7px 0 8px;
    }
  }

  &__delete {
    position: absolute;
    top: 5px;
    right: 5px;

    @include display(960px) {
      top: 5px;
      right: 3px;
    }

    svg {
      width: 15px;
      height: 15px;

      @include display(960px) {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__signed {
    position: absolute;
    top: auto;
    right: 5px;
    bottom: 5px;

    @include display(960px) {
      top: auto;
      right: 3px;
      bottom: 2px;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  &.active {
    height: 61px;
    padding-top: 0;
    background: #fff;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 12px 44px 0 rgba(0, 0, 0, 0.10);
    cursor: default;
    border-color: transparent;

    @include display(960px) {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      color: #000;
      text-decoration: none;
    }

    .contract-tab__title {
      padding-top: 20px;

      @include display(960px) {
        padding-top: 10px;
      }
    }

    .contract-tab__signed {
      bottom: 0;
      right: 5px;
      top: unset;
    }
  }

  &--add-attachment {
    color: #5E239E;
    background: transparent;
    border: 0 none;
    padding: 17px 0 18px;

    @include display(960px) {
      white-space: normal;
      min-width: 90px;
      display: flex;
      text-align: left;
      align-items: center;
      line-height: 14px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-right: 0;

      svg {
        width: 20px;
      }
    }

    > span {
      position: relative;
      top: -2px;
      margin-left: 6px;
    }

    &.hidden {
      display: none !important;
    }
  }
}

.contract-tab-menu {
  width: 40px;
  min-width: 40px;
  padding: 17px 0;
  border: solid 1px #d4dff0;
  background-color: #ecf0f6;
  z-index: 1;
  border-radius: 6px 0 0 0 !important;
  box-shadow: 7px 2px 6px 0 rgba(0,0,0,0.1);

  &.open {
    svg {
      transform: rotate(180deg);
    }
  }

  @include display(960px) {
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.contract-menu {
  background: #fff;
  border-radius: 10px;
  position: absolute;
  z-index: 1000;
  top: 65px;
  left: 5px;
  box-shadow: 0 22px 54px 0 rgba(0, 0, 0, 0.1);
  padding: 26px 0;

  @include display(960px) {
    top: 60px;
    left: 0;
    width: 100%;
    max-width: 300px;
  }

  &__item {
    display: block;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.03px;
    color: #000000;
    text-decoration: none;
    text-align: left;
    padding: 8px 30px;
    cursor: pointer;
    border-left: 3px solid transparent;
    position: relative;

    &.active {
      border-color: #5e239e;
    }

    &.hidden {
      display: none !important;
    }

    .contract-tab__signed {
      top: -3px;
      left: 2px;
      right: auto;
      position: relative;
    }
  }
}
</style>
