<template lang="html">
    <div class="credit-notification">

        <div class="credit-notification__ico">
            <IcoCredits />
        </div>
        <div class="credit-notification__content">
            <div class="credit-notification__data">
                <h2 class="credit-notification__title">{{ $t('dashboard.out_of_credits_heading') }}</h2>
                <div class="credit-notification__desc">
                    <p>{{ $t('dashboard.out_of_credits_text') }}</p>
                </div>
            </div>
            <div v-if="this.activeWorkspace.is_owner && !this.activeWorkspace.owner_credits" class="credit-notification__controll">
                <Button type="primary" @click="$router.push({ 'name': 'subscription' })">{{ $t('dashboard.recharge_credit') }}</Button>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapGetters } from 'vuex';
    import IcoCredits from './icons/IcoCredits.vue';

    export default {
        name: 'CreditNotification',
        components: {
            IcoCredits,
        },
        props: ['invitation'],
        data() {
            return {
                completed: false
            }
        },
        created() {

        },
        computed: {
            ...mapGetters([
                'profile'
            ]),
            activeWorkspace: function() {
                // return owner_credits
                if( this.profile && this.$route.params.workspace_id ) {

                    for( var i = 0; i < this.profile.workspaces.length; i++ ) {
                        if( this.$route.params.workspace_id == this.profile.workspaces[i].id ) {
                            return this.profile.workspaces[i];
                        }
                    }

                    return false;

                } else {
                    return false;
                }
            },
        },
        methods: {

        }
    }
</script>


<style lang="scss">
    .credit-notification{
        width: 100%;
        max-width: 845px;
        position: relative;
        padding: 33px;
        background: #FFE2E2;
        box-shadow: 0 12px 24px 0 rgba(0,0,0,0.05);
        border-radius: 6px;
        margin: {
          bottom: 15px;
          left: auto;
          right: auto;
        }

        @include md{
            margin-top: 10px;
        }

        @include sm{
            text-align: center;
            padding: 30px 0;
        }
    }

    .credit-notification__ico{
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 33px;

        @include sm{
            // padding-top: 3.3rem;
            position: static;
            transform: translate(0, 0);
        }
    }

    .credit-notification__content{
        padding-left: 80px;
        padding-right: 380px;

        @include lg{
            padding-right: 260px;
        }

        @include md{
            padding-right: 0;
        }

        @include sm{
            padding-left: 0;
        }
    }

    .credit-notification__title{
        margin: 11px 0 0 0;
        color: #AE0000;

        @include md {
          text-align: center;
        }
    }

    .credit-notification__desc{
        margin-top: 3px;
    }

    .credit-notification__desc,
    .credit-notification__desc p{
        color: #AE0000;
    }

    .credit-notification__controll{
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 33px;
        width: 360px;
        text-align: right;
        vertical-align: middle;

        @include md{
            position: static;
            width: 100%;
            transform: translate(0, 0);
            text-align: center;
        }

        @include sm{
            margin-top: 15px;
        }
    }

    .credit-notification__controll__input{
        display: inline-block;

        @include sm{
            text-align: center;
            display: block;
        }
    }
</style>
