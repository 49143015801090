var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contract-document-tab pa-5",class:{
    'contract-document-tab__active': _vm.canShowHighlighting,
    'pl-4': _vm.isMobileDevice,
    'pl-6': !_vm.isMobileDevice,
  }},[_c('v-row',{attrs:{"align":"center","justify":"start","no-gutters":""}},[(_vm.isMobileDevice)?_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"pt-0 mt-0",attrs:{"icon":""},on:{"click":function($event){return _vm.changeContract(_vm.contract.id)}}},[(_vm.isDocumentOpen)?_c('v-icon',{staticClass:"pt-0 mt-0",attrs:{"color":"primary","large":""}},[_vm._v(" mdi-chevron-up ")]):_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v(" mdi-chevron-down ")])],1)],1):_vm._e(),_c('v-col',{staticClass:"text-left pr-0",class:{ 'pl-3': _vm.isMobileDevice },attrs:{"cols":_vm.isMobileDevice ? 10 : 11}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var hover = ref.hover;
return [_c('a',{staticClass:"text-decoration-none text-left pl-0 pr-auto primary--text",class:{ 'text-decoration-underline': hover },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.changeContract(_vm.contract.id)}}},[_c('span',{staticStyle:{"word-wrap":"break-word","overflow":"hidden"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [(_vm.isUnfinished)?_c('v-avatar',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"float":"left"},attrs:{"size":"20","rounded":""}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"#F8E71D"}},[_vm._v("mdi-alert")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('contract.info.undone')))])]),_vm._v(" "+_vm._s(_vm.contract.title)+" ")],1)])]}}])})],1),_c('v-col',{staticClass:"text-left pr-0",attrs:{"cols":"1"}},[_c('v-menu',{staticStyle:{"z-index":"1001 !important"},attrs:{"transition":"slide-y-transition","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{staticClass:"py-0",attrs:{"max-width":"17rem","width":"17rem"}},[(_vm.canChangeContract)?_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.replaceContractDocument}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"color":"#AEBACE"}},[_vm._v("mdi-file-document-edit-outline")])],1),_c('v-list-item-content',{staticClass:"font-weight-medium text-decoration-underline text-left py-2",staticStyle:{"color":"#666b71 !important"}},[_vm._v(" "+_vm._s(_vm.$t('contract.replace_document'))+" ")])],1):_vm._e(),(_vm.canChangeContract && _vm.canDeleteContract)?_c('v-divider',{staticClass:"mx-2"}):_vm._e(),(_vm.canDeleteContract)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.preventDefault();_vm.showDeleteContractDocumentDialog = true}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-content',{staticClass:"font-weight-medium text-decoration-underline text-left py-2",staticStyle:{"color":"#666b71 !important"}},[_vm._v(" "+_vm._s(_vm.$t('contract.remove_document'))+" ")])],1):_vm._e()],1)],1)],1)],1),(_vm.isDocumentOpen && _vm.isMobileDevice)?_c('v-divider',{staticClass:"mt-4 ml-2 mr-1 mb-2"}):_vm._e(),(_vm.isDocumentOpen && _vm.isMobileDevice)?_c('v-row',[_c('v-col',{class:{ 'pa-0 mt-1': _vm.contract.type !== 'form' },attrs:{"cols":"12"}},[_vm._t("default")],2)],1):_vm._e(),_c('SConfirmDialog',{attrs:{"message":("" + (_vm.$t('docs.delete.confirm')))},on:{"confirmation-callback":_vm.deleteContractDocument},model:{value:(_vm.showDeleteContractDocumentDialog),callback:function ($$v) {_vm.showDeleteContractDocumentDialog=$$v},expression:"showDeleteContractDocumentDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }