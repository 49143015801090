<template>
  <div class="dashboard-notifications">
    <DashboardNotification
      v-for="notification in dashboardNotifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardNotification from '@dashboard/components/DashboardNotification';

export default {
  name: 'DashboardNotifications',
  components: {
    DashboardNotification,
  },
  computed: {
    ...mapGetters({
      dashboardNotifications: 'dashboardFeature/notifications',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
