<template>
  <Data :showMenu="false" :loader="!loaded">
    <template v-slot:title>
      <TutorialHeaderStripe />
    </template>
    <div class="tutorial-wrapper">
      <h1 class="tutorial-heading">{{ $t('tutorial.created_account.title') }}</h1>
      <h2 class="tutorial-heading">{{ $t('tutorial.created_account.description') }}</h2>
      <!-- invitations -->
      <div v-if="profile && profile.invitations" >
        <template v-for="invitation in profile.invitations">
          <template v-if="!invitation.accepted">
            <TutorialMessage
              id="acceptInvitation"
              :backgroundColor="'#FFFAD0'"
              :textColor="'#8C5729'"
              :button="{ buttonText: $t('tutorial.invitation.accept'), buttonAction: createAcceptInvitationAction('accept', invitation.id, invitation.inputs)}"
              :declineAction="createAcceptInvitationAction('decline', invitation.id)"
            >
              <template v-slot:icon><span><img src="/images/new-invitation.png"></span></template>
              <template v-slot:message>
                <strong>{{ $t('tutorial.invitation.new') }}</strong>
                {{ $t('tutorial.invitation.new.team', { team: invitation.workspace_title }) }}
                {{ $t('tutorial.invitation.new.user', { user: invitation.user_full_name }) }}<br>
              </template>
            </TutorialMessage>
          </template>
          <template v-else>
            <TutorialMessage
              id="acceptedInvitation"
              :backgroundColor="'#E9FAF3'"
              :textColor="'#296A49'">
              <template v-slot:icon><span><img src="/images/invitation-accepted.png"></span></template>
              <template v-slot:message><strong>{{ $t('tutorial.invitation.accepted') }}</strong></template>
            </TutorialMessage>
          </template>
        </template>
      </div>

      <!-- waiting documents -->
      <TutorialMessage
        v-for="awaitingSignature in awaitingSignatures"
        id="signAwaitingSignature"
        :backgroundColor="'#FFFAD0'"
        :textColor="'#8C5729'"
        :button="{
          buttonText: $t('contract.sign'),
          buttonAction: createSignAwaitingAction(awaitingSignature.id, profile.workspaces[0].id)
        }"
        :declineAction="createDeclineAwaitingAction(awaitingSignature.id)"
      >
        <template v-slot:message>
          <span v-html="$t('tutorial.sign.pendingHtml')"></span>
          {{ awaitingSignature.creator }}
        </template>
      </TutorialMessage>
      <!-- try signature -->
      <TutorialTab
        v-if="!tabs.trySign.hidden && hasWorkspaces"
        :id="'trySign'"
        :heading="$t('tutorial.sign.tryHtml')"
        :content="$t('tutorial.sign.sample')"
        :button="{text: $t('tutorial.sign.try'), action: redirectToTutorialSignature()}"
        :type="'closable'"
        :collapsed="tabs.trySign.collapsed"
        :icon="'/images/try-sign.png'"
        :borderStyle="'shadow'"
        :background="'#fff'"
        @toggle="toggleTab($event)"
      />

      <!-- upload own document -->
      <TutorialTab
        v-if="!tabs.addDocument.hidden && hasWorkspaces"
        :id="'addDocument'"
        :heading="$t('tutorial.doc.titleHtml')"
        :content="$t('tutorial.doc.description')"
        :button="{text: $t('tutorial.doc.upload'), action: createWorkspaceContractLink()}"
        :linkSecondary="{text: $t('tutorial.doc.help'), link: $t('links.faq') }"
        :type="'collapsable'"
        :collapsed="tabs.addDocument.collapsed"
        :icon="'/images/own-document.png'"
        :borderStyle="'solid'"
        :background="'none'"
        @toggle="toggleTab($event)"
      />

      <!-- use templates -->
      <TutorialTab
        :id="'useTemplate'"
        :heading="$t('tutorial.templates.title')"
        :content="useTemplateTextContent"
        :type="'collapsable'"
        :collapsed="tabs.useTemplate.collapsed"
        :borderStyle="'solid'"
        :icon="'/images/forms.png'"
        :background="'none'"
        @toggle="toggleTab($event)"
      />
      <div v-if="showProfileFinish && profile" id="tutorial-profile-finish">
        <TutorialProfileFinish
          :tasks="tasks"
          :finished="countFinishedTasks"
          :profile="profile"
          @hideComponent="showProfileFinish = false"
        />
      </div>

      <div class="skip-panel" v-if="hasWorkspaces">
        <div class="skip-panel-button-wrapper">
          <v-btn
            color="primary"
            text
            outlined
            @click="skip()"
          >
            {{ $t('general.skip') }}
            <v-icon style="position: absolute; right: .1rem">mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </Data>
</template>

<script>
  import { mapGetters } from 'vuex';
  import TutorialTab from './components/TutorialTab';
  import TutorialMessage from './components/TutorialMessage'
  import TutorialProfileFinish from './components/TutorialProfileFinish'
  import TutorialHeaderStripe from './components/TutorialHeaderStripe'
  import profileCompletenessHelper from '@/helpers/ProfileCompletenessHelper.js';
  import { ContractService } from '@/services/ContractService.js';
  import { DashboardService } from '@/services/DashboardService.js';
  import IcoArrowBack from '@/components/svg/IcoArrowBack.vue';
  import { DataService } from '@/services/DataService.js';

  export default {
    name: 'Tutorial',
    components: {
      TutorialTab,
      TutorialMessage,
      TutorialProfileFinish,
      TutorialHeaderStripe,
      IcoArrowBack,
    },
    data() {
      return {
        loaded: true,
        alerts: {},
        tabs: {
          initialized: false,
          trySign: { hidden: true, collapsed: true },
          addDocument: { hidden: true, collapsed: true },
          useTemplate: { hidden: false, collapsed: true },
        },
        useTemplateTextContent: this.$t('templates.text_tutorial'),
        showProfileFinish: true,
        awaitingSignatures: [],
        gotAwaitingSignatures: false,
      };
    },
    computed: {
      ...mapGetters([
        'profile'
      ]),
      tasks() {
        return profileCompletenessHelper.getProfileTasks(this.profile);
      },
      countFinishedTasks() {
        return profileCompletenessHelper.countFinishedTasks(this.tasks);
      },
      hasWorkspaces() {
        return this.profile && this.profile.workspaces && this.profile.workspaces.length > 0;
      }
    },
    methods: {
      async getAwaitingSignatures() {
        if (this.profile.workspaces && this.profile.workspaces.length > 0 && !this.gotAwaitingSignatures ) {
          try {
            const response = await DataService.getContracts({
              workspace_id: this.profile.workspaces[0].id,
              states: ['pending.me'],
            });
            this.awaitingSignatures = response.data;
          } catch (e) {
          }
        }
      },
      async acceptInvitation(id, inputs) {
        try {
          await DashboardService.acceptInvivation( id, inputs || [] );

          if (!this.hasWorkspaces) {
            this.$router.replace({ name: 'dashboard'});
            return;
          }

          const invitations = [ ...this.profile.invitations ];
          invitations.find((invitation) => {
            if (invitation.id === id) {
              invitation.accepted = true;
            }
          });

          this.$store.commit('updateProfile', { ...this.profile, invitations });
          this.$notification.success(this.$t('workspace.invitation.accepted.ok'));
        } catch (e) {
          this.$notification.error(this.$t('workspace.invitation.accepted.failed'));
        }
      },
      async declineInvitation(id) {
        try {
          await  DashboardService.declineInvitation( id );

          const invitations = this.profile.invitations.filter((invitation) => (invitation.id !== id));
          this.$store.commit('updateProfile', { ...this.profile, invitations });
          this.$notification.success(this.$t('workspace.invitation.declined.ok'));
        } catch (e) {
          this.$notification.error(this.$t('workspace.invitation.declined.failed'));
        }
      },
      createAcceptInvitationAction(action, id, inputs) {
        if (action === 'accept') {
          return () => {
            this.acceptInvitation(id, inputs);
          };
        }

        return () => {
          this.declineInvitation(id);
        };
      },
      createSignAwaitingAction(contract_id, workspace_id) {
          return () => {
            this.$router.push({name: 'documentsDetail', params: { workspace_id, contract_id }});
          };
      },
      createDeclineAwaitingAction(id) {
        return () => {
          this.awaitingSignatures = this.awaitingSignatures.filter((item) => (item.id !== id))
        };
      },
      scrollToTutorial() {
        this.$nextTick(() => {
          const wrapper = document.querySelector('#tutorial-profile-finish')
          wrapper.scrollIntoView();
        });
      },
      async createSampleContract() {
          try {
            const response = await ContractService.createSampleContract();
            if (response && response.contract_id && response.workspace_id) {
              localStorage.setItem('createdSampleContract', response.contract_id);
              this.$router.push({
                name: 'documentsDetail',
                params: {
                  workspace_id: response.workspace_id,
                  contract_id: response.contract_id,
                }
              });
            } else {
              throw new Error('Contract not created');
            }
          } catch (e) {
            this.loaded = true;
            this.$notification.error(this.$t('tutorial.sign.sample.error'));
          }
      },
      toggleTab(tab) {
        if (tab === 'trySign') {
          this.tabs.trySign.hidden = true;

          if (!this.tabs.addDocument.hidden) {
            this.tabs.addDocument.collapsed = false;
          } else {
            this.tabs.useTemplate.collapsed = false;
          }

        } else {
          this.tabs[tab].collapsed = !this.tabs[tab].collapsed;
        }
      },
      skip() {
        const redirect = () => this.$router.replace({ name: 'dashboard'});
        DashboardService.skipTutorial().then(redirect, redirect);
      },
      redirectToTutorialSignature() {
        return () => {
          this.createSampleContract();
        }
      },
      createWorkspaceContractLink() {
        return () => {
          const workspace = profileCompletenessHelper.getOwnedWorkspace(this.profile);
          let workspaceId = null;
          if (workspace) {
            workspaceId = workspace.id;
          } else if (this.profile.workspaces && this.profile.workspaces.length) {
            workspaceId = this.profile.workspaces[0].id;
          } else if (this.profile.invitations && this.profile.invitations.length) {
            workspaceId = this.profile.invitations[0].workspace_id;
          } else {
            throw new Error('User has no workspace'); // should not happen, better safe than sorry (route would cause 404 without id)
          }
          this.$router.push({ name: 'createNew', params: { workspace_id: workspaceId }, query: { fromTutorial: true }});
        };
      }
    },
    watch: {
      profile(newVal) {
        if (!this.tabs.initialized && newVal.id) {
          const hasSignedAtLeastOneContract = profileCompletenessHelper.hasSignedAtLeastOneContract(this.profile);
          this.tabs.trySign.hidden = hasSignedAtLeastOneContract;
          if (!hasSignedAtLeastOneContract) {
            this.tabs.trySign.collapsed = false;
          }

          const hasCreatedDocContract = profileCompletenessHelper.hasCreatedDocContract(this.profile);
          this.tabs.addDocument.hidden = hasCreatedDocContract;
          if (!hasCreatedDocContract && hasSignedAtLeastOneContract) {
            this.tabs.addDocument.collapsed = false;
          }
          if (hasCreatedDocContract && hasSignedAtLeastOneContract) {
            this.tabs.useTemplate.collapsed = false;
          }

          this.tabs.initialized = true;
          if (this.$route.hash === '#profileFinish') {
            this.scrollToTutorial();
          }
        }

        this.getAwaitingSignatures();
        this.gotAwaitingSignatures = true;
      },
    },
  }
</script>
<style lang="scss">
  .tutorial-wrapper {
    max-width: 770px;
    margin: 0 auto;
    padding: 40px 0 70px;

    @include md() {
      margin-top: 2rem;
    }

    h1.tutorial-heading {
      text-align: left;
      font-weight: bold;
      margin-bottom: 10px;
    }
    h2.tutorial-heading {
      text-align: left;
      margin-bottom: 25px;
      font-size: 16px;
      color: #2f2f2f;
    }
    .skip-panel {
      position: fixed;
      box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.1);
      bottom: 0;
      left: 0;
      right: 0;
      background: white;
      width: 100%;
      z-index: 99;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .skip-panel-button-wrapper {
        position: relative;
        &:hover {
          .skip-panel-icon-wrapper {
            color: #9268BE;
            transition: 0.5s;
          }
        }
        button {
          width: 171px;
          font-weight: bold;
          font-size: 14px;
          margin: 0 auto;
          height: 46px;
          .button__text {
            position: relative;
            left: -2px;
          }
        }
        .skip-panel-icon-wrapper {
          position: absolute;
          top: 6px;
          right: 20px;
          color: #5E239E;
          transform: rotate(-180deg);
          display: flex;
          transition: 0.5s;
        }
      }
    }
  }
</style>
