<template>
  <v-menu v-model="isSelectOpened" class="language-toggle" transition="slide-y-transition" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        v-on="on"
        v-bind="attrs"
        class="language-toggle__button"
        :class="{
          'language-toggle__button--backgound': !transparent,
          'language-toggle__button--border px-2': border,
        }"
      >
        <component :is="selectedLocale.flag" class="language-toggle__choice--flag" />

        <span v-if="title" class="ml-2 subtitle-1">
          {{ selectedLocale.locale.toUpperCase() }}
        </span>

        <v-icon color="#141414" class="mr-0">
          {{ `mdi-chevron-${isSelectOpened ? 'up' : 'down'}` }}
        </v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="option in availableLocales"
        :key="option.locale"
        class="language-toggle__choice"
        @click="selectOption(option)"
      >
        <v-list-item-icon class="mr-0">
          <component :is="option.flag" class="language-toggle__choice--flag" />
        </v-list-item-icon>

        <v-list-item-content>
          <span class="language-toggle__choice--text">
            {{ option.locale.toUpperCase() }}
          </span>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { Locales, getLocales } from '@/plugins/i18n/Locales';
import { getCurrentLocale } from '@/plugins/i18n';

export default {
  name: 'LanguageToggle',
  components: {
    ...Locales.flagComponents,
  },
  data() {
    return {
      isSelectOpened: false,
      options: getLocales(this.onlyFullyLocalized),
    };
  },
  props: {
    locale: {
      type: String,
      default: getCurrentLocale(this),
    },
    border: {
      type: Boolean,
      default: true,
    },
    title: {
      type: Boolean,
      default: true,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    availableLocales() {
      return this.options.filter((option) => {
        return option.locale !== this.selectedLocale.locale;
      });
    },
    selectedLocale() {
      return (
        this.options.find((option) => {
          return option.locale === this.locale;
        }) || this.options[0]
      );
    },
  },
  methods: {
    selectOption(option) {
      this.$emit('onLocaleChanged', option.locale);
    },
  },
};
</script>

<style lang="scss" scoped>
.language-toggle {
  &__button {
    color: #141414;

    &--backgound {
      background-color: #ffffff;
    }

    &--border {
      border: 1px solid #d7dada;
    }
  }

  &__choice {
    border-bottom: solid 1px #e7eaeb !important;

    &:last-child {
      border-bottom: unset !important;
    }

    &--flag {
      width: 40px;
      max-height: 30px;
      zoom: 0.5 !important;
      border-radius: 8px !important;
    }

    &--text {
      padding-left: 0.5rem;
    }
  }
}

.v-menu__content {
  border-radius: 12px !important;
  max-width: 14rem;
  width: 100%;
  overflow: hidden;
}
</style>
