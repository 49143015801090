import * as types from './mutation_types';

export default {
  [types.SET_LOADING](state, payload) {
    state.isLoading = payload;
  },
  [types.SET_ERROR](state) {
    state.error = false;
  },
  [types.SET_CONTRACT](state, payload) {
    state.contract = Object.assign({}, payload);
  },
  [types.SET_ACTIVE_CONTRACT](state, payload) {
    state.activeContract = payload;
  },
  [types.SET_CURRENT_SIGN_IDENTITY]: (state, payload) => {
    state.contractCurrentSignIdentity = payload;
  },
  [types.SET_DOCUMENT_HISTORY](state, payload) {
    state.history = payload;
  },
  [types.SET_SIGN_IDENTITIES]: (state, payload) => {
    state.contractSignIdentities = payload;
  },
};
