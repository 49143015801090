<template>
  <div class="signatory-search">
    <div
      class="signatory-search--toggle color-light-gray"
      :class="{'signatory-search--content--open': openSearchId === currentId}"
      @click="onToggleClick"
    >
      {{ label }}
      <v-icon v-if="openSearchId === currentId" class="ml-auto mr-0">mdi-menu-up</v-icon>
      <v-icon v-else class="ml-auto mr-0">mdi-menu-down</v-icon>
    </div>

    <div v-if="openSearchId === currentId" class="signatory-search--content">
      <div class="signatory-search--input">
        <input
          ref="signatorySearchInput"
          type="text"
          v-model="searchTerm"
          class="signatory-search--main-text color-light-gray black--text"
        >
        <IcoSearch />
      </div>

      <template v-if="!isLoadingDynamicList && !debounceTimeout">
        <div v-if="contacts.length" class="signatory-search--results-wrapper">
          <div class="py-0 px-5">
            <ul class="signatory-search--ul pl-0">
              <li v-for="contact in contacts" :key="contact.id" @click="onSignerSelect(contact)">
                <v-row align="center" justify="start" no-gutters>
                  <v-col class="col-auto pr-5 text-left">
                    <VerificationBadge :avatar-size="30" :contact="contact" />
                  </v-col>

                  <v-col class="col-10 text-left">
                    <p class="signatory-search--signers-result-heading color-black">{{ contact.lastname }} {{ contact.firstname }}</p>
                    <p
                      v-if="contact.organization_name"
                      class="signatory-search--signers-result-organization color-gray"
                    >{{ contact.organization_name }}</p>
                    <p
                      v-if="contact.email"
                      class="signatory-search--signers-result-email color-gray"
                    >{{ contact.email }}</p>
                  </v-col>
                </v-row>
              </li>
            </ul>
          </div>
        </div>

        <div v-if="searchTerm.length >= 3" class="signatory-search--no-results">
          <div v-if="!contacts.length" class="signatory-search--no-results-content color-light-gray">
            <p class="color-light-gray">
              {{ $t('contract.search.not_found') }}
            </p>

            <p v-if="isDynamicList" class="color-light-gray">
              {{ $t('contract.search.create_question') }}
            </p>
          </div>

          <Button v-if="isDynamicList" type="add-person" @click="createNewUser()">
            <span class="icon-plus"></span>
            {{ $t('contract.search.create_link') }}
            {{ searchTerm }}
          </Button>
        </div>

        <div v-if="!searchTerm.length && !contacts.length" class="signatory-search--no-results">
          <p class="color-light-gray">
            {{ $t('contract.search.placeholder') }}
          </p>
        </div>
      </template>

      <div v-else>
        <div class="signatory-search--no-results">
          <p v-if="searchTerm.length > 2" class="color-light-gray">
            {{ $t('contract.search.loading') }}
          </p>

          <p v-else class="color-light-gray">
            {{ $t('contract.search.placeholder') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcoSearch from '@/components/svg/IcoSearch.vue';
import VerificationBadge from "@contacts/components/VerificationBadge";

export default {
  name: 'SignatorySearch',
  components: {
    VerificationBadge,
    IcoSearch,
  },
  props: {
    contacts: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    openSearchId: {
      required: false,
      default: null,
    },
    currentId: {
      required: true,
    },
    isDynamicList: {
      type: Boolean,
      required: true,
    },
    isLoadingDynamicList: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      searchTerm: '',
      debounceTimeout: 0,
    };
  },
  watch: {
    searchTerm() {
      if (!this.isDynamicList) {
        return this.$emit('onSearchTermChange', this.searchTerm);
      }
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.$emit('onSearchTermChange', this.searchTerm);
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = false;
      }, 500);
    },
    openSearchId() {
      this.searchTerm = '';
    }
  },
  methods: {
    createNewUser() {
      this.$emit('onCreateNewUserClick')
    },
    onSignerSelect(id) {
      this.$emit('onSignerSelect', id);
    },
    onToggleClick() {
      if (this.currentId === this.openSearchId) {
        this.$emit('toggleSearch', null)
      } else {
        this.$emit('toggleSearch', this.currentId);
        this.$nextTick(() => {
          this.$refs.signatorySearchInput.focus();
        });
      }
    },
  },
}
</script>

<style lang="scss">
.signatory-search {
  position: relative;
}
.signatory-search--toggle {
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 6px;
  border: 1px solid #C5CCD8;
  padding-left: 15px;
  line-height: 52px;
  text-align: left;
  background: #fff;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  padding-right: 25px;
  user-select: none;
  @include md {
    line-height: 10px;
    padding: 10px;
  }
}
.signatory-search--toggle svg {
  margin-left: auto;
  width: 17px;
  height: 17px;
  transform: rotate(180deg);
}
.signatory-search--toggle.signatory-search--content--open svg {
  transform: rotate(0deg);
}

.signatory-search--input {
  position: relative;
  display: flex;
  align-items: center;
}

.signatory-search--input svg {
  position: absolute;
  left: 15px;
  width: 20px;
  height: 20px;
}

.signatory-search--content {
  position: absolute;
  width: 100%;
  z-index: 10;
  background: white;
  border: 1px solid #C5CCD8;
  border-top: 0;
  border-radius: 0 0 6px 6px;
}
.signatory-search--content--open {
  border-radius: 6px 6px 0 0;
}
.signatory-search--no-results {
  padding: 40px;
}
.signatory-search--main-text {
  color: #000;
  font-weight: bold;
  font-size: 1rem;
  position: relative;
}
.signatory-search--small-text {
  color: #80868E;
  font-size: 10px;
}
.signatory-search--no-results-content {
  margin-bottom: 16px;
}
.signatory-search--results-wrapper .button {
  width: 100%;
}
.signatory-search--no-results-content p {
  margin-bottom: 12px;
  font-size: 17px;
  line-height: 17px;
  font-weight: 400;
  letter-spacing: -.5px;
}
.color-gray {
  color: #80868E;
}
.color-light-gray {
  color: #9FA6B1;
}
.color-black {
  color: #000;
}
.signatory-search--main-text {
  padding: 0 45px 0;
  margin: 0;
  width: 100%;
  height: 52px;
  outline: 0;
  background: #F2F6FC;
  border: 0;
  border-bottom: 1px solid #C5CCD8;
}
.signatory-search--other-results {
  padding: 20px;
  padding-bottom: 0;
  border-top: 0;
}
.signatory-search-photo {
  background-image: unset;
  width: 30px;
  height: 30px;
  background: #D4DFF0;
  line-height: 30px;
  color: white;
  font-weight: bold;
  border-radius: 100%;
  font-size: 14px;
  float: left;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.signatory-search--signers-result-organization, .signatory-search--signers-result-email {
  font-size: 14px;
  line-height: 14px;
  text-align: left;
}
.signatory-search--signers-result-heading {
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 2px;
  text-align: left;
  font-weight: 400;
}
.signatory-search--ul {
  list-style: none;
}
.signatory-search--ul:first-child {
  border-top: 0;
}
.signatory-search--ul:last-child {
  border-bottom: 0;
}
.signatory-search--ul li {
  border-bottom: 1px solid #BBC7DA;
  padding: 13px 20px;
  margin: 0 -20px;
  cursor: pointer;
}
.signatory-search--ul li:hover {
  background: #F2F6FC;
}
.signatory-search--ul li:first-child {
  border-top: 0;
}
.signatory-search--ul li:last-child {
  border-bottom: 0;
}
.signatory-search--ul li p {
  margin: 0;
}
.signatory-search--list-item-content {
  display: flex;
  align-self: center;
}
.signatory-search--results-wrapper {
  max-height: 385px;
  overflow: auto;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.1250875350140056) 0%, rgba(0,0,0,0) 8%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 100%);
}
.signatory-search--list-item-content-text {
  padding-left: 10px;
  display: flex;
  align-self: center;
  flex-direction: column;
}
.signatory-search--no-results .button {
  width: 100%;
}
</style>
