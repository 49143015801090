<template>
  <div
    v-bind:style="{ background: backgroundColor, color: textColor }"
    class="tutorial-message__wrapper">
    <div class="tutorial-message__inner">
      <div class="tutorial-message__left">
        <div class="tutorial-message__icon">
          <slot name="icon" />
        </div>
        <div class="tutorial-message__content">
          <slot name="message" />
        </div>
      </div>
      <div v-if="button" class="tutorial-message__right">
        <v-btn
          v-if="button"
          color="primary"
          class="mb-2"
          @click="button.buttonAction"
        >
          {{ button.buttonText }}
        </v-btn>
      </div>
    </div>
    <div v-if="declineAction" @click="declineAction" class="tutorial-message__close">
      <IcoClose/>
    </div>
  </div>
</template>
<script>
  import IcoClose from '@/components/svg/IcoClose.vue';

  export default {
    name: 'TutorialMessage',
    props: {
      backgroundColor: {
        type: String,
        required: true,
      },
      textColor: {
        type: String,
        required: true,
      },
      button: {
        type: Object,
        required: false,
      },
      declineAction: {
        type: Function,
        required: false,
      }
    },
    components: {
      IcoClose,
    }
  }
</script>
<style lang="scss">
  .tutorial-message__wrapper {
    width: 100%;
    padding: 20px 50px 20px 30px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;

    @include md {
      padding: 30px 20px 20px;
    }

    .tutorial-message__inner {
      display: flex;
      @include md {
        flex-direction: column;
      }
      .tutorial-message__left {
        display: flex;
        flex: 1 0 0;
        align-items: center;
        .tutorial-message__icon {
          width: 30px;
          height: 30px;
          margin-right: 15px;
          span {
            width: 100%;
            img {
              width: 100%;
            }
          }
        }

        .tutorial-message__content {
          flex: 1 0 0;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .tutorial-message__right {
        width: fit-content;
        align-items: center;
        justify-content: center;
        display: flex;

        @include md {
          width: 100%;
          margin-top: 10px;
        }

        .button {
          margin: 0;
          padding: 10px 20px;
          font-size: 14px;
          width: fit-content;
          height: fit-content;
          .button__ico--left {
            display: none;
          }
        }
      }
    }
    .tutorial-message__close {
      position: absolute;
      cursor: pointer;
      top: 15px;
      right: 15px;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
</style>
