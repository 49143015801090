<template lang="html">
  <ContractLayout
    :background-image="contract.settings && contract.settings.background ? contract.settings.background : `/images/contract-desktop-bg.20200622.jpg`"
    :contract="contract"
    :is-loading="isLoading"
    is-preview
  >
    <template v-slot:content>
      <div class="contract view" style="max-width: 1340px">
        <div class="contract__data contract__data--show" style="width: 100% !important">
          <Loader v-if="isLoading" class="register-loader" style="text-align: center; padding-left: 0" />

          <div v-else>
            <v-alert
              v-if="!signIdentitiesSuccessfullyLoaded"
              icon="mdi-alert"
              type="warning"
              class="mt-5 mt-sm"
              prominent
              dense
            >
              {{ $t('signature.placeholders.not_ready') }}
            </v-alert>

            <div class="contract-preview__content">
              <div class="contract-preview__content-tabs">
                <ContractSideTabsClient
                  :contracts="attachments.length ? attachments : [contract]"
                  :selectedContractId="selectedContract.contract_id"
                  :workspace_id="contract.workspace_id"
                  :mainContractId="contract.id"
                  :show-sign-button="sticker === 'sign' && unfinishedSignaturesCount.length < 2"
                  :contract-primary-color="contractPrimaryColor"
                  @click="changeSelectedContract"
                  @sign-contract="signContract"
                  @show-signatures="showSignaturesOnMobile"
                >
                  <div class="content content--view">
                    <DocumentView
                      v-if="$vuetify.breakpoint.mobile"
                      :selected-contract-id="selectedContract.id"
                      :sign="signContract"
                      :contractTemplate="
                        selectedContract && selectedContract.type === 'form'
                          ? selectedContract.content
                          : null
                      "
                    />

                    <DetailSignatures
                      v-if="$vuetify.breakpoint.mobile && (selectedContract.type === 'form' || isSignaturesOutsideOfDocumentsPages)"
                      ref="signs"
                      :contract="selectedContract"
                      :sign="signContract"
                      :customColor="contractPrimaryColor"
                    />
                  </div>

                  <div v-if="selectedContract.current_user && selectedContract.current_user.can_accept" class="no-signature-button">
                    <p>{{ $t('signing.signature_not_required') }}</p>
                    <Button
                      type="secondary"
                      :customPrimaryColor="contract.settings.primary_color"
                      :loading="isProcessingSignOrApprove"
                      @click="acceptContract()"
                    >
                      {{ $t('signature.agree_with_contract') }}
                    </Button>
                  </div>

                  <div v-else-if="!signIdentityIsSigned" class="contract__buttons">
                    <div v-if="!contract.skip_header_changing" style="margin-top: 10px;">
                      <a href="#" @click.prevent="$router.push({name: 'contractData', params: { contract_id: contract.contract_id, token: $route.params.hash }})">
                        {{ $t('contract.edit_header') }} <span class="icon icon-pen"></span>
                      </a>
                    </div>
                  </div>
                </ContractSideTabsClient>

                <div v-if="!isMobileDevice && selectedContract" class="contract-preview__content-tabs-info">
                  <div class="contract-preview__content-tabs-info--row">
                    <span class="row__title">{{ $t('general.creator') }}:</span>
                    <span class="row__data">{{ selectedContract.author_name }}</span>
                  </div>
                  <div class="contract-preview__content-tabs-info--row">
                    <span class="row__title">{{ $t('general.created_at') }}:</span>
                    <span class="row__data">{{ $d(selectedContract.created) }}</span>
                  </div>
                </div>
              </div>

              <div v-if="!isMobileDevice" class="contract-preview__content-document pa-0 ma-0" :style=" selectedContract.type === 'form' ? 'padding: 5rem 5rem 1rem;' : ''">
                <div v-if="!isLoadingContract" class="content content--view">
                  <div v-if="signIdentitiesSuccessfullyLoaded && showSticker !== null" class="contract-preview__content-document--sign-info">
                    <span class="contract-preview__content-document--sign-info-close icon-close" @click="sticker = null"></span>
                    <span>{{ $t('contract.review.info') }}</span> <br/>
                    <span
                      v-html="
                        showSticker === 'approve'
                          ? $t('contract.review.action_agree')
                          : $t('contract.review.action_sign')
                      "
                      class="strong-text"
                      style="word-break: break-word"
                      @click="scrollToSigns"
                    ></span>
                  </div>

                  <DocumentView
                    v-if="!$vuetify.breakpoint.mobile"
                    :selected-contract-id="selectedContract.id"
                    :sign="signContract"
                    :contractTemplate="
                      selectedContract && selectedContract.type === 'form'
                        ? selectedContract.content
                        : null
                    "
                  />

                  <DetailSignatures
                    v-if="!$vuetify.breakpoint.mobile && (selectedContract.type === 'form' || isSignaturesOutsideOfDocumentsPages)"
                    ref="signs"
                    :contract="selectedContract"
                    :sign="signContract"
                    :customColor="contractPrimaryColor"
                  />
                </div>

                <div v-if="selectedContract.current_user && selectedContract.current_user.can_accept" class="no-signature-button">
                  <p>{{ $t('signing.signature_not_required') }}</p>
                  <Button
                    type="secondary"
                    :customPrimaryColor="contract.settings.primary_color"
                    :loading="isProcessingSignOrApprove"
                    @click="acceptContract"
                  >
                    {{ $t('signature.agree_with_contract') }}
                  </Button>
                </div>

                <div v-else-if="!signIdentityIsSigned" class="contract__buttons">
                  <div v-if="!contract.skip_header_changing" style="margin: 10px auto;">
                    <a href="#" @click.prevent="$router.push({name: 'contractData', params: { contract_id: contract.contract_id, token: $route.params.hash }})">
                      {{ $t('contract.edit_header') }} <span class="icon icon-pen"></span>
                    </a>
                  </div>
                </div>
              </div>

              <div v-if="!isMobileDevice" class="contract-preview__content-previews">
                <DocumentPagePreviews v-if="selectedContract && selectedContract.type === 'doc'" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <v-dialog :value="showContinueDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            {{ $t('contract.to_done') }}
          </v-card-title>

          <v-card-text class="py-0">
            <v-list two-line>
              <v-list-item-group>
                <template v-for="(item, index) in hasUndoneContracts">
                  <v-list-item :key="item.id" @click="changeSelectedContract(item)">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.contract_title"></v-list-item-title>

                      <v-list-item-subtitle
                        v-if="
                          item.current_sign_identity.contract_role === 'sign' ||
                          item.current_sign_identity.contract_role === 'sign_certificate' ||
                          item.current_sign_identity.contract_role === 'sign_bank_id_sign'
                        "
                        class="text--primary"
                        v-text="$t('contract.to_done.sign')"
                      ></v-list-item-subtitle>

                      <v-list-item-subtitle
                        v-if="item.current_sign_identity.contract_role === 'approve'"
                        class="text--primary"
                        v-text="$t('contract.to_done.approve')"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider v-if="index < hasUndoneContracts.length - 1" :key="index"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :color="contract.settings.primary_color || 'primary'"
              text
              @click="showContinueDialog = false"
            >
              {{ $t('general.continue') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </ContractLayout>
</template>


<script>
import _ from 'lodash';
import {mapActions, mapGetters} from 'vuex';
import { ContractService } from '@/services/ContractService.js';
import { WorkflowHelper } from '@/helpers/WorkflowHelper.js';
import { ContractHelper } from '@/helpers/ContractHelper.js';
import { getDocumentPreviewImages } from '@/common/reusable/contractFunctions';
import { getSignatureVerticalPosition } from '@/common/reusable/signatureFunctions';
import FileSaver from 'file-saver';
import Loader from '@/components/Loader.vue';
import ContractLayout from "@/views/contract/components/ContractLayout";
import DetailSignatures from '@/components/contract/DetailSignatures.vue';
import ContractSidePreviews from "@/components/ContractSidePreviews";
import DocumentPagePreviews from "@contract/components/DocumentPagePreviews";
import DocumentView from "@contract/components/DocumentView";
import { publicApiRequest } from "@/services/ApiService";
import { fetchContractCurrentSignIdentity, fetchContractSignIdentities } from "@publicSign/store/actions";
import { hasFeatureFlag } from "@/common/reusable/featureFlagsChecker";

export default {
  name: 'contractView',
  components: {
    DocumentView,
    DocumentPagePreviews,
    ContractSideTabsClient: () => import('@/components/ContractSideTabsClient'),
    ContractSidePreviews,
    ContractLayout,
    Loader,
    DetailSignatures,
  },
  data() {
    return {
      loaded: false,
      sign_identity: '',
      isProcessingSignOrApprove: false,
      showImages: '',
      selectedContract: this.contract,
      loadedContract: true,
      sticker: null,
      showContinueDialog: false,
      canShowSignaturesOnMobile: true,
      backgroundLoader: null,
      signIdentitiesSuccessfullyLoaded: true,
    }
  },
  computed: {
    ...mapGetters({
      contract: "contract",
      isMobileDevice: 'window/isMobileDevice',
      isAllPagesLoaded: 'pdfDocument/isAllPagesLoaded',
      unfinishedSignatures: 'signing/unfinishedSignatures',
      loadedContractCurrentSignIdentity: 'publicContract/currentSignIdentity',
      loadedContractSignIdentities: 'publicContract/signIdentities',
    }),
    canDrawSignaturesState() {
      return this.contract.type === 'form' || this.isAllPagesLoaded;
    },
    contractCurrentSignIdentity() {
      return this.loadedContractCurrentSignIdentity || this.contract?.current_sign_identity || null;
    },
    contractSignIdentities() {
      return this.loadedContractSignIdentities || this.contract?.sign_identities || [];
    },
    signIdentityIsSigned () {
      return this.sign_identity?.is_signed || false
    },
    attachments() {
      return this.contract.attachments;
    },
    contractPrimaryColor() {
      return _.get(this, 'contract.settings.primary_color', 'rgb(94, 35, 158)');
    },
    isLoading() {
      return !this.loaded
    },
    isLoadingContract() {
      return !this.loadedContract
    },
    previewImages() {
      return getDocumentPreviewImages(this.showImages)
    },
    showSticker () {
      return this.sticker
    },
    hasUndoneContracts () {
      return this.getUndoneContracts()
    },
    unfinishedSignaturesCount() {
      const signIdentityId = this.contract?.sign_identity?.id;

      if (!signIdentityId) {
        return [];
      }

      return this.unfinishedSignatures(signIdentityId) || [];
    },
    isSignaturesOutsideOfDocumentsPages() {
      return hasFeatureFlag(this.contract, 'hasAppendedSignsToTheEnd');
    },
  },
  mounted() {
    const bankIdResult = this.$route.query?.bankIdResult;

    if (bankIdResult) {
      if ('success' === bankIdResult) {
        this.$notification.success(this.$t('signing.signature_inserted'));
      } else if ('failure' === bankIdResult) {
        this.$notification.error(
          `${this.$t('contract.sign.error_occurred')} - ${this.$route.query?.error}`,
        );
      }
    }

    this.resetSignIdentities();
    this.resetCurrentSignIdentity();
    this.fetchData();
    this.fetchContract(this.$route.params.hash).then((resp) => {
      const self = this;

      let isBankIdSign = false;

      for (let signIdentity of resp?.sign_identities) {
        if ("sign_bank_id_sign" === signIdentity?.contract_role) {
          isBankIdSign = true;
        }
      }

      if (!isBankIdSign) {
        window.addEventListener('beforeunload', this.beforeWindowUnload);

        this.backgroundLoader = setInterval(() => {
          publicApiRequest({
            authorization: 'ApiToken',
            authToken: this.$route.params.hash,
            method: 'GET',
            endpoint: `api/v1/invitecontract?with=completed&selectedContractId=${self.selectedContract?.contract_id || self.$route.query.contract_id}`,
          })
            .then(async (resp) => {
              if (this.contract?.settings?.background) {
                resp.settings.background = this.contract.settings.background;
              }

              await this.loadSignIdentities({
                authToken: this.$route.params.hash,
                contractId: this.selectedContract?.contract_id || this.$route.query.contract_id || resp?.id,
              });

              this.$store.commit(
                'setContract',
                Object.assign(resp, {
                  sign_identities: this.contractSignIdentities,
                  current_sign_identity: this.contractCurrentSignIdentity,
                }),
              );

              if (self.sign_identity?.is_signed_with_certificate !== resp.sign_identity?.is_signed_with_certificate) {
                if (this.backgroundLoader) {
                  clearInterval(self.backgroundLoader);
                }

                if (
                  this.$route.name === 'contractView' &&
                  typeof self.sign_identity?.is_signed_with_certificate !== 'undefined' &&
                  typeof resp.sign_identity?.is_signed_with_certificate !== 'undefined'
                ) {
                  return self.$router.push({
                    name: 'contractComplete',
                    params: {
                      hash: self.$route.params.hash,
                      email: resp.sign_identity?.email,
                    },
                  })
                }
              }
            });
        }, 3000);
      }
    })
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);

    this.setSignatures([]);

    if (this.backgroundLoader) {
      clearInterval(self.backgroundLoader);
    }
  },
  methods: {
    ...mapActions({
      fetchContract: 'publicContract/fetchContract',
      fetchDocumentWithLinkAsUnregistered: 'pdfDocument/fetchDocumentWithLinkAsUnregistered',
      resetDocument: 'pdfDocument/reset',
      setSignatures: 'signing/setSignatures',
      fetchContractCurrentSignIdentity: 'publicContract/fetchContractCurrentSignIdentity',
      fetchContractSignIdentities: 'publicContract/fetchContractSignIdentities',
      resetCurrentSignIdentity: 'publicContract/resetContractCurrentSignIdentity',
      resetSignIdentities: 'publicContract/resetContractSignIdentities',
    }),
    showSignaturesOnMobile(show) {
      this.canShowSignaturesOnMobile = show;
    },
    getUndoneContracts() {
      let undoneContracts = []

      if (ContractHelper.isApprovalRequiredForCurrentUser(this.contract) && this.contract.current_sign_identity?.is_approved === false) {
        undoneContracts.push(this.contract)
      }
      if (ContractHelper.isSignatureRequiredForCurrentUser(this.contract) && this.contract.current_sign_identity?.is_signed === false) {
        undoneContracts.push(this.contract)
      }

      this.contract.attachments.forEach((attachment) => {
        if (ContractHelper.isApprovalRequiredForCurrentUser(attachment) && attachment.current_sign_identity?.is_approved === false) {
          if (this.contract.id != attachment.id) {
            undoneContracts.push(attachment)
          }
        }
        if (ContractHelper.isSignatureRequiredForCurrentUser(attachment) && attachment.current_sign_identity?.is_signed === false) {
          if (this.contract.id != attachment.id) {
            undoneContracts.push(attachment)
          }
        }
      })

      return undoneContracts
    },
    beforeWindowUnload(e) {
      const undoneContracts = this.hasUndoneContracts
      if (undoneContracts.length !== 0) {
        // Show continuing dialog
        this.showContinueDialog = true
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    },
    scrollToSigns() {
      if (this.showSticker === 'approve') {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth"
        })
      } else if (this.showSticker === 'sign') {
        let signPosition = 0;

        if (this.selectedContract.type === 'form') {
          const templateSignButtons = document.getElementsByClassName('contract-sign-button')
          signPosition = getSignatureVerticalPosition(templateSignButtons[0]) - window.innerHeight / 2
        } else if (this.selectedContract.type === 'doc') {
          let pageHeight = document.getElementsByClassName('page')[0].clientHeight

          let firstSignPosition = this.sign_identity.positions[0]
          this.sign_identity.positions.forEach((sign) => {
            if (firstSignPosition === null || typeof firstSignPosition.offset_top === 'undefined' || (sign.page >= 0 && sign.page < firstSignPosition.page || sign.offset_top < firstSignPosition.offset_top)) {
              firstSignPosition = sign
            }
          })

          const signPositionOnPage = firstSignPosition.offset_top / 100 * pageHeight
          signPosition = pageHeight * firstSignPosition.page + signPositionOnPage
        }

        window.scrollTo({
          top: signPosition,
          behavior: "smooth"
        })
      }
    },
    signContract() {
      if (this.backgroundLoader) {
        clearInterval(this.backgroundLoader);
      }

      WorkflowHelper.clickOnSignLink({
        contract: this.selectedContract,
        markApproved: () => this.markApproved(),
        fastsign: (preloadedSignature) => {
          // copy-pasted from ContractSign.vue
          if (!this.loaded) {
            return; // Prevent double sending
          }
          this.loaded = false;
          ContractService.sendSignature(
            {
              signature_date: preloadedSignature.date,
              signature_place: preloadedSignature.place,
            },
            preloadedSignature.image,
            this.$route.params.hash,
            this.selectedContract.contract_id,
            this.selectedContract.current_sign_identity.id,
            ''
          ).then(
            response => {
              this.loaded = true;
              if (response.code == 400) {
                return this.$notification.error(response.title); // TODO: i18n - displaying backend error message
              } else if (response.code > 400) {
                return this.$notification.error(this.$t('contract.errors.signature_exists'));
              }
              if (response.state === 'completed') {
                window.dataLayer.push({'event': 'contract_proposal_sign_completed'});
              }
              this.processAcceptedOrFastsignedContract();
            },
            () => {
              this.loaded = true;
              this.$notification.error(this.$t('contract.errors.sign'));
            }
          )
        },
        routes: {
          params: {hash: this.$route.params.hash, contract_id: this.selectedContract.id},
        },
      });
    },
    getDoc(contract) {
      this.resetDocument();

      ContractService
        .getDocUnauthorized(contract || this.contract, this.$route.params.hash, 'html')
        .then(() => {
          this.fetchDocumentWithLinkAsUnregistered({
            authToken: this.$route.params.hash,
            contractId: this.selectedContract.id,
          })
          this.loaded = true;
        })
        .catch(() => {
          this.$notification.error(this.$t('contract.not_loaded'));
          this.loadedContract = true;
          this.loaded = true;
        });
    },
    async loadSignIdentities({ authToken, contractId }) {
      await this.fetchContractCurrentSignIdentity({ authToken, contractId });

      await this.fetchContractSignIdentities({ authToken, contractId })
      .then(() => {
        this.signIdentitiesSuccessfullyLoaded = true;
      })
      .catch(() => {
        this.signIdentitiesSuccessfullyLoaded = false;
      });
    },
    fetchData() {
      ContractService.contractFromApiToken({
        component: this,
        selectedContractId: this.selectedContract?.contract_id || this.$route.query.contract_id,
        onSuccess: async (response) => {
          await this.loadSignIdentities({
            authToken: this.$route.params.hash,
            contractId: this.selectedContract?.contract_id || this.$route.query.contract_id || response?.id,
          });

          this.$store.commit(
            'setContract',
            Object.assign(response, {
              sign_identities: this.contractSignIdentities,
              current_sign_identity: this.contractCurrentSignIdentity,
            }),
          );

          await publicApiRequest({
            authorization: 'ApiToken',
            authToken: this.$route.params.hash,
            method: 'GET',
            endpoint: `api/client/v1/contract/${this.$route.query?.contract_id || response?.id}/state/virtual`
          });

          const signatures = [];
          const signingSignIdentities = response?.sign_identities?.filter(
            (s) => 'approve' !== s.contract_role
          );

          for (let signIdentity of signingSignIdentities) {
            if (signIdentity?.positions?.length > 0) {
              for (let position of signIdentity.positions) {
                signatures.push({ signIdentity, position });
              }
            } else {
              signatures.push({ signIdentity, position: null });
            }
          }

          this.setSignatures(signatures);

          let changing = false;
          if (this.$route.query.contract_id) {
            for (let index in this.contract.attachments) {
              const attachment = this.contract.attachments[index];

              if (`${attachment.id}` === `${this.$route.query?.contract_id}`) {
                changing = true;
                this.changeSelectedContract(attachment);
                break;
              }
            }
          }

          if (changing) {
            return;
          }

          if (
            this.contract?.type === 'doc' ||
            this.contract?.type === 'wysiwyg'
          ) {
            this.getDoc();
          } else {
            this.loaded = true;
          }

          this.sign_identity = this.contract.sign_identity;

          this.selectedContract = this.contract;
          this.setupSticker()
        },
      });
    },
    changeSelectedContract(contract) {
      if (this.showContinueDialog) {
        this.showContinueDialog = false
      }

      this.setSignatures([]);
      this.selectedContract = contract;
      this.$store.state.currentContractId = contract?.id;

      ContractService.contractFromApiToken({
        component: this,
        selectedContractId: contract?.id,
        onSuccess: (response) => {
          this.$store.commit('setContract', response);
          this.sign_identity = contract?.sign_identity;

          const signatures = [];
          const signingSignIdentities = contract?.sign_identities?.filter(
            (s) => 'approve' !== s.contract_role
          );

          for (let signIdentity of signingSignIdentities) {
            if (signIdentity?.positions?.length > 0) {
              for (let position of signIdentity.positions) {
                signatures.push({ signIdentity, position });
              }
            } else {
              signatures.push({ signIdentity, position: null });
            }
          }

          this.setSignatures(signatures);

          if (
            this.contract?.type === 'doc' ||
            this.contract?.type === 'wysiwyg'
          ) {
            this.getDoc();
          } else {
            this.loaded = true;
          }

          this.setupSticker()
        },
      });
    },
    acceptContract() {
      if (this.backgroundLoader) {
        clearInterval(this.backgroundLoader);
      }

      WorkflowHelper.clickOnApproveLink({
        contract: this.selectedContract,
        markApproved: () => this.markApproved(),
        routes: {
          params: {hash: this.$route.params.hash, contract_id: this.selectedContract.id},
        },
      });
    },
    markApproved() {
      if (this.isProcessingSignOrApprove) {
        return; // Prevent double sending
      }
      this.isProcessingSignOrApprove = true;
      ContractService.markApprovedAsUnregistered( this.selectedContract.id, this.selectedContract.sign_identity.id, this.$route.params.hash )
        .then(() => {
          this.isProcessingSignOrApprove = false;
          this.processAcceptedOrFastsignedContract();
        }, () => {
          this.isProcessingSignOrApprove = false;
          this.$notification.error(this.$t('contract.errors.accept'));
        });
    },
    processAcceptedOrFastsignedContract() {
      for (let i in this.contract.attachments) {
        if (this.contract.attachments[i].current_sign_identity && parseInt(this.contract.attachments[i].id, 10) === parseInt(this.selectedContract.id, 10)) {
          this.contract.attachments[i].current_sign_identity.is_approved = true;
          this.contract.attachments[i].sign_identity.is_approved = true;
          this.contract.attachments[i].current_sign_identity.is_signed = true;
          this.contract.attachments[i].sign_identity.is_signed = true;
        }
      }
      // TODO: workflow - all contracts are loaded at once which complicates logging opened contract and redirects
      // - getNextUnsigned+redirect duplicated in ContractSign, CreateSign/DataContract...
      // - response can't be used, because page expects ApiInvite (at least contract_id) -> unify ApiInvite (prefer current_sign_identity, no contractId etc.)
      const unsignedAttachment = WorkflowHelper.getNextUnsignedAfterApprove(this.contract);
      if (unsignedAttachment) {
        this.changeSelectedContract(unsignedAttachment);
        this.$router.replace({ query: { 'contract_id': unsignedAttachment.contract_id } })
      } else {
        this.$router.push({
          name: 'contractComplete',
          params: {
            hash: this.$route.params.hash,
            email: this.selectedContract.current_sign_identity.email,
          },
        });
      }
    },
    getPDF() {
      if (this.contract.attachments && this.contract.attachments.length > 0) {
        ContractService.openPDFZipUnauthorized(this.contract.contract_id, this.$route.params.hash);
      } else {
        ContractService.getPDFUnauthorized( this.contract.contract_id, this.$route.params.hash ).then(response => {
          response.arrayBuffer().then((buffer) => {
            const base64Flag = 'data:application/pdf;base64,';
            const imageStr = this.arrayBufferToBase64(buffer);
            FileSaver.saveAs(base64Flag + imageStr, this.contract.contract_title +".pdf");
            this.loadingPDF = false;
          });
        })
      }
    },
    setupSticker () {
      this.sticker = null
      if (ContractHelper.isApprovalRequiredForCurrentUser(this.selectedContract)) {
        if (ContractHelper.isUnsignedOrUnapproved(this.selectedContract) && this.selectedContract.current_user.can_accept) {
          this.sticker = 'approve';
        }
      }
      if (ContractHelper.isSignatureRequiredForCurrentUser(this.selectedContract)) {
        if (ContractHelper.isUnsignedOrUnapproved(this.selectedContract)) {
          this.sticker = 'sign';
        }
      }
    }
  }
}
</script>

<style lang="scss">
.v-alert__content {
  text-align: left;
}

.contract.view a:hover {
  color: #000000;
}

.contract__view .tabs-component {
  text-align: center;
}

.contract__view .tabs-component-tab{
  @include sm{
    padding: 0 !important;
  }
}

.contract__view .tabs-component-tabs{
  display: inline-block !important;
}

.contract-signature--light{
  position: static !important;
}

.contract{
  .content con-options, .content con-text, .content con-textarea, .content con-number, .content con-date, .content con-section {
    color: black !important;
  }
}

.contract__view img{
  width: 100%;
  height: auto;
}

.contract__view .buttons .button {
  margin-top: 20px;
}

.no-signature-button {
  background-color:rgba(255, 255, 255, 0.9);
  border-radius: 6px;
  margin: auto;
  text-align: center;
  padding: 0 50px;

  @include sm {
    padding: 0 0 30px 0;
  }
}

con-sign {
  width: 100%;
  display: table;
  clear: both;
  margin-top: 80px;
  margin-bottom: 80px;
}

.contract-signature {
  &.hidden {
    display: none !important;
  }
}

con-agree {
  display: none;
}

con-agree .option-input {
  margin: 0 5px 0 0;
  top: 0;
}

// backend styles duplicated in contract_final.css
.con-sign-wrapper {
  .con-sign-item {
    float: left;
    min-width: 170px;

    h3 {
      @include md{
        margin: 20px 0 20px;
      }
    }
    con-sign {
      @include md{
        margin: 0;
      }
    }
  }
}
.con-sign-wrapper-1 {
  .con-sign-item {
    width: 100%;
    max-width: unset;
  }
}
.con-sign-wrapper-2 {
  .con-sign-item {
    width: 50%;
  }
}
.con-sign-wrapper-3 {
  .con-sign-item {
    width: 33.3%;
  }
}
</style>

<style lang="scss" scoped>
.contract-preview {
  margin: 0 auto;
  max-width: 1340px;

  &__content {
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    //overflow: hidden;
    box-shadow: 0 7px 24px 0 rgba(192, 191, 194, 0.65);
    height: 100%;

    @include md() {
      border-radius: 0;
    }

    &-tabs {
      width: 24.7%;
      background-color: #f2f6fc;
      border-right: 1px solid #d8d8d8;

      @include md() {
        width: 100%;
      }

      &-info {
        padding: 20px;
        &--row {
          padding: 10px;
          display: flex;
          justify-content: space-between;

          span.row__title {
            color: #5b5f66;
            text-align: left;
            font-size: 14px;
          }
          span.row__data {
            color: #000000;
            text-align: right;
            font-size: 14px;
          }

          a {
            text-align: left;
            font-size: 14px;
            color: #666b71;
          }
        }
      }
    }

    &-tabs.position-bottom {
      border-right: none;
    }

    &-document {
      flex: 1;
      background-color: #FFFFFF;

      &--sign-info {
        z-index: 999;
        background-color: #fefad1;
        width: 105px;
        position: absolute;
        top: 50px;
        left: 30%;
        padding: 30px 20px 20px;
        color: #8b5824;
        line-height: 20px;
        box-shadow: 0 7px 24px 0 rgba(192, 191, 194, 0.65);
        font-size: 13px;
        font-weight: initial;

        .strong-text {
          font-weight: bolder;
          cursor: pointer;
        }

        &-close::before {
          content: '\e912';
          color: #848b96;
          position: absolute;
          top: 7px;
          right: 5px;
          cursor: pointer;
        }
      }

      &--sign-info::after {
        z-index: 999;
        content:'';
        position: absolute;
        top: 100%;
        left: 0;
        border-style: solid;
        border-width: 30px 52px 0 52px;
        border-color: #fefad1 transparent transparent transparent;
        line-height: 0;
        _border-color: #007bff #000000 #000000 #000000;
        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
      }
    }

    &-previews {
      width: 7.3%;
      background-color: #f2f6fc;
      border-left: 1px solid #d8d8d8;
    }
  }

  @include md() {
    padding: 0;
    flex-direction: column;

    &__content {
      flex-direction: column;
    }
  }
}
</style>
