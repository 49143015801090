<template lang="html">
    <div class="input input-text" v-bind:class="{'invalid': invalid, 'disabled': disabled}">
        <label>
            <span v-if="label" class="input__label">{{ label }}</span>
            <textarea :placeholder="placeholder" :disabled="disabled" v-model="content" @input="handleInput" class="textarea__data"><slot/></textarea>

        </label>
        <p v-if="invalid" class="input__error">{{error}}</p>

        <div class="tip" v-if="tip">{{ $t('general.tip') }}: {{tip}}</div>
        <div class="tip" v-if="hint" v-html="hint"></div>
    </div>
</template>


<script>
    export default {
        name: 'Input',
        props: {
            value: String,
            label: String,
            validation: {
                default: true
            },
            placeholder: String,
            disabled: Boolean,
            error: String,
            search: Boolean,
            invalid: {
                default: false
            },
            tip: String,
            hint: String,
        },
        components: {

        },
        data() {
            return {

            }
        },
        created() {

        },
        computed: {
            content: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.value = value;
                },
            },
        },
        methods: {
            handleInput () {
              this.$emit('input', this.content)
            }
        }
    }
</script>


<style lang="scss">
    .textarea__data{
        outline: none;
        background: rgb(255, 255, 255);
        border: 1px solid rgb(177, 187, 203);
        border-radius: 6px;
        height: 216px;
        padding: 15px 20px;
        font-size: 17px;
        font-weight: 300;
        color: black;
        letter-spacing: -0.03px;
        max-width: 100%;
        margin: 0px;
        min-width: 100%;
        max-height: 200px;
        min-height: 150px;
    }
    .input-text.input{
        width: 100%;
    }
    .content-box{
        .textarea__data{
            font-size: 15px;
        }
    }

    .tip{
        border-radius: 6px;
        //border: 1px solid #F1EAAF;
        background: #FFFAD1;
        padding: 10px 30px;
        font-size: 15px;
        color: #73560B;
        text-align: left;
        line-height: 21px;
        font-weight: 300;
        margin-top: 15px;
        position:relative;
        margin-bottom: 25px;
        box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);

        &:before{
            content: "";
            width: 8px;
            height: 8px;
            //border: 1px solid #F1EAAF;
            background: #FFFAD1;
            position: absolute;
            top: -6px;
            transform: rotate(45deg);
            border-bottom: none;
            border-right: 0px;
            left: 25px;

            @include md{
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
            }
        }

        @include md{
            padding: 10px 15px;
        }
    }
</style>
