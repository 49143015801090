<template>
  <v-menu
    bottom
    :close-on-content-click="false"
    :max-width="$vuetify.breakpoint.mobile ? '20rem' : '25rem'"
    rounded="lg"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <a
        href="#"
        class="d-flex pr-3 text-decoration-none"
        @click.prevent=""
        v-bind="attrs"
        v-on="on"
      >
        <span
          class="d-block text-truncate font-weight-regular text-decoration-underline"
          style="color: #5b5f66; text-underline-offset: 1px"
        >
          {{ previewTitle }}
        </span>
        <v-icon small class="pl-1">mdi-pencil-outline</v-icon>
      </a>
    </template>

    <v-card>
      <v-list>
        <v-list-item
          v-for="item in filteredAvailableAuthentications"
          :key="item.value"
          class="contract-sign-settings__choice"
        >
          <v-row align="center" justify="space-between" no-gutters class="py-2" style="width: 100%">
            <v-col cols="12" class="d-flex align-center justify-space-between">
              <v-checkbox
                v-model="setting"
                color="primary"
                class="mt-0 mb-1 pr-3"
                :label="$t(item.text)"
                :value="item.value"
                :disabled="item.disabledUpdate || item.disabled"
                hide-details
              ></v-checkbox>

              <span
                class="mr-0 ml-auto px-2 py-1 contract-sign-settings__credits font-weight-medium text-truncate"
              >
                {{ $tc('general.credits', 1, { credits: item.credit_cost }) }}
              </span>
            </v-col>

            <v-col cols="12" class="ml-8">
              <v-row
                v-if="'bankId' === item.value"
                align="center"
                justify="start"
                style="width: 100%"
                no-gutters
              >
                <v-col
                  v-for="logo in bankIdLogos"
                  :key="logo.name"
                  class="pr-2"
                  :class="`col-${logoItemColLength}`"
                >
                  <v-img
                    :src="logo.image"
                    :alt="logo.name"
                    width="auto"
                    height="100%"
                    max-height="2rem"
                    contain
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import FeatureFlagsChecker from '@/common/reusable/featureFlagsChecker';

export default {
  name: 'AuthenticationsSelect',
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      defaultTitle: this.$t('general.verification'),
      bankIdLogos: [
        {
          name: 'Logo Air Bank',
          image: require('@signIdentity/assets/air_bank_logo.png'),
        },
        {
          name: 'Logo Česká spořitelna',
          image: require('@signIdentity/assets/csas_logo.png'),
        },
        {
          name: 'Logo ČSOB',
          image: require('@signIdentity/assets/csob_logo.png'),
        },
        {
          name: 'Logo Komerční banky',
          image: require('@signIdentity/assets/komercni_banka_logo.png'),
        },
        {
          name: 'Logo Moneta',
          image: require('@signIdentity/assets/moneta_logo.png'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      availableAuthentications: 'contractObject/availableAuthentications',
      contract: 'contract',
    }),
    setting: {
      get() {
        if (this.value.includes('aml') && this.value.includes('bankId')) {
          return ['select'];
        }

        return this.value || [];
      },
      set(value) {
        this.$emit('input', value?.length > 0 && 'select' === value[0] ? ['aml', 'bankId'] : value);
      },
    },
    filteredAvailableAuthentications() {
      const hasEnabledAmlVerification = FeatureFlagsChecker.hasEnabledAmlVerification(
        this.contract,
      );
      const hasEnabledBankIdVerification = FeatureFlagsChecker.hasEnabledBankIdVerification(
        this.contract,
      );

      if (hasEnabledAmlVerification && hasEnabledBankIdVerification) {
        return this.availableAuthentications;
      }

      return this.availableAuthentications?.filter((auth) => {
        if (hasEnabledAmlVerification) {
          return 'aml' === auth.value;
        }

        if (hasEnabledBankIdVerification) {
          return 'bankId' === auth.value;
        }
      });
    },
    previewTitle() {
      if (0 === this.setting.length) {
        return `${this.defaultTitle}: ${this.$t('general.without_identification')}`;
      }

      let title = `${this.defaultTitle}: `;
      this.setting.forEach((e, index) => {
        const option = this.availableAuthentications.filter((option) => option.value === e);

        if (option.length > 0) {
          title = title.concat(
            0 === index ? this.$t(option[0].text) : ` + ${this.$t(option[0].text)}`,
          );
        }
      });

      return title;
    },
    logoItemColLength() {
      return Math.floor(12 / this.bankIdLogos.length);
    },
  },
  watch: {
    setting: {
      handler: function (value) {
        this.setDisabledOptions(value);
      },
    },
    previewTitle: {
      handler: function () {
        if (0 === this.value.length) {
          this.availableAuthentications.forEach((item) => {
            item.disabledUpdate = false;
          });
        }
      },
    },
  },
  created() {
    this.setDisabledOptions(this.setting);
  },
  methods: {
    setDisabledOptions(selected) {
      this.availableAuthentications.forEach((item) => {
        selected.forEach((valueItem) => {
          item.disabledUpdate = item.value !== valueItem;
        });
      });
    },
  },
};
</script>

<style lang="scss">
.contract-sign-settings {
  &__credits {
    background-color: #6decaf;
    border-radius: 50px;
    font-size: 0.6rem;
  }

  &__logos {
    height: 30px;
    margin: 0 0.4rem;
  }

  &__choice {
    border-top: solid 1px #e7eaeb !important;
    flex-wrap: wrap;

    &:first-child {
      border-top: unset !important;
    }
  }
}
</style>
