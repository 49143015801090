import axios from 'axios';
import router from '@/router';
import store from '@/store';
import environment from '@/config/environment';
import { documentDetailPages } from '@/common/variables/contract';

/**
 * Method to create request as unregistered user
 *
 * @param authToken
 * @param method
 * @param endpoint
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const publicSignRequest = ({ authToken, method, endpoint, data }) => {
  return axios
    .request({
      method: method,
      headers: {
        Accept: 'application/json',
        Authorization: `ApiToken ${authToken}`,
        'Content-Type': 'application/json',
      },
      url: `${environment.getApiUrl()}/${endpoint}`,
      data: !data ? null : data,
    })
    .then((resp) => {
      return resp?.data;
    })
    .catch((err) => {
      const errorResponse = err?.response?.data;

      if ('Invalid api token' === errorResponse?.message) {
        return router.push({ name: 'public-sign-expired-link' });
      }

      throw errorResponse;
    });
};

/**
 * Method to fetch document info for unregistered user waiting for success response
 *
 * @type {(function(*=, *=, *=): void)|*}
 */
export const circularFetchDocumentWithLinkAsUnregistered = (function () {
  return function ({ authToken, contractId, timeout, next }) {
    publicSignRequest({
      authToken,
      method: 'GET',
      endpoint: `api/client/v1/contracts/${contractId}/document`,
    })
      .then(async (resp) => {
        next(resp);
      })
      .catch(() => {
        if (
          documentDetailPages.includes(router.currentRoute.name) &&
          'doc' === store.getters['contract']?.type
        ) {
          return setTimeout(function () {
            circularFetchDocumentWithLinkAsUnregistered({
              authToken,
              contractId,
              timeout,
              next,
            });
          }, timeout);
        }
      });
  };
})();
