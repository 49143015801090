<template>
  <div :style="`margin: 0 auto; max-width: 470px; ${$vuetify.breakpoint.mobile ? 'width: 100%' : 'width: 320px'}`">
    <span style="color: #000000; margin: 0 auto 1rem !important; max-width: 470px">
      {{ $t('contract.prefill.katuz') }}:
    </span>
    <div v-if="selectedOption || isLoading">
      <div>
        <div class="input__data" style="line-height: 56px">
          <template v-if="selectedOption">
            {{ selectedOption.name }} ({{ selectedOption.code }})
          </template>
        </div>

        <div>
          <a v-if="selectedOption" @click="deselectOption()">
            {{ $t('general.change') }}
          </a>
        </div>
      </div>
    </div>
    <Search
      v-else-if="allOptions.length"
      :query="query"
      :results="filteredOptions"
      :validation="true"
      :loading="false"
      :canShowResults="true"
      :isWorkspaceInput="true"
      :minLength="2"
      @onChange="changeQuery"
      @onResetQuery="resetQuery"
      @onChangeDebounced="findByQuery"
      @onSearch="findByQuery"
      @onSelectResult="selectOption"
    >
      <template v-slot:searchResult="katastralniUzemi" @resultClick="resultClick">
        <p class="search__item-text">
          {{ katastralniUzemi.name }} ({{ katastralniUzemi.code }})
        </p>
      </template>
      <template v-slot:notFoundDialog="{result, closeDialog}">
        <p>{{ $t('contract.prefill.katastr_unknown') }}</p>
      </template>
    </Search>
  </div>
</template>

<script>
  import Search from "./Search.vue";
  import { KatastrService } from '@/services/KatastrService.js';

  export default {
    name: 'KatastrSearch',
    components: {
      Search,
    },
    props: {
      defaultKatastralniUzemi: {
        required: true,
      }
    },
    data() {
      return {
        isLoading: true,
        query: '',
        allOptions: [],
        filteredOptions: [],
        selectedOption: null,
      };
    },
    created() {
      this.preloadUzemi(this.defaultKatastralniUzemi);
    },
    methods: {
      async preloadUzemi(preselectedCode) {
        if (this.allOptions.length) {
          return;
        }
        const response = await KatastrService.getKatastralniUzemi(preselectedCode);;
        this.selectedOption = response.selected;
        this.allOptions = response.options;
        this.isLoading = false;
      },
      changeQuery(query) {
        this.query = query;
      },
      resetQuery() {
        this.query = '';
        this.filteredOptions = this.limitedOptions(this.allOptions);
      },
      findByQuery(query) {
        this.filteredOptions = this.limitedOptions(
          this.allOptions.filter(uzemi => `${uzemi.name} ${uzemi.code}`.toLowerCase().indexOf(query.toLowerCase()) !== -1)
        );
      },
      limitedOptions(options) {
        return options.slice(0, 20); // too many options in original list
      },
      selectOption(selectedUzemi) {
        this.selectedOption = selectedUzemi;
        this.$emit('changeKatastralniUzemi', selectedUzemi.code);
      },
      deselectOption() {
        this.selectedOption = null;
        this.resetQuery();
      },
    },
  }
</script>

<style lang="scss" scoped>
  .identity-modal-form .land-registry__input-wrapper {
    .input-search {
      margin: 0 auto 1rem !important;
      max-width: 470px;
      width: 320px;

      @include md() {
        width: 100%;
      }
    }

    .input__buttons {
      position: absolute;
      top: 20px;
      right: -60px;
      text-align: left;
      cursor: pointer;
    }
  }
</style>
