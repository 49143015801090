<template lang="html">
  <div v-if="!completed" class="invitation">
    <div class="invitation__ico">
      <IcoPeople />
    </div>
    <div class="invitation__content">
      <div class="invitation__data">
        <h2 class="invitation__title">{{ $t('workspace.invitation.dashboard.title') }}</h2>
        <div class="invitation__desc">
          <p>{{ $t('workspace.invitation.dashboard.item', { user: invitation.user_full_name, team: invitation.workspace_title }) }}</p>
        </div>
      </div>
      <div class="invitation__custom-inputs" v-if="inputs && inputs.length">
        <strong>{{ $t('workspace.invitation.dashboard.inputs') }}</strong>
        <WorkspaceCustomInput
          :inputs="inputs"
          :errors="errors"
          :disabledNames="true"
          :invitationForm="true"
          @change="setCustomInput"
        />
      </div>
      <div class="invitation__controll">
        <div class="invitation__controll__input">
          <v-btn
            color="primary"
            x-large
            @click="acceptInvitation()"
          >
            <v-icon>mdi-check</v-icon>
            {{ $t('general.accept') }}
          </v-btn>
        </div>
        <div class="invitation__controll__input">
          <v-btn
            color="primary"
            text
            outlined
            x-large
            @click="declineInvitation()"
          >
            {{ $t('general.decline') }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { DashboardService } from '@/services/DashboardService.js';
  import IcoPeople from '@/components/svg/IcoPeople.vue';
  import WorkspaceCustomInput from "@/components/WorkspaceCustomInput";

  export default {
    name: 'Invitation',
    components: {
      IcoPeople,
      WorkspaceCustomInput,
    },
    props: ['invitation'],
    data() {
      return {
        completed: false,
        inputs: [],
        errors: [],
        sentCustomInputs: false,
      }
    },
    created() {
      this.inputs = this.invitation.custom_inputs;
    },
    methods: {
      acceptInvitation() {

        this.sentCustomInputs = true;
        this.validateCustomInputs();

        if (this.errors.length > 0) {
          return;
        }

        DashboardService.acceptInvivation(this.invitation.id, this.inputs).then(response => {
          this.$notification.success(this.$t('workspace.invitation.accepted.ok'));
          this.$store.dispatch('getUserProfile');
          this.completed = true
        }, error => {
          this.$notification.error(this.$t('workspace.invitation.accepted.failed'));
        });
      },

      declineInvitation() {
        DashboardService.declineInvitation(this.invitation.id).then(response => {
          this.$notification.success(this.$t('workspace.invitation.declined.ok'));
          this.completed = true;
        }, error => {
          this.$notification.error(this.$t('workspace.invitation.declined.failed'));
        });
      },

      setCustomInput(inputs) {
        this.inputs = inputs;
        if (this.sentCustomInputs) {
          this.validateCustomInputs();
        }
      },

      validateCustomInputs() {
        let errors = [];
        _.each(this.inputs, (customInput) => {
          if (!customInput.value) {
            errors.push(customInput.id);
          }
        });
        this.errors = errors;
      },
    }
  }
</script>


<style lang="scss">
  .invitation{
    width: 100%;
    max-width: 845px;
    position: relative;
    padding: 33px;
    background: #E9FBF4;
    box-shadow: 0 12px 24px 0 rgba(0,0,0,0.05);
    border-radius: 6px;
    margin: {
      bottom: 15px;
      left: auto;
      right: auto;
    }

    @include sm{
      text-align: center;
      padding: 30px 0;
    }

    &__custom-inputs {
      > strong {
        font-size: 14px;
        color: #000;
        margin-bottom: 10px;
        display: block;
        margin-top: 20px;
      }

      .workspace-custom-inputs {
        margin: 0;
      }
    }
  }

  .invitation__ico{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 33px;

    @include sm{
      // padding-top: 3.3rem;
      position: static;
      transform: translate(0, 0);
    }
  }

  .invitation__content{
    padding-left: 80px;
    padding-right: 380px;

    @include lg{
      padding-right: 260px;
    }

    @include md{
      padding-right: 0;
    }

    @include sm{
      padding-left: 0;
    }
  }

  .invitation__title{
    margin: 11px 0 0 0;
    color: #074E2C;

    @include md {
      text-align: center;
    }
  }

  .invitation__desc{
    margin-top: 3px;
  }

  .invitation__desc,
  .invitation__desc p{
    color: #074E2C;
  }

  .invitation__controll{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 33px;
    width: 360px;
    text-align: right;
    vertical-align: middle;

    @include md{
      position: static;
      width: 100%;
      transform: translate(0, 0);
      text-align: left;
    }

    @include sm{
      margin-top: 15px;
    }
  }

  .invitation__controll__input{
    display: inline-block;
    padding: 0 .5rem;

    @include sm{
      text-align: center;
      display: block;
    }
  }
</style>
