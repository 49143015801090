import Environment from '@/config/environment';

export default function GoogleDrive() {
  this.isDevelopment = Environment.isDevelopment();

  this.production = {
    clientId: '837969300817-lu6ohd94c4oj22snuq7m5666lbi9g9fh.apps.googleusercontent.com',
    devKey: 'AIzaSyDWFBM9dQJgG4-q_MgHkmFRcL4O216wXrQ',
  };

  this.development = {
    clientId: '667186793349-a0cnscbb7brcfvsg26isiq6fe8h1u7mc.apps.googleusercontent.com',
    devKey: 'AIzaSyDRxvnhStQVXc6HPLTWConqqiI5ZtDlBYE',
  };

  this.getClientId = () => {
    return this.isDevelopment ? this.development.clientId : this.production.clientId;
  };

  this.getDeveloperKey = () => {
    return this.isDevelopment ? this.development.devKey : this.production.devKey;
  };
}
