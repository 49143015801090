import { hexToRgbaStyle } from '@/common/reusable/colorFunctions';
import FeatureFlags from '@/common/reusable/featureFlagsChecker';

export const isLoading = (state) => {
  return state.isLoading;
};

export const isReady = (state) => {
  return !state.isLoading;
};

export const hasError = (state) => {
  return !!state.error;
};

export const error = (state) => {
  return state.error;
};

export const contract = (state) => {
  return state.contract;
};

export const activeContractId = (state) => {
  return state.activeContract?.contract_id;
};

export const currentSignIdentity = (state) => {
  return state.contractCurrentSignIdentity;
};

export const signIdentities = (state) => {
  return state.contractSignIdentities;
};

export const settings = (state) => {
  return state.contract?.settings;
};

export const history = (state) => {
  return state.history;
};

export const avatar = (state, getters) => {
  return getters['contract']?.avatar_url;
};

export const logo = (state, getters) => {
  return getters['settings']?.logo || null;
};

export const primaryColor = (state, getters) => {
  return getters['settings']?.primary_color || '#5e239e';
};

export const primaryTextColor = (state, getters) => {
  return getters['settings']?.textColor || '#000000';
};

export const layerColor = (state, getters) => {
  return hexToRgbaStyle(getters['settings']?.layerColor || '#ffffff', 0.9);
};

export const hasFlattenFirstScreen = (state, getters) => {
  return FeatureFlags.hasFlattenFirstScreen(getters['contract']);
};
