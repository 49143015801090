<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ico-N/ico-24-delete.gray</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-24-delete.gray" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group" transform="translate(6.000000, 4.000000)" fill-rule="nonzero" stroke="#B4C1D6" stroke-width="2">
        <path d="M0.754368327,3.5 C1.00808646,9.53622147 1.1879951,12.7921456 1.29409424,13.2677724 C1.45324295,13.9812127 2.14635502,15.0015212 3.02119284,15.0015212 C3.89603066,15.0015212 7.95684322,15.0015212 8.72906252,15.0015212 C9.50128183,15.0015212 10.5622247,14.4424689 10.6769372,13.2677724 C10.7534121,12.4846414 10.9689966,9.22871729 11.3236906,3.5" id="Path-46"></path>
        <path d="M3.8306307,5.54614445 L4.36205765,11.8616862" id="Path-47"></path>
        <path d="M7.50343318,5.54614445 L8.03486013,11.8616862" id="Path-47-Copy" transform="translate(7.769147, 8.703915) scale(-1, 1) translate(-7.769147, -8.703915) "></path>
        <path d="M0.0468162719,2.87334151 L11.8001765,2.87334151 C11.830582,1.81363034 11.5387694,1.28377476 10.9247386,1.28377476 C10.0036925,1.28377476 1.97532417,1.28377476 1.01107022,1.28377476 C0.368234255,1.28377476 0.0468162719,1.81363034 0.0468162719,2.87334151 Z" id="Path-49"></path>
        <path d="M3.91499408,1.1911605 C4.10150773,0.468230081 4.42048527,0.106764874 4.87192671,0.106764874 C5.54908886,0.106764874 6.60503935,0.106764874 7.12593332,0.106764874 C7.47319597,0.106764874 7.73267337,0.468230081 7.90436553,1.1911605" id="Path-50"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IcoTrash",
};
</script>
