export function parseDate(date) {
    if (date) {
      var full_date_arr = date.split(" ");
      var date_arr = full_date_arr[0].split("-");
      return parseInt(date_arr[2]) + '. ' + parseInt(date_arr[1]) + '. ' + parseInt(date_arr[0]);
    }

    return '';
}

export function getCurrentDate() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    let time = with_leading_zeros(today.getHours()) + ':' + with_leading_zeros(today.getMinutes()) + ':' + with_leading_zeros(today.getSeconds());

    return dd + '. ' + mm + '. ' + yyyy + ' ' + time;
}

function with_leading_zeros(item) {
    return (item < 10 ? '0' : '') + item;
}
