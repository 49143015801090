<template>
  <div class="contract-layout-wrapper" :key="locale" :style="`background-image: url(${backgroundImage})`">
    <div>
      <Loader v-if="isLoading" />

      <div v-else class="contract-layout">
        <div v-if="isPreview" class="contract-layout__document-header">
          <div class="contract-layout__document-header-logo" :style="`background-color: ${layerColor}`">
            <div v-if="!isMobileDevice" class="contract-layout__document-header-logo-item"></div>
            <Logo class="contract-layout__document-header-logo-item" :logo="contract.settings ? contract.settings.logo : null" :white="false"/>

            <div class="contract-layout__document-header-logo-item contract__language-select dark text-right">
              <LanguageToggle :locale="currentLocale" @onLocaleChanged="changeLocale($event)" />
            </div>
          </div>

          <div v-if="isMobileDevice" class="contract-layout__document-header-title-mobile">
            <DocumentActionsSummarization />
            <h1>{{ contract.contract_title }}</h1>
            <v-row class="contract-layout__document-header-title-mobile-row" align="center" justify="space-between">
              <v-col class="additional-questions">
                <IcoQuestions />
                <a
                  :href="$t('links.faq')"
                  target="_blank"
                  class="view-pdf"
                >{{ $t('signing.signing_faq') }}</a>
              </v-col>
              <v-col cols="auto">
                <v-menu v-model="openDotsMenu" transition="slide-y-transition" offset-y offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#5e239e"
                      class="pl-3 pr-1"
                      outlined
                      x-large
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t('general.more') }}
                      <v-icon class="pa-0 ma-0 pl-1"> mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>

                  <v-list max-width="17rem">
                    <v-list-item class="font-weight-medium" link @click="download()">
                      <v-list-item-icon class="mr-4">
                        <IcoDownload />
                      </v-list-item-icon>
                      {{ $t('contract.download_all') }}
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      class="font-weight-medium"
                      :class="{
                        'd-none': contract.current_user && !contract.current_user.can_reject_some_contract
                      }"
                      link
                      @click="rejectRequest = true"
                    >
                      <v-list-item-icon class="mr-4">
                        <IcoRejectRed />
                      </v-list-item-icon>
                      {{ $t('general.decline') }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </div>

          <div v-else class="contract-layout__document-header-title">
            <v-row class="contract-layout__document-header-title-row" align="center" justify="space-between">
              <v-col>
                <h1>{{ contract.contract_title }}</h1>
              </v-col>
              <v-col cols="auto">
                <v-menu v-model="openDotsMenu" transition="slide-y-transition" offset-y offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#5e239e"
                      class="pl-3 pr-1"
                      outlined
                      x-large
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t('general.more') }}
                      <v-icon class="pa-0 ma-0 pl-1"> mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>

                  <v-list max-width="17rem">
                    <v-list-item class="font-weight-medium" link @click="download()">
                      <v-list-item-icon class="mr-4">
                        <IcoDownload />
                      </v-list-item-icon>
                      {{ $t('contract.download_all') }}
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      class="font-weight-medium"
                      :class="{
                        'd-none': contract.current_user && !contract.current_user.can_reject_some_contract
                      }"
                      link
                      @click="rejectRequest = true"
                    >
                      <v-list-item-icon class="mr-4">
                        <IcoRejectRed />
                      </v-list-item-icon>
                      {{ $t('general.decline') }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <div class="contract-layout__document-header-title-info" style="display: flex;">
              <DocumentActionsSummarization />
              <div class="additional-questions">
                <IcoQuestions />
                <a
                  :href="$t('links.faq')"
                  target="_blank"
                  class="view-pdf"
                >{{ $t('signing.signing_faq') }}</a>
              </div>
            </div>
          </div>
        </div>

        <div class="contract-layout__content-page" :class="{'turn-off-padding': isMobileDevice && isPreview }">
          <div v-if="!isPreview" class="contract__language-select text-right">
            <LanguageToggle :locale="currentLocale" @onLocaleChanged="changeLocale($event)" />
          </div>

          <div class="contract-layout__content-page-content">
            <slot name="content" />
          </div>

          <v-toolbar class="signing-layout__footer mx-auto" width="100%" dense flat dark>
            <v-spacer></v-spacer>
            <span>Powered by</span>
            <img src="/images/signi_cz_rgb_color_neg_transparent.png" width="auto" height="25px" alt="">
            <v-spacer></v-spacer>
          </v-toolbar>

        </div>
      </div>
    </div>

    <RejectContractDialog v-model="rejectRequest" />
  </div>
</template>

<script>
import { VDialog } from 'vuetify/lib'
import { mapGetters } from 'vuex'
import { ContractService } from "@/services/ContractService"
import { hexToRgbaStyle } from '@/common/reusable/colorFunctions';
import { changeLocaleWithoutSave, getCurrentLocale } from "@/plugins/i18n"
import Loader from "@/components/Loader"
import LanguageSelect from "@/plugins/i18n/LanguageSelect"
import FileSaver from 'file-saver'
import IcoDownload from "@/components/svg/IcoDownload"
import IcoRejectRed from "@/components/svg/IcoRejectRed"
import DocumentState from "@/views/dashboard/components/DocumentState";
import IcoQuestions from "@/components/svg/IcoQuestions";
import LanguageToggle from "@/plugins/i18n/components/LanguageToggle";
import DocumentActionsSummarization from '@publicSign/components/DocumentActionsSummarization';
import RejectContractDialog from '@/features/publicSign/components/RejectContractDialog';

export default {
  name: "ContractLayout",
  components: {
    DocumentActionsSummarization,
    LanguageToggle,
    IcoQuestions,
    DocumentState,
    IcoRejectRed,
    IcoDownload,
    Loader,
    LanguageSelect,
    RejectContractDialog,
  },
  data() {
    return {
      actualLocale: this.$t.locale,
      openDotsMenu: false,
      rejectRequest: false,
    }
  },
  props: {
    backgroundImage: {
      type: String,
      default: '/images/contract-desktop-bg.20200622.jpg'
    },
    contract: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    landscapeSigning: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters({
      isMobileDevice: "window/isMobileDevice"
    }),
    locale () {
      return this.actualLocale
    },
    currentLocale() {
      return getCurrentLocale(this)
    },
    signatureWrapper () {
      return this.landscapeSigning ? VDialog : 'div'
    },
    layerColor () {
      return hexToRgbaStyle(this.contract?.settings?.layerColor || '#ffffff', 0.9)
    },
  },
  methods: {
    changeLocale(locale) {
      localStorage.setItem('sign_app_locale', locale)
      const isChangedLocale = changeLocaleWithoutSave(this, locale)

      if (isChangedLocale) {
        this.actualLocale = locale
      }
    },
    download() {
      if (this.contract.attachments && this.contract.attachments.length > 0) {
        ContractService.openPDFZipUnauthorized(
          this.contract.contract_id,
          this.$route.params.hash
        )
      } else {
        ContractService
          .getPDFUnauthorized(
            this.contract.contract_id,
            this.$route.params.hash
          )
          .then((response) => {
            response.arrayBuffer().then((buffer) => {
              const base64Flag = 'data:application/pdf;base64,'
              const imageStr = this.arrayBufferToBase64(buffer)
              FileSaver.saveAs(base64Flag + imageStr, this.contract.contract_title + '.pdf')
            })
          })
      }
    },
    arrayBufferToBase64(buffer) {
      let binary = '';
      let bytes = [].slice.call(new Uint8Array(buffer));
      bytes.forEach((b) => binary += String.fromCharCode(b));
      return window.btoa(binary);
    },
  },
}
</script>

<style lang="scss" scoped>
.signing-layout {
  &__footer {
    max-width: 90%;
    width: 100%;
    border-top: solid 1px rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.1) !important;
    border-radius: 8px;
    font-size: .8rem;

    svg {
      display: inline-block;
      position: relative;
      top: .3rem;
      left: .4rem;
    }
  }
}

.contract-layout-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;
}

.contract-layout {
  &__document-header {
    background: #FFFFFF;

    .contract__language-select {
      padding-right: 50px !important;

      @include md {
        padding-right: 15px !important;
      }
    }

    &-logo {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d8d8d8;

      &-item {
        flex: 1;
      }

      .logo {
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
        padding: 10px;

        @include md {
          text-align: left;
        }
      }
    }

    &-title-mobile {
      padding: 20px 20px;

      h1 {
        text-align: left;
        font-weight: bold;
        word-wrap: break-word;
        overflow: hidden;
      }

      &-row {
        display: flex;
        a {
          text-align: left;
        }
      }
    }

    &-title {
      padding: 20px 50px;

      & > div > a {
        color: #808388 !important;
        font-weight: initial;
      }

      &-row {
        display: flex;

        h1 {
          text-align: left;
          font-weight: bold;
          word-wrap: break-word;
          //overflow: hidden;
        }
      }

      &-info {
        display: flex;
        align-items: center;
        align-content: center;

        & > * {
          padding: 0 20px 5px 0;
        }
      }
    }
  }

  &__content-page {
    padding: 20px 50px 0;

    @include md {
      padding: 15px 15px;

      &.turn-off-padding {
        padding: 0;
      }
    }
  }

  &__content-page-footer {
    width: 100%;
    border-top: solid 1px rgba(255, 255, 255, 0.1);

    &-powered-by {
      display:flex;
      align-items: center;
      padding: 20px 0;
      color: #fff;
      font-size: 13px;
      font-weight: lighter;

      @include md {
        text-align: center;
        padding: 10px 0;
        justify-content: center;
      }

      svg {
        display: inline-block;
        position: relative;
        top: 4px;
        left: 7px;
      }
    }
  }
}

.dots-action {
  flex: 10%;
  text-align: right;
  cursor: pointer;
  position: relative;
}

.dots-action:after {
  color: #5e239e;
  content: '\2807';
  font-size: 30px;
  padding: 8px 10px 8px 17px;
  border: 1px solid #5e239e;
  border-radius: 6px;
}

.dots-action-active {
  background: white;
  border-radius: 6px;
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.65);
  position: absolute;
  width: 240px;
  top: 60px;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 550;

  &::after {
    content: '';
    position: absolute;
    top: -5px;
    right: 20px;

    background-color: #ffffff;
    transform: rotate(-45deg);
    height: 10px;
    width: 10px;
  }

  div {
    display: flex;
    flex-shrink: 0;
    margin: 10px;

    & > a {
      margin-top: 1px;
      flex: 90%;
      text-align: left;
      padding-left: 12px;
      color: #5e239e;
      font-weight: initial;
      font-size: initial;
    }

    & > svg {
      position: relative;
      top: -3px;
    }
  }

  div.reject-button {
    border-top: 1px solid #e0e3e8;
    padding-top: 20px;
  }
}

.additional-questions {
  display: flex;
  align-items: center;

  a {
    margin-top: 3px;
    flex: 90%;
    color: #808388;
    font-weight: initial;
    margin-left: 8px;
  }

  @include md {
    align-items: unset;
  }
}
</style>
