import axios from 'axios';
import environment from '@/config/environment';
import { authHeader } from '@/config/authHeader';

/**
 * @param requestSettings.method
 * @param requestSettings.path
 * @param requestSettings.data
 * @param requestSettings.headers
 * @param requestSettings.responseType
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const internalApiRequest = (requestSettings) => {
  const requestConfig = {
    method: requestSettings.method,
    headers: {
      ...authHeader(),
      ...requestSettings.headers,
    },
    url: `${environment.getApiUrl()}/${requestSettings.path}`,
  };

  if (requestSettings.data) {
    requestConfig.data = requestSettings.data;
  }

  if (requestSettings.responseType) {
    requestConfig.responseType = requestSettings.responseType;
  }

  return axios.request(requestConfig).then((resp) => resp.data);
};
