<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
    <title>ico-N/ico-24-odmitnout.red</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-24-odmitnout.red" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
      <path d="M5.5,6.5 L17.2686023,18.2686023" id="Line-3" stroke="#CD0000" stroke-width="2"></path>
      <path d="M5.5,6.5 L17.2686023,18.2686023" id="Line-3-Copy" stroke="#CD0000" stroke-width="2" transform="translate(11.500000, 12.500000) scale(-1, 1) translate(-11.500000, -12.500000) "></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IcoRejectRed"
}
</script>

<style lang="scss" scoped></style>
