<template lang="html">
    <svg width="125px" height="141px" viewBox="0 0 125 141" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Mobil" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-98.000000, -142.000000)">
                <g transform="translate(98.000000, 142.000000)">
                    <g>
                        <circle stroke="#6DECAF" stroke-width="2" fill-rule="nonzero" cx="63" cy="63" r="53"></circle>
                        <circle stroke="#E8EDF5" fill-rule="nonzero" cx="62.5" cy="62.5" r="62"></circle>
                        <g id="ico/ico-smlouva" transform="translate(24.000000, 25.000000)">
                            <g id="Group-5" transform="translate(38.500000, 37.000000) scale(-1, 1) translate(-38.500000, -37.000000) translate(20.000000, 9.000000)">
                                <path d="M2.3863185e-13,10.8416032 C1.28881919e-13,38.3841883 1.28881919e-13,52.7061529 2.3863185e-13,53.8074971 C4.03219186e-13,55.4595134 1.25014548,56 2.70647363,56 C4.16280177,56 33.3422152,56 34.934911,56 C36.5276068,56 37,54.9071985 37,53.0918862 C37,51.276574 37,3.85207611 37,2.42381145 C37,0.995546794 36.1452554,-1.54199293e-14 34.4599079,0 C33.336343,1.02799529e-14 25.3686229,1.02799529e-14 10.5567478,0 L2.3863185e-13,10.8416032 Z" id="Path-10" stroke="#6DECAF" stroke-width="2" fill-rule="nonzero"></path>
                                <path d="M0.855216634,11 C5.70600019,11 8.61719999,11 9.58881602,11 C11.0462401,11 10.9998337,9.45093376 10.9998337,7.42453897 C10.9998337,6.07360911 10.9998337,3.59876278 10.9998337,3.80251386e-14" id="Path-16" stroke="#6DECAF" stroke-width="2" fill-rule="nonzero"></path>
                                <path d="M8.51275377,20 L13.2947163,20" id="Path-12" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                                <path d="M16.1850161,20 L29.7636054,20" id="Path-12-Copy" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                                <path d="M8.51275377,28 L13.2947163,28" id="Path-12-Copy-5" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                                <path d="M16.1850161,28 L29.7636054,28" id="Path-12-Copy-4" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                                <path d="M8.51275377,36 L13.2947163,36" id="Path-12-Copy-9" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                                <path d="M16.1850161,36 L29.7636054,36" id="Path-12-Copy-8" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                                <path d="M8.51275377,24 L22.1595954,24" id="Path-12-Copy-3" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                                <path d="M24.8753133,24 L29.7636054,24" id="Path-12-Copy-2" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                                <path d="M8.51275377,32 L22.1595954,32" id="Path-12-Copy-7" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                                <path d="M24.8753133,32 L29.7636054,32" id="Path-12-Copy-6" stroke="#D4DFF0" stroke-width="2" fill-rule="nonzero"></path>
                                <polyline id="Path-13" stroke="#6DECAF" stroke-width="2" fill-rule="nonzero" transform="translate(22.744186, 44.899692) scale(-1, 1) translate(-22.744186, -44.899692) " points="16 44.5320806 17.352554 46.7993848 19.1577136 43 21.3539692 46.7993848 23.0808439 43.5795037 24.6228607 46.7993848 29.4883711 44.5320806"></polyline>
                            </g>
                        </g>
                    </g>
                    <g transform="translate(40.000000, 96.000000)" fill-rule="nonzero">
                        <circle fill="#6DECAF" cx="22.5" cy="22.5" r="22.5"></circle>
                        <polyline stroke="#FFFFFF" stroke-width="4" stroke-linecap="round" points="12.7358491 24.0545915 19.3855151 30.7042575 33.1086405 16.9811321"></polyline>
                    </g>
                </g>
            </g>
        </g>
    </svg>

</template>

<script>
    export default {
        name: 'IcoContractCreated'
    }
</script>
