import { hostname } from '@/services/helpers/variables.js'
import { parseJSON } from '@/services/helpers/json.js'

export function fetchNews () {
  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }

  return fetch(`${hostname}/api/v1/news`, requestOptions).then(handleResponse)
}

function handleResponse (response) {
  return response
    .text()
    .then(
      (text) => {
        const data = parseJSON(text)

        if (!response.ok) {
          const error = (data && data.message) || response.statusText

          return Promise.reject(error)
        }

        return data
      }
    )
}
