<template lang="html">
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
    <title>ico-N/ico-24-home.gray</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-24-home.gray" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M7.00213699,7.47137877 L12.002137,4 L22.204274,10.4546464 L21.204274,12.1866972 L19.002137,10.9152928 L19.002137,20 L5.00213699,20 L5.00213699,10.9747205 L2.8,12.2461249 L1.8,10.514074 L5.00213699,8.66531939 L5.00213699,3 L7.00213699,3 L7.00213699,7.47137877 Z M17.002137,9.76059229 L12.3941584,6.70693489 L7.00213699,9.90614268 L7.00213699,18 L17.002137,18 L17.002137,9.76059229 Z" id="Combined-Shape" fill="#B4C1D6" fill-rule="nonzero"></path>
    </g>
</svg>
</template>

<script>
  export default {
    name: 'IcoMenuHome',
  }
</script>
