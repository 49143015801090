<template lang="html">
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="27">
    <rect width="40" height="27" fill="#005BBB" />
    <rect width="40" height="13" y="14" fill="#FFD500" />
  </svg>
</template>

<script>
export default {
  name: 'IcoFlagUA',
};
</script>
