import { getSignSettings } from '@contract/services/WorkspaceSignSettingsService';

export const namespaced = true;

export const state = {
  data: null,
  authentications: [],
  verifications: [],
};

export const mutations = {
  setAuthenticationOptions: (state, payload) => {
    payload.available_signs.forEach((verification) => {
      verification.disabled = '1' === verification.disabled;
    });

    payload.available_authentication.forEach((authentication) => {
      authentication.disabled = '1' === authentication.disabled;
    });

    if (payload?.available_authentication?.length > 1) {
      payload.available_authentication.push({
        credit_cost: 5,
        disabled: false,
        text: 'verification.can_select',
        value: 'select',
      });
    }

    state.verifications = payload.available_signs;
    state.authentications = payload.available_authentication;
  },
};

export const actions = {
  fetchSignSettings: ({ commit }, workspaceId) => {
    return getSignSettings(workspaceId).then((resp) => {
      commit('setAuthenticationOptions', resp);
    });
  },
};

export const getters = {
  availableAuthentications: (state) => {
    return state.authentications;
  },
  availableVerifications: (state) => {
    return state.verifications;
  },
};
