<template lang="html">
  <ContractLayout
    :background-image="contract.settings && contract.settings.background ? contract.settings.background : `/images/contract-desktop-bg.20200622.jpg`"
    :contract="contract"
    :is-loading="isLoading"
    is-preview
  >
    <template v-slot:content>
      <div class="contract view" style="max-width: 1000px" >
        <div class="contract__data contract__data--show" style="width: 100% !important;">
          <template v-if="!isLoading">
            <SigniModal v-model="showModal" :persistent="false">
              <div class="contract-complete__modal text-center">
                <div class="modal-container__icon">
                  <IcoSigned />
                </div>

                <div class="modal-header modal-container__head">
                  <h1>{{ $t('signing.signature_attached_message') }}</h1>
                </div>

                <div class="modal-body modal-container__message">
                  <p v-html="$t('signing.signature_attached_notice')"></p>
                </div>

                <div class="modal-container__button">
                  <v-btn
                    :color="contractPrimaryColor"
                    max-width="12rem"
                    width="100%"
                    x-large
                    dark
                    @click="completeContract"
                  >
                    {{ $t('general.close') }}
                  </v-btn>
                </div>
              </div>
            </SigniModal>
          </template>
        </div>
      </div>
    </template>
  </ContractLayout>
</template>


<script>
import { mapGetters } from 'vuex';
import { ContractService } from '@/services/ContractService.js';
import { goToLinkWithTarget } from "@/common/reusable/routing";
import ContractLayout from "@/views/contract/components/ContractLayout";
import Loader from '@/components/Loader.vue';
import ContractTabsClient from '@/components/ContractTabsClient.vue';
import DetailSignatures from '@/components/contract/DetailSignatures.vue';
import Modal from "@/components/Modal";
import IcoSigned from "@/components/svg/IcoSigned";
import SigniModal from "@/components/SigniModal";

export default {
  name: 'contractComplete',
  components: {
    SigniModal,
    IcoSigned,
    Modal,
    ContractLayout,
    Loader,
    ContractTabsClient,
    DetailSignatures,
  },
  data() {
    return {
      loaded: false,
      sign_identity: '',
      showImages: '',
      selectedContract: this.contract,
      showModal: false,
    }
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
    }),
    contractPrimaryColor() {
      return this.contract?.settings?.primary_color || 'rgb(94, 35, 158)';
    },
    isLoading() {
      return !this.loaded;
    }
  },
  mounted() {
    ContractService.contractFromApiToken({
      component: this,
      isCompletedContractAllowed: true,
      onSuccess: (response) => {
        this.$store.commit('setContract', response);

        setTimeout(() => {
          this.loaded = true;
          this.showModal = true
        }, 500);
      },
    });
  },
  methods: {
    completeContract() {
      const redirectUrl = this.contract?.url_redirect || this.contract?.settings?.url_redirect || undefined;

      if ('undefined' === typeof redirectUrl) {
        return this.$router.push({
          name: 'contractConfirm',
          params: {
            email: this.$route.params.email,
            hash: this.$route.params.hash
          }
        });
      }

      return goToLinkWithTarget(redirectUrl, '_self');
    },
  },
}
</script>

<style lang="scss">
.contract-complete__modal {
  .modal-container {
    padding: 100px 90px;
    transition: all 0.3s ease;

    @include md() {
      padding: 50px 40px;
    }

    &__icon {
      margin-bottom: 50px;
      @include sm() {
        margin-bottom: 25px;
      }
    }

    &__head h1 {
      font-weight: bold;
    }

    &__message {
      padding: 0 50px 25px;
      @include md() {
        padding-left: 20px;
        padding-right: 20px;
      }

      p {
        font-size: initial;
        font-weight: initial;
      }
    }

    &__button {
      .button {
        width: fit-content !important;
        padding: 0 50px;
        background-color: #5d9822 !important;

        span {
          color: #ffffff;
        }
      }
    }
  }
}

.contract-signature--light{
  position: static !important;
}

.message-enter,
.message-leave-to {
  opacity: 0;
}

.message-enter-active,
.message-leave-active {
  transition: opacity 0.7s ease-out;
}

.contract__data-complete {
  position: absolute;
  top: -95px;
  left: 0;
  background-color: #fefad1;
  border-radius: 6px;
  width: 400px;
  max-width: 90vw;
  padding: 20px;
  margin: 0 auto;
  text-align: left !important;
  z-index: 500;

  span {
    display: initial !important;
    color: #8f5b2e;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 15px;
    font-weight: initial;
    line-height: 1.4 !important;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 30px;

    background-color: #fefad1;
    transform: rotate(-45deg);
    height: 15px;
    width: 15px;
  }

  .close::after {
    content: '\00d7';
    color: #9da1a2;
    font-weight: initial;
    font-size: initial;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 501;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
}
</style>
