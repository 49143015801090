import router from '@/router';
import { isRoutePublicPage } from '@/services/helpers';

export const automaticLogout = () => {
  localStorage.removeItem('token');
  const urlBeforeLogin = window.location.href;
  const authRequired = router.currentRoute.meta.authRequired || false;
  const isRoutePublic = isRoutePublicPage(router.currentRoute.name);

  if (urlBeforeLogin.indexOf('redirect_to') !== -1) {
    return;
  }

  if (authRequired || isRoutePublic) {
    return;
  }

  return router.push({
    name: 'login',
    query: {
      redirect_to: urlBeforeLogin,
    },
  });
};
