import { getAuthsStatus, submitAmlVerification } from '@verification/services/authService';
import FeatureFlags from '@/common/reusable/featureFlagsChecker';
import Vuetify from '@/plugins/vuetify';

export const namespaced = true;

export const state = {
  auths: {},
  modes: {
    backgroundUpdates: false,
    dispatchToMobile: false,
    editing: false,
  },
};

export const mutations = {
  setModeBackgroundUpdates(state, payload) {
    state.modes.backgroundUpdates = payload;
  },
  setModeDispatchToMobile(state, payload) {
    state.modes.dispatchToMobile = payload;
  },
  setModeEditing(state, payload) {
    state.modes.editing = payload;
  },
  setAuths(state, payload) {
    Object.assign(Vuetify.framework.theme.themes.light, {
      primary: payload.workspace?.primary_color || '#5f23a0',
      secondary: payload.workspace?.text_color || '#000000',
    });

    state.auths = payload;
  },
};

export const actions = {
  setModeBackgroundUpdates: (context, payload) => {
    return context.commit('setModeBackgroundUpdates', payload);
  },
  setModeDispatchToMobile: (context, payload) => {
    return context.commit('setModeDispatchToMobile', payload);
  },
  setModeEditing: (context, payload) => {
    return context.commit('setModeEditing', payload);
  },
  fetchAuths: (context, authToken) => {
    return getAuthsStatus(authToken).then(async (resp) => {
      await context.commit('setAuths', resp);

      if (resp?.auth_document_only_one_image) {
        await context.dispatch('authsDocument/setOnlyOneFeature', true, { root: true });
      }

      if (true === resp.is_bank_id_type) {
        await context.dispatch('authsBankId/setBankId', resp.auths.bankId, {
          root: true,
        });
        await context.dispatch('authsBankId/fetchBankIdLink', authToken, {
          root: true,
        });
      }

      if ('undefined' !== resp.auths.political) {
        await context.dispatch('authsPolitical/setPolitical', resp.auths.political, {
          root: true,
        });
      }

      if (typeof resp.auths?.document !== 'undefined') {
        await context.dispatch('authsDocument/fetchDocument', authToken, {
          root: true,
        });
      }

      if (typeof resp.auths?.sms !== 'undefined') {
        await context.dispatch('authsSms/setSms', resp.auths.sms, {
          root: true,
        });
      }

      if (typeof resp.auths?.microTransaction !== 'undefined') {
        await context.dispatch(
          'authsMicroTransaction/setMicroTransaction',
          resp.auths.microTransaction,
          {
            root: true,
          },
        );
      }
    });
  },
  submitProcess: (context, authToken) => {
    return submitAmlVerification(authToken).then(
      () => {},
      (err) => {
        throw err;
      },
    );
  },
};

export const getters = {
  isAmlType: (state) => {
    if (0 === Object.keys(state.auths?.auths || {}).length) {
      return false;
    }

    for (let auth in state.auths.auths) {
      if ('bankId' === auth) {
        return false;
      }
    }

    return true;
  },
  isBankIdType: (state) => {
    if (0 === Object.keys(state.auths?.auths || {}).length) {
      return false;
    }

    for (let auth in state.auths.auths) {
      if ('bankId' === auth) {
        return true;
      }
    }

    return false;
  },
  modeBackgroundUpdates: (state) => {
    return state.modes?.backgroundUpdates || false;
  },
  modeDispatchToMobile: (state) => {
    return state.modes?.dispatchToMobile || false;
  },
  modeEditing: (state) => {
    return state.modes?.editing || false;
  },
  auths: (state) => {
    return state.auths;
  },
  contact: (state) => {
    return state.auths?.contact || null;
  },
  contract: (state) => {
    return state.auths?.contract || null;
  },
  user: (state) => {
    return state.auths?.user || null;
  },
  userAvatar: (state) => {
    return state.auths?.user?.src || null;
  },
  isSent: (state) => {
    return true === state.auths?.is_sent;
  },
  isDisabledChangePhoneNumber: (state) => {
    return FeatureFlags.cantChangePhoneNumber(state.auths.workspace);
  },
  prefilledPhoneNumber: (state) => {
    return state.auths?.contact?.mobile || state.auths?.docs_person?.mobile || '';
  },
  workspace: (state) => {
    return state.auths?.workspace || null;
  },
  workspaceLogo: (state) => {
    return state.auths?.workspace?.logo || '';
  },
  workspaceBackground: () => {
    return (
      state.auths?.workspace?.background || require('@verification/assets/default-background.jpg')
    );
  },
  workspaceLayerColor: () => {
    return state.auths?.workspace?.layer_color || 'white';
  },
};
