import Vue from 'vue';
import router from '@/router';
import { authHeader } from '@/config/authHeader';
import { autologoutIfUnauthorized } from './helpers';
import { i18n, loadLocaleForUnregistered } from '@/plugins/i18n';
import { hostname } from '@/services/helpers/variables.js';
import { parseJSON } from '@/services/helpers/json.js';
import { Locales } from '@/plugins/i18n/Locales';

export const ContractService = {
    prefill,
    update,
    send,
    extendExpiration,
    getDuplicatedPrice,
    duplicate,
    create,
    detail,
    detailForFirstStepOfWizard,
    detailWithLinks,
    detailWithSignsAndLinks,
    detailUnauthorized,
    getComments,
    manageComments,
    createSampleContract,
    getRegisteredUsersForEditCounterparty,
    editCounterpartyRegistered,
    getCounterparty,
    contractFromApiToken,
    reject,
    rejectAuthorized,
    editCounterpartyUnregistered,
    sendIdentification,
    sendPhoneAuthorized,
    sendPhone,
    sendPin,
    sendPinAuthorized,
    sendSignature,
    sendSignatureAuthorized,
    sendSignOnMobileLink,
    sendSignOnMobileLinkUnauthorized,
    getLastDocuments,
    getPDF,
    openPDFZipAuthorized,
    getDoc,
    getDocUnauthorized,
    getPDFUnauthorized,
    openPDFZipUnauthorized,
    resendInvitation,
    searchExternalDB,
    searchAres,
    saveSignPositions,
    delete: _delete,
    deleteCompleted,
    signaturefieldshown,
    signaturefieldshownUnauthorized,
    addAttachment,
    addAttachments,
    markApprovedAsRegistered,
    setContractViewed,
    markApprovedAsUnregistered,
    redrawContractAfterSendIfNotDraft,
};

async function prefill(contractId) {
  const response = await fetch(
    `${hostname}/api/v1/contracts/${contractId}/prefill`,
    {
      method: 'POST',
      headers: { ...authHeader() },
    }
  );
  return response.status || 500;
}

function saveSignPositions(contract_id, signIdentitiesWithPositions) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: JSON.stringify(signIdentitiesWithPositions)
    };

    return fetch(`${hostname}/api/v1/contracts/${contract_id}/signs/positions`, requestOptions)
        .then(handleResponse);
}

function resendInvitation( contract_id ) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
    };

    return fetch(`${hostname}/api/v1/contracts/${contract_id}/resendpartynotification`, requestOptions)
        .then(handleResponse);
}

function searchAres(ico) {
    return searchExternalDB(ico, Locales.enum.CS);
}

function searchExternalDB(ico, country, token) {
    if (token) {
      return unauthorizedApi({
          method: "GET",
          url: `/company?country=${country}&ico=${ico}`,
          token: token,
      });
    }
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };
    return fetch(`${hostname}/api/v1/company?country=${country}&ico=${ico}`, requestOptions)
        .then(handleResponseFull);
}

function getPDF( contract_id ) {
    var token = localStorage.getItem('token');

    const requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/pdf',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        responseType: 'arrayBuffer  ',
    };

    return fetch(`${hostname}/api/v1/contracts/${contract_id}/pdf`, requestOptions).then(handleResponseRaw);
}

function getPDFUnauthorized( contract_id, token ) {
    return unauthorizedApi({
        method: 'GET',
        url: `/contracts/${contract_id}/pdf`,
        token: token,
        responseFormat: 'application/pdf',
        responseType: 'arrayBuffer  ',
        withCompleted: true,
    });
}

// passing token in queryString isn't best practice, but it's better than no auth at all (rework master-impersonate.html, if security is improved)
function openPDFZipUnauthorized(contract_id, token) {
    const win = window.open(`${hostname}/api/v1/contracts/${contract_id}/pdfzip?apiToken=${token}`, '_blank');
    win.focus();
}

function openPDFZipAuthorized(contract_id ) {
    var token = localStorage.getItem('token');
    const win = window.open(`${hostname}/api/v1/contracts/${contract_id}/pdfzip?access_token=${token}`, '_blank');
    win.focus();
}


function getDoc(contract, format) {
    if (!contract) {
        // probably dead code? rather keep it because of https://gitlab.com/digitalfactorycz/ismlouva/-/commit/1cecb2a
        return Promise.reject('No document available for contract');
    }
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };
    return fetch(`${hostname}/api/v1/documents/${contract.id}?format=${format}`, requestOptions)
        .then(handleResponse);
}

function getDocUnauthorized(contract, token, format) {
    if (!contract) {
        return Promise.reject('No document available for contract');
    }
    return unauthorizedApi({
        method: 'GET',
        url: `/documents/${contract.id}?format=${format}`,
        token: token,
        withCompleted: true,
    });
}

function getLastDocuments(workspace, type) {
  const requestOptions = {
      method: 'GET',
      headers: { ...authHeader() },
  };

  return fetch(`${hostname}/api/v1/workspaces/${workspace}/lastdocuments?type=${type}`, requestOptions)
      .then(handleResponse);
}

function signaturefieldshown( contract_id, sign_id ) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
    };

    return fetch(`${hostname}/api/v1/contracts/${contract_id}/signs/${sign_id}/signaturefieldshown`, requestOptions)
        .then(handleResponse);
}


function signaturefieldshownUnauthorized( contract_id, sign_id, token ) {
    return unauthorizedApi({
        method: 'POST',
        url: `/contracts/${contract_id}/signs/${sign_id}/signaturefieldshown`,
        token: token,
        withCompleted: true,
    });
}

function sendSignature( data, file, token, contract_id, identity_id, progress) {
    const dataToSend = {
        bin_data: file,
        signature_date: data.signature_date,
        signature_place: data.signature_place,
        progress: progress,
    };
    return unauthorizedApi({
        method: 'POST',
        url: `/contracts/${contract_id}/signs/${identity_id}/patch`,
        token: token,
        body: JSON.stringify(dataToSend),
    });
}


function sendSignatureAuthorized( data, file, contract_id, identity_id, progress) {

    const dataToSend = {
        bin_data: file,
        signature_date: data.signature_date,
        signature_place: data.signature_place,
        progress: progress,
    }

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: JSON.stringify( dataToSend ),
    };

    return fetch(`${hostname}/api/v1/contracts/${contract_id}/signs/${identity_id}/patch`, requestOptions)
        .then(handleResponseSignatureAuhtorized);
}

function handleResponseSignatureAuhtorized( response ) {
  return response.text().then(text => {
      const json = parseJSON(text);
      if (!response.ok) {
          if (response.status === 404) {
              return router.push({name: 'notFound'});
          } else {
              // CreateSign has only success handler, so Promise.reject can't be used without bigger refactoring
              const error = (json && (json.message || json.title)) || response.statusText;
              return {
                  code: response.status,
                  title: error,
              };
          }
      }
      return json;
  });
}


function sendPinAuthorized(pin, contract_id, identity_id) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: JSON.stringify({ pin })
    };

    return fetch(`${hostname}/api/v1/contracts/${contract_id}/signs/${identity_id}/verifypin`, requestOptions)
        .then(handleResponsePin);
}


function sendPin(pin, token, contract_id, identity_id) {
    return unauthorizedApi({
        method: 'POST',
        url: `/contracts/${contract_id}/signs/${identity_id}/verifypin`,
        token: token,
        body: JSON.stringify({ pin }),
        errorHandler: (json, httpCode) => json ? json.code : httpCode,
    });
}

function getRegisteredUsersForEditCounterparty(contract_id, identity_id)  {
  const requestOptions = {
      method: 'GET',
      headers: { ...authHeader() }
  };
  return fetch(`${hostname}/api/v1/contracts/${contract_id}/signs/${identity_id}`, requestOptions)
      .then(handleResponse);
}

function editCounterpartyUnregistered( data, token, contract_id, identity_id ) {
    return unauthorizedApi({
        method: 'PUT',
        url: `/contracts/${contract_id}/signs/${identity_id}`,
        token: token,
        body: JSON.stringify(data),
    });
}

function editCounterpartyRegistered( contract_id, identity_id, data ) {
  const requestOptions = {
      method: 'PUT',
      headers: { ...authHeader() },
      body: JSON.stringify( data )
  };
  return fetch(`${hostname}/api/v1/contracts/${contract_id}/signs/${identity_id}`, requestOptions)
      .then(handleResponse);
}

function sendIdentification(token, contractId, signIdentityId, request) {
  return unauthorizedApi({
      method: 'POST',
      url: `/contracts/${contractId}/signs/${signIdentityId}/verifyidentity`,
      token: token,
      body: JSON.stringify(request),
      errorHandler: (json, httpCode) => json ? json.code : httpCode,
  });
}

function sendPhoneAuthorized( data, contract_id, identity_id ) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: JSON.stringify( data )
    };

    return fetch(`${hostname}/api/v1/contracts/${contract_id}/signs/${identity_id}/pin`, requestOptions)
        .then(handleResponsePin);
}


function sendPhone( data, token, contract_id, identity_id ) {
    return unauthorizedApi({
        method: 'POST',
        url: `/contracts/${contract_id}/signs/${identity_id}/pin`,
        token: token,
        body: JSON.stringify(data),
        errorHandler: (json, httpCode) => json ? json.code : httpCode,
    });
}

function handleResponsePin(response) {
  return response.text().then(text => {
      const json = parseJSON(text);
      if (!response.ok) {
          if (response.status === 404) {
              router.push({name: 'notFound'});
              return;
          }
          return Promise.reject(json ? json.code : response.status);
      }
      return json;
  });
}

function sendSignOnMobileLink (identity_id, data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: JSON.stringify( data )
    };

    return fetch(`${hostname}/api/v1/signs/signsms/${identity_id}`, requestOptions)
        .then(handleResponse);
}

function sendSignOnMobileLinkUnauthorized (identity_id, data, token) {
    return unauthorizedApi({
        method: 'POST',
        url: `/signs/signsms/${identity_id}`,
        token: token,
        body: JSON.stringify(data),
        withCompleted: true,
    });
}

function rejectAuthorized( contract_id, data ) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: JSON.stringify( data )
    };

    return fetch(`${hostname}/api/v1/contracts/${contract_id}/decline`, requestOptions)
        .then(handleResponse);
}


function reject( contract_id, data, token ) {
    return unauthorizedApi({
        method: 'POST',
        url: `/contracts/${contract_id}/decline`,
        token: token,
        body: JSON.stringify(data),
    });
}

function contractFromApiToken({ component, onSuccess, onError, isCompletedContractAllowed, selectedContractId }) {
    const token = component.$route.params.hash;

    unauthorizedApi({
        method: 'GET',
        url: `/invitecontract`,
        token: token,
        withCompleted: isCompletedContractAllowed,
        selectedContractId: selectedContractId || null,
    })
      .then((response) => {
        loadLocaleForUnregistered(component, response);

        onSuccess(response);
      })
      .catch(() => {
        component.$notification.error(component.$t('general.data_not_loaded'));

        if (onError) {
          onError();
        }
      });
}

function getCounterparty( contract_id, searchString ) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };

    return fetch(`${hostname}/api/v1/contracts/${contract_id}/users?q=${searchString}`, requestOptions)
        .then(handleResponse);
}

function createSampleContract() {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() }
    };
    return fetch(`${hostname}/api/v1/users/current/sampleContract`, requestOptions)
        .then(handleResponse);
}

function getDuplicatedPrice(contract) {
  const requestOptions = {
      method: 'GET',
      headers: { ...authHeader() },
  };

  return fetch(`${hostname}/api/v2/contract/${contract.id}/duplicate/price`, requestOptions)
      .then(handleResponse);
}

function duplicate(contract, errorHandler) {
  const requestOptions = {
      method: 'POST',
      headers: { ...authHeader() },
  };

  return fetch(`${hostname}/api/v2/contract/${contract.id}/duplicate`, requestOptions)
      .then(handleResponse)
      .then(
          (newContract) => {
              router.push({
                  name: 'createContractNew',
                  params: {
                      workspace_id: newContract.workspace_id,
                  },
                  query: {
                      contract_id: newContract.id,
                  },
              });
          },
          errorHandler
      );
}

function create(data, activeWorkspace) {
    const workspace = activeWorkspace || {
        id: null,
        locale: null,
        settings: {
            contracts: {
                is2FAEnabled: true,
                draftExpirationDays: null,
                completedExpirationDays: null,
            },
        },
    };
    let endingDate = null;
    if (workspace.settings.contracts.completedExpirationDays) {
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + workspace.settings.contracts.completedExpirationDays);
        endingDate = futureDate.toISOString().slice(0, 10);
    }
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: JSON.stringify({
            // overridable workspace settings via ContractCreateForm
            one_device: 0, // don't create default one-device contracts (confused users IS-500)
            workspace_id: workspace.id,
            locale: workspace.locale,
            contract_ending_date: endingDate,
            send_notification_for_ending_contract: endingDate ? 1 : 0,
            // required contract data
            ...data,
            // uneditable settings
            contacts_ids_csv: router.currentRoute && router.currentRoute.query ? router.currentRoute.query.contacts : '',
        }),
    };
    return fetch(`${hostname}/api/v1/contracts`, requestOptions)
        .then(handleResponse);
}

function update(id, data) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader() },
        body: JSON.stringify(data)
    };
    return fetch(`${hostname}/api/v1/contracts/${id}`, requestOptions)
        .then(handleResponse);
}

function send(id, request) {
  const requestOptions = {
      method: 'POST',
      headers: { ...authHeader() },
  };
  const queryString = request ? `?${new URLSearchParams(request).toString()}` : '';
  return fetch(`${hostname}/api/v1/contracts/${id}/send${queryString}`, requestOptions)
      .then(handleResponse);
}

function extendExpiration(workspaceId, contracts, extendDaysCount) {
  const requestOptions = {
      method: 'POST',
      headers: { ...authHeader() },
      body: JSON.stringify({
          contracts_ids: contracts.map(c => c.id),
          extra_days_count: extendDaysCount,
      }),
  };
  return fetch(`${hostname}/api/v1/contracts/${workspaceId}/expiration`, requestOptions)
      .then(handleResponse);
}

function detailForFirstStepOfWizard(id, workspace_id) {
  return detail(id, workspace_id, null, '1st_step');
}

function detailWithLinks(id, workspace_id) {
  return detail(id, workspace_id, null, 'links');
}

function detailWithSignsAndLinks(id, workspace_id) {
  return detail(id, workspace_id, null, 'signs,links');
}

function detail(id, workspace_id, attachment_id, withData) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };

    const queryString = [];

    if (workspace_id) {
        queryString.push(`workspace_id=${workspace_id}`);
    }
    if (attachment_id) {
        queryString.push(`attachment_id=${attachment_id}`);
    }
    if (withData) {
        queryString.push(`with=${withData}`);
    }

    return fetch(`${hostname}/api/v1/contracts/${id}?${queryString.join('&')}`, requestOptions)
      .then((response) => {
        return response.text().then((text) => {
          const data = parseJSON(text);

          if (!response.ok) {
            if (response.status === 404) {
              return router.push({ name: 'dashboard' });
            }

            if (!store.getters['profile']) {
              return autologoutIfUnauthorized(response);
            }

            if (response.status === 401) {
              return router.push({ name: 'dashboard' });
            }

            if (data && data.title === 'Uživatel nemá dostatek kreditů') {
              return Promise.reject(data.title)
            }

            return Promise.reject((data && data.message) || response.statusText);
          }

          return data;
        });
      });
}

function detailUnauthorized(id, workspace_id, attachment_id, token) {
    return unauthorizedApi({
        method: 'GET',
        url: workspace_id
            ? `/contracts/${id}?workspace_id=${workspace_id}${attachment_id ? `&attachment_id=${attachment_id}` : ''}`
            : `/contracts/${id}${attachment_id ? `?attachment_id=${attachment_id}` : ''}`,
        token: token,
        withCompleted: true,
    });
}

function getComments(contractId, mode) {
  const requestOptions = {
      method: 'GET',
      headers: { ...authHeader() }
  };
  return fetch(`${hostname}/api/v1/contracts/${contractId}/comments?mode=${mode}`, requestOptions).then(handleResponse);
}

function manageComments(contractId, action, comment) {
  const requestOptions = {
      method: 'POST',
      headers: { ...authHeader() },
      body: JSON.stringify({
          // check constants in ManageComments and format in ManageCommentsTest
          action,
          comment
      }),
  };
  return fetch(`${hostname}/api/v1/contracts/${contractId}/comments`, requestOptions).then(handleResponse);
}

function addAttachment(data, contract_id) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: JSON.stringify( data )
    };

    return fetch(`${hostname}/api/v1/contracts/${contract_id}/attachment`, requestOptions)
      .then(handleResponse);
}

function addAttachments(contractId, attachments) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: JSON.stringify(attachments)
    };

    return fetch(`${hostname}/api/v1/contracts/${contractId}/attachments`, requestOptions)
      .then(handleResponse);
}

function _delete( id, deleteComplete ) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${hostname}/api/v1/contracts/${id}?delete_complete=${deleteComplete ? '1' : '0'}`, requestOptions).then(handleResponseDeleteWorkspace);
}

function deleteCompleted( id ) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  return fetch(`${hostname}/api/v2/contract/${id}/delete/documents`, requestOptions).then(handleResponseDeleteWorkspace)
}

function handleResponse( response ) {
    return response.text().then(text => {
        const data = parseJSON(text);

        if (!response.ok) {
            if (response.status === 404) {
                router.push({name: 'notFound'});
                return;
            }

            autologoutIfUnauthorized(response);

            if (data && data.title === 'Uživatel nemá dostatek kreditů') {
                return Promise.reject(data.title)
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}


function handleResponseRaw( response ) {
    return response;
}



function handleResponseFull( response ) {
    return response.text().then(text => {

        if (!response.ok) {
            if (response.status === 404) {
                router.push({name: 'notFound'});
                return;
            }
            // TODO: when returning error as success result is good idea?
            // (addAttachment - it caused redirect to homepage, if API returned code 400)
        }

        return parseJSON(text);
    });
}


function handleResponseDeleteWorkspace( response ) {
    return response.text().then(text => {
        const data = parseJSON(text);

        if (!response.ok) {

            if (response.status === 404) {
                router.push({name: 'notFound'});
                return;
            }

            autologoutIfUnauthorized(response);

            // const error = (data && data.message) || response.statusText;
            return Promise.reject(data);
        }

        return data;
    });
}

function markApprovedAsRegistered(id, signId) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader() },
        body: JSON.stringify( [] )
    };

    return fetch(`${hostname}/api/v1/contracts/${id}/signs/${signId}/approve`, requestOptions)
      .then(handleResponse);
}

function setContractViewed(id, signId) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader() },
        body: JSON.stringify( [] )
    };

    return fetch(`${hostname}/api/v1/contracts/${id}/signs/${signId}/viewed`, requestOptions)
      .then(handleResponse);
}

function markApprovedAsUnregistered(id, signId, token) {
    return unauthorizedApi({
        method: 'POST',
        url: `/contracts/${id}/signs/${signId}/accept`,
        token: token,
        body: JSON.stringify([]),
    });
}


function redrawContractAfterSendIfNotDraft(contract, isDryRun) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
    };
    return fetch(`${hostname}/api/v1/contracts/${contract.id}/redraw-after-send?isDryRun=${isDryRun ? 1 : 0}`, requestOptions)
      .then(handleResponse);
}

export function unauthorizedApi({ url, token, method, body, responseFormat, responseType, withCompleted, selectedContractId, errorHandler }) {
    const request = {
        method: method,
        headers: {
            'Accept': responseFormat || 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `ApiToken ${token}`,
        },
        body: body || undefined,
        responseType: responseType || undefined,
    };
    const queryStrings = [];
    if (withCompleted) {
        queryStrings.push('with=completed');
    }
    if (selectedContractId) {
        queryStrings.push(`selectedContractId=${selectedContractId}`);
    }
    const queryStringDelimiter = url.indexOf('?') === -1 ? '?' : '&';
    let queryString = queryStrings.length ? `${queryStringDelimiter}${queryStrings.join('&')}` : '';
    const promise = fetch(`${hostname}/api/v1${url}${queryString}`, request);

    // no redirect for img/pdf/zip endpoints (only expired token is checked)
    // otherwise fail fast if token is outdated/invalid/expired
    if (responseType) {
        return promise.then(handleRawResponse);
    } else {
        return promise.then(handleExpirableResponse);
    }

    function handleRawResponse(response) {
        return response;
    }

    function handleExpirableResponse(response) {
        return response.text().then(text => {
            const json = parseJSON(text);
            if (!response.ok) {
                const error = (json && (json.message || json.title)) || response.statusText;
                if (response.status === 401) {
                    return router.push({
                        name: 'contractExpiredLink',
                        params: {
                            isApitokenOutdated: `${error === 'Outdated api token'}`,
                            isApitokenExpired: `${error === 'Expired api token'}`,
                        },
                    });
                } else if (response.status === 404) {
                    // don't think that this happens, but rather keep it because of old handlers
                    return router.push({
                        name: 'notFound'
                    });
                }

                return Promise.reject(errorHandler ? errorHandler(json, response.status) : error);
            }
            return json;
        });
    }
}
