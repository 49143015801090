import axios from 'axios';
import router from '@/router';
import store from '@/store';
import { authHeader } from '@/config/authHeader';
import { authorizedRequest } from '@/services/ApiService';
import { documentDetailPages } from '@/common/variables/contract';
import environment from '@/config/environment';

export const updateContract = ({ contractId, contractData }) => {
  return axios
    .put(`${environment.getApiUrl()}/api/v1/contracts/${contractId}`, contractData, {
      headers: { ...authHeader() },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw err.response.data;
    });
};

export const fetchContractSignIdentities = (function () {
  return function ({ contractId, attempt, timeout, next, catchError }) {
    axios
      .get(`${environment.getApiUrl()}/api/v2/contract/${contractId}/signIdentity/list`, {
        headers: authHeader(),
      })
      .then((resp) => {
        next(resp.data);
      })
      .catch((err) => {
        if (0 === attempt) {
          return catchError(err);
        }

        return setTimeout(function () {
          fetchContractSignIdentities({
            contractId,
            attempt,
            timeout,
            next,
            catchError,
          });
        }, timeout);
      });
  };
})();

export const getContractCheckList = (function () {
  return function ({ contractId, attempt, timeout, next, catchError }) {
    attempt -= 1;
    authorizedRequest({
      method: 'GET',
      endpoint: `api/v1/contracts/${contractId}/controllist`,
    })
      .then(async (resp) => {
        next(resp);
      })
      .catch((err) => {
        if (0 === attempt) {
          return catchError(err);
        }

        return setTimeout(function () {
          getContractCheckList({
            contractId,
            attempt,
            timeout,
            next,
            catchError,
          });
        }, timeout);
      });
  };
})();

export const getContractAmlRevisionList = (function () {
  return function ({ contractId, attempt = 5, timeout = 2500, next, catchError }) {
    attempt -= 1;
    authorizedRequest({
      method: 'GET',
      endpoint: `api/v1/auths/contracts/${contractId}/revisionList`,
    })
      .then(async (resp) => {
        next(resp);
      })
      .catch((err) => {
        if (0 === attempt) {
          return catchError(err);
        }

        return setTimeout(function () {
          getContractAmlRevisionList({
            contractId,
            attempt,
            timeout,
            next,
            catchError,
          });
        }, timeout);
      });
  };
})();

export const circularFetchDocumentWithLink = (function () {
  return function ({ contractId, timeout, next, catchError }) {
    authorizedRequest({
      method: 'GET',
      endpoint: `/api/v2/contracts/${contractId}/document`,
    })
      .then(async (resp) => {
        next(resp);
      })
      .catch((err) => {
        if (
          err?.errorCode !== 'Signi.Exceptions.Document.DocumentFailedException' &&
          documentDetailPages.includes(router.currentRoute.name) &&
          'doc' === store.getters['contract']?.type
        ) {
          return setTimeout(function () {
            circularFetchDocumentWithLink({
              contractId,
              timeout,
              next,
              catchError,
            });
          }, timeout);
        } else {
          catchError(err);
        }
      });
  };
})();

export const fetchContractDocumentHistory = ({ idContract, idCurrentSignIdentity = null }) => {
  return authorizedRequest({
    method: 'GET',
    endpoint: `api/v2/contract/${idContract}/history${
      idCurrentSignIdentity ? `/${idCurrentSignIdentity}` : ''
    }`,
  });
};
