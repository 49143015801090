<template lang="html">

        <div class="loader-wrap">
            <div class="loader"></div>
        </div>

</template>


<script>
    export default {
        name: 'Loader',
        model: {

        },
        props: {

        },
        components: {

        },
        data() {
            return {

            }
        },
        created() {

        },
        computed: {

        },
        methods: {

        },
        watch: {

        }
    }
</script>


<style lang="scss">
        .loader{
            width: 100px;
            height: 100px;
            background: url(/images/loader.svg);
            background-position: center;
            background-size:contain;
            animation: spin 4s linear infinite;
            left: 50%;
            margin-left: -50px;
            position: relative;
            background-repeat: no-repeat;
        }

        .loader-wrap{
            position: absolute;
            width: 100px;
            margin-left: auto;
            margin-right: auto;
            top: 50%;
            left: 50%;

            transform: translateX(-50%) translateY(-50%);
        }

        .menuOpen .loader-wrap{
            padding-left: 325px;
        }

        .register-loader{
            top: 0px;
            position: relative;
            left: 0px;
            transform: unset;
        }
</style>
