<template>
  <component v-if="isFullLayout" :is="routeLayout" :key="currentLocale">
    <router-view :key="routeKey" />
  </component>
  <v-app v-else>
    <component :is="routeLayout" :key="currentLocale">
      <router-view :key="routeKey" />
    </component>
  </v-app>
</template>

<script>
import { changeLocaleWithoutSave, i18n } from '@/plugins/i18n';
import { isRoutePublicPage } from '@/services/helpers';
import axios from 'axios';
import cookie from 'vue-cookies';
import environment from '@/config/environment';
import EmptyLayout from '@/layouts/EmptyLayout';

export default {
  name: 'App',
  components: {
    EmptyLayout,
  },
  data() {
    return {
      currentHash: cookie.get('app-version-hash') || localStorage.getItem('app-version-hash'),
      hashChanged: false,
      newHash: '',
      allCookiesAccepted: false,
    };
  },
  computed: {
    currentLocale() {
      if ('undefined' === typeof this.$route.meta.authRequired) {
        return isRoutePublicPage(this.$route.name) ? 'public-page' : this.$i18n.locale;
      }

      return this.$route.meta.authRequired ? this.$i18n.locale : 'public-page';
    },
    routeKey() {
      if (['documentsTable', 'contacts', 'template'].includes(this.$route.name)) {
        return this.$route.name;
      }

      return this.$route.fullPath;
    },
    routeLayout() {
      return this.$route.meta.layout || EmptyLayout;
    },
    isFullLayout() {
      return this.$route?.meta?.fullLayout || false;
    },
  },
  watch: {
    '$route.query.lang': {
      handler: function (predefinedLanguage) {
        if (typeof predefinedLanguage !== 'undefined') {
          i18n.locale = predefinedLanguage;
        }
      },
    },
    hashChanged: {
      handler: function (value) {
        if (value) {
          this.reloadApp();
        }
      },
    },
  },
  created() {
    const predefinedLanguage = this.$route.query?.lang;

    if (typeof predefinedLanguage !== 'undefined') {
      changeLocaleWithoutSave(this, predefinedLanguage);
      localStorage.setItem('locale', predefinedLanguage);
      localStorage.setItem('sign_app_locale', predefinedLanguage);
    }

    if (!environment.isDevelopment()) {
      // eslint-disable-next-line no-console
      console.log(
        `%c${this.$t('console.warning.stop')}`,
        'font-family: roboto; font-size: 54px; font-weight: bold; color: red;',
      );
      // eslint-disable-next-line no-console
      console.log(
        `%c${this.$t('console.warning.message')}`,
        'font-family: roboto; font-size: 18px;',
      );
      // eslint-disable-next-line no-console
      console.log(
        `%c${this.$t('console.warning.more')}`,
        'font-family: roboto; font-size: 18px; margin-top: 0.5rem',
      );
    }

    if ('production' === process.env.NODE_ENV) {
      this.initVersionCheck();
    }

    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        this.$workbox.messageSW({ type: 'SKIP_WAITING' });
      });
    }
  },
  mounted() {
    if (cookie.get('accepted')) {
      this.allCookiesAccepted = true;
    } else {
      cookie.set('accepted', 'false');
      this.allCookiesAccepted = false;
    }
  },
  methods: {
    acceptAllCookies() {
      this.allCookiesAccepted = true;
      cookie.set('accepted', 'true');
    },
    initVersionCheck() {
      this.checkVersion();

      setInterval(() => {
        this.checkVersion();
      }, 1000 * 60 * 2);
    },
    async checkVersion() {
      axios.get(`${environment.getAppUrl()}/version.json`).then((resp) => {
        this.newHash = resp.data.hash;
        this.hashChanged = this.hasHashChanged(this.currentHash, this.newHash);
      });
    },
    hasHashChanged(currentHash, newHash) {
      return currentHash ? currentHash !== newHash : true;
    },
    reloadApp() {
      this.currentHash = this.newHash;
      cookie.set('app-version-hash', this.newHash);
      localStorage.setItem('app-version-hash', this.newHash);
      window.location.reload();
    },
  },
  errorCaptured(err, vm, info) {
    if (environment.isDevelopment()) {
      console.log({
        component: vm.$options.name,
        state: vm,
        info: info,
        error: err,
      });

      return true;
    }

    return false;
  },
};
</script>

<style lang="scss">
@import '~@/styles/index.scss';

@import '~@/assets/sass/_contract.scss';
@import '~@/assets/sass/_form.scss';
@import '~@/assets/sass/_contract-edited.scss';
@import '~@/assets/sass/_signature.scss';

.notificationCenter.topRight {
  z-index: 9999;
}

svg {
  overflow: hidden;
}

p {
  margin-bottom: 10px;
  font-size: 15px;
  //color: #4a4a4a;
  letter-spacing: -0.03px;
  line-height: 1.7;
  font-weight: 300;
}

.md-hidden {
  display: block;

  @include md {
    display: none !important;
  }
}

.left {
  float: left;
}

.right {
  float: right;
}

.p-bigger {
  font-weight: normal;
  font-size: 22px;
  color: #000000;
  letter-spacing: -0.05px;
  text-align: center;
  line-height: 29px;
  margin-bottom: 10px;
}

.p-title {
  margin-bottom: 20px;
}

body.fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.drag-in-progress body {
  @include md {
    position: sticky;
    overflow: hidden;
    .content-box__data {
      touch-action: none;
    }
  }
}

small {
  font-size: 16px;
  color: #000000;
  letter-spacing: -0.03px;
  text-align: center;
  line-height: 25px;
  font-weight: 300;
}

a {
  font-size: 16px;
  color: #000000 !important;
  letter-spacing: -0.03px;
  text-align: center;
  font-weight: 500;
  text-decoration: underline;
  transition: 0.2s;
  &:hover {
    text-decoration: underline;
    color: #5e239e !important;
    transition: 0.2s;
  }
}

.a-loader {
  position: relative;

  span.a-loader__svg {
    width: 20px;
    height: 20px;
    background: url('/images/loader.svg');
    background-position: center;
    background-size: contain;
    animation: spin 4s linear infinite;

    position: absolute;
    right: -30px;
    top: 50%;
    background-repeat: no-repeat;
    display: block;
    margin-top: -10px;
    z-index: 4;
  }
}

a.light-link {
  font-weight: 300;
}

a.a-purple {
  color: #5e239e;
  &:hover {
    color: #9268be;
  }
}

.contract__buttons a span {
  color: #000000 !important;
  display: inline-block;
  padding-left: 10px;

  &:before {
    color: #000000 !important;
  }
}

.contract__buttons a:hover span {
  color: #5e239e !important;
  display: inline-block;
  padding-left: 10px;

  &:before {
    color: #5e239e !important;
  }
}

strong {
  font-weight: bold;
}

h1 {
  font-size: 33px;
  color: #000000;
  letter-spacing: -0.06px;
  text-align: center;
  font-weight: normal;
  margin-bottom: 20px;
  @include md {
    font-size: 28px;
  }
}

h2 {
  font-size: 23px;
  color: #000000;

  @include md {
    font-size: 25px;
  }
}

.bigger-h2 {
  font-size: 33px;
  color: #000000;
  text-align: center;
  font-weight: normal;
  margin-bottom: 20px;
  @include md {
    font-size: 28px;
  }
}

.p-big {
  font-size: 18px;
}
.br-phone {
  display: none;
  @include sm {
    display: block;
  }
}

.content {
  font-size: 15px;
  color: #4a4a4a;

  con-options,
  con-text,
  con-textarea,
  con-number,
  con-date,
  con-section {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    cursor: auto;
    font-weight: 300;
    color: #4a4a4a;
    text-decoration: none;
  }

  con-identity,
  con-section {
    font-size: 15px;
    color: #4a4a4a;
  }

  p {
    margin-top: 20px;
  }

  p.centered {
    text-align: center;
  }

  h2 {
    text-align: center;
    margin: 30px 0 0 0;
  }
}
</style>
