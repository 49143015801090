<template lang="html">
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="SYN001---DSKTP---Detail-smlouvy" transform="translate(-884.000000, -384.000000)" fill-rule="nonzero" stroke="currentColor" stroke-width="2">
        <g id="btn-ghost-odmitnout" transform="translate(862.000000, 364.000000)">
          <g id="Group-7" transform="translate(23.000000, 21.000000)">
            <path d="M0.145908189,13.8251741 C0.145908189,13.8251741 4.55442497,9.41665737 13.3714585,0.599623817" id="Path-17"></path>
            <path d="M13.3714585,13.8251741 L0.145908189,0.599623817 L13.3714585,13.8251741 Z" id="Path-17-Copy"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>


<script>
  export default {
    name: 'IcoReject'
  }
</script>
