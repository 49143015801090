<template>
  <Data
    :loader="!loaded"
    :shadow="shadow"
    :showMenu="false"
    :panelLoad="panelLoad"
    :showBackNavigation="true"
    :disableMenu="true"
    :closeAndSave="true"
    @close="closeAndSave"
    @onNavigationBack="navigateBackHandler"
  >
    <v-card class="mx-auto" max-width="45rem" elevation="0" style="margin-top: -3rem; background: transparent !important">
      <div class="mx-auto" style="max-width: 35rem;">
        <ProgressBar v-if="!isAttachment" :currentStep="1" :some-document-failed="someDocumentFailed" />
      </div>

      <ContractCreateHeader />

      <div class="py-3">
        <ContractDocumentPreview
          v-for="document in contractDocuments"
          :key="document.id"
          :document="document"
          @on-document-delete="showDeleteDocumentDialog = true; documentToDelete = $event"
          @on-document-failed="errorDocumentIds.push(`${document.id}`)"
        />
      </div>

      <v-card class="my-5" elevation="0" style="border: 1px solid #D4DFF0">
        <v-toolbar dense flat>
          <v-spacer></v-spacer>

          <v-dialog v-model="helpDialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text color="#7D8593" class="px-1" v-bind="attrs" v-on="on" style="margin-right: -0.7rem">
                <span class="text-decoration-underline">{{ $t('general.help') }}</span>
                <v-icon color="#B4C1D6" class="ml-2">
                  mdi-help-circle-outline
                </v-icon>
              </v-btn>
            </template>

            <v-card class="pa-0">
              <v-card-text class="pt-5">
                {{ $t('info.document.head') }}
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="helpDialog = false"
                >
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>

        <PredefinedWorkflowSelect
          @on-select-template="onChangePredefinedSettingsTemplate"
        />

        <v-card-text class="px-0 pt-0">
          <div class="px-4 px-md-9 pt-0 pb-5" style="border-bottom: 1px solid #D4DFF0;">
            <v-checkbox
              v-if="showOneDevice"
              v-model="one_device"
              :label="$t('contract.one_device_sign')"
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-show="!isAttachment"
              v-model="hasSequentialSigningOrder"
              :label="$t('contract.signing_order.label')"
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-show="!isAttachment"
              v-model="enabledFindAnchors"
              hide-details
            >
              <template v-slot:label>
                <div>
                  {{ $t('contract.signature_positions.preserve') }}

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon color="#B3C0D5" v-on="on">mdi-information</v-icon>
                    </template>

                    {{ $t('contract.signature_positions.preserve_info') }}
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </div>

          <CreateParties
            v-if="arePartiesEditable"
            ref="createParties"
            :getEditRequest="getEditRequest"
            :hasReviewComments="hasReviewComments"
            :hasSequentialSigningOrder="!one_device ? hasSequentialSigningOrder : true"
            :isEditingContactsTemplate="isEditingContactsTemplate"
            :isSmsPinRequiredForNewUsers="workspaceContractSettings.is2FAEnabled"
            :hasAdvancedWorkflowSelects="hasAdvancedWorkflowSelects"
            :contract="contract"
            :some-document-failed="someDocumentFailed"
          />
        </v-card-text>
      </v-card>

      <v-card v-if="isOnedriveEnabled || !this.isUpdate" class="pa-5 text-center">
        <div  class="mx-auto content-finish__box-one-drive" @click="openOnedriveExplorer">
          <img src="/images/onedrive.png" alt="onedrive logo" class="content-finish__box-one-drive-logo" />
          {{ $t('contract.onedrive.select_folder') }}:<br />
          <div class="content-finish__box-one-drive-choose" :title="$t('contract.onedrive.help')">
            <span class="content-finish__box-one-drive-name">
              {{ onedrivePath }}
            </span>
            <IcoInfo />
          </div>
        </div>

        <div v-if="!this.isUpdate" class="py-5 text-center">
          <v-btn
            color="primary"
            :loading="loading"
            x-large
            @click="createContract()"
          >
            {{ $t('contract.create_new_contract') }}
            <v-icon class="pl-3">
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </v-card>

      <div v-if="chooseOnedriveFolder" class="content-finish__structure-explorer">
        <FolderStructureExplorer
          :loadFunction="onedriveDataLoad"
          @selectFolder="selectOnedriveFolder($event)"
          @cancel="chooseOnedriveFolder = false"
        />
      </div>
    </v-card>

    <SConfirmDialog
      v-model="showDeleteDocumentDialog"
      :message="`${$t('docs.delete.confirm')}`"
      @confirmation-callback="deleteDocument"
    />
  </Data>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { hasFeatureFlag } from '@/common/reusable/featureFlagsChecker';

import IcoInfo from '@/components/svg/IcoInfo.vue';

import FileSaver from 'file-saver';

import { TemplateService } from '@/services/TemplateService.js';
import { ContractService } from '@/services/ContractService.js';
import { minLength, required } from "vuelidate/lib/validators";
import { WorkflowHelper } from '@/helpers/WorkflowHelper.js';
import { ExternalDriveService } from '@/services/ExternalDriveService.js';
import FeatureFlags from '@/common/reusable/featureFlagsChecker';
import DatePicker from "../../components/DatePicker";
import ProgressBar from "../../components/ProgressBar";
import CreateParties from './CreateParties';
import FolderStructureExplorer from './components/FolderStructureExplorer.vue';
import LanguageSelect from '@/plugins/i18n/LanguageSelect.vue';
import ContractCreateHeader from "@contract/components/ContractCreateHeader";
import ContractDocumentPreview from "@contract/components/ContractDocumentPreview";
import SConfirmDialog from '@/common/components/SConfirmDialog';
import PredefinedWorkflowSelect from "@contract/components/create/PredefinedWorkflowSelect";

export default {
  name: 'ContractCreateForm',
  components: {
    PredefinedWorkflowSelect,
    ContractCreateHeader,
    ContractDocumentPreview,
    IcoInfo,
    ProgressBar,
    DatePicker,
    CreateParties,
    FolderStructureExplorer,
    LanguageSelect,
    SConfirmDialog,
  },
  props: {
    panelLoad: {
      required: false,
      default: false,
      type: Boolean,
    },
    isAttachment: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      selectedPredefinedSettingsTemplate: null,
      defaultContractSettings: {},
      documentToDelete: null,
      showDeleteDocumentDialog: false,
      helpDialog: false,
      isLoadingTemplatePdfPreview: false,
      loading: false,
      loaded: false,
      one_device: false,
      workspace_id: this.$route.params.workspace_id,
      contract_id: this.$route.query.contract_id ? this.$route.query.contract_id : false,
      isNew: this.$route.query.contract_id,
      shadow: this.isAttachment,
      showMenu: this.isAttachment,
      title: '',
      number: '',
      waiting_limit: null,
      date: null,
      endingDate: null,
      send_notification_for_ending_contract: false,
      contractLocale: null,
      templatePreview: {
        id: null,
        name: null,
        hasPdfPreview: false,
      },
      wysiwygCreditCost: 1,
      chooseOnedriveFolder: false,
      onedriveFolder: '',
      errorDocumentIds: [],
    }
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      profile: 'profile',
      myIdentity: 'myIdentity',
      workspaceById: 'workspaceById',
      predefinedSettingsTemplatesList: 'predefinedWorkflow/list',
    }),
    enabledFindAnchors: {
      deep: true,
      get() {
        return this.contract?.feature_flags?.enabledFindAnchors || false;
      },
      set(val) {
        if (this.loaded) {
          this.contract.feature_flags.enabledFindAnchors = val;
        }
      },
    },
    hasSequentialSigningOrder: {
      deep: true,
      get() {
        return this.contract?.feature_flags?.hasSequentialSigningOrder || false;
      },
      set(value) {
        if (this.loaded) {
          this.contract.feature_flags.hasSequentialSigningOrder = value;
        }
      }
    },
    activeWorkspace() {
      return this.workspaceById(this.$route.params.workspace_id) || {
        // fallback to default values, so every call does not have to check existing workspace
        is_owner: false,
        locale: this.profile ? this.profile.locale : null,
        settings: {
          contracts: {
            is2FAEnabled: true,
            isOnedeviceEnabled: false,
            draftExpirationDays: null,
            completedExpirationDays: null,
          },
        },
      };
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
    someDocumentFailed() {
      return this.errorDocumentIds?.length > 0;
    },
    isUpdate() {
      return this.$route.query.contract_id;
    },
    arePartiesEditable() {
      return !this.isAttachment
        && this.isUpdate // contract from store might be outdated (e.g. edit contract -> go to dashboard -> create new wysiwyg)
        && this.contract && this.contract.id;
    },
    workspaceContractSettings() {
      return this.activeWorkspace.settings.contracts;
    },
    isOnedriveEnabled() {
      return this.activeWorkspace.settings.onedrive_enabled;
    },
    hasAdvancedWorkflowSelects() {
      return !this.isAttachmentContract;
    },
    hasReviewComments() {
      const hasReviewComments = !this.isAttachmentContract
        && !this.one_device
        && !this.isEditingContactsTemplate
        && this.contract.comments_usage;

      return !!hasReviewComments; // type Boolean would convert empty string to true
    },
    isAttachmentContract() {
      return this.isAttachment || (this.contract && this.contract.is_attachment);
    },
    isEditingContactsTemplate() {
      return FeatureFlags.isEditingContactsTemplate(this.contract);
    },
    onedrivePath() {
      return this.onedriveFolder && this.onedriveFolder != '/' ? this.onedriveFolder : this.$t('contract.onedrive.root');
    },
    contractDocuments () {
      if (this.contract?.attachments?.length === 0) {
        return [this.contract]
      }

      return this.contract?.attachments || []
    },
    showOneDevice() {
      if (
        !this.workspaceContractSettings.isOnedeviceEnabled ||
        this.isAttachmentContract ||
        this.isEditingContactsTemplate
      ) {
        return false;
      }

      return this.workspaceContractSettings.isOnedeviceEnabled || this.one_device;
    },
  },
  watch: {
    profile: function () {
      // hotfix for page reload page with new contract - profile is not yet loaded in fetchData
      this.loadDataFromWorkspaceSettings();
    },
    contract(newValue) {
      if (newValue.title) {
        this.title = newValue.title;
      }
    }
  },
  validations: {
    title: {
      required,
      minLength: minLength(2)
    },
  },
  created() {
    this.fetchData();
    this.fetchWorkspaceSignatureFooterSettings(this.$route.params.workspace_id);
  },
  methods: {
    ...mapActions({
      fetchSignSettings: 'contractObject/fetchSignSettings',
      fetchSettingsTemplatesList: 'predefinedWorkflow/fetchSettingsTemplatesList',
      fetchWorkspaceSignatureFooterSettings: 'workspace/fetchWorkspaceSignatureFooterSettings',
    }),
    onChangePredefinedSettingsTemplate(selectedPredefinedSettingsTemplate) {
      if (null === selectedPredefinedSettingsTemplate) {
        return (() => {
          this.$store.commit('contractSignIdentities/SET_SIGN_IDENTITIES', this.defaultContractSettings.sign_identities);
          this.$store.commit('setContract', Object.assign(
            {},
            this.contract,
            {
              locale: this.defaultContractSettings.locale,
              one_device: this.defaultContractSettings.one_device,
              sign_identities: this.defaultContractSettings.sign_identities,
              url_redirect: this.defaultContractSettings.url_redirect,
              feature_flags: {
                ...this.contract.feature_flags,
                enabledFindAnchors: this.defaultContractSettings.enabled_find_anchors,
              }
            }
          ));
        })();
      }

      const currentSettings = this.predefinedSettingsTemplatesList.find((item) => {
        return item.id === selectedPredefinedSettingsTemplate.id;
      });

      if (currentSettings) {
        this.$store.commit('contractSignIdentities/SET_SIGN_IDENTITIES', currentSettings.sign_identities);
        this.$store.commit('setContract', Object.assign(
          {},
          this.contract,
          {
            locale: currentSettings.locale,
            one_device: currentSettings.one_device,
            sign_identities: currentSettings.sign_identities,
            url_redirect: currentSettings.url_redirect,
            feature_flags: {
              ...this.contract.feature_flags,
              enabledFindAnchors: currentSettings.enabled_find_anchors,
            }
          }
        ));
      }
    },
    onedriveDataLoad(path = null) {
      return ExternalDriveService.listOnedriveDirectories(this.$route.params.workspace_id, path);
    },
    openOnedriveExplorer() {
      this.chooseOnedriveFolder = true
    },
    selectOnedriveFolder(folder) {
      this.onedriveFolder = folder;
      this.chooseOnedriveFolder = false;
    },
    closeAndSave() {
      if (!this.isUpdate) {
        // ignore outdated contract in store (e.g. edit contract -> go to dashboard -> create new wysiwyg)
      } else if (this.$refs.createParties) {
        this.$refs.createParties.updateContract({
          onSuccess: () => {
            this.loading = false;
          },
          onError: () => {
            this.$notification.error(this.$t('contract.create.error.parties_save'));
            this.loading = false;
          },
        });
      } else {
        this.updateContract();
      }
      this.navigateBackHandler();
    },
    navigateBackHandler() {
      this.$router.push({name: 'dashboard', params: {workspace_id: this.$route.params.workspace_id}});
    },
    async fetchData() {
      await this.fetchSignSettings(this.workspace_id);
      await this.fetchSettingsTemplatesList(this.workspace_id);

      if (this.isAttachment) {
        this.loaded = true;

        this.one_device = this.contract.one_device;
        this.send_notification_for_ending_contract = this.contract.send_notification_for_ending_contract;

        if (this.contract.contract_ending_date) {
          this.date = new Date(this.contract.contract_ending_date.split(" ")[0]);
        } else {
          this.date = null;
        }

        this.waiting_limit = this.contract.waiting_limit;
        this.contractLocale = this.contract.locale;
      } else if (this.isUpdate) {
        ContractService
          .detailForFirstStepOfWizard(this.contract_id, this.workspace_id)
          .then((response) => {
              this.$store.commit('setContract', response);
              this.defaultContractSettings = Object.assign({}, response);

              if (WorkflowHelper.redirectIfContractIsNotEditableInWizard(response)) {
                return;
              }

              if (this.contract.template && this.contract.template.id) {
                this.loadTemplatePdfPreview(this.contract.template);
              }

              this.title = this.contract.title;
              this.number = this.contract.number;
              this.one_device = this.contract?.one_device || hasFeatureFlag(this.contract, 'oneDeviceChecked');
              this.waiting_limit = this.contract.waiting_limit;
              this.contractLocale = this.contract.locale;
              this.onedriveFolder = this.contract.onedrive_folder;

              if (this.contract.contract_ending_date) {
                this.date = new Date(this.contract.contract_ending_date.split(" ")[0]);
                this.endingDate = this.contract.contract_ending_date.split(" ")[0];
              } else {
                this.date = null;
                this.endingDate = null;
              }

              this.send_notification_for_ending_contract = this.contract.send_notification_for_ending_contract;
              this.loaded = true;
            })
          .catch(() => {
            this.$notification.error(this.$t('contract.not_loaded'));
            this.loaded = true;
          });
      } else {
        this.loadDataFromWorkspaceSettings();
        this.loaded = true;
      }
    },
    loadDataFromWorkspaceSettings() {
      if (this.waiting_limit === null) {
        this.waiting_limit = this.workspaceContractSettings.draftExpirationDays || null;
      }
      if (!this.send_notification_for_ending_contract) {
        this.send_notification_for_ending_contract = !!this.workspaceContractSettings.completedExpirationDays;
        if (this.workspaceContractSettings.completedExpirationDays) {
          const futureDate = new Date();
          futureDate.setDate(futureDate.getDate() + this.workspaceContractSettings.completedExpirationDays)
          this.date = futureDate;
          this.endingDate = futureDate.toISOString().slice(0, 10);
        }
      }
      if (!this.contractLocale) {
        this.contractLocale = this.activeWorkspace.locale;
      }
    },
    loadTemplatePdfPreview(template) {
      TemplateService.detail(this.workspace_id, template.id).then(data => {
        this.templatePreview = {
          id: template.id,
          name: template.title,
          hasPdfPreview: data.is_preview_pdf,
        };
      });
    },
    getTemplatePDF() {
      this.isLoadingTemplatePdfPreview = true;

      TemplateService.getPDF(this.templatePreview.id).then(response => {

        response.arrayBuffer().then((buffer) => {
          var base64Flag = 'data:application/pdf;base64,';
          var imageStr = this.arrayBufferToBase64(buffer);
          FileSaver.saveAs(base64Flag + imageStr, this.templatePreview.name +".pdf");
          this.isLoadingTemplatePdfPreview = false;
        });

      })
    },

    arrayBufferToBase64(buffer) {
      var binary = '';
      var bytes = [].slice.call(new Uint8Array(buffer));

      bytes.forEach((b) => binary += String.fromCharCode(b));

      return window.btoa(binary);
    },

    createContract() {
      this.$v.$touch();

      if ( this.$v.$error ) {
        this.$notification.error(this.$t('general.fill_required'));
        this.loading = false;
        return;
      }

      this.loading = true;

      if (this.isUpdate) {
        this.updateContract();
      } else {

        if (this.isAttachment) {
          this.createNewContractAttachment();
        } else {
          this.createNewContract();
        }
      }
    },
    getEditRequest() {
      const data = {
        workspace_id: this.workspace_id,
        one_device: this.one_device && !this.isEditingContactsTemplate ? 1 : 0,
        contract_ending_date: this.endingDate,
        send_notification_for_ending_contract : this.send_notification_for_ending_contract ? 1 : 0,
        title: this.contract.title,
        number: this.contract.number || '',
        note: this.contract.note || null,
        locale: this.contract.locale,
        feature_flags: {
          enabledFindAnchors: this.contract?.feature_flags?.enabledFindAnchors || false,
          hasSequentialSigningOrder: this.contract?.feature_flags?.hasSequentialSigningOrder || false,
        },
      };
      if (this.isOnedriveEnabled) {
        // TODO: external drive - refactoring root dir vs no dir
        data.onedrive_folder = this.onedriveFolder || '/';
      }
      return data;
    },
    updateContract() {
      const data = this.getEditRequest();
      return ContractService.update(this.contract.id, data).then(response => {
        this.$store.commit('setContract', response);
        this.loading = false;
        return false;
      }, () => {
        this.$notification.error(this.$t('contract.create.error.edit'));
        this.loading = false;
        return true;
      });
    },
    createNewContract() {
      const data = {
        ...this.getEditRequest(),
        type: 'wysiwyg',
      };
      ContractService.create(data, this.activeWorkspace).then(response => {
        this.$router.push({
          name: 'createContractNew',
          params: { workspace_id: this.activeWorkspace.id },
          query: { contract_id: response.id }
        });
      }, this.processCreateError);
    },
    createNewContractAttachment() {
      const data = {
        type: 'wysiwyg',
        workspace_id: this.workspace_id,
        contract_id: this.contract.id,
        title: this.title,
        number: this.number,
      };
      ContractService.addAttachment( data, this.contract.id ).then(response => {
        this.$router.push({name: 'createOwn', params: {workspace_id: this.$route.params.workspace_id, contract_id: response.id}});
      }, this.processCreateError);
    },
    processCreateError(e) {
      // TODO: error message duplicated in other files (not used in AttachmentPanel, so it might be dead code)
      let errorMessage = 'Návrh smlouvy se nepodařilo vytvořit.';
      if (e === 'Uživatel nemá dostatek kreditů') {
        errorMessage = 'Nemůžete smlouvu vytvořit, dokupte si prosím kredity';
      }
      this.$notification.error(errorMessage);
      this.loading = false;
    },
    deleteDocument(documentId = null) {
      documentId = documentId || this.documentToDelete;

      ContractService
        .delete(documentId)
        .then(() => {
          this.errorDocumentIds.splice(this.errorDocumentIds.indexOf(`${documentId}`), 1);

          const nextContract = this.contractDocuments.find(
            (contract) => `${contract.id}` !== `${this.$route.query.contract_id}` &&
              contract.state === 'draft'
          );

          if (typeof nextContract?.id === 'undefined') {
            return this.$router.push({
              name: 'dashboard',
              params: {
                workspace_id: this.$route.params.workspace_id,
              },
            });
          }

          this.$notification.success(this.$t('docs.delete.ok'));

          if (`${this.$route.query.contract_id}` === `${documentId}`) {
            return this.$router.push({
              name: 'createContractNew',
              params: {
                workspace_id: this.$route.params.workspace_id,
              },
              query: {
                contract_id: nextContract.id,
              }
            });
          }

          this.fetchData();
        })
        .catch(() => {
          this.$notification.error(this.$t('docs.delete_draft.failed'));
        })
        .finally(() => {
          this.documentToDelete = null;
        });
    }
  },
}
</script>

<style lang="scss">
.content-info {
  position: relative;
}

.feature-flags {
  margin: 10px 0;
  .feature-flags-flag {
    font-size: 12px;
    padding: 3px 5px;
    color: black;
    background-color: #ddd;
    border-radius: 8px;
  }
}

.content-finish__box {
  div.select, div.select-wrap-under {
    width: 320px;
  }

  @include md {
    &.create-content-step1 {
      padding: {
        left: 20px !important;
        bottom: 1em !important;
        right: 20px !important;
      };
    }
  }
}
.create-content-step1.content-finish__box {
  border-radius: 6px 6px 0 0;
  max-width: 750px;
  border: 1px solid #DEE7F4;
  border-bottom: 0;
  @include md {
    border-bottom: 1px solid #DEE7F4;
  }
}

.cards{
  margin-left: -10px;
  margin-right: -10px;
}

.cards__item{
  width: 50%;
  float: left;
  margin-top: 20px;
  padding: 0 10px;

  @include sm{
    width: 100%;
  }
}

.template-button-create {
  position: absolute;
  right: 0px;
  top: -75px;
  @include md {
    display: none;
  }
}
.template-button-create--mobile{
  // display: none;
  text-align: left;
  margin-top: 30px;

  @include md{
    display: block;
  }
}

.template-selected {
  font-size:16px;
}

.view-pdf {
  position: relative;
}

.view-pdf__loading {
  width: 20px;
  height: 20px;
  background: url(/images/loader.svg);
  background-position: center;
  background-size:contain;
  animation: spin 4s linear infinite;

  position: absolute;
  right: -30px;
  top: 50%;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  margin-top: -10px;
  z-index: 4;
}

.template-attachment__credit-cost {
  max-width: 320px;
  margin: 17px 0;
  display: flex;
  align-items: flex-start;
  font-size: 15px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.47;
  letter-spacing: -0.03px;
  color: #616161;

  svg {
    width: 23px;
    margin-right: 4px;
    margin-top: 2px;
  }

  p {
    text-align: left;
  }
}
body #app .create-content-step1 {
  padding-bottom: 10px;
  padding-left: 70px;
  padding-right: 70px;

  h1 {
    text-align: left;
    font-weight: bold;
    margin-bottom: 28px;
  }
  .content-contract-info {
    @include md {
      padding: 20px 20px 0;
    }
  }
}
.feature-flags {
  display: flex;
  padding-left: 20px;
}
.create-content-step1 .content-contract-info .input {
  margin: 0;
  margin-top: 10px;
}
.input.draft-expiration {
  margin-top: 1em !important;
  @include md {
    width: 100%;
  }
}
.content-finish__box-links-align-left {
  text-align: left;
}
.content-finish__box-one-drive {
  font-size: 16px;
  color: black;
  padding: 10px;
  border: 1px solid gainsboro;
  border-radius: 6px;
  width: fit-content;
  cursor: pointer;
  line-height: 1.5;
}
.content-finish__box-one-drive-logo {
  height: 16px;
  display: block;
  margin-bottom: 5px;
}
.content-finish__box-one-drive-name {
  color: #5d259e;
  font-weight: bold;
  margin-right: 20px;
}
.content-finish__box-one-drive-choose {
  cursor: pointer;
  svg {
    vertical-align: middle;
  }
}
</style>
