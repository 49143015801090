const routes = [
  {
    path: '/sign/:hash',
    alias: '/contract/received/:hash',
    name: 'public-sign-intro',
    component: () => import(/* webpackChunkName: "public-sign__intro" */ './views/PublicSignIntro'),
  },
  {
    path: '/sign/:hash/rejected',
    name: 'public-sign-rejected',
    component: () =>
      import(/* webpackChunkName: "public-sign__rejected" */ './views/RejectedContract'),
  },
  {
    path: '/sign/:hash/expired-link',
    name: 'public-sign-expired-link',
    component: () =>
      import(/* webpackChunkName: "public-sign__expired-link" */ './views/ExpiredContract'),
  },
];

routes.forEach((route) => {
  route.meta = {
    authRequired: false,
    fullLayout: true,
    layout: () => import(/* webpackChunkName: "public-sign-layout" */ '@/layouts/PublicSignLayout'),
  };
});

export default routes;
