export const namespaced = true;

export const state = {
  timer: null,
  timeout: false,
  timePassed: 0,
  availableTime: 10 * 60,
  fullDashArray: 283,
  colors: {
    info: {
      color: 'green',
    },
    warning: {
      color: 'orange',
      threshold: 2 * 60,
    },
    alert: {
      color: 'red',
      threshold: 60,
    },
  },
};

export const mutations = {
  startTimer(state, payload) {
    state.timer = payload;
  },
  stopTimer(state) {
    clearInterval(state.timer);
    state.timeout = true;
    state.timer = null;
  },
  setTimeout(state) {
    state.timeout = true;
  },
};

export const actions = {
  startTimer: (context) => {
    if (context.getters['timer'] !== null) {
      return;
    }

    context.state.timer = setInterval(() => {
      if (context.getters['timePassed'] >= context.getters['availableTime']) {
        context.commit('setTimeout');
        context.commit('stopTimer');

        return;
      }

      context.state.timePassed++;
    }, 1000);
  },
  stopTimer: (context) => {
    context.commit('stopTimer');
  },
};

export const getters = {
  timer: (state) => {
    return state.timer;
  },
  timeout: (state) => {
    return state.timeout;
  },
  timePassed: (state) => {
    return state.timePassed;
  },
  availableTime: (state) => {
    return state.availableTime;
  },
  colors: (state) => {
    return state.colors;
  },
  fullDashArray: (state) => {
    return state.fullDashArray;
  },
};
