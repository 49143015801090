<template lang="html">
    <div class="logo-symbol">
        <svg width="144px" height="31px" viewBox="0 0 144 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="SYN001---DSKTP---Přijatá-smlouva---odmítnutí" transform="translate(-528.000000, -93.000000)">
                    <g id="v2">
                        <g id="logo/signee-inverse" transform="translate(528.000000, 93.000000)">
                            <g id="logo/signee">
                                <g id="logo/signee-symbol" fill-rule="nonzero">
                                    <path fill="#5E239E" d="M11.8286676,0.782507187 C7.04117886,3.57755672 4.00228641,5.36493488 2.71199024,6.14464169 C0.776545978,7.31420189 -0.661857344,10.2752075 0.521335726,12.6479325 C1.31013111,14.2297492 4.58832477,19.9298633 10.3559167,29.7482749 C14.6368457,27.2579338 17.6899165,25.4906917 19.5151291,24.4465487 C22.252948,22.8803341 23.0483333,20.0346443 21.706183,17.6500177 C20.8114161,16.0602666 17.518911,10.4377631 11.8286676,0.782507187 Z" id="Path-10"></path>
                                    <path d="M20.1459291,0 C12.0658686,4.65823808 7.42129499,7.35974146 6.21220813,8.10451015 C4.39857784,9.22166317 4.33567391,11.7814138 4.96531235,12.958375 C5.59495078,14.1353362 7.69214802,15.1762788 9.71665626,14.0673269 C11.7411645,12.958375 18.7252986,8.93937689 20.4257018,7.8483118 C22.126105,6.75724671 22.6734691,4.54722287 21.9435837,3.0800219 C21.4569935,2.10188791 20.8577753,1.07521395 20.1459291,0 Z" id="Path-11" fill="#6DECAF"></path>
                                    <path d="M15.7977941,16.3966942 C7.71773364,21.0549323 3.07316,23.7564357 1.86407314,24.5012044 C0.0504428515,25.6183574 -0.0124610838,28.178108 0.617177355,29.3550692 C1.24681579,30.5320305 3.34401303,31.572973 5.36852127,30.4640211 C7.39302952,29.3550692 14.3771636,25.3360711 16.0775668,24.245006 C17.77797,23.1539409 18.3253341,20.9439171 17.5954487,19.4767161 C17.1088585,18.4985821 16.5096403,17.4719082 15.7977941,16.3966942 Z" id="Path-11-Copy" fill="#6DECAF" transform="translate(9.083501, 23.666338) scale(-1, -1) translate(-9.083501, -23.666338) "></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
</template>


<script>
    export default {
        name: 'LogoSymbol',
        components: {

        },
        data() {
            return {

            }
        },
        created() {

        },
        computed: {

        },
        methods: {

        }
    }
</script>


<style lang="scss">
    .logo-symbol{
        height: 60px;
        position: relative;
        float: left;

        svg{
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

</style>
