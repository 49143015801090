<template lang="html">
  <ContractLayout
    :background-image="contract.settings && contract.settings.background ? contract.settings.background : `/images/contract-desktop-bg.20200622.jpg`"
    :contract="contract"
  >
    <template v-slot:content>
      <div class="contract confirm">
        <div class="contract__data contract__data--info">
          <div class="contract__logo">
            <Logo :logo="contract.settings ? contract.settings.logo : null" :white="false" larger/>
          </div>

          <template v-if="!loaded">
            <Loader class="register-loader" style="text-align: center; padding-left: 0" />
          </template>

          <template v-if="loaded">
            <h1 class="contract-title">{{ $t('signing.thank_you') }}</h1>

            <div class="contract__message contract__message--margin">

              <p v-if="isStateCompleted" class="p-bigger font-weight-light">{{ $t('signing.successfully_signed_notice') }}</p>

              <p v-else class="p-bigger font-weight-light">{{ $t('signing.successfully_signed_and_sent_counterparty_notice') }}</p>

              <div v-if="isStateCompleted" class="contract__links contract__links--info">
                <a v-if="contract.attachments && contract.attachments.length > 0"
                   @click.prevent="getPDFZip()"
                   href="#"
                   class="view-pdf"
                >
                  {{ $t('contract.download') }} <span v-if="loadingPDF" class="view-pdf__loading"></span>
                </a>
                <a v-else
                   @click.prevent="getPDF()"
                   href="#"
                   class="view-pdf"
                >
                  {{ $t('contract.download_pdf') }} <span v-if="loadingPDF" class="view-pdf__loading"></span>
                </a>
              </div>
            </div>

            <div v-if="canCreateAccount" class="contract__info contract__info--thin" style="width: 100%">
              <p class="p-bigger p-title">{{ $t('signing.want_to_access_your_contracts_online') }}</p>
            </div>

            <div v-if="canCreateAccount" class="contract__buttons contract__buttons--closer">
              <v-btn
                :color="contractPrimaryColor"
                max-width="15rem"
                width="100%"
                x-large
                style="color: #ffffff"
                @click="$router.push({name: 'registerEmail', params: {register_email: $route.params.email}})"
              >
                {{ $t('login.create_account_link') }}
              </v-btn>
            </div>
          </template>
        </div>
      </div>
    </template>
  </ContractLayout>
</template>


<script>
import ContractLayout from "@/views/contract/components/ContractLayout";
import _ from 'lodash';

import { mapGetters } from 'vuex';
import FileSaver from 'file-saver';
import { ContractService } from '@/services/ContractService.js';
import Loader from '@/components/Loader.vue';
import environment from "@/config/environment";

export default {
  name: 'contractConfirm',
  components: {
    ContractLayout,
    Loader,
  },
  data() {
    return {
      loaded: false,
      loadingPDF: false,
    }
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters([
      'contract',
    ]),
    canCreateAccount() {
      return !environment.isRestrictedMode();
    },
    contractPrimaryColor() {
      return this.contract?.settings?.primary_color || 'primary'
    },
    isStateCompleted() {
      return this.contract.state === 'completed'
    },
  },
  methods: {
    fetchData() {
      this.loaded = false;
      ContractService.contractFromApiToken({
        component: this,
        isCompletedContractAllowed: true,
        onSuccess: (response) => {
          this.$store.commit('setContract', response);
          this.loaded = true;
        },
        onError: () => {
          this.loaded = true;
        },
      });
    },

    getPDF() {
      this.loadingPDF = true;

      ContractService.getPDFUnauthorized( this.contract.contract_id, this.$route.params.hash ).then(response => {

        response.arrayBuffer().then((buffer) => {
          var base64Flag = 'data:application/pdf;base64,';
          var imageStr = this.arrayBufferToBase64(buffer);
          FileSaver.saveAs(base64Flag + imageStr, this.contract.contract_title +".pdf");
          this.loadingPDF = false;
        });

      })
    },

    getPDFZip() {
      ContractService.openPDFZipUnauthorized(this.contract.contract_id, this.$route.params.hash);
    },

    str2bytes(str) {
      var bytes = new Uint8Array(str.length);
      for (var i=0; i<str.length; i++) {
        bytes[i] = str.charCodeAt(i);
      }
      return bytes;
    },

    arrayBufferToBase64(buffer) {
      var binary = '';
      var bytes = [].slice.call(new Uint8Array(buffer));

      bytes.forEach((b) => binary += String.fromCharCode(b));

      return window.btoa(binary);
    },
  }
}
</script>

<style lang="scss">
.contract.confirm a:hover {
  color: #5d2a9c;
}
</style>
