<template>
  <v-dialog v-model="showCreateInvitationDialog" max-width="45rem">
    <template v-slot:activator="{ on, attrs }">
      <v-btn id="createInvitationDialogActivator" color="primary" v-bind="attrs" v-on="on" text>
        <v-icon left>mdi-plus</v-icon>
        {{ $t('workspace.team.add_member') }}
      </v-btn>
    </template>

    <InvitationForm
      v-model="invitation"
      :is-submitted="submitted"
      @success-changes="onCreateInvitation"
      @discard-changes="onCloseDialog"
    />
  </v-dialog>
</template>

<script>
import { createInvitation } from '@workspace/services/workspaceMembersService';
import InvitationForm from '@workspace/components/InvitationForm';

export default {
  name: 'CreateInvitation',
  components: {
    InvitationForm,
  },
  data() {
    return {
      invitation: {
        all_contracts: false,
        all_contacts: true,
        email: '',
        position: '',
        rights: 'signer',
      },
      invitationDefaultConfig: {
        all_contracts: false,
        all_contacts: true,
        email: '',
        position: '',
        rights: 'signer',
      },
      showCreateInvitationDialog: false,
      submitted: false,
    };
  },
  computed: {
    workspaceId() {
      return this.$route.params.workspace_id;
    },
  },
  methods: {
    onCreateInvitation() {
      this.submitted = true;

      createInvitation(
        Object.assign({}, this.invitation, {
          all_contracts: this.invitation.all_contracts ? 1 : 0,
          all_contacts: this.invitation.all_contacts ? 1 : 0,
          workspace_id: this.workspaceId,
        }),
      )
        .then(() => {
          this.$emit('reload-workspace');
          this.onCloseDialog();
        })
        .catch(() => {
          this.$notification.error(this.$t('workspace.invitation.not_possible'));
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    onCloseDialog() {
      this.invitation = Object.assign(this.invitationDefaultConfig);
      this.showCreateInvitationDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
