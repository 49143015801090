<template>
  <ContractLayout
    :background-image="contract.settings && contract.settings.background ? contract.settings.background : `/images/contract-desktop-bg.20200622.jpg`"
    :contract="contract"
    :is-loading="isLoading"
  >
    <template v-slot:content>
        <div class="contract twofa">
            <div class="contract__data contract__data--thin">
                <div class="contract__logo">
                    <Logo :logo="contract.settings ? contract.settings.logo : null" :white="false" larger/>
                </div>

                <h1 class="contract-title">{{ $t('two_factor_auth.two_factor_auth_heading') }}</h1>


                <div class="contract__message contract__message--margin">
                    <p class="p-bigger p-title">{{ $t('two_factor_auth.enter_pin_heading_heading') }}</p>
                    <p><small>{{ $t('two_factor_auth.enter_pin_heading_text') }}</small></p>
                </div>

                <InputPIN ref="inputPin" v-model="pinCode" @confirmPin="confirmPin" />

                <div class="contract__buttons contract__buttons--closer">
                    <Button
                        :loading="isConfirmingPIN"
                        style="position: relative;"
                        type="primary"
                        class="button--wider button--top"
                        @click="confirmPin"
                        :customPrimaryColor="contract.settings.primary_color"
                    >
                      {{ $t('two_factor_auth.submit_pin') }}
                    </Button>
                </div>

                <p v-if="canChangePhoneNumber">
                  <a href="#" @click.prevent="$router.push({name: 'contract2FAStart', params: { hash: $route.params.hash }})">
                    {{ $t('two_factor_auth.didnt_receive_pin') }}
                  </a>
                </p>

                <div v-if="canChangePhoneNumber" class="contract__links contract__links--upper">
                  <a href="#" @click="$router.push({name: 'contract2FAStart', params: { hash: $route.params.hash }})">
                    {{ $t('two_factor_auth.change_mobile_phone') }}
                  </a>
                </div>
            </div>
        </div>
    </template>
  </ContractLayout>
</template>


<script>
import { mapGetters } from 'vuex';
import { ContractService } from '@/services/ContractService.js';
import { WorkflowHelper } from '@/helpers/WorkflowHelper.js';
import FeatureFlags from '@/common/reusable/featureFlagsChecker';
import ContractLayout from "@/views/contract/components/ContractLayout";
import InputPIN from '@/components/InputPIN.vue';

export default {
  name: 'contract2FASubmit',
  components: {
    ContractLayout,
    InputPIN,
  },
  data() {
    return {
      loaded: false,
      isConfirmingPIN: false,
      pinCode: ''
    };
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      myIdentity: 'myIdentity',
    }),
    isLoading() {
      return !this.loaded;
    },
    canChangePhoneNumber () {
      return !FeatureFlags.cantChangePhoneNumber(this.contract);
    }
  },
  created() {
    ContractService.contractFromApiToken({
      component: this,
      selectedContractId: this.$route.params?.contract_id || this.contract.id,
      onSuccess: (response) => {
        this.$store.commit('setContract', response);
        this.loaded = true

        if (WorkflowHelper.isMobileReadyForPIN(response) && this.$route.query.pin) {
          return this.sendPinToPhone();
        }

        if (typeof this.$route.params.receivedPinCode !== 'undefined') {
          this.autoConfirmPin(this.$route.params.receivedPinCode)
        }
      },
    });
  },
  methods: {
    sendPinToPhone() {
      const contract_id = this.$route.params?.contract_id || this.contract?.contract_id;

      let signIdentityId = this.contract?.sign_identity?.id;

      if (contract_id !== this.contract.id) {
        for (let index in this.contract.attachments) {
          const attachment = this.contract.attachments[index];

          if (parseInt(attachment.id, 10) === parseInt(contract_id, 10)) {
            signIdentityId = attachment.sign_identity.id;
            break;
          }
        }
      }

      ContractService
        .sendPhone({ mobile: this.myIdentity?.mobile }, this.$route.params.hash, contract_id, signIdentityId)
        .then(
          (response) => {
            if (typeof response.smsPin === 'undefined') {
              return
            }

            this.autoConfirmPin(response.smsPin)
          },
          (errorCode) => {
            this.$notification.error(
              errorCode == 403
                ? this.$t('contract.errors.access_2fa')
                : this.$t('contract.errors.send_pin_on_mobile')
            );
          }
        );
    },
    autoConfirmPin (pinCode) {
      setTimeout(() => {
        this.pinCode = pinCode
      }, 200)

      setTimeout(() => {
        this.confirmPin()
      }, 200)
    },
    confirmPin() {
      if (this.isConfirmingPIN) {
        return; // Prevent double sending
      }

      if (this.pinCode === '') {
        return;
      }

      this.isConfirmingPIN = true;
      if (true) {
        const selectedContractId = this.$route.params.contract_id || this.contract.contract_id;
        const selectedContract = WorkflowHelper.getSelectedContractForUnregistredUser(this.contract, selectedContractId);
        ContractService.sendPin(this.pinCode, this.$route.params.hash, selectedContract.id, selectedContract.current_sign_identity.id).then(
          response => {
            this.isConfirmingPIN = false;
            WorkflowHelper.redirectAfterVerifiedSmsPin({
              contract: selectedContract,
              routeParams: {
                hash: this.$route.params.hash,
                contract_id: selectedContract.id,
              },
              detailQuery: {
                contract_id: selectedContract.id,
              },
              autoapprove: () => ContractService.markApprovedAsUnregistered(
                selectedContract.id,
                selectedContract.current_sign_identity.id,
                this.$route.params.hash
              ),
              redirectToCompleted: ()  => this.$router.push({
                name: 'contractComplete',
                params: {
                  hash: this.$route.params.hash,
                  email: selectedContract.current_sign_identity.email,
                },
              }),
            });
          },
          errorCode => {
            if (errorCode == 429) {
              this.$notification.error(this.$t('contract.errors.pin_limit'));
              this.redirectToPhone(contract_id);
              return;
            }
            this.isConfirmingPIN = false;
            this.$notification.error(errorCode == 403 ? this.$t('contract.errors.access_2fa') : this.$t('contract.errors.invalid_pin'));
          }
        );
      }
    },
    redirectToPhone(contract_id) {
      this.$router.push({name: 'contract2FAStart', params: { hash: this.$route.params.hash, contract_id: contract_id }})
    },
  }
};
</script>

<style lang="scss">
.contract.twofa a:hover {
  color: #5d2a9c;
}
</style>
