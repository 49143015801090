export function insertAtCursor(ref, myValue, spaceAfter = false) {
  const space = spaceAfter ? ' ' : '';
  if (ref.selectionStart || ref.selectionStart == '0') {
    const startPos = ref.selectionStart;
    const endPos = ref.selectionEnd;
    const text = ref.value.substring(0, startPos) + myValue + space + ref.value.substring(endPos, ref.value.length);
    const newCursorPosition = ref.selectionStart + myValue.length + 1;
    return {text, newCursorPosition};
  } else {
    return ref.value += space + myValue;
  }
}

export function setCursorPosition(ref, newCursorPosition) {
  ref.selectionStart = ref.selectionEnd = newCursorPosition;
  ref.focus();
}
