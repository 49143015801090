<template>
  <v-container class="sign-identity-role__card ma-0 mb-5 py-4 px-3" fluid>
    <v-row align="start" justify="start" no-gutters>
      <v-col v-if="isDraggable" class="col-auto">
        <v-icon color="#b3c2d6">mdi-drag</v-icon>
      </v-col>

      <v-col class="col-auto px-2">
        <VerificationBadge :avatar-size="30" :contact="signIdentity" />
      </v-col>

      <v-col class="pr-5">
        <v-row align="start" justify="space-between" no-gutters>
          <v-col>
            <div class="proposer__item-name">
              <span class="font-weight-bold">Role:</span>
              <span class="signatory-search--signers-result-name">
                {{ signIdentity.variable_position }}
              </span>
            </div>
          </v-col>

          <v-col class="col-auto">
            <!--            <v-btn icon @click="$emit('on-update', signIdentity)">-->
            <!--              <v-icon color="#B3C1D6"> mdi-pencil-outline </v-icon>-->
            <!--            </v-btn>-->

            <v-btn icon @click="$emit('on-remove', signIdentity)">
              <v-icon color="#B3C1D6">mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12">
            <v-select
              class="mt-2 font-weight-regular"
              v-model="signIdentity.contract_role"
              :items="availableRoles"
              item-value="value"
              item-text="name"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VerificationBadge from '@/features/contacts/components/VerificationBadge';

export default {
  name: 'SignIdentityRoleCard',
  components: {
    VerificationBadge,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    signIdentity: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    availableRoles() {
      return [
        {
          name: this.$t('contract.signs.role_sign'),
          value: 'sign',
        },
        {
          name: this.$t('contract.signs.role_approve'),
          value: 'approve',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-identity-role__card {
  border: 1px solid #c5ccd8;
  border-radius: 6px;
  background-color: #fff;
}
</style>
