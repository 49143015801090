<template>
  <div class="content-box__header__signs">
    <div class="content-box__header__back">
      <span v-if="isAllSignaturesPlaced">
        <s-help :message="$t('info.document.sign_boxes')"></s-help>
        {{ $t('contract.sign_boxes_for_placement') }}:
      </span>

      <v-btn
        v-if="isEditSignaturesForced"
        color="primary"
        class="ml-auto mr-0"
        x-large
        :block="$vuetify.breakpoint.mobile"
        @click="$emit('continueToSignButtons')"
      >
        {{ $t('contract.continue_to_signature') }}
      </v-btn>
    </div>

    <div class="sign-group__wrapper">
      <div class="sign-group__container">
        <div
          v-for="(signatureGroup, index) in unsignedSignatures"
          :key="index"
          class="sign-group"
          :class="{'signature-group-empty': !signatureGroup }"
        >
          <div
            v-if="
              signatureGroup &&
              !signatureGroup.every((signature) => signature.is_placed)
            "
            class="sign-group__header"
          >
            {{
              index === 0
                ? $t('contract.parties.proposer2')
                : $t('contract.parties.counterparties2', { partyOrder: index })
            }}
          </div>
          <div class="sign-group-all">
            <div
              class="sign-group__inner"
              v-for="signature in signatureGroup"
              :key="signature.signIdentity.id"
            >
              <Drag :data="signature">
                <button
                  v-if="!signature.is_placed"
                  @mouseup="autoPlaceSignature(signature)"
                  :class="{
                    'sign-button': true,
                    'sign-button--owner': signature.is_proposer_sign,
                    'sign-button--counter': !signature.is_proposer_sign,
                    proposer: signature.is_proposer_sign,
                    negotiator: !signature.is_proposer_sign,
                    active: activeSignPlacement === 'ownerSign',
                    inconsistent: signature.is_inconsistent,
                  }"
                  type="button"
                  name="button"
                >
                  <div class="sign-button__inner">
                    <div
                      class="sign-button__avatar"
                      :style="[
                        signature.signIdentity.avatar_url
                          ? {
                              'background-image':
                                `url(${signature.signIdentity.avatar_url})`,
                            }
                          : { 'background-image': 'unset' },
                      ]"
                    >
                      <span v-if="!signature.signIdentity.avatar_url">
                        {{ getSignInitials(signature) }}
                      </span>
                    </div>
                    <div class="sign-button__content">
                      <div class="sign-button__name">
                        <template v-if="signature.signIdentity.variable_position">
                          {{ signature.signIdentity.variable_position }}
                        </template>
                        <template v-else>
                          {{ signature.firstname }} {{ signature.lastname }}
                        </template>
                      </div>
                      <div class="sign-button__desc">
                        {{
                          signature.signIdentity.organization_name ||
                          $t('general.signature')
                        }}
                      </div>
                    </div>
                    <div class="sign-button__ico">
                      <span class="icon-check"></span>
                    </div>
                  </div>
                  <div class="sign-button__handle">
                    <IcoPlaceSignature />
                  </div>
                </button>
              </Drag>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-box__extended">
      <span
        class="sign__load-from-other-documents"
        @click="openManageSignIdentities"
      >
        {{ $t('contract.signs.edit') }}
      </span>

      <span
        class="sign__load-from-other-documents"
        :title="$t('contract.signs.load_from_history')"
        @click="$emit('openSignaturePositionsModal')"
      >
        {{ $t('contract.signs.load_from_history2') }}
      </span>
    </div>

    <ContractSignIdentitiesManagement
      :show-dialog="manageSignIdentities && !insertingSignatory"
      :unsigned-signatures="editedUnsignedSignatures"
      @save-sign-identities="saveSignatoryIdentities"
      @remove-sign-identity="editSignatoryIdentities($event)"
      @close-dialog="manageSignIdentities = false"
    />
  </div>
</template>

<script>
import { ContractHelper } from '@/helpers/ContractHelper';
import { Drag, DragAwareMixin } from 'vue-easy-dnd';
import IcoPlaceSignature from '@/components/svg/IcoPlaceSignature';
import IcoSearchPurple from '@/components/svg/IcoSearchPurple.vue';
import IcoInfo from '@/components/svg/IcoInfo';
import { getElementBounds, getPage } from '@/services/helpers/signatureHelper';
import { ContractService } from '@/services/ContractService.js';
import { mapGetters } from 'vuex';
import IcoClose from '@/components/svg/IcoClose';
import Modal from '@/components/Modal.vue';
import { cloneDeep } from 'lodash';
import ContractSignIdentitiesManagement from '@contract/components/ContractSignIdentitiesManagement';
import SAvatar from '@/common/components/SAvatar';

export default {
  name: 'ContentSigns',
  components: {
    SAvatar,
    ContractSignIdentitiesManagement,
    Drag,
    DragAwareMixin,
    IcoPlaceSignature,
    IcoSearchPurple,
    IcoClose,
    IcoInfo,
    Modal,
  },
  mixins: [DragAwareMixin],
  props: {
    signatures: {
      type: Array,
      required: true,
    },
    activeSignPlacement: {
      required: true,
    },
    isEditSignaturesForced: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      manageSignIdentities: false,
      insertingSignatory: false,
      editingSignatoryIdentities: false,
      editedUnsignedSignatures: [],
      savingIdentities: false,
    };
  },
  computed: {
    isAllSignaturesPlaced() {
      return !this.unsignedSignatures.every((signatureGroup) =>
        signatureGroup.every((signature) => signature.is_placed)
      );
    },
    filteredEditedUnsignedSignatures() {
      return this.editedUnsignedSignatures.filter(
        (item) =>
          item &&
          item.signIdentity &&
          ContractHelper.isSigningContractRole(item.signIdentity)
      );
    },
    availableSignatories() {
      return this.editedUnsignedSignatures
        .filter((s) => !ContractHelper.isSigningContractRole(s.signIdentity))
        .map((item) => ({
          ...item.signIdentity,
          name: `${item.signIdentity.firstname} ${item.signIdentity.lastname}`,
        }));
    },
    unsignedSignatures() {
      const res = this.signatures
        .filter((signature) =>
          ContractHelper.hasIncompleteSigningContractRole(
            this.contract,
            signature.signIdentity
          )
        )
        .reduce((groupsObject, item) => {
          if (item.signIdentity.is_proposer) {
            if (groupsObject[0]) {
              groupsObject[0].push(item);
            } else {
              groupsObject[0] = [item];
            }
          } else {
            if (groupsObject[item.signIdentity.party_order]) {
              groupsObject[item.signIdentity.party_order].push(item);
            } else {
              groupsObject[item.signIdentity.party_order] = [item];
            }
          }
          return groupsObject;
        }, []);
      document.res = res;
      return res;
    },
    ...mapGetters(['contract']),
  },
  watch: {
    dragData(newVal) {
      if (newVal) {
        this.$emit('placeSign', newVal)
      }
    },
  },
  beforeDestroy() {
    if (this.editingSignatoryIdentities) {
      this.$emit('rerender');
    }
  },
  methods: {
    getSignInitials(signature) {
      return signature?.signIdentity?.initials || signature?.signIdentity?.variable_position?.charAt(0)?.toUpperCase();
    },
    openManageSignIdentities() {
      this.manageSignIdentities = true;
      this.editedUnsignedSignatures = cloneDeep(
        this.signatures.filter(
          (signature) =>
            !signature.signIdentity.is_signed &&
            ContractHelper.isFillableSignIdentity(
              signature.signIdentity,
              this.contract
            )
        )
      );
    },
    async editSignatoryIdentities(signatory) {
      this.editingSignatoryIdentities = true;

      const currentSignIdentityWrapper = this.editedUnsignedSignatures.find(
        (item) => {
          return item.signIdentity.id === signatory.signIdentity.id;
        }
      );

      if (currentSignIdentityWrapper) {
        ContractHelper.editContractRole(
          currentSignIdentityWrapper.signIdentity,
          'approve'
        );
      }
    },
    async saveSignatoryIdentities() {
      this.savingIdentities = true;

      try {
        await ContractService.update(this.contract.id, {
          parties: this.editedUnsignedSignatures.map((s) => ({
            id: s.signIdentity.id,
            contract_role: s.signIdentity.contract_role,
          })),
        });
      } catch (e) {
        this.$notification.error(this.$t('contract.create.error.parties_save'));
      }

      await this.$router.push({ query: { reloaded: `${Date.now()}` } });

      this.editingSignatoryIdentities = false;
    },
    autoPlaceSignature(signature) {
      const trim = document.querySelector('.content-box__data').getBoundingClientRect().top + 100 + window.scrollY;
      const scrollOfPanel = window.scrollY + document.querySelector('.content-box__header').getBoundingClientRect().top;
      const elementBounds = getElementBounds(trim);
      const pageToPlace = getPage(elementBounds, scrollOfPanel);

      this.$emit('autoPlaceSign', {
        page: pageToPlace.element,
        signPlacement: signature,
        pageY: scrollOfPanel - 545,
        pageNumber: pageToPlace.index,
      });
    },
  },
};
</script>

<style lang="scss">
.sign-button {
  position: relative;
  border: 1px solid #dfe7f3;
  background-color: #fff;
  padding: 4px;
  border-radius: 6px;
  color: black;
  font-size: 14px;
  float: left;
  cursor: pointer;
  @include animation;
  @include clearfix;

  @include md {
    font-size: 13px;
  }

  &.active {
    border-color: #5d2a9c;
  }

  &.completed .sign-button__ico {
    opacity: 1;
  }
}

.sign-button__avatar {
  float: left;
  width: 32px;
  height: 32px;
  line-height: 32px;
  color: white;
  font-weight: bold;
  border-radius: 100%;
  font-size: 14px;
  background-image: none;
  background: #d4e0ef;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.sign-container {
  position: absolute;

  &#sign1 {
    top: 25px;
  }
}

.sign-button__content {
  padding-left: 40px;
  text-align: left;
}

.sign-button__inner {
  @include clearfix;
  padding: 7px 45px 7px 4px;
}

.sign-button__name {
  font-weight: bold;
  font-size: 16px;
  color: #5e239e;
  letter-spacing: -0.03px;
}

.sign-button__desc {
  font-weight: 300;
  font-size: 13px;
  color: #8e97a4;
  letter-spacing: -0.03px;
}

.sign-button__ico {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px;
  @include animation;
}
.sign-group__wrapper {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 20px 0 12px 0;

  .sign-group__container {
    width: max-content;
    .sign-group {
      margin: 0 0 0 20px;
      width: fit-content;
      float: left;
      &:last-child:after {
        clear: both;
        float: none;
        content: '';
        display: table;
      }
      .sign-group__header {
        text-align: left;
        margin-bottom: 10px;
        font-weight: bold;
        color: black;
      }
      .sign-group__inner {
        float: left;
        margin-right: 0.5rem;
      }
      .sign-group__inner:last-child:after {
        clear: both;
        float: none;
        content: '';
        display: table;
      }
    }
  }
}
.content-box__header__signs {
  @include md {
    width: 100%;
    display: block;
    text-align: center;
  }
  .content-box__header__back {
    display: flex;
    justify-content: space-between;
    .content-box__header__signature-settings {
      font-size: 13px;
      text-decoration: underline;
      cursor: pointer;
      letter-spacing: initial;
      text-transform: initial;
    }
  }
}

.sign-button__handle {
  background: red;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  right: -7px;
  top: -7px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5e239e+0,6f43a8+100 */
  background: #5e239e; /* Old browsers */
  background: -moz-linear-gradient(
      left,
      #5e239e 0%,
      #6f43a8 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
      left,
      #5e239e 0%,
      #6f43a8 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
      to right,
      #5e239e 0%,
      #6f43a8 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5e239e', endColorstr='#6f43a8',GradientType=1 ); /* IE6-9 */
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: white;
    width: 60%;
    transform: rotate(-30deg);
  }
}
.content-box__extended {
  margin: 0 0 10px 0;
  .sign__load-from-other-documents {
    padding: 0 0 0 20px;
    text-decoration: underline;
    cursor: pointer;
    color: #9ca2ad;
    font-size: 13px;
  }
}
.manage-sign-identities__panel {
  hr {
    margin: 20px 0;
    background: #edf2f7;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #edf2f7;
    padding: 0;
  }
  .manage-sign-identities__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > span {
      text-decoration: underline;
      text-align: left;
      color: #5e239e;
      cursor: pointer;
      font-size: 15px;
    }
    button {
      font-size: 14px;
      height: 45px;
      width: 130px;
      margin: 0;
      .button__ico--left {
        display: none;
      }
    }
  }
  .sign-group {
    margin-bottom: 10px;
    .sign-group__header {
      margin-bottom: 5px;
    }
  }
}
.sign-button__bin-wrapper {
  height: 100%;
  display: flex;
  position: absolute;
  right: 10px;
  top: 0;
  align-items: center;
  .icon-bin:before {
    color: #8f96a3;
  }
}

.add-signature-fileld {
  text-decoration: underline;
  text-align: left;
  color: #5e239e;
  cursor: pointer;
  padding: 0;
  line-height: 15px;
}

.signature-group-empty {
  display: none;
}

.manage-sign-identities__close {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 15px;
  height: 15px;
}
.manage-sign-identities__select {
  width: 100%;
  height: 300px;
  overflow: scroll;
  position: relative;
  margin: 0 auto;
}

.content-box__header__signs {
  .modal {
    .modal-mask {
      justify-content: center;
      align-items: center;
      @include md {
        top: 60px;
      }
      .modal-wrapper {
        max-width: 300px;
        margin: 0 auto;
        padding: 0;
        top: 80px;
        position: relative;
        .modal-container {
          padding: 30px 20px;
          &:before {
            display: none;
          }
          .modal-default-button {
            top: 0;
            right: 0;
            background: none;
          }
          .modal-inner {
            h2 {
              line-height: 25px;
            }
            .sign-button {
              width: 100%;
              margin: 0 0 10px 0;
              float: none;
            }
            .select {
              width: 100%;
            }
            .select__data {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
