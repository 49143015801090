import { createDocumentImage } from '@verification/services/authService';

const convertDataUriToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',');
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const namespaced = true;

export const state = {
  dotState: {
    top: 50,
    left: 50,
    dotPosition: 'selfie',
  },
  selfie: {
    image: null,
    state: 'waiting',
  },
  settings: {
    documentTypeId: 2,
    documentTypeSide: 'FRONT',
  },
  movingDone: false,
  images: [],
};

export const mutations = {
  addImage(state, payload) {
    state.images.push(payload);
  },
  setDotState(state, payload) {
    state.dotState = payload;
  },
  setSelfie(state, payload) {
    state.selfie.image = payload;
  },
  setSettingsState(state, payload) {
    state.selfie.state = payload;
  },
  setMovingDone(state) {
    state.movingDone = true;
  },
};

export const actions = {
  addImage: (context, payload) => {
    context.commit('addImage', payload);
  },
  setDotState: (context, payload) => {
    context.commit('setDotState', payload);

    return payload;
  },
  setMovingDone: (context) => {
    context.commit('setMovingDone');
  },
  setSelfie: (context, payload) => {
    context.commit('setSelfie', payload);
  },
  uploadSelfieImage: (context, payload) => {
    const formData = new FormData();
    const file = convertDataUriToBlob(payload.image);

    formData.append('auth_image', file, 'authsDocumentSelfie');
    formData.append('auth_document_type_id', context.getters.documentTypeId);
    formData.append('auth_document_side', context.getters.documentTypeSide);

    return createDocumentImage(payload.authToken, formData).then(
      (resp) => {
        context.dispatch('setSelfie', resp).then(() => {
          context.commit('setSettingsState', 'uploaded');
        });
      },
      () => {
        context.commit('setSettingsState', 'error');
      },
    );
  },
};

export const getters = {
  dotState: (state) => {
    return state.dotState;
  },
  documentTypeId: (state) => {
    return state.settings.documentTypeId;
  },
  documentTypeSide: (state) => {
    return state.settings.documentTypeSide;
  },
  selfie: (state) => {
    return state.selfie.image;
  },
  selfieState: (state) => {
    return state.selfie.state;
  },
  isMovingDone: (state) => {
    return state.movingDone;
  },
  images: (state) => {
    return state.images.filter((image) => {
      return image.dotPosition !== 'selfie';
    });
  },
};
