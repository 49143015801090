/**
 * Pages showing document
 * @type {string[]}
 */
export const documentDetailPages = [
  'contractView',
  'createContent',
  'createContractNew',
  'documentsDetail',
];
