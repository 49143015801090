<template>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="454.886px" height="454.886px" viewBox="0 0 454.886 454.886" style="enable-background:new 0 0 454.886 454.886;"
	 xml:space="preserve">
    <g>
      <g>
        <path d="M257.762,454.886c-41.798,0-75.812-34-75.812-75.812V136.465c-0.056-12.127,4.65-23.587,13.252-32.235
          c17.18-17.266,47.294-17.266,64.474,0c8.602,8.648,13.311,20.108,13.254,32.312v212.2c0,8.387-6.785,15.168-15.168,15.168
          c-8.377,0-15.158-6.781-15.158-15.168V136.465c0.014-4.13-1.559-7.95-4.415-10.839c-5.745-5.746-15.754-5.746-21.499,0
          c-2.858,2.889-4.427,6.708-4.413,10.767v242.681c0,25.086,20.405,45.49,45.485,45.49c25.086,0,45.49-20.404,45.49-45.49V106.141
          c0-41.801-34.009-75.814-75.812-75.814c-41.803,0-75.817,34.014-75.817,75.814v212.28c0,8.382-6.781,15.163-15.161,15.163
          s-15.161-6.781-15.161-15.163v-212.28C121.302,47.623,168.92,0,227.441,0s106.144,47.623,106.144,106.141v272.933
          C333.584,420.886,299.584,454.886,257.762,454.886z"/>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {
  name: 'IcoPaperClip',
};
</script>
