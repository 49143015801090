<template>
  <div class="px-4 px-md-9 pt-0 pb-5 text-center">
    <v-checkbox v-model="saveSettingsAsTemplate" class="shrink mr-0 mt-0">
      <template v-slot:label>
        {{ $t('contract.workflow.predefined.save_header') }}
      </template>
    </v-checkbox>

    <v-expand-transition>
      <v-container v-if="saveSettingsAsTemplate" class="title-input-block mx-auto" fluid>
        <v-row align="center" justify="space-between" class="px-4 py-2" no-gutters>
          <v-col class="col-12 col-md-3 text-left">
            <span class="subtitle-1 font-weight-regular black--text">
              {{ $t('contract.workflow.predefined_title') }}
            </span>
          </v-col>

          <v-col class="col-12 col-md-9 px-0 pb-1">
            <div class="input-block">
              <template v-if="isSubmitted">
                <div class="prepared-name black--text">
                  {{ templateTitle }}
                  <v-icon right @click="onCancel">mdi-trash-can-outline</v-icon>
                </div>
              </template>

              <template v-else>
                <v-text-field
                  v-model="newTitle"
                  class="my-0 mx-2 pa-0"
                  :placeholder="$t('contract.workflow.predefined_placeholder')"
                  hide-details
                ></v-text-field>
              </template>

              <v-btn color="primary" :disabled="isSubmitted" @click="onSubmitTitle">
                {{ $t('general.save') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row align="center" justify="center" class="px-4 pb-2" no-gutters>
          <v-col class="col-12 col-md-3"></v-col>

          <v-col
            class="col-12 col-md-9 subtitle-2 title-input-block__info font-weight-regular text-left"
          >
            {{ $t('contract.workflow.predefined.info') }}
          </v-col>
        </v-row>
      </v-container>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'PredefinedWorkflowTitleInput',
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      newTitle: '',
      saveSettingsAsTemplate: false,
      submitted: false,
    };
  },
  computed: {
    templateTitle: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isSubmitted() {
      return this.submitted;
    },
  },
  methods: {
    onSubmitTitle() {
      this.templateTitle = this.newTitle;
      this.submitted = true;
    },
    onCancel() {
      this.templateTitle = '';
      this.submitted = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-input-block {
  width: 100%;
  background-color: #f8fbff;
  border: 1px solid #dde6f4;
  border-radius: 6px;

  &__info {
    color: #8c93a0;
  }
}

.input-block {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.3rem 0.3rem;
  border: 1px solid #b2bbcb;
  border-radius: 6px;
}

.prepared-name {
  background-color: #e8edf5;
  border: 1px solid #b4c1d6;
  border-radius: 6px;
  margin: 0.2rem 0.3rem;
  padding: 0.3rem 0.5rem;
}
</style>
