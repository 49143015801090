<template lang="html">
  <Data :loader="isLoadingFirstRequest" :isDashboard="true">
    <div class="title dashboard__title pb-5">
      <v-row align="center" justify="space-between">
        <v-col v-if="!isSelectedOneGroup">
          <h1 class="mb-0">{{ $t("contacts.title") }}</h1>
        </v-col>

        <v-col v-else>
          <h1 class="mb-0">
            {{ selectedGroups[0].name }}
            <a href="#" @click.prevent="openModalWithRow('renameGroup', selectedGroups[0])">
              <IcoEdit />
            </a>
            <a href="#" @click.prevent="openModalWithRow('deleteGroup', selectedGroups[0])">
              <IcoTrash />
            </a>
          </h1>
        </v-col>

        <v-col cols="auto">
          <CreateNewContacts
            v-if="!isEmptyContentDisplayed"
            is-toolbar-button
            @importContacts="openModal('importFromFile', [])"
            @createContact="openModal('upsertIdentity', [])"
          />
        </v-col>
      </v-row>
    </div>

    <div class="dashboard-documents__wrapper dashboard-contacts">
      <div v-if="isEmptyContentDisplayed" class="dashboard-contacts__empty-content">
        <div class="empty-content__icon">
          <IcoContactLarge />
        </div>
        <div class="empty-content__message">
          <div class="empty-content__message-head">
            <h2>{{ $t("contacts.no_contact") }}</h2>
          </div>
          <div class="empty-content__message-paragraph">
            <p>{{ $t("contacts.info") }}</p>
          </div>
        </div>
        <CreateNewContacts
          @importContacts="openModal('importFromFile', [])"
          @createContact="openModal('upsertIdentity', [])"
        />
      </div>

      <div v-else class="dashboard-documents__table-wrapper">
        <div v-if="dashboardCheckedDocuments.length > 0" class="dashboard-documents__panel dashboard-documents__desktop-panel">
          <div>{{ $tc('table.checkedItems', dashboardCheckedDocuments.length, { count: dashboardCheckedDocuments.length }) }}:</div>
          <div class="dashboard-documents__panel-link">
            <span>
              <IcoSendDocument class="contacts__panel-link-icon" />
              <router-link :to="{ name: 'createNew', params: { workspace_id: filters.workspace_id }, query: { contacts: selectedContactIdsCsv } }">
                {{ $t('contacts.send_many') }}
              </router-link>
            </span>
            <span @click="openModalWithCheckedRows('openGroupsModal')">
              <IcoGroups class="contacts__panel-link-icon" />
              {{ $t('groups.edit') }}
            </span>
            <span @click="openModalWithCheckedRows('deleteContacts')">
              <IcoTrash class="contacts__panel-link-icon" />
              {{ $t('contacts.delete') }}
            </span>
          </div>
        </div>
        <div v-else class="dashboard-documents__filters">
          <div :class="{'dashboard-documents__filters-desktop': !isMobileDevice, 'dashboard-documents__filters-mobile': isMobileDevice}">
            <v-text-field
              type="text"
              :value="filters.searchQuery"
              :placeholder="$t('placeholder.find_name')"
              prepend-inner-icon="mdi-magnify"
              class="filters__search--input"
              hide-details
              clearable
              outlined
              @input="onSearchQueryChange($event)"
            ></v-text-field>
            <MultiSelect
              :placeholder="`${$t('contacts.groups')}:`"
              :options="workspaceData.groups"
              @saveSelected="(keys) => updateFilters('groups', keys)"
            />
          </div>
        </div>

        <div :class="{
          'document-table': true,
          'document-table-disabled': isLoadingTableRows
        }">
          <div class="document-table-header">
            <div class="document-table-item__checkbox">
              <label class="all-checked-label">
                <Checkbox @click="checkOrUncheckAll()" :value="areCheckedAll()" :disabled="!rows.length" />
                <span @click.stop.prevent="checkOrUncheckAll()" class="document-table-item__checkbox-mobile-label">
                  {{ areCheckedAll() ? $t('table.uncheckAll') : $t('table.checkAll') }}
                </span>
              </label>
            </div>
            <div class="document-table-item__name document-table-item__name-header">
              {{ $t('general.client_name') }} / {{ $t("general.company") }}
            </div>
            <div class="document-table-item__state document-table-item__state-header">
              {{ $t('contacts.groups') }}
            </div>
            <div class="document-table-item__created document-table-item__created-header">
              {{ $t('general.email_simple') }}, {{ $t("general.phone") }}
            </div>
            <div class="document-table-item__changed document-table-item__changed-header">
              {{ $t('company.address') }}
            </div>
          </div>
          <div v-if="rows.length">
            <div class="document-table-item" v-for="row in rows" :key="row.id">
              <div v-if="!isMobileDevice" class="document-table-row">
                <div class="document-table-item__checkbox">
                  <Checkbox @click="checkboxClick(row)" :value="isChecked(row)" />
                </div>
                <div
                  class="document-table-item__name document-table-row__name contacts-table-row__name"
                  @click="$router.push({ name: `contactDetail`, params: { workspace_id: $route.params.workspace_id, contact_id: row.id }})"
                >
                  <div class="pr-3">
                    <VerificationBadge :contact="row" />
                  </div>

                  <div class="document-table-row__name-author">
                    <div class="contacts-table-row__author">
                      {{ row.fullname }}
                    </div>
                    <div v-if="row.organization_name" class="contacts-table-row__company">
                      {{ row.organization_name }}
                    </div>
                  </div>
                </div>
                <div class="document-table-item__state document-table-row__state contacts-table-row__groups">
                  <DashboardGroup v-for="group in row.groups" :key="group.id" :title="group.name" />
                </div>
                <div class="document-table-item__created document-table-row__created">
                  {{ row.email }}<br />
                  {{ row.mobile }}
                </div>
                <div class="document-table-item__changed document-table-row__changed">
                  {{ row.organization_street }}<br />
                  {{ row.organization_city }} {{ row.organization_zip }}
                </div>
                <div @click="openedRowId = !openedRowId ? row.contact_id : null" class="document-table-item__actions document-table-row__actions">
                  <div class="document-table-row__actions-dots">
                    ...
                  </div>
                </div>
                <div v-if="openedRowId === row.contact_id" class="document-table-item__actions-item">
                  <div>
                    <IcoSendDocument class="contacts__panel-link-icon" />
                    <router-link class="sidemenu-workplace__a" :to="{ name: 'createNew', params: { workspace_id: filters.workspace_id }, query: { contacts: row.contact_id } }">
                      {{ $t('contacts.send_one') }}
                    </router-link>
                  </div>
                  <div v-if="hasContactFeatureFlag(row, 'isEditable')">
                    <IcoEdit class="contacts__panel-link-icon" />
                    <a href="#" @click.prevent="openModalWithRow('upsertIdentity', row)">
                      {{ $t('contacts.edit') }}
                    </a>
                  </div>
                  <div>
                    <IcoGroups class="contacts__panel-link-icon" />
                    <a href="#" @click.prevent="openModalWithRow('openGroupsModal', row)">
                      {{ $t('groups.edit') }}
                    </a>
                  </div>
                  <div v-if="row.has_aml_verified" class="contacts-table-row__action">
                    <IcoReject class="contacts__panel-link-icon" style="color: #B4C1D6; width: 24px" />
                    <a href="#" @click.prevent="openModalWithRow('cancelVerification', row)">
                      {{ $t('verification.cancel') }}
                    </a>
                  </div>
                  <div v-if="hasContactFeatureFlag(row, 'isEditable')" class="separator-hr"></div>
                  <div v-if="hasContactFeatureFlag(row, 'isEditable')">
                    <IcoTrash class="contacts__panel-link-icon" />
                    <a href="#" @click.prevent="openModalWithRow('deleteContacts', row)">
                      {{ $t('contacts.delete') }}
                    </a>
                  </div>
                </div>
              </div>

              <div v-else class="document-table-row">
                <div class="contacts-mobile-header-row pt-2">
                  <div>
                    <Checkbox @click="checkboxClick(row)" :value="isChecked(row)" />
                  </div>

                  <div class="pr-3">
                    <v-badge v-if="row.has_aml_verified" avatar overlap>
                      <template v-slot:badge>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-avatar v-on="on" slot="activator" color="success">
                              <v-icon>mdi-check</v-icon>
                            </v-avatar>
                          </template>

                          <span>{{ $t('aml.verification') }}</span>
                        </v-tooltip>
                      </template>

                      <SAvatar
                        :firstname="row.firstname"
                        :lastname="row.lastname"
                      />
                    </v-badge>

                    <SAvatar
                      v-else
                      :firstname="row.firstname"
                      :lastname="row.lastname"
                    />
                  </div>

                  <div
                    class="document-table-row__name-author"
                    @click="$router.push({ name: `contactDetail`, params: { workspace_id: $route.params.workspace_id, contact_id: row.id }})"
                  >
                    <div class="contacts-table-row__author">
                      {{ row.fullname }}
                    </div>
                    <div v-if="row.organization_name" class="contacts-table-row__company">
                      {{ row.organization_name }}
                    </div>
                  </div>
                  <div @click="openedRowId = !openedRowId ? row.contact_id : null" class="document-table-item__actions document-table-row__actions">
                    <div class="document-table-row__actions-dots">
                      ...
                    </div>
                  </div>
                  <div v-if="openedRowId === row.contact_id" class="document-table-item__actions-item">
                    <div class="contacts-table-row__action">
                      <IcoSendDocument class="contacts__panel-link-icon" />
                      <router-link class="sidemenu-workplace__a" :to="{ name: 'createNew', params: { workspace_id: filters.workspace_id }, query: { contacts: row.contact_id } }">
                        {{ $t('contacts.send_one') }}
                      </router-link>
                    </div>
                    <div class="contacts-table-row__action" v-if="hasContactFeatureFlag(row, 'isEditable')">
                      <IcoEdit class="contacts__panel-link-icon" />
                      <a href="#" @click.prevent="openModalWithRow('upsertIdentity', row)">
                        {{ $t('contacts.edit') }}
                      </a>
                    </div>
                    <div class="contacts-table-row__action">
                      <IcoGroups class="contacts__panel-link-icon" />
                      <a href="#" @click.prevent="openModalWithRow('openGroupsModal', row)">
                        {{ $t('groups.edit') }}
                      </a>
                    </div>
                    <div v-if="row.has_aml_verified" class="contacts-table-row__action">
                      <IcoReject class="contacts__panel-link-icon" style="color: #B4C1D6; width: 24px" />
                      <a href="#" @click.prevent="openModalWithRow('cancelVerification', row)">
                        {{ $t('verification.cancel') }}
                      </a>
                    </div>
                    <div v-if="hasContactFeatureFlag(row, 'isEditable')" class="separator-hr"></div>
                    <div v-if="hasContactFeatureFlag(row, 'isEditable')" class="contacts-table-row__action">
                      <IcoTrash class="contacts__panel-link-icon" />
                      <a href="#" @click.prevent="openModalWithRow('deleteContacts', row)">
                        {{ $t('contacts.delete') }}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="contacts-mobile-content">
                  <div class="contacts-mobile-content__row">
                    <div class="contacts-mobile-content__row-item">
                      {{ $t("contacts.groups") }}:
                    </div>
                    <div class="contacts-mobile-content__row-item contacts-mobile-content__row-item-content">
                      <DashboardGroup v-for="group in row.groups" :key="group.id" :title="group.name" />
                    </div>
                  </div>
                  <div class="contacts-mobile-content__row">
                    <div class="contacts-mobile-content__row-item">
                      {{ $t("general.email_simple") }}:
                    </div>
                    <div class="contacts-mobile-content__row-item contacts-mobile-content__row-item-content">
                      {{ row.email }}
                    </div>
                  </div>
                  <div class="contacts-mobile-content__row">
                    <div class="contacts-mobile-content__row-item">
                      {{ $t("general.phone") }}:
                    </div>
                    <div class="contacts-mobile-content__row-item contacts-mobile-content__row-item-content">
                      {{ row.mobile }}
                    </div>
                  </div>
                  <div class="contacts-mobile-content__row">
                    <div class="contacts-mobile-content__row-item">
                      {{ $t("company.address") }}:
                    </div>
                    <div class="contacts-mobile-content__row-item contacts-mobile-content__row-item-content">
                      {{ row.organization_street }}<span v-if="row.organization_street">,</span>
                      {{ row.organization_city }}<span v-if="row.organization_city">,</span>
                      {{ row.organization_zip }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="document-table-empty">
            {{ $t('table.noResults') }}
          </div>
        </div>

        <div v-if="dashboardCheckedDocuments.length > 0" class="dashboard-documents__panel dashboard-documents__mobile-panel">
          <div>{{ $tc('table.checkedItems', dashboardCheckedDocuments.length, { count: dashboardCheckedDocuments.length }) }}:</div>
          <div class="dashboard-documents__panel-link">
            <span>
              <IcoSendDocument class="contacts__panel-link-icon" />
              <router-link :to="{ name: 'createNew', params: { workspace_id: filters.workspace_id }, query: { contacts: selectedContactIdsCsv } }">
                {{ $t('contacts.send_many') }}
              </router-link>
            </span>
            <span @click="openModalWithCheckedRows('openGroupsModal')">
              <IcoGroups class="contacts__panel-link-icon" />
              {{ $t('groups.edit') }}
            </span>
            <span @click="openModalWithCheckedRows('deleteContacts')">
              <IcoTrash class="contacts__panel-link-icon" />
              {{ $t('contacts.delete') }}
            </span>
          </div>
        </div>
      </div>

      <Modal v-if="dashboardModal && dashboardModal.contactAction && dashboardModal.contactAction !== 'upsertIdentity' && dashboardModal.contactAction !== 'importFromFile'" :enableClose="true" @close="closeModal">
        <div v-if="dashboardModal.contactAction === 'deleteContacts'" class="dashboard-documents__modal-bulk">
          <div class="dashboard-documents__modal-bulk-heading">
            {{ $tc('table.checkedItems', dashboardModal.contactsSelected.length, { count: dashboardModal.contactsSelected.length }) }}
          </div>
          <Button buttonType="submit" type="secondary" @click="deleteContact()">
            {{ $t('general.delete') }}
          </Button>
        </div>
        <div v-if="dashboardModal.contactAction === 'createGroup'">
          <form @submit.prevent="createNewGroup()">
            <v-text-field
              type="text"
              name="groupName"
              v-model="modalEditor.newName"
              :label="$t('general.title')"
              :placeholder="$t('placeholder.name')"
              :error="hasNewGroupError"
              :messages="[newGroupError]"
              outlined
            ></v-text-field>

            <v-btn
              type="submit"
              color="primary"
              :disabled="hasNewGroupError || modalEditor.newName.length < 2"
              x-large
              block
            >
              {{ $t('contacts.group_create') }}
            </v-btn>
          </form>
        </div>
        <div v-if="dashboardModal.contactAction === 'renameGroup'">
          <form @submit.prevent="renameGroup()">
            <Input v-model="modalEditor.newName" type="text" :placeholder="$t('placeholder.name')" :label="$t('general.title')" />
            <div class="profile-buttons dashboard-save-button">
              <Button buttonType="submit" type="primary" :disabled="modalEditor.newName.length < 2">
                {{ $t('general.rename') }}
              </Button>
            </div>
          </form>
        </div>
        <div v-if="dashboardModal.contactAction === 'deleteGroup'" class="dashboard-documents__modal-bulk">
          <div class="dashboard-documents__modal-bulk-heading" v-html="$t('groups.delete_confirmHtml', { groupName: dashboardModal.contactsSelected[0].name })"></div>
          <Button buttonType="submit" type="secondary" @click="deleteGroup()">
            {{ $t('general.delete') }}
          </Button>
        </div>
        <div v-if="dashboardModal.contactAction === 'cancelVerification'" class="dashboard-documents__modal-bulk">
          <div class="dashboard-documents__modal-bulk-heading text-center">
            {{ $t('verification.cancel.question') }}
          </div>
          <v-btn
            color="error"
            :loading="cancelingVerification"
            x-large
            block
            @click="cancelContactVerification"
          >
            {{ $t('verification.cancel') }}
          </v-btn>
        </div>
      </Modal>
    </div>

    <ImportContacts
      v-if="dashboardModal && dashboardModal.contactAction === 'importFromFile'"
      :groupsIdsCsv="selectedGroupsIdsCsv"
      @reloadContacts="reloadCurrentData"
      @close="closeModal()"
    />

    <GroupsModal
      v-if="dashboardModal && dashboardModal.contactAction && dashboardModal.contactAction === 'openGroupsModal'"
      :contacts="dashboardModal.contactsSelected"
      @reloadAfterChangingGroups="reloadDataAndCloseModal"
      @close="closeModal"
    >
    </GroupsModal>

    <Identity
      v-if="dashboardModal && dashboardModal.contactAction === 'upsertIdentity'"
      class="modal-phone-off"
      :isContactEditor="true"
      :modalConfig="{
        contractId: null,
        editedSignIdentity: dashboardModal.contactsSelected.length ? dashboardModal.contactsSelected[0] : null,
      }"
      @close="closeModal()"
      @upsertIdentity="updateContactFromIdentityModal"
    />

    <LoadMore
      v-if="hasNextPage"
      :isLoadingTableRows="isLoadingTableRows"
      @onLoadMore="onLoadMore"
    />
  </Data>
</template>

<script>
  import { mapGetters } from "vuex";
  import { ContactService } from '@/services/ContactService.js';
  import { cancelVerification } from "@contacts/services/verificationService";
  import { hasFeatureFlag } from '@/common/reusable/featureFlagsChecker';
  import Modal from '@/components/Modal.vue';
  import Identity from '@/views/create/components/Identity.vue';
  import IcoContactLarge from "@/views/dashboard/components/icons/IcoContactLarge";
  import IcoTrash from "@/views/dashboard/components/icons/IcoTrash";
  import IcoEdit from "@/views/dashboard/components/icons/IcoEdit";
  import IcoSendDocument from "@/views/dashboard/components/icons/IcoSendDocument";
  import IcoGroups from "@/views/dashboard/components/icons/IcoGroups";
  import GroupsModal from "@/views/dashboard/components/GroupsModal";
  import ImportContacts from "@/views/dashboard/components/ImportContacts";
  import DashboardGroup from "@/views/dashboard/components/DashboardGroup";
  import CreateNewContacts from "@/views/dashboard/components/CreateNewContacts";
  import LoadMore from "@/views/dashboard/components/LoadMore";
  import SAvatar from '@/common/components/SAvatar';
  import IcoReject from "@/components/svg/IcoRejectGray";
  import VerificationBadge from "@contacts/components/VerificationBadge";

  export default {
    name: 'Contacts',
    components: {
      VerificationBadge,
      IcoReject,
      SAvatar,
      DashboardGroup,
      GroupsModal,
      ImportContacts,
      CreateNewContacts,
      LoadMore,
      IcoGroups,
      IcoSendDocument,
      IcoEdit,
      IcoTrash,
      IcoContactLarge,
      Modal,
      Identity,
    },
    data() {
      return {
        newGroupError: '',
        isLoadingFirstRequest: true,
        isLoadingTableRows: true,
        isEmptyContentDisplayed: false,
        debounceTimeout: false,
        openedRowId: false,
        rows: [],
        workspaceData: {
          groups: [],
        },
        filters: {
          workspace_id: this.$route.params.workspace_id,
          groups: this.getActiveGroupsFromUrl(),
          searchQuery: '',
        },
        page: 0,
        limit: 50,
        hasNextPage: false,
        modalEditor: {
          newName: '',
        },
        cancelingVerification: false,
      }
    },
    computed: {
      ...mapGetters({
        dashboardModal: "dashboardModal",
        dashboardCheckedDocuments: "dashboardCheckedDocuments",
        isMobileDevice: "window/isMobileDevice",
      }),
      hasNewGroupError () {
        return this.newGroupError !== ''
      },
      selectedContactIdsCsv() {
        const ids = this.dashboardCheckedDocuments.map(c => parseInt(c.contact_id));
        ids.sort((a, b) => a - b); // url should be same, if I click first then second, or second then first contact
        return ids.join(',');
      },
      selectedGroupsIdsCsv() {
        return this.selectedGroups.map(g => g.key).join(',');
      },
      selectedGroups() {
        return this.workspaceData.groups.filter((group) => {
          return group.isSelected;
        })
      },
      isSelectedOneGroup() {
        return this.selectedGroups.length === 1;
      },
    },
    async created() {
      this.fetchData();
      this.$store.dispatch('resetDashboard');
    },
    methods: {
      async cancelContactVerification() {
        this.cancelingVerification = true;

        await Promise.all(
          this.dashboardModal.contactsSelected.map(async (contact) => {
            await cancelVerification({
              contactId: contact.id,
              verificationTypes: {
                types: [
                  'bankId',
                  'document',
                  'microTransaction',
                  'payment',
                  'political'
                ]
              },
            })
          })
        );

        this.cancelingVerification = false;
        this.reloadDataAndCloseModal();
      },
      fetchData() {
        this.isLoadingTableRows = true;
        this.isEmptyContentDisplayed = false;
        ContactService.getContacts({
          workspace_id: this.filters.workspace_id,
          groups_ids: this.filters.groups,
          search_query: this.filters.searchQuery,
          pagination: {
            limit: this.limit,
            offset: this.page * this.limit,
          },
          load_metadata: true,
        }).then(
          response => {
            this.loadAvailableGroups(response.meta);
            const newDocuments = response.data;
            if (this.page) {
              this.rows = this.rows.concat(newDocuments);
            } else {
              this.rows = newDocuments;
            }
            this.hasNextPage = newDocuments.length == this.limit;
            this.reloadCheckedDocuments();
            this.isLoadingTableRows = false;
            this.isLoadingFirstRequest = false;
            this.isEmptyContentDisplayed =
              !this.rows.length &&
              !this.filters.searchQuery &&
              (!this.filters.groups.length || this.isSelectedOneGroup);
          },
          () => {
            this.isLoadingTableRows = false;
            this.isLoadingFirstRequest = false;
          }
        );
      },
      loadAvailableGroups(meta) {
        this.workspaceData.groups = (meta.groups || []).map(group => ({
          name: group.name,
          key: group.id,
          isSelected: this.filters.groups.indexOf(group.id) != -1,
        }));
        this.$store.commit('dashboardSetAvailableGroups', this.workspaceData.groups);
      },
      onLoadMore() {
        this.page += 1;
        this.fetchData();
      },
      reloadCurrentData() {
        this.page = 0;
        this.fetchData();
      },
      onSearchQueryChange(value) {
        this.updateFilters('searchQuery', value);
      },
      updateFilters(type, newValue) {
        if (JSON.stringify(this.filters[type]) == JSON.stringify(newValue)) {
          return;
        }
        this.filters[type] = newValue;
        const debounceDuration = 500;
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(
          () => {
            resetPagination(this);
            this.fetchData();
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = false;
            updateQueryParams(this.filters, this.workspaceData, this);
          },
          debounceDuration
        );

        function resetPagination(vue) {
          vue.page = 0;
        }

        function updateQueryParams(filters, workspaceData, vue) {
          setTimeout(() => {
            const query = {};
            if (filters.groups.length) {
              // avoids NavigationDuplicated for string/integer conversion...
              query.groups = filters.groups.length == 1 ? filters.groups[0] : filters.groups.join(',');
            }
            if (JSON.stringify(query) == JSON.stringify(vue.$route.query)) {
              return;
            }
            vue.$router.replace({ query });
          }, 500);
        }
      },
      getActiveGroupsFromUrl() {
        if (!this.$route.query || !this.$route.query.groups) {
          return [];
        }
        return String(this.$route.query.groups).split(',').filter(id => parseInt(id)).map(id => parseInt(id));
      },
      hasContactFeatureFlag(row, flag) {
        return hasFeatureFlag(row, flag);
      },
      areCheckedAll() {
        return this.rows.length && this.dashboardCheckedDocuments.length === this.rows.length;
      },
      checkOrUncheckAll() {
        if (this.areCheckedAll()) {
          this.$store.commit('dashboardDeselectAllDocuments');
          return;
        }
        this.$store.commit('dashboardSelectAllDocuments', this.rows);
      },
      reloadCheckedDocuments() {
        const checkedIds = this.dashboardCheckedDocuments.map(c => c.id);
        const checkedRows = this.rows.filter(c => checkedIds.indexOf(c.id) !== -1);
        this.$store.commit('dashboardSelectAllDocuments', checkedRows);
      },
      checkboxClick(row) {
        this.$store.commit('dashboardCheckDocument', row);
      },
      isChecked(row) {
        return this.dashboardCheckedDocuments.some((c) => (c.id === row.id));
      },
      openModalWithCheckedRows(action) {
        this.openModal(action, this.dashboardCheckedDocuments);
      },
      openModalWithRow(action, row) {
        this.openModal(action, [row]);
      },
      openModal(action, contacts) {
        this.openedRowId = null;
        this.$store.commit('dashboardOpenModal', {
          contactAction: action,
          contactsSelected: contacts.map(row => ({
            custom_inputs: [],
            ...row,
          })),
        });
      },
      updateContactFromIdentityModal({ updatedIdentity, modalConfig }) {
        const editedContact = modalConfig.editedSignIdentity || {};
        const request = {
          workspace_id: this.filters.workspace_id,
          action: 'upsertContacts',
          contacts: [
            {
              id: editedContact ? editedContact.id : null,
              email: updatedIdentity.email,
              mobile: updatedIdentity.mobile,
              firstname: updatedIdentity.firstname,
              lastname: updatedIdentity.lastname,
              birth_date: updatedIdentity.number,
              organization_name: updatedIdentity.organization_name,
              organization_city: updatedIdentity.organization_city,
              organization_street: updatedIdentity.organization_street,
              organization_zip: updatedIdentity.organization_zip,
              organization_ico: updatedIdentity.organization_ico,
              organization_dic: updatedIdentity.organization_dic,
            }
          ],
          groups: this.selectedGroups.map(g => ({ id: g.key })),
        };
        ContactService.manageContacts(request).then(
          () => {
            return this.reloadDataAndCloseModal();
          },
          () => {
            this.$notification.error(this.$t('contract.errors.general_save'));
          }
        );
      },
      createNewGroup() {
        const request = {
          workspace_id: this.filters.workspace_id,
          action: 'upsertGroups',
          groups: [
            {
              name: this.modalEditor.newName,
            }
          ],
        };
        ContactService.manageContacts(request).then(
          () => {
            this.reloadDataAndCloseModal();
          },
          () => {
            this.$notification.error(this.$t('contract.errors.general_save'));
          }
        );
      },
      renameGroup() {
        ContactService.manageContacts(this.createGroupQuery({
          action: "upsertGroups",
          workspace_id: this.$route.params.workspace_id,
        })).then(() => {
          this.reloadDataAndCloseModal();
        }).catch(() => {
          this.$notification.error(this.$t('contract.errors.general_save'));
        });
      },
      deleteGroup() {
        ContactService.manageContacts(this.createGroupQuery({
          action: "deleteGroups",
          workspace_id: this.$route.params.workspace_id,
        })).then(() => {
          this.filters.groups = [];
          this.reloadDataAndCloseModal();
        }).catch(() => {
          this.$notification.error(this.$t('contract.errors.general_save'));
        });
      },
      createGroupQuery(data) {
        return {
          workspace_id: data.workspace_id,
          action: data.action,
          groups: [{
            id: this.selectedGroups[0].key,
            name: this.modalEditor.newName,
          }]
        };
      },
      closeModal() {
        this.modalEditor = {
          newName: '',
        };
        this.$store.commit('dashboardCloseModal');
      },
      deleteContact() {
        ContactService.manageContacts({
          workspace_id: this.filters.workspace_id,
          action: 'deleteContacts',
          contacts: this.dashboardModal.contactsSelected,
        }).then(
          () => {
            this.reloadDataAndCloseModal();
          },
          () => {
            this.$notification.error(this.$t('contract.errors.general_save'));
          }
        );
      },
      reloadDataAndCloseModal() {
        this.reloadCurrentData();
        this.closeModal();
      },
    },
    watch: {
      '$route.query.groups'() {
        const activeGroups = this.getActiveGroupsFromUrl();
        _.each(this.workspaceData.groups, selectOption => {
          selectOption.isSelected = activeGroups.indexOf(selectOption.key) !== -1;
        });
        this.updateFilters('groups', activeGroups);
      },
      dashboardModal() {
        if (!this.dashboardModal || this.dashboardModal.contactAction !== 'renameGroup') {
          this.modalEditor.newName = '';
        } else {
          this.modalEditor.newName = this.dashboardModal.contactsSelected[0].name;
        }
      },
      'modalEditor.newName': {
        handler: function () {
          let groupExist = false
          this.workspaceData.groups.forEach((group) => {
            if (this.modalEditor.newName.toLowerCase() === group.name.toLowerCase()) {
              groupExist = true
            }
          })

          if (groupExist) {
            this.newGroupError = this.$t('groups.create_error.exist')
          } else {
            this.newGroupError = ''
          }
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.dashboard__title {
  max-width: 100%;

  @include md {
    margin: unset !important;
  }
}

// TODO: UI - complicated version copy-pasted in Documents for managing selected group
.dashboard-contacts__title-wrapper {
  display: flex;
  .dashboard-contacts__item {
    flex: 50%;
    a {
      margin: 0 5px;
    }
  }
}

.dashboard-documents__filters-desktop {
  justify-content: unset;

  & > * {
    margin-right: 10px;
  }

  @include lg {
    display: flex;
  }
}

.dashboard-contacts__empty-content {
  text-align: center !important;
  border: 1px solid #e0e3e8;
  border-radius: 6px;
  padding: 130px 33%;

  .empty-content__icon {
    margin-bottom: 20px;
  }

  .empty-content__message {
    h2 {
      text-align: center;
      font-weight: unset;
      color: #5f6369;
    }

    p {
      text-align: center;
      color: #848b99;
    }
  }

  @include md {
    padding: 130px 10%;
  }
}

.dashboard-contacts.dashboard-documents__wrapper {
  .document-table-header, .document-table-row {
    grid-template-columns: 40px 6fr 4fr 5fr 6fr 40px;
    width: 100%;
  }
  .document-table-header > div {
    color: #939ca8;
  }
  .document-table-row > div {
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: smaller;
  }

  .contacts-table-row__name {
    display: flex;
    cursor: pointer;

    &-avatar {
      border-radius: 50%;
      background: #d4e0ef;
      color: white;
      float: left;
      text-align: center;
      padding: 8px;
      margin-right: 10px;
    }
    .contacts-table-row__author {
      color: black;
    }
    .contacts-table-row__company {
      font-size: small;
      color: #a3a3a3;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .contacts-table-row__groups {
    text-align: center;
    display: flex;
    flex-wrap: wrap;

    &-item {
      margin: 1px;
      border: 1px solid #82e2b2;
      border-radius: 6px;
      font-size: small;
      color: #82e2b2;
      padding: 0 5px;
      float: left;
    }
  }

  @include md {
    .document-table-row {
      padding: 15px;
    }

    .checkbox-wrap {
      all: initial;
    }

    .document-table-row__name-author {
      padding-bottom: unset;
      cursor: pointer;

      .contacts-table-row__author {
        color: black;
      }
      .contacts-table-row__company {
        font-size: small;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .document-table-row__actions-dots {
      position: relative;
      top: 15px;
    }
  }

  .contacts-mobile-header-row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;

    & > div {
      align-items: center;
    }
  }

  .contacts-mobile-content {
    padding: 15px 0;
  }

  .contacts-mobile-content__row {
    display: flex;
    flex-wrap: nowrap;
    white-space : normal;

    .contacts-mobile-content__row-item {
      padding: 5px 5px 0 0;
      flex: 22%;

      &-content {
        flex: 78%;
      }
    }
  }
}

div.modal-container {
  max-width: unset !important;
  max-height: unset !important;
}

.contacts__panel-link-icon {
  margin-right: 6px;
}

.document-table-item__actions-item {
  width: 90%;
  max-width: 350px;

  .contacts-table-row__action {
    width: 100%;
    & > svg {
      width: 10%;
    }
    & > a {
      width: 90%;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.contacts__modal-delete {
  @include md {
    min-height: 100px;
    .button {
      margin-top: 30px;
    }
  }
}
</style>
