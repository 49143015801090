
export function parseJSON(text) {
  if (!text || !text.trim()) {
    return null;
  }
  try {
    return JSON.parse(text);
  } catch (e) {
    return {
      code: 500,
      title: `Bad JSON response [${text}]`,
    };
  }
}
