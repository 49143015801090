<template>
  <div class="calendar-wrap" :class="{'calendar-clicked': isShown}">
    <span class="calendar-label">
      {{label}}
    </span>

    <div v-click-outside="closeCalendar" class="calendar-input">
      <date-time-picker
        v-model="date"
        format="d. L. yyyy"
        locale="cs"
        :disabled="disabled"
        ref="datePickerRef"
      ></date-time-picker>
    </div>

    <div class="date-time-picker-table" @click.prevent="closeCalendar">
      <div class="pick-dates">
        <span class="pick-dates__item" @click.prevent="setDateToday()">
          {{ $t('calendar.relativeDays.today') }}
          {{ today }}
        </span>

        <span class="pick-dates__item" @click.prevent="setDateTomorrow()">
          {{ $t('calendar.relativeDays.tomorrow') }}
          {{ tomorrow }}
        </span>

        <span class="pick-dates__item" @click.prevent="setDateTwoDaysLater()">
          {{ $t('calendar.relativeDays.dayAfterTomorrow') }}
          {{ twodayslater }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import DateTimePicker from 'vue-vanilla-datetime-picker'
import ClickOutside from 'vue-click-outside'
import moment from 'moment'

export default {
  name: 'Date',
  props: {
    placeholder: String,
    label: String,
    value: Date,
    disabled: Boolean
  },
  components: {
    DateTimePicker: DateTimePicker
  },
  data () {
    return {
      isMounted: false,
      calendarOpened: false,
      date: null,
      today: null,
      tomorrow: null,
      twodayslater: null,
      todayValue: null,
      tomorrowValue: null,
      twodayslaterValue: null
    }
  },
  created () {
    this.today = moment(new Date()).format('D. M.')
    this.tomorrow = moment(new Date()).add(1, 'day').format('D. M.')
    this.twodayslater = moment(new Date()).add(2, 'day').format('D. M.')

    this.todayValue = moment(new Date())
    this.tomorrowValue = moment(new Date()).add(1, 'day')
    this.twodayslaterValue = moment(new Date()).add(2, 'day')

    if (this.value) {
      this.date = this.value
    } else {
      this.date = new Date()
    }
  },
  mounted () {
    this.isMounted = true
  },
  computed: {
    datePickerRef () {
      if (!this.isMounted) {
        return
      }

      return _.get(this.$refs, 'datePickerRef', null)
    },
    isShown () {
      return this.datePickerRef && _.get(this.datePickerRef.$data, 'isShow', false)
    }
  },
  methods: {
    openCalendar () {
      this.datePickerRef.$data.isShow = true
    },
    closeCalendar () {
      this.datePickerRef.$data.isShow = false
    },
    setDateToday () {
      this.date = this.todayValue.toDate()
    },
    setDateTomorrow () {
      this.date = this.tomorrowValue.toDate()
    },
    setDateTwoDaysLater () {
      this.date = this.twodayslaterValue.toDate()
    }
  },
  directives: {
    ClickOutside
  },
  watch: {
    date: function () {
      this.$emit('returnDate', this.date)
    }
  }
}
</script>

<style lang="scss">
.weekday__cell{
  width: 39px;
  height: 39px;
  float: left;
  font-size: 16px;
  color: #635C73;
  text-align: center;
  text-transform: capitalize;
  font-weight: 300;
  padding: 9px;
}
.date-picker__cell{
  width: 39px;
  height: 39px;
  float: left;
  font-size: 16px;
  color: #293132;
  text-align: center;
  font-weight: 300;
  padding: 9px;
  border-radius: 6px;
  transition: 0.1s;
  cursor:pointer;
  &:hover{
    background: #5E239E;
    transition: 0.1s;
    color:white;
  }
}

.date-picker__row{
  width: 273px;
  margin-left: auto;
  margin-right: auto;
}
.weekday__row{
  width: 273px;
  margin-left: auto;
  margin-right: auto;
}

.datetime-picker__button{

  width: 100%;
  outline: none;
  background: #FFFFFF;
  border: 1px solid #B1BBCB;
  border-radius: 6px;
  height: 54px;
  max-width: 230px;
  padding: 0 15px;
  font-size: 17px;
  font-weight: 300;
  color: black;
  letter-spacing: -0.03px;
  text-align: left;
  cursor:pointer;
  &:active, &:focus{
    border: solid 1px #5E239E;
  }
  &:after{
    content: "";
    background: url(/images/calendar.svg);
    position: absolute;
    width: 24px;
    height: 24px;
    background-size: contain;
    right: 10px;
    background-repeat: no-repeat;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
}

.calendar-clicked {
  .datetime-picker__button {
    border: solid 1px #5E239E;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom: solid 1px #D4DFF0;
    z-index: 10;
    position: relative;
    pointer-events: none;
  }
}

.calendar-input {
  width: 100%;
  position:relative;
}

.calendar-label{
  font-weight: normal;
  font-size: 15px;
  color: black;
  letter-spacing: -0.03px;
  margin-bottom: 5px;
  display: block;
  text-align: left;
}

.time-picker__button{
  display: none;
}

.date-picker{
  margin-top: -1px;
  width: 320px;
  height: 401px;
  text-align: center;
  position: absolute;
  z-index: 1001;
  background: white;
  border-bottom-left-radius: 6px;
  border: solid 1px #5E239E;
  border-right: solid 1px #D4DFF0;
  padding: 22px 25px 35px 25px;
  @include md{
    border-bottom: solid 1px #D4DFF0;
    border-right: solid 1px #5E239E;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 6px;
  }
}

.date-picker__header{
  border-bottom: solid 1px #D4DFF0;
  margin-bottom: 15px;
  padding-bottom: 15px;
  position:relative;
}
.date-picker__day--previous, .date-picker__day--next{
  background: #F9F9F9;
  border-radius: 0px;
  transition: border-radius 0s;
  &:hover{
    border-radius: 6px;
    transition: border-radius 0s;
  }
}

.date-picker__month-header{
  font-size: 16px;
  color: #000000;
  text-align: center;
  text-transform: capitalize;
  background: white;
  border: none;
  padding: 10px 3px;
  cursor:pointer;
}
.date-picker__year-header{
  font-size: 16px;
  color: #000000;
  text-align: center;
  text-transform: capitalize;
  background: white;
  border: none;
  padding: 10px 3px;
  cursor:pointer;
}

.date-picker__prev_month{
  background: white;
  border: none;
  line-height: 17px;
  position: absolute;
  left: 15px;
  top: 10px;
  cursor:pointer;
  &:after{
    content: "";
    background-image: url(/images/arrow.svg);
    background-color: white;
    position: absolute;
    width: 20px;
    height: 17px;
    top: 0px;
    left: 0px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: rotate(180deg);
  }
}

.date-picker__next_month{
  background: white;
  border: none;
  line-height: 17px;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor:pointer;
  &:after{
    content: "";
    background-image: url(/images/arrow.svg);
    background-color: white;
    position: absolute;
    width: 20px;
    height: 17px;
    top: 0px;
    right: 0px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.date-picker__prev_year{
  background: white;
  border: none;
  line-height: 17px;
  position: absolute;
  left: 15px;
  top: 10px;
  cursor:pointer;
  &:after{
    content: "";
    background-image: url(/images/arrow.svg);
    background-color: white;
    position: absolute;
    width: 20px;
    height: 17px;
    top: 0px;
    left: 0px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: rotate(180deg);
  }
}

.date-picker__next_year{
  background: white;
  border: none;
  line-height: 17px;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor:pointer;
  &:after{
    content: "";
    background-image: url(/images/arrow.svg);
    background-color: white;
    position: absolute;
    width: 20px;
    height: 17px;
    top: 0px;
    right: 0px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.date-picker__prev_decade{
  background: white;
  border: none;
  line-height: 17px;
  position: absolute;
  left: 15px;
  top: 10px;
  cursor:pointer;
  &:after{
    content: "";
    background-image: url(/images/arrow.svg);
    background-color: white;
    position: absolute;
    width: 20px;
    height: 17px;
    top: 0px;
    left: 0px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: rotate(180deg);
  }
}

.date-picker__next_decade{
  background: white;
  border: none;
  line-height: 17px;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor:pointer;
  &:after{
    content: "";
    background-image: url(/images/arrow.svg);
    background-color: white;
    position: absolute;
    width: 20px;
    height: 17px;
    top: 0px;
    right: 0px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.calendar-wrap:not(.calendar-clicked) {
  .date-time-picker-table {
    display:none;
  }
}
.calendar-clicked {
  position:relative;

  .date-time-picker-table{
    display:block;
    width: 195px;
    background: white;
    height: 401px;
    position: absolute;
    left: 320px;
    z-index: 10;
    border: solid 1px #5E239E;
    border-left: 0px;
    margin-top: -1px;
    margin-left: 0px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    @include md{
      height: 140px;
      width: 320px;
      left: 0px;
      margin-top: 401px;
      border-top: none;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 6px;
      border-left: 1px solid #5E239E;
    }
  }
}
.pick-dates{
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.pick-dates__item{
  font-size: 15px;
  color: #812383;
  text-decoration: underline;
  font-weight: normal;
  width: 100%;
  display: block;
  padding: 3px 0px;
  margin: 5px 0px;
  cursor:pointer;
  &:hover{
    color:#5E239E;
  }
}
.calendar-wrap{
  position:relative;
}

.date-picker__year-button{
  background: transparent;
  border: none;
  outline: none;
  font-size: 15px;
  color: #812383;
  text-decoration: underline;
  font-weight: normal;
  width: 100%;
  display: block;
  padding: 3px 0px;

  cursor: pointer;
  &:hover{
    color:#5E239E;
  }
}
.date-picker__month-button{
  background: transparent;
  border: none;
  outline: none;
  font-size: 15px;
  color: #812383;
  text-decoration: underline;
  font-weight: normal;
  width: 100%;
  display: block;
  padding: 3px 0px;

  cursor: pointer;
  &:hover{
    color:#5E239E;
  }
}

.calendar--left .date-time-picker-table {
  border: solid 1px #5E239E;
  border-right: 0;
  left: -195px;
  border-radius: 6px 0 0 6px;
}

.calendar--left .date-picker {
  border: solid 1px #5E239E;
  border-left: solid 1px #D4DFF0;
  border-radius: 0 6px 6px 0;
}
</style>
