<template>
  <div class="note">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Note',
}
</script>

<style lang="scss">
.note{
  background: #FFFAD1;
  box-shadow: 0 12px 24px 0 rgba(0,0,0,0.05);
  border-radius: 6px;
  padding:20px;
  position:relative;
  margin: 0 30px;
  z-index: 3;

  p{
    color: #614909;
    line-height: 22px;
    margin-bottom: 0;
    font-weight: 300;
  }

  &:before{
    content: "";
    background: url(/images/note-arrow.svg);
    width: 65px;
    height: 35px;
    background-size: contain;
    position: absolute;
    left: -80px;
    top: 50%;
    background-repeat: no-repeat;
  }
}

.content-box__note-first{
  top: 130px;
}

.sidemenu__note-workspace.note{
  margin-top: 15px;

  &:after{
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: #fefad1;
    top: 3px;
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
    z-index: -1;
  }

  &:before{
    content: "";
    background: url(/images/note-arrow2.svg);
    width: 35px;
    height: 65px;
    background-size: contain;
    position: absolute;
    top: 69%;
    background-repeat: no-repeat;
    right: -25px;
    left: unset;
    transform: rotate(0deg);
  }
}
</style>
