<template>
  <v-layout align-center justify-center fill-height ma-0>
    <v-layout column align-center justify-center px-2>
      <div
        v-if="isApproved"
        class="pending-state--text d-block text-truncate text-center font-weight-medium"
      >
        {{ $t('docs.pending.generic') }}
      </div>

      <v-btn
        v-else
        v-bind="size"
        :color="buttonColor"
        :loading="isSubmitted"
        class="d-inline-block sign-button px-2 px-md-3"
        max-width="12rem"
        width="100%"
        dark
        @click="onSignRequest"
      >
        <div v-if="isOneDeviceSigning" style="width: 100%">
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12" class="d-block text-truncate">
              {{ $t('contract.sign') }}
            </v-col>
            <v-col cols="12" class="text-center font-weight-light text-caption">
              <span
                v-html="signIdentityTitle"
                class="d-block text-center font-weight-light text-caption text-truncate"
              ></span>
            </v-col>
          </v-row>
        </div>

        <span v-else class="text-truncate">{{ $t('contract.sign') }}</span>
      </v-btn>
    </v-layout>

    <SignWithCertificate
      v-model="signWithCertificate"
      :contract-id="contract.contract_id || contract.id"
      :active-contract-id="activeContractId || contract.id"
      :contract-title="contract.title"
      :sign-identity-id="signIdentity.id"
    />
  </v-layout>
</template>

<script>
import { i18n } from '@/plugins/i18n';
import { mapActions, mapGetters } from 'vuex';
import { ContractService } from '@/services/ContractService';
import { getErrorResponseMessage } from '@/common/reusable/errorResponse';
import { signWithBankIdSign } from '@contract/services/signContractService';
import { WorkflowHelper } from '@/helpers/WorkflowHelper';
import SignWithCertificate from '@signing/components/SignWithCertificate';
import environment from '@/config/environment';

export default {
  name: 'SignButton',
  components: { SignWithCertificate },
  props: {
    identifier: {
      type: Number,
      required: true,
    },
    signIdentity: {
      type: Object,
      required: true,
    },
    sign: {
      type: Function,
      default: undefined,
    },
    isOneDeviceSigning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      approved: false,
      signWithCertificate: false,
      submitted: false,
    };
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      canSignDocument: 'signing/isAllSignIdentitySignaturesConfirmed',
      unfinishedSignatures: 'signing/unfinishedSignatures',
      activeContractId: 'publicContract/activeContractId',
    }),
    isApproved() {
      return this.approved;
    },
    size() {
      const sizes = {
        xs: this.isOneDeviceSigning ? '' : 'x-small',
        sm: 'large',
        md: 'x-large',
        lg: 'x-large',
        xl: 'x-large',
      }[this.$vuetify.breakpoint.name];

      return sizes ? { [sizes]: true } : {};
    },
    buttonColor() {
      return this.contract?.settings?.primary_color || 'primary';
    },
    isSigningWithCertificate() {
      return this.contract?.has_sign_with_certificate || false;
    },
    signIdentityTitle() {
      let signIdentityFullName = `<span>${this.signIdentity.firstname} ${this.signIdentity.lastname}</span>`;

      return this.signIdentity.organization_name
        ? (signIdentityFullName += `, <span>${this.signIdentity.organization_name}</span>`)
        : signIdentityFullName;
    },
    isSubmitted() {
      return this.submitted;
    },
  },
  methods: {
    ...mapActions({
      approveSign: 'signing/approveSign',
    }),
    onSignRequest() {
      this.approveSign(this.identifier).then(() => {
        if (this.isSigningWithCertificate) {
          this.signWithCertificate = true;
        } else if (this.canSignDocument(this.signIdentity.id)) {
          this.onSignProcess();
        } else {
          this.approved = true;

          const unfinishedSignatures = this.unfinishedSignatures(this.signIdentity.id);

          if (unfinishedSignatures?.length > 0) {
            this.$notification.success(
              `${this.$t('signing.number_of_waiting_signatures')}: ${unfinishedSignatures?.length}`,
            );
          }

          this.$nextTick(() => {
            const target = document.querySelectorAll('.page-signature button')[0];

            if (target) {
              this.$vuetify.goTo(target, {
                duration: 800,
                offset: 500,
                easing: 'easeInOutCubic',
              });
            }
          });
        }
      });
    },
    onSignProcess() {
      if ('sign_bank_id_sign' === this.signIdentity.contract_role) {
        this.submitted = true;

        const url = window.location.href;
        const payload = {
          error_url: `${url}${url.includes('?') ? '&' : '?'}bankIdResult=failure`,
          redirect_url: `${url}${url.includes('?') ? '&' : '?'}bankIdResult=success`,
          locale: i18n.locale,
        };

        if (this.$route.params?.hash) {
          payload.redirect_url = this.contract?.attachments?.length
            ? `${url}${url.includes('?') ? '&' : '?'}bankIdResult=success`
            : `${environment.getAppUrl()}${
                this.$router.resolve({
                  name: 'contractConfirm',
                  params: {
                    email: this.signIdentity?.email,
                    hash: this.$route.params.hash,
                  },
                }).href
              }`;
        }

        return (async () => {
          await signWithBankIdSign({
            authToken: this.$route.params?.hash || null,
            contractId: this.$store.state?.currentContractId || this.contract?.id,
            payload,
          }).catch((err) => {
            this.submitted = false;
            this.$notification.error(getErrorResponseMessage(err));
          });
        })();
      }

      if (typeof this.sign !== undefined) {
        this.sign();
      } else {
        WorkflowHelper.clickOnSignLink({
          contract: this.contract,
          markApproved: () => this.markApproved(),
          fastsign: (preloadedSignature) => {
            ContractService.sendSignatureAuthorized(
              {
                signature_date: preloadedSignature.date,
                signature_place: preloadedSignature.place,
              },
              preloadedSignature.image,
              this.contract.id,
              this.contract.current_sign_identity.id,
              [],
            )
              .then((resp) => {
                if (
                  (400 === resp.code && 'Smlouva je již uzavřená' === resp.title) ||
                  resp.code >= 400
                ) {
                  return this.$notification.error(`${this.$t('general.error')} - ${resp.title}`);
                }

                if ('completed' === resp.state) {
                  window.dataLayer.push({
                    event: 'contract_proposal_sign_completed',
                  });
                }

                this.processAcceptedOrFastSignedContract(
                  resp,
                  WorkflowHelper.getNextUnsignedAfterApprove(resp),
                );
              })
              .catch(() => {
                this.$notification.error(this.$t('contract.errors.sign'));
              });
          },
          routes: {
            params: {
              workspace_id: this.$route.params.workspace_id,
              contract_id: this.$route.params.contract_id,
            },
          },
        });
      }
    },
    markApproved() {
      ContractService.markApprovedAsRegistered(this.contract.id, this.signIdentity.id)
        .then((resp) => {
          this.processAcceptedOrFastSignedContract(
            resp,
            WorkflowHelper.getNextUnsignedAfterApprove(resp),
          );
          this.loader = false;
          this.loading = false;
        })
        .catch(() => {
          this.$notification.error(this.$t('contract.errors.accept'));
          this.loader = false;
          this.loading = false;
        });
    },
    processAcceptedOrFastSignedContract(response, unApprovedContract) {
      const contract = response;

      if (unApprovedContract) {
        this.$router.push({
          name: 'documentsDetail',
          params: {
            workspace_id: this.$route.params.workspace_id,
            contract_id: unApprovedContract.id,
          },
        });
        location.reload();
      } else {
        if (this.contract.one_device && contract.state !== 'completed') {
          this.$router.push({
            name: 'documentsDetailCounterparty',
            params: {
              workspace_id: this.$route.params.workspace_id,
              contract_id: this.contract.main_contract_id || this.contract.id,
            },
            query: {
              viewContract: true,
              complete: true,
            },
          });
        } else {
          this.$store.commit('setContract', contract);
          this.$router.push({
            name: 'createCompleted',
            params: {
              workspace_id: `${this.$route.params.workspace_id}`,
              contract_id: `${this.contract.id}`,
            },
            query: { viewContract: 'true' },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
button.sign-button::v-deep {
  z-index: 99999;
}

.pending-state--text {
  font-size: clamp(0.2rem, 1.8vw, 1rem);
  width: 100%;
}

.download-button::v-deep a {
  color: inherit !important;
  font-weight: inherit;

  &:hover {
    color: var(--v-primary-base) !important;
  }
}
</style>
