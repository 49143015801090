<template>
  <svg width="80px" height="80px" viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ico/ico-80-podepsano</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico/ico-80-podepsano" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-2" fill-rule="nonzero" stroke="#6DECAF" stroke-width="2">
        <g id="177259240">
          <path d="M37.8021911,27.1673469 L31.8080741,33.3248553 L4.17029596,46.4946584 C5.0009291,52.7652683 5.0009291,58.7674433 4.17029596,64.5011833 C3.33966281,70.2349234 1.94956416,75.1472274 0,79.2380952 C5.52212498,76.9883898 11.3983351,75.5347451 17.6286305,74.8771612 C23.8589258,74.2195773 29.0883792,74.463336 33.3169908,75.6084372 L47.0098634,46.4946584 L52.0707483,42.0467233" id="Path-27"></path>
          <path d="M0,79.2380952 L15.847619,63.3904762" id="Path-28"></path>
          <circle id="Oval-10" cx="22.6394558" cy="56.5986395" r="5.79183673"></circle>
          <path d="M32.7357166,33.7762448 C31.0607081,30.1710068 31.4080769,27.0120492 33.7778229,24.2993718 C37.3324419,20.2303558 45.7554977,24.1532574 52.1478102,30.5308812 C58.5401228,36.9085051 60.4873884,45.0919949 57.4767379,48.4255683 C55.4696376,50.6479506 52.3229496,50.1885019 48.0366737,47.0472223" id="Path-29"></path>
          <path d="M40.7510204,17.2478851 C45.8777709,14.3163377 51.491234,15.9878282 57.59141,22.2623567 C63.6915859,28.5368852 64.9790715,33.9451245 61.4538669,38.4870748" id="Path-30"></path>
          <path d="M47.5428571,15.847619 L63.3904762,0" id="Path-38"></path>
          <path d="M65.6544218,29.4312925 L79.2380952,15.847619" id="Path-40"></path>
        </g>
        <g id="ico/ico-smlouva-potvrzena" transform="translate(58.000000, 58.000000)">
          <g>
            <circle id="Oval" cx="11" cy="11" r="10"></circle>
            <polyline id="Path-21" points="6.89409153 11.200782 9.79140387 13.9825555 15.7072927 8.06666667"></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IcoSigned"
}
</script>

<style lang="scss" scoped></style>
