export const rgbToHex = ({ red, green, blue }) => {
  return (
    '#' +
    [red, green, blue]
      .map((x) => {
        const hex = x.toString(16);
        return 1 === hex.length ? '0' + hex : hex;
      })
      .join('')
  );
};

export const hexToRgbaStyle = (hex, opacity) => {
  let color;

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    color = hex.substring(1).split('');

    if (3 === color.length) {
      color = [color[0], color[0], color[1], color[1], color[2], color[2]];
    }

    color = '0x' + color.join('');

    return (
      'rgba(' + [(color >> 16) & 255, (color >> 8) & 255, color & 255].join(',') + `, ${opacity})`
    );
  }
};

export const randomDarkColor = () => {
  return rgbToHex({
    red: Math.floor(Math.random() * 125 + 125),
    green: Math.floor(Math.random() * 125 + 125),
    blue: Math.floor(Math.random() * 125 + 125),
  });
};
