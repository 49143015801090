export default () => ({
  isLoading: false,
  error: null,

  activeContract: {},
  contract: {},
  contractCurrentSignIdentity: null,
  contractSignIdentities: [],
  history: [],
});
