<template>
  <v-row class="mt-5 mb-10" align="center" justify="center" no-gutters>
    <v-expansion-panels v-if="canShowSubjects">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="mb-0">
            {{ $t('workspace.settings.subjects') }}
          </h3>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-expansion-panels>
            <v-row>
              <SubjectsCustomizingItem
                v-for="subject in availableSubjects"
                :key="subject.key"
                :subject="subject"
                :locale="locale"
                @reload-subjects="reloadSubjects"
              />
            </v-row>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import { i18n } from '@/plugins/i18n';
import { manageSubjects } from '@workspace/services/workspaceService';
import SubjectsCustomizingItem from '@workspace/components/SubjectsCustomizingItem';
import featureFlagsChecker from '@/common/reusable/featureFlagsChecker';

export default {
  name: 'SubjectsCustomizing',
  components: {
    SubjectsCustomizingItem,
  },
  data() {
    return {
      loaded: false,
      subjects: [],
    };
  },
  props: {
    workspace: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      default: i18n.locale,
    },
  },
  computed: {
    canShowSubjects() {
      return this.loaded && this.subjects.length > 0;
    },
    hasEnabledAmlAuthentication() {
      return featureFlagsChecker.hasEnabledAmlAuthentication(this.workspace);
    },
    availableSubjects() {
      if (this.hasEnabledAmlAuthentication) {
        return this.subjects;
      }

      return this.subjects.filter((subject) => {
        return !subject.key.startsWith('aml_');
      });
    },
  },
  watch: {
    locale: {
      handler: function () {
        this.reloadSubjects();
      },
    },
  },
  created() {
    manageSubjects(this.$route.params.workspace_id, this.locale)
      .then((resp) => {
        this.subjects = resp.mail_subjects;
      })
      .catch(() => {
        this.subjects = [];
      })
      .finally(() => {
        this.loaded = true;
      });
  },
  methods: {
    reloadSubjects() {
      manageSubjects(this.$route.params.workspace_id, this.locale)
        .then((resp) => {
          this.subjects = resp.mail_subjects;
        })
        .catch(() => {
          this.$notification.error(this.$t('general.error'));
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
