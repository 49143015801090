<template>
  <v-avatar :size="size" :style="`background: ${backgroundColor}`">
    <img v-if="hasProfileImage" :src="profileImage" alt="" />
    <span v-else-if="hasInitials" class="avatar-initials">
      {{ initials }}
    </span>
    <v-img v-else :src="defaultAvatar" contain />
  </v-avatar>
</template>

<script>
export default {
  name: 'SAvatar',
  props: {
    size: {
      type: Number,
      default: 50,
    },
    firstname: {
      type: String,
      default: '',
    },
    lastname: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: '',
    },
    profileImage: {
      type: String,
      default: null,
    },
    backgroundColor: {
      type: String,
      default: '#d4e0ef',
    },
  },
  computed: {
    defaultAvatar() {
      return require('@/assets/defaultAvatar.svg');
    },
    initials() {
      if (!this.firstname && !this.lastname) {
        return this.position ? this.getInitials([this.position]) : false;
      }

      return this.getInitials([this.firstname, this.lastname]);
    },
    hasInitials() {
      return !!this.initials;
    },
    hasProfileImage() {
      return this.profileImage !== null;
    },
  },
  methods: {
    getInitials(words) {
      return `${words?.map((word) => word.charAt(0).toUpperCase()).join('')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  &-initials {
    border-radius: 50%;
    color: white;
    float: left;
    text-align: center;
    padding: 10px;
    font-weight: bold;
  }
}
</style>
