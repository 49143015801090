<template lang="html">
  <div class="load-more">
    <Button
      :disabled="isLoadingTableRows"
      :loading="isLoadingTableRows"
      type="secondary"
      @click="$emit('onLoadMore')"
    >
      {{ $t('general.load_more') }}...
    </Button>
  </div>
</template>

<script>
export default {
  name: 'LoadMore',
  props: ['isLoadingTableRows'],
}
</script>

<style lang="scss" scoped>
.load-more {
  text-align: center;
  margin-top: 20px;

  .button {
    height: 50px;
    max-width: 200px;
  }
}
</style>
