<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
	<div
		class="input-search"
		:class="{ 'empty': isEmpty, 'loading': loading, input: isWorkspaceInput, 'focus': focus }"
	>
		<Input
			:class="{'invalid': !validation, 'disabled': disabled}"
			:placeholder="placeholder" search type="text" :label="label" title="Zadejte příjmení"
			:validation="validation"
			:name="query"
			ref="searchInput"
			@input="onChange"
			@onSearch="onSearch"
			@onFocus="onSetFocus"
			@onBlur="onSetBlur"
			@keyDownDown="onKeyUpDown"
			@keyDownUp="onKeyUpUp"
			@keyUpEnter="onEnter"
		>
			<template v-slot:inputIcons>
				<div class="input-search__icons">
					<IcoClose
						class="input-search__ico-reset"
						:class="{ 'input-search__ico-reset--hidden': isEmpty}"
						@click.native.prevent.stop="$emit('onResetQuery')"
					/>
					<Loader class="input-search__loader"></Loader>
				</div>
			</template>
		</Input>
		<transition name="fade">
			<div class="search-input-results" v-show="!loading && hasResults && canShowResults">
				<div class="search-input-results-box" ref="resultsBox" v-show="hasResults">
					<transition-group name="fade" tag="div">
						<div
              v-for="(result, index) in results"
              :key="index"
              ref="result"
              class="searched-users"
              :style="{animationDelay: `${(index * 50)}ms`}"
              :class="{'selected': index === selectedIndex}"
						>
							<div @click="$emit('onSelectResult', result)">
								<a href="#">
									<slot name="searchResult" v-bind="result"></slot>
								</a>
							</div>
						</div>
					</transition-group>
				</div>
			</div>
		</transition>
		<transition name="fade">
			<div v-show="isNotFoundDialogOpen" class="search-input__dialog search-input__dialog--no-result">
				<slot name="notFoundDialog" :query="query" :closeDialog="closeNotFoundDialog"></slot>
			</div>
		</transition>
		<transition name="fade">
			<div v-show="isShowSuccessDialogOpen" class="search-input__dialog search-input__dialog--success">
				<slot name="successDialog" :results="results" :closeDialog="closeSuccessDialog"></slot>
			</div>
		</transition>
	</div>
</template>

<script>
	import _ from 'lodash';
	import { required, minLength } from 'vuelidate/lib/validators';
	import IcoClose from '@/components/svg/IcoClose.vue';
	import Loader from '@/components/Loader.vue';

	export default {
		name: "Search",
		components: {
			IcoClose,
			Loader,
		},
		props: {
			validation: {
				type: Boolean,
				default: true,
				required: true,
			},
			query: {
				type: String,
				required: true,
			},
			results: {
				type: Array,
				required: true,
			},
			isWorkspaceInput: {
				type: Boolean,
				default: true,
				required: false,
			},
			placeholder: {
				type: String,
				required: false,
				default: '',
			},
			label: String,
			disabled: Boolean,
			canShowResults: Boolean,
			loading: Boolean,
			minLength: Number,
		},
		data() {
			return {
				focus: false,
				blur: true,
				blurDisabled: false,
				selectedIndex: 0,
				allowOpenNotFoundDialog: false,
				allowOpenSuccessDialog: false,
				resultsBoxScrollTop: 0,
				isScrollingDown: false,
				isScrollingUp: false,
			}
		},
		validations: {
			query: {
				required,
				minLength: minLength(2),
			},
		},
		computed: {
			isEmpty() {
				return this.query === '';
			},
			hasResults() {
				return this.results && this.results.length > 0;
			},
			searchInput() {
				return _.get(this.$refs, 'searchInput', {});
			},
			hasFocus() {
				return this.focus || !this.blur;
			},
			lastResultIndex() {
				return this.results.length - 1;
			},
			selectedResult() {
				return this.results[this.selectedIndex];
			},
			isNotFoundDialogOpen() {
				return !this.isEmpty && !this.loading && this.hasFocus && !this.hasResults && this.allowOpenNotFoundDialog;
			},
			isShowSuccessDialogOpen() {
				return !this.isEmpty && this.hasResults && this.allowOpenSuccessDialog;
			},
			selectedItemRef() {
				return _.chain(
					_.get(this.$refs, 'result', []))
					.filter((r) => {
						return r.classList.contains('selected');
					})
					.head()
					.value();
			},
		},
		methods: {
			onChange(query) {
				this.$emit('onChange', query);
				this.onChangeDebounced(query);
			},
			onChangeDebounced: _.debounce(function (query) {
				if (query !== '' && query.length >= this.minLength) {
					this.$emit('onChangeDebounced', query);
					this.init();
				}
			}, 1000),
			onSetFocus() {
				this.focus = true;
				this.blur = false;
				this.$emit('onFocusChange', true);
			},
			onSetBlur() {
				this.$emit('onFocusChange', false);
				this.onSetBlurDebounced();
			},
			onSetBlurDebounced: _.debounce(function () {
				if (this.loading || this.blur || !this.focus) return;
				this.blur = true;
			}, 350),
			focusInput() {
				this.searchInput.focusInput();
				this.focus = true;
				this.blur = false;
			},
			onSearch() {
				this.$emit('onSearch', this.query);
				this.init();
			},
			selectResult() {
				this.$emit('onSelectResult', this.selectedResult);
				this.allowOpenSuccessDialog = true;
			},
			onEnter() {
				if (this.hasResults && this.selectedResult) {
					this.selectResult();
				}
			},
			closeNotFoundDialog() {
				this.allowOpenNotFoundDialog = false;
			},
			closeSuccessDialog() {
				this.allowOpenSuccessDialog = false;
			},
			init() {
				this.allowOpenNotFoundDialog = true;
				this.allowOpenSuccessDialog = false;
				this.selectedIndex = 0;
			},
			onKeyUpDown() {
				this.selectedIndex++;
				this.scrollDown();
			},
			onKeyUpUp() {
				this.selectedIndex--;
				this.scrollUp();
			},
			scrollDown() {
				this.isScrollingDown = true;
				this.isScrollingUp = false;
			},
			scrollUp() {
				this.isScrollingUp = true;
				this.isScrollingDown = false;
			},
		},
		watch: {
			selectedIndex() {
				let skipIndexes = 5;

				skipIndexes = (skipIndexes * 2 + 1 ) >= this.lastResultIndex ? 0 : skipIndexes;
				if (this.selectedIndex <= 0) this.selectedIndex = 0;
				if (this.selectedIndex > this.lastResultIndex) this.selectedIndex = this.lastResultIndex;


				if (this.selectedIndex <= skipIndexes && this.isScrollingDown) return;
				// if (this.lastResultIndex === this.selectedIndex) return;
				if (this.selectedIndex >= this.lastResultIndex - skipIndexes && this.isScrollingUp) return;


				const scrollJumpHeight = this.selectedIndex * ( this.selectedItemRef.offsetHeight );

				let scrollTop = scrollJumpHeight - (skipIndexes * this.selectedItemRef.offsetHeight);
				this.$refs.resultsBox.scroll(0, scrollTop);
			},
		}
	}
</script>

<style lang="scss">
	@import "~@/assets/sass/_animate.scss";

	.disabled {
		.input-search {
			pointer-events: none;
		}
	}

	.search__user.loading {
		.icon-search {
			opacity: 0;
		}

		.loader-wrap.input-search__loader {
			opacity: 1;
		}
	}

	.input-search {
		position: relative;
		margin-left: 15px;
		@include md {
			margin-left: 0;
		}

		.input-search__ico-reset {
			position: absolute;
			cursor: pointer;
			pointer-events: all;
			opacity: 0;
			left: 10px;
			height: auto;
			transform: scale(0.8);
			top: 0;

			-webkit-transition: all 420ms ease-in-out;
			-moz-transition: all 420ms ease-in-out;
			-o-transition: all 420ms ease-in-out;
			transition: all 420ms ease-in-out;

			&.input-search__ico-reset--hidden {
				opacity: 0;
				pointer-events: none;
			}
		}

		.input-search__icons {
			top: 3px;
			position: relative;
			width: 100%;
			height: 100%;
		}

		.input__data:not(.input__data--empty):focus {
			padding-left: 35px;

			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			transition-delay: 1420ms;
			transition: all 420ms ease-in-out;

			~ .input__icons {
				.input-search__icons {
					.input-search__ico-reset {
						opacity: 1;
					}
				}
			}
			/*padding-left: 35px;*/
		}

		input.input__data {
			-webkit-transition: padding-left 420ms ease-in-out;
			-moz-transition: padding-left 420ms ease-in-out;
			-o-transition: padding-left 420ms ease-in-out;
			transition: padding-left 420ms ease-in-out;

			padding-left: 20px;

			&:not(.input__data--empty) {
				/*padding-left: 20px;*/
			}

			:focus {
				~ .input-search__icons {
					opacity: 1;
				}

			}
		}

		.icon-search {
			cursor: pointer;
			opacity: 1;

			-webkit-transition: opacity 420ms ease-in-out;
			-moz-transition: opacity 420ms ease-in-out;
			-o-transition: opacity 420ms ease-in-out;
			transition: opacity 420ms ease-in-out;
		}

		&.loading {
			.icon-search {
				opacity: 0;
			}

			.loader-wrap.input-search__loader {
				opacity: 1;
			}
		}

		.loader-wrap.input-search__loader {
			-webkit-transform: initial;
			transform: initial;
			left: initial;
			top: 0;
			right: 0;
			height: 100%;
			width: 64px;
			justify-content: center;
			flex-direction: row;
			align-items: center;
			display: flex;
			opacity: 0;
			-webkit-transition: opacity 420ms ease-in-out;
			-moz-transition: opacity 420ms ease-in-out;
			-o-transition: opacity 420ms ease-in-out;
			transition: opacity 420ms ease-in-out;

			.loader {
				left: initial;
				height: 35px;
				width: 35px;
				padding: 0;
				margin: 0;
			}
		}

		.search-input-results {
			position: relative;
			top: -6;
			z-index: 1;
			width: 100%;
			padding: 0;

			a {
				font-weight: normal;
				font-size: 14px;
				text-decoration: none;

				.contact-preview__img {
					float: left;
					margin-right: 10px;
					margin-top: 5px;
				}
			}
			p {
				line-height: 20px;
				margin-bottom: 15px;
			}
			.searched-email {
				text-align: left;
				font-weight: 300;
				padding-left: 40px;
			}
		}

		&:not(.focus) {
			.search-input-results-box {
				display: none;
				opacity: 0;
			}
		}

		.search-input-results-box {
			border: 1px solid #5E239E;
			border-top: none;
			background: #fff;
			padding: 0;
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;

			max-height: 65vh;
			overflow: hidden;
			overflow-y: scroll;
			scroll-behavior: auto;

			.searched-users {
				border-bottom: 1px solid #D4DFF0;
				padding: 12px 25px 10px 25px;
				margin: 0;
				//@include animation;

				&:hover {
					background-color: #F2F6FC;
				}

				&.selected {
					background-color: #ebf1f9;
				}

				&:last-child {
					border-bottom: none;
					border-bottom-left-radius: 6px;
					border-bottom-right-radius: 6px;
				}

				&:first-child {
					border-top: 1px solid #D4DFF0;
				}
			}
		}

		.search-input__dialog {
			padding: 20px 20px 20px 20px;
			border: 1px solid #5E239E;
			border-top: none;
			background: #fff;
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;

			p {
				margin-bottom: 0 !important;
				padding-bottom: 0;
			}

			&.closed {
				display: none;
				opacity: 0;
			}
			&.search-input__dialog--no-results {
			}
			&.search-input__dialog--success {
			}
		}
	}
</style>
