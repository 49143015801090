import { submitSms } from '@verification/services/authService';

export const namespaced = true;

export const state = {
  smsVerification: undefined,
};

export const mutations = {
  setSms(state, payload) {
    state.smsVerification = payload;
  },
};

export const actions = {
  setSms: (context, payload) => {
    context.commit('setSms', payload);
  },
  submitSms: (context, payload) => {
    return submitSms(payload.authToken, payload.data).then(
      (resp) => {
        context.commit('setSms', resp);
      },
      (err) => {
        throw err;
      },
    );
  },
};

export const getters = {
  sms: (state) => {
    return state.smsVerification;
  },
  isSmsDone: (state) => {
    return 'submitted' === state.smsVerification?.status || 'ok' === state.smsVerification?.status;
  },
  isWithoutSmsVerification: (state) => {
    return 'undefined' === typeof state.smsVerification;
  },
};
