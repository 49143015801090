<template lang="html">
  <Data :loader="isLoadingFirstRequest" :isDashboard="true">
    <div class="dashboard-documents__wrapper dashboard-reports">
      <h1 class="dashboard-documents__heading">{{ $t('reports.credits') }}</h1>
      <div class="dashboard-documents__table-wrapper">
        <div class="dashboard-documents__filters">
          <div class="dashboard-documents__filters-desktop reports-filters">
            <v-text-field
              type="text"
              :value="filters.searchQuery"
              :placeholder="$t('placeholder.fulltext')"
              prepend-inner-icon="mdi-magnify"
              class="filters__search--input"
              hide-details
              clearable
              outlined
              @input="onSearchQueryChange($event)"
            ></v-text-field>

            <div class="dashboard-datepicker" :class="{ 'dashboard-datepicker-open': isDatepickerOpened }">
              <div @click.prevent.stop="clearCreatedAt" v-if="datepickerCreatedModel.startDate" class="dashboard-datepicker-clear-icon"></div>
              <div v-else class="dashboard-datepicker-icon"></div>
              <date-range-picker
                ref="picker"
                :opens="'left'"
                v-model="datepickerCreatedModel"
                :locale-data="datepickerLocale"
                :singleDatePicker="false"
                :timePicker="false"
                :timePicker24Hour="false"
                :showWeekNumbers="false"
                :showDropdowns="false"
                :auto-apply="true"
                :ranges="false"
                @toggle="toggleCreatedDatepicker"
                @select="updateCreatedAt"
              >
                <template v-slot:input="picker" style="min-width: 350px;">
                  <template v-if="picker && picker.startDate">
                    <span v-line-clamp="1">{{ $t('general.created_at') }}: {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}</span>
                  </template>
                  <template v-else>
                    <span>{{ $t('general.created_at') }}:</span>
                  </template>
                </template>
              </date-range-picker>
            </div>

            <v-btn
              color="primary"
              :loading="isBuildingCsv"
              :disabled="isBuildingCsv"
              class="ml-auto"
              x-large
              @click="downloadCsv"
            >
              {{ $t('reports.download_csv') }}
            </v-btn>
          </div>
        </div>

        <div :class="{
          'document-table': true, // TODO: document-table-reports with different columns and filters...
          'document-table-disabled': isLoadingDocuments
        }">
          <div class="document-table-header">
            <div class="document-table-item__name document-table-item__name-header">
              {{ $t('workspace.team.title') }}
            </div>
            <div class="document-table-item__created document-table-item__created-header">
              {{ $t('general.created_at') }}
            </div>
            <div class="document-table-item__changed document-table-item__changed-header">
              {{ $t('account.credits') }}
            </div>
          </div>
          <div v-if="rows.length">
            <div class="document-table-item" v-for="row in rows" :key="row.id">

              <div class="document-table-row">
                <div class="document-table-row__name-author">
                  <strong :title="row.user.email">{{ row.user.fullname }}</strong><br />
                  {{ row.contract.is_deleted ? $t('general.deleted_at') : $t('general.created_at') }}:
                  <router-link
                    v-if="!row.contract.is_deleted"
                    :to="{ name: 'documentsDetail', params: { workspace_id: row.contract.workspace_id, contract_id: row.contract.id } }"
                    target="_blank"
                  >
                    {{ row.contract.title }}
                  </router-link>
                  <span v-else>
                    {{ row.contract.title }}
                  </span>
                </div>
                <div class="document-table-item__created document-table-row__created">
                  {{ row.created_at | formatDatetime }}
                </div>
                <div :class="{
                    'document-table-item__changed': true,
                    'document-table-row__changed': true,
                    'document-table-item__credits-amount': true,
                    'document-table-item__credits-amount-positive': row.credits.amount >= 0,
                    'document-table-item__credits-amount-negative': row.credits.amount < 0,
                }">
                  {{ row.credits.amount > 0 ? `+${row.credits.amount}` : row.credits.amount }}
                </div>
              </div>

            </div>
            <div class="document-table-stats">
              {{ `${rows.length}/${totalRowsCount}` }}
            </div>
          </div>
          <div v-else class="document-table-empty">
            {{ $t('table.noResults') }}
          </div>
        </div>

      </div>
    </div>
    <LoadMore
      v-if="hasNextPage"
      :isLoadingTableRows="isLoadingDocuments"
      @onLoadMore="onLoadMore"
    />
  </Data>
</template>

<script>
  import { DataService } from '@/services/DataService.js';
  import moment from 'moment';
  import FileSaver from 'file-saver';
  import { parseDate } from '@/services/helpers/parseDate.js';
  import DateRangePicker from 'vue2-daterange-picker'
  import LoadMore from "@/views/dashboard/components/LoadMore";
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

  export default {
    name: 'Reports',
    components: {
      DateRangePicker,
      LoadMore,
    },
    filters: {
      formatDate: (jsDate) => jsDate ? parseDate(moment(jsDate).format('YYYY-MM-DD')) : '',
      formatDatetime: (jsDate) => moment(jsDate).format('D. M. YYYY HH:mm:ss'),
    },
    data() {
      return {
        isLoadingFirstRequest: true,
        isLoadingDocuments: true,
        isBuildingCsv: false,
        debounceTimeout: false,
        rows: [],
        isDatepickerOpened: false,
        datepickerCreatedModel: {
          startDate: null,
          endDate: null,
        },
        datepickerLocale: {
          firstDay: 1,
          format: 'DD. MM. YYYY', // TODO: i18n - move date format to locale + use it in parseDate
          daysOfWeek: [
            this.$t('calendar.shortDays.sun'),
            this.$t('calendar.shortDays.mon'),
            this.$t('calendar.shortDays.tue'),
            this.$t('calendar.shortDays.wed'),
            this.$t('calendar.shortDays.thu'),
            this.$t('calendar.shortDays.fri'),
            this.$t('calendar.shortDays.sat'),
          ],
          monthNames: [
            this.$t('calendar.months.jan'),
            this.$t('calendar.months.feb'),
            this.$t('calendar.months.mar'),
            this.$t('calendar.months.apr'),
            this.$t('calendar.months.may'),
            this.$t('calendar.months.jun'),
            this.$t('calendar.months.jul'),
            this.$t('calendar.months.aug'),
            this.$t('calendar.months.sep'),
            this.$t('calendar.months.oct'),
            this.$t('calendar.months.nov'),
            this.$t('calendar.months.dec'),
          ],
        },
        filters: {
          workspace_id: this.$route.params.workspace_id,
          searchQuery: '',
          createdAt: {
            from: null,
            to: null,
          },
        },
        sortSettings: {
          column: 'created_at',
          isDescending: true,
        },
        page: 0,
        limit: 50,
        totalRowsCount: 0,
        hasNextPage: false,
      }
    },
    async created() {
      this.fetchData();
    },
    methods: {
      fetchData() {
        this.isLoadingDocuments = true;
        const filters = this.buildFiltersQuery('json');
        DataService.getCreditsReport(filters).then(
          response => {
            const newDocuments = response.data;
            if (this.page) {
              this.rows = this.rows.concat(newDocuments);
            } else {
              this.rows = newDocuments;
            }
            if (!this.page) {
              this.totalRowsCount = response.meta.count;
            }
            this.hasNextPage = newDocuments.length == this.limit;
            this.isLoadingDocuments = false;
            this.isLoadingFirstRequest = false;
          },
          () => {
            this.isLoadingDocuments = false;
            this.isLoadingFirstRequest = false;
          }
        );
      },
      onLoadMore() {
        this.page += 1;
        this.fetchData();
      },
      onSearchQueryChange(value) {
        this.updateFilters('searchQuery', value);
      },
      toggleCreatedDatepicker() {
        this.isDatepickerOpened = !this.isDatepickerOpened;
      },
      clearCreatedAt() {
        this.datepickerCreatedModel = {
          startDate: null,
          endDate: null,
        };
        this.updateCreatedAt(this.datepickerCreatedModel);
      },
      updateCreatedAt(newDatepickerModel) {
        this.updateFilters('createdAt', {
          from: newDatepickerModel.startDate,
          to: newDatepickerModel.endDate,
        });
      },
      updateFilters(type, newValue) {
        if (JSON.stringify(this.filters[type]) == JSON.stringify(newValue)) {
          return;
        }
        this.filters[type] = newValue;
        const debounceDuration = 500;
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(
          () => {
            resetPagination(this);
            this.fetchData();
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = false;
          },
          debounceDuration
        );

        function resetPagination(vue) {
          vue.page = 0;
        }
      },
      downloadCsv() {
        this.isBuildingCsv = true;
        const filters = this.buildFiltersQuery('csv');
        DataService.getCreditsReport(filters).then(
          csv => {
            const blob = new Blob([csv], {type: "text/csv;charset=utf-8"})
            FileSaver.saveAs(blob, 'report_credits.csv');
            this.isBuildingCsv = false;
          },
          () => {
            this.isBuildingCsv = false;
          }
        );
      },
      buildFiltersQuery(format) {
        const normalizeDate = jsDate => jsDate ? moment(jsDate).format('YYYY-MM-DD') : null;
        return {
          workspace_id: this.filters.workspace_id,
          search_query: this.filters.searchQuery,
          created_at: {
            from: normalizeDate(this.filters.createdAt.from),
            to: normalizeDate(this.filters.createdAt.to),
          },
          pagination: {
            limit: this.limit,
            offset: this.page * this.limit,
          },
          format,
        };
      },
    },
  }
</script>

<style lang="scss">
.document-table-item__credits-amount-positive {
  color: green !important;
}
.document-table-item__credits-amount-negative {
  color: red !important;
}
// hotfix css from Documents - override grid-template-columns, unify widths...
.dashboard-reports.dashboard-documents__wrapper {
  text-align: left;
  .dashboard-documents__filters-desktop, .document-table-header, .document-table-row {
    display: flex;
    justify-content: space-between;
    align-items: left;
    & > div {
      display: block;
    }
    & > div:first-child {
      width: 60%;
    }
    & > div:nth-child(2) {
      width: 25%;
    }
    & > div:nth-child(3) {
      width: 15%;
      text-align: right;
    }
    @include md {
      display: block;
      & > div {
        display: block;
        text-align: left;
        width: 100% !important;
      }
    }
  }

  .reports-filters {
    justify-content: unset !important;
  }

  .dashboard-documents__filters-desktop {
    .dashboard-datepicker {
      margin: 0 1ex;
      @include md {
        margin: 1ex 0;
      }
    }
    .button--primary {
      margin-bottom: 0;
    }
  }
  .document-table-header > div {
    line-height: 53px;
  }
  .document-table-row > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .document-table-stats {
    width: 100%;
    font-size: 13px;
    line-height: 30px;
    text-align: right;
    color: #999;
  }
}
</style>
