<template lang="html">
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
    <title>ico-N/ico-24-vzory.gray</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-24-vzory.gray" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="ico/ico-9-vzory-gray" fill-rule="nonzero" stroke="#B4C1D6" stroke-width="2">
        <g id="Group" transform="translate(3.500000, 2.200000)">
          <polyline id="Path-34" points="1 11.4637987 4.29021425 6.6 13.7970713 6.6 17 11.4637987"></polyline>
          <g id="ico/ico-vzory-1" transform="translate(3.498387, 0.800000)">
            <path d="M1.53966661e-13,4.0715013 C1.37355861e-13,11.8492638 1.45661261e-13,15.9043128 1.78882861e-13,16.2366483 C2.28703893e-13,16.7351514 0.378422417,16.8982456 0.819256882,16.8982456 C1.26009135,16.8982456 10.0927787,16.8982456 10.574892,16.8982456 C11.0570053,16.8982456 11.2,16.568488 11.2,16.0207095 C11.2,15.4729311 11.2,1.16238086 11.2,0.731395736 C11.2,0.300410611 10.9412665,-4.65303131e-15 10.4311073,0 C10.0910011,3.10202087e-15 7.94581739,3.10202087e-15 3.9955561,0 L1.53966661e-13,4.0715013 Z" id="Path-10" fill="#FFFFFF" transform="translate(5.600000, 8.449123) scale(-1, 1) translate(-5.600000, -8.449123) "></path>
            <path d="M6.25964912,4.8 C8.11051891,4.8 9.22131919,4.8 9.59204997,4.8 C10.1481461,4.8 10.1304393,4.12404382 10.1304393,3.23979882 C10.1304393,2.65030216 10.1304393,1.57036922 10.1304393,-7.10542736e-15" id="Path-16" transform="translate(8.195076, 2.400000) scale(-1, 1) translate(-8.195076, -2.400000) "></path>
          </g>
          <path d="M0.498387097,11.4 L5.6600511,11.4 C6.28371693,13.4649999 7.41361087,14.4974998 9.04973292,14.4974998 C10.685855,14.4974998 11.7778829,13.4649999 12.3258167,11.4 L17.4983871,11.4 L17.4983871,17.8 L0.498387097,17.8 L0.498387097,11.4 Z" id="Path-31" fill="#FFFFFF"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IcoMenuTemplate',
  }
</script>
