<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="SYN010---DSKTP---Připojit---3" transform="translate(-1151.000000, -29.000000)" stroke="#979797">
        <g id="ico/ico-close" transform="translate(1152.000000, 30.000000)">
          <g>
            <path d="M0.23828125,17.8789062 L17.4785156,0.638671875" id="Path-26" stroke-width="2" fill-rule="nonzero"></path>
            <path d="M0.23828125,17.8789062 L17.4785156,0.638671875" id="Path-26-Copy" stroke-width="2" fill-rule="nonzero" transform="translate(8.858398, 9.258789) scale(-1, 1) translate(-8.858398, -9.258789) "></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IcoClose",
  }
</script>
