<template>
  <v-layout align-center justify-center fill-height ma-0>
    <v-layout column align-center justify-center px-2>
      <div class="participant-name font-weight-medium text-center text-truncate">
        {{ participantName }}
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  name: 'SignByOtherPreview',
  props: {
    participantName: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.participant-name {
  width: 100%;
  font-size: clamp(0.2rem, 2vw, 1.2rem);
}
</style>
