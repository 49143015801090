import { render, staticRenderFns } from "./Contacts.vue?vue&type=template&id=611ba566&scoped=true&lang=html&"
import script from "./Contacts.vue?vue&type=script&lang=js&"
export * from "./Contacts.vue?vue&type=script&lang=js&"
import style0 from "./Contacts.vue?vue&type=style&index=0&id=611ba566&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "611ba566",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VBadge,VBtn,VCol,VIcon,VRow,VTextField,VTooltip})
