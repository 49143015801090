<template>
  <v-card
    v-if="!isLoading"
    :color="workspaceLayerColor"
    class="mx-auto my-md-10 pt-10 text-center"
    width="100%"
    max-width="35rem"
  >
    <v-card-text>
      <Logo class="mx-auto" :logo="workspaceLogo" :white="false" larger />
    </v-card-text>

    <v-card-text v-if="userAvatar !== null">
      <v-avatar size="110">
        <img :src="userAvatar" alt="" />
      </v-avatar>
    </v-card-text>

    <v-card-text class="px-10 px-md-15">
      <div class="title font-weight-bold secondary--text mb-2">
        {{ welcomeMessage }}
      </div>

      <div class="subtitle-1 mb-10 font-weight-medium secondary--text">
        {{ $t('authentication.aml_info') }}
      </div>

      <div>
        <span
          class="auth-terms font-weight-bold secondary--text"
          v-html="agreeWithTosMessage"
        ></span>
      </div>

      <div>
        <v-btn color="secondary" class="text-decoration-underline mt-10" text @click="openWhyLink">
          {{ $t('aml.why_we_want') }}
        </v-btn>
      </div>

      <v-row v-if="useSignpost" class="mt-3" align="center" justify="space-between">
        <v-col v-for="choice in signpostChoices" :key="choice.type" class="col-12 col-md-6">
          <v-btn color="primary" :loading="isSubmitting" x-large block @click="submit(choice.type)">
            {{ $t(`${choice.type}.verification`) }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-else class="mt-3" align="center" justify="center">
        <v-col class="col-auto">
          <v-btn color="primary" :loading="isSubmitting" x-large @click="submit()">
            {{ $t('authentication.go_to_aml') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-btn color="secondary" text class="mt-5 mb-2" x-large @click="declineDialog = true">
      {{ $t('general.decline') }}
    </v-btn>

    <DeclineDialog v-model="declineDialog" />

    <AlertMessage v-model="error" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getSignpostChoices, setSignpostChoice } from '@verification/services/authService';
import { goToLinkWithTarget } from '@/common/reusable/routing';
import DeclineDialog from '@verification/components/DeclineDialog';
import AlertMessage from '@verification/components/AlertMessage';

export default {
  name: 'Authentication',
  components: {
    DeclineDialog,
    AlertMessage,
  },
  data() {
    return {
      error: false,
      loaded: false,
      submitted: false,
      declineDialog: false,
      signpostChoices: [],
    };
  },
  computed: {
    ...mapGetters({
      contact: 'auths/contact',
      contract: 'auths/contract',
      user: 'auths/user',
      userAvatar: 'auths/userAvatar',
      isAmlType: 'auths/isAmlType',
      isBankIdType: 'auths/isBankIdType',
      bankIdLink: 'authsBankId/bankIdLink',
      workspace: 'auths/workspace',
      workspaceLogo: 'auths/workspaceLogo',
      workspaceLayerColor: 'auths/workspaceLayerColor',
    }),
    isLoading() {
      return !this.loaded;
    },
    isSubmitting() {
      return this.submitted;
    },
    contactName() {
      return `${this.user?.firstname || ''} ${this.user?.lastname || ''}`;
    },
    fromCompany() {
      if ('' === this.user?.invoice_organization_name) {
        return null;
      }

      if (null === this.user?.invoice_organization_name) {
        return null;
      }

      return this.$t('authentication.from_company', {
        contractCompany: this.user?.invoice_organization_name || '',
      });
    },
    welcomeMessage() {
      return null === this.fromCompany
        ? this.$t('authentication.from', {
            contactName: this.contactName,
          })
        : this.$t('authentication.from', {
            contactName: this.contactName,
            fromCompany: this.fromCompany,
          });
    },
    useSignpost() {
      return this.signpostChoices?.length > 1;
    },
    agreeWithTosMessage() {
      if (
        'app.signi.com' === window.location.hostname &&
        (8554 === this.workspace?.id || 8659 === this.workspace?.id)
      ) {
        return 'Pokračováním na další stránku souhlasíte s <a href="https://www.rl.cz/informace-a-pouceni-o-zpracovani-osobnich-udaju/">"Informace a poučení o zpracování osobních údajů"</a>';
      }

      return this.$t('login.agree_with_tos_auto.app_links', {
        terms: this.$t('links.terms_and_conditions.unregistered'),
        privacy: this.$t('links.privacy_policy'),
      });
    },
    isBankIdVerification() {
      return 1 === this.signpostChoices?.length && 'bankId' === this.signpostChoices[0]?.type;
    },
  },
  created() {
    getSignpostChoices({ authToken: this.$route.params.authToken }).then((resp) => {
      this.signpostChoices = resp;
    });
  },
  mounted() {
    this.fetchAuths(this.$route.params.authToken)
      .then(() => {
        this.loaded = true;
      })
      .catch(() => {
        this.loaded = true;
        this.error = true;
      });
  },
  methods: {
    ...mapActions({
      fetchAuths: 'auths/fetchAuths',
    }),
    openWhyLink() {
      if (this.isBankIdVerification) {
        goToLinkWithTarget(this.$t('links.bank_id'));
      } else {
        goToLinkWithTarget(this.$t('links.aml'));
      }
    },
    submit(signpostChoice = null) {
      const authToken = this.$route.params.authToken;
      let choice;

      this.submitted = true;

      if (1 === this.signpostChoices?.length) {
        choice = this.signpostChoices[0].type;
      } else {
        choice = signpostChoice;
      }

      setSignpostChoice({ authToken, choice })
        .then(() => {
          if ('bankId' === choice) {
            return (async () => {
              this.fetchAuths(authToken)
                .then(() => {
                  if (null === this.bankIdLink) {
                    this.submitted = false;
                    this.error = true;

                    return;
                  }

                  goToLinkWithTarget(this.bankIdLink, '_self');
                })
                .catch(() => {
                  this.submitted = false;
                  this.error = true;
                })
                .finally(() => {
                  this.submitted = false;
                });
            })();
          }

          this.fetchAuths(authToken)
            .then(() => {
              return this.$router
                .push({
                  name: 'authentication-questionnaire',
                  params: { authToken },
                })
                .then(() => {
                  this.submitted = false;
                });
            })
            .catch(() => {
              this.submitted = false;
              this.error = true;
            });
        })
        .catch(() => {
          this.submitted = false;
          this.error = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-terms {
  color: var(--v-secondary-base);
}

.auth-terms::v-deep a {
  color: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;

  &:hover {
    color: var(--v-primary-base) !important;
  }
}
</style>
