<template>
  <Data :loader="isLoadingFirstRequest" :isDashboard="true">
    <div class="dashboard-documents__header">
      <div v-if="!oneSelectedFolder">
        <h1 class="dashboard-documents__heading">
          {{ $t('docs') }}
        </h1>
      </div>
      <div v-else class="dashboard-documents__header-wrapper">
        <h1 class="dashboard-documents__header-wrapper-title dashboard-documents__heading">
          {{ oneSelectedFolder.name }}
        </h1>
        <div class="dashboard-documents__header-wrapper-actions">
          <div class="dashboard-documents__header-wrapper-action">
            <a href="#" @click.prevent="openModalWithSelectedFolder('renameFolder')">
              <IcoEdit />
            </a>
          </div>
          <div class="dashboard-documents__header-wrapper-action" v-if="!documents.length && !isLoadingDocuments">
            <a href="#" @click.prevent="openModalWithSelectedFolder('deleteFolder')">
              <IcoTrash />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard-documents__wrapper">
      <div class="dashboard-documents__table-wrapper">
        <div v-if="dashboardCheckedDocuments.length > 0" class="dashboard-documents__panel dashboard-documents__desktop-panel">
          <div>{{ $tc('table.checkedItems', dashboardCheckedDocuments.length, { count: dashboardCheckedDocuments.length }) }}:</div>
          <div class="dashboard-documents__panel-link">
            <v-btn class="px-3" text @click="openModalWithCheckedDocuments('move')">
              <v-icon color="#B4C1D6" left>mdi-folder-move-outline</v-icon>
              <span class="subtitle-1 filters__button--primary font-weight-regular">
                {{ $t('folders.moveToFolder') }}
              </span>
            </v-btn>

            <v-tooltip :disabled="isBulkExtendSupported" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn class="px-3" :disabled="!isBulkExtendSupported" text @click="openModalWithCheckedDocuments('extendExpiration')">
                    <v-icon color="#B4C1D6" left>mdi-lock-clock</v-icon>
                    <span class="subtitle-1 filters__button--primary font-weight-regular">
                      {{ $t('docs.pending_expiration.extend') }}
                    </span>
                  </v-btn>
                </div>
              </template>

              <span>{{ $t('docs.bulk_action.extend.not_supported') }}</span>
            </v-tooltip>

            <v-tooltip :disabled="isBulkDeleteSupported" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn class="px-3" :disabled="!isBulkDeleteSupported" text @click="showBulkDocumentsDeleteDialog = true">
                    <v-icon color="#B4C1D6" left>mdi-trash-can-outline</v-icon>
                    <span class="subtitle-1 filters__button--primary font-weight-regular">
                      {{ $t('general.delete') }}
                    </span>
                  </v-btn>
                </div>
              </template>

              <span>{{ $t('docs.bulk_action.delete.not_supported') }}</span>
            </v-tooltip>

            <v-tooltip :disabled="isBulkDeletePdfSupported" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn class="px-3" :disabled="!isBulkDeletePdfSupported" text @click="showBulkDocumentsPdfsDeleteDialog = true">
                    <v-icon color="#B4C1D6" left>mdi-trash-can-outline</v-icon>
                    <span class="subtitle-1 filters__button--primary font-weight-regular">
                      {{ $t('general.delete_pdf') }}
                    </span>
                  </v-btn>
                </div>
              </template>

              <span>{{ $t('docs.bulk_action.delete_pdf.not_supported') }}</span>
            </v-tooltip>
          </div>
        </div>
        <div class="dashboard-documents__filters" v-else>
          <div class="dashboard-documents__filters-desktop">
            <v-text-field
              type="text"
              :value="filters.searchQuery"
              :placeholder="$t('placeholder.fulltext')"
              prepend-inner-icon="mdi-magnify"
              class="filters__search--input"
              hide-details
              clearable
              outlined
              @input="onSearchQueryChange($event)"
            ></v-text-field>

            <MultiSelect
              class="pl-2"
              :placeholder="`${$t('folders.title')}:`"
              :options="workspaceData.folders"
              @saveSelected="(keys) => updateFilters('folders', keys)"
            />

            <MultiSelect
              class="pl-2"
              :placeholder="`${$t('tags')}:`"
              :options="workspaceData.tags"
              :show-no-tags-option="true"
              @saveSelected="(keys) => updateFilters('tags', keys)"
            />

            <MultiSelect
              class="pl-2"
              :placeholder="`${$t('general.state')}:`"
              :options="workspaceData.states"
              @saveSelected="(keys) => updateFilters('states', keys)"
            />

            <div class="dashboard-datepicker ml-2" :class="{ 'dashboard-datepicker-open': isDatepickerOpened }">
              <div @click.prevent.stop="clearCreatedAt" v-if="datepickerCreatedModel.startDate" class="dashboard-datepicker-clear-icon"></div>
              <div v-else class="dashboard-datepicker-icon"></div>
              <date-range-picker
                ref="picker"
                :opens="'left'"
                v-model="datepickerCreatedModel"
                :locale-data="datepickerLocale"
                :singleDatePicker="false"
                :timePicker="false"
                :timePicker24Hour="false"
                :showWeekNumbers="false"
                :showDropdowns="false"
                :auto-apply="true"
                :ranges="false"
                @toggle="toggleCreatedDatepicker"
                @select="updateCreatedAt"
              >
                <template v-slot:input="picker" style="min-width: 350px;">
                  <template v-if="picker && picker.startDate">
                    <span v-line-clamp="1">{{ $t('general.created_at') }}: {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}</span>
                  </template>
                  <template v-else>
                    {{ $t('general.created_at') }}:
                  </template>
                </template>
              </date-range-picker>
            </div>
          </div>

          <div class="dashboard-documents__filters-mobile">
            <v-text-field
              type="text"
              :value="filters.searchQuery"
              :placeholder="$t('placeholder.fulltext')"
              prepend-inner-icon="mdi-magnify"
              class="filters__search--input"
              hide-details
              clearable
              outlined
              @input="onSearchQueryChange($event)"
            ></v-text-field>

            <v-expand-transition>
              <div v-show="openFilters">
                <MultiSelect
                  :placeholder="`${$t('folders.title')}:`"
                  :options="workspaceData.folders"
                  @saveSelected="(keys) => updateFilters('folders', keys)"
                />

                <MultiSelect
                  :placeholder="`${$t('tags')}:`"
                  :options="workspaceData.tags"
                  :show-no-tags-option="true"
                  @saveSelected="(keys) => updateFilters('tags', keys)"
                />

                <MultiSelect
                  :placeholder="`${$t('general.state')}:`"
                  :options="workspaceData.states"
                  @saveSelected="(keys) => updateFilters('states', keys)"
                />

                <div class="dashboard-datepicker" :class="{ 'dashboard-datepicker-open': isDatepickerOpened }">
                  <div @click.prevent.stop="clearCreatedAt" v-if="datepickerCreatedModel.startDate" class="dashboard-datepicker-clear-icon"></div>
                  <div v-else class="dashboard-datepicker-icon"></div>
                  <date-range-picker
                    ref="picker"
                    :opens="'left'"
                    v-model="datepickerCreatedModel"
                    :locale-data="datepickerLocale"
                    :singleDatePicker="false"
                    :timePicker="false"
                    :timePicker24Hour="false"
                    :showWeekNumbers="false"
                    :showDropdowns="false"
                    :auto-apply="true"
                    :ranges="false"
                    @toggle="toggleCreatedDatepicker"
                    @select="updateCreatedAt"
                  >
                    <template v-slot:input="picker" style="min-width: 350px;">
                      <template v-if="picker && picker.startDate">
                        <span v-line-clamp="1">
                          {{ $t('general.created_at') }}: {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}
                        </span>
                      </template>
                      <template v-else>
                        {{ $t('general.created_at') }}:
                      </template>
                    </template>
                  </date-range-picker>
                </div>
              </div>
            </v-expand-transition>

            <div class="filter-documents-open" :class="{'active-filters-wrapper': filtersCount > 0 && !openFilters}">
              <span class="funnel-wrapper" v-if="!openFilters" @click="openFilters = true">
                <img class="funnel-ico" src="/images/funnel.png">
                {{ $t('table.filters.docs') }}
              </span>
              <span class="active-filters" v-if="filtersCount > 0 && !openFilters">
                {{ $t('table.filters.active') }}: {{ filtersCount }}
              </span>
              <span class="funnel-wrapper" v-if="openFilters" @click="openFilters = false">
                <img class="funnel-ico" src="/images/funnel.png">
                {{ $t('table.filters.hide') }}
              </span>
            </div>
          </div>
        </div>

        <div :class="{
          'document-table': true,
          'document-table-disabled': isLoadingDocuments
        }">
          <div class="document-table-header">
            <div class="document-table-item__checkbox">
              <label class="all-checked-label">
                  <Checkbox @click="checkOrUncheckAll()" :value="areCheckedAll" :disabled="!documents.length" />
                  <span @click.stop.prevent="checkOrUncheckAll()" class="document-table-item__checkbox-mobile-label">{{ areCheckedAll ? $t('table.uncheckAll') : $t('table.checkAll') }}</span>
              </label>
            </div>
            <div @click="onSortChange('title')" class="document-table-item__name document-table-item__name-header">
              <span>{{ $t('general.title') }}</span>
              <div v-if="sortSettings.column == 'title'">
                <template v-if="sortSettings.isDescending">
                  <div class="document-table-item__arrow-down">
                    <IcoArrowDownGrey/>
                  </div>
                </template>
                <template v-else>
                  <div class="document-table-item__arrow-up">
                    <IcoArrowDownGrey/>
                  </div>
                </template>
              </div>
            </div>
            <div class="document-table-item__state document-table-item__state-header">
              {{ $t('contract.state') }}
            </div>
            <div @click="onSortChange('created_at')" class="document-table-item__created document-table-item__created-header">
              <span>{{ $t('general.created_at') }}</span>
              <div v-if="sortSettings.column == 'created_at'">
                <template v-if="sortSettings.isDescending">
                  <div class="document-table-item__arrow-down">
                    <IcoArrowDownGrey/>
                  </div>
                </template>
                <template v-else>
                  <div class="document-table-item__arrow-up">
                    <IcoArrowDownGrey/>
                  </div>
                </template>
              </div>
            </div>
            <div @click="onSortChange('updated_at')" class="document-table-item__changed document-table-item__changed-header">
              <span>{{ $t('general.updated_at') }}</span>
              <div v-if="sortSettings.column == 'updated_at'">
                <template v-if="sortSettings.isDescending">
                  <div class="document-table-item__arrow-down">
                    <IcoArrowDownGrey/>
                  </div>
                </template>
                <template v-else>
                  <div class="document-table-item__arrow-up">
                    <IcoArrowDownGrey/>
                  </div>
                </template>
              </div>
            </div>
            <div class="document-table-item__select-filters-mobile">
              <span @click="openModalWithCheckedDocuments('sort')">{{ $t('table.order') }}</span>
            </div>
            <div class="document-table-item__actions document-table-item__actions-header">
            </div>
          </div>
          <div v-if="documents.length">
            <div class="document-table-item" v-for="item in documents" :key="item.id">

              <DocumentTableRow :item="item" @reload="fetchData()" />

            </div>
          </div>
          <div class="document-table-empty" v-else>
            {{ $t('table.noResults') }}
          </div>
        </div>

        <div v-if="dashboardCheckedDocuments.length > 0" class="dashboard-documents__panel dashboard-documents__mobile-panel">
          <div>{{ $tc('table.checkedItems', dashboardCheckedDocuments.length, { count: dashboardCheckedDocuments.length }) }}:</div>
          <div class="dashboard-documents__panel-link">
            <v-btn class="px-3" text @click="openModalWithCheckedDocuments('move')">
              <v-icon color="#B4C1D6" left>mdi-folder-move-outline</v-icon>
              <span class="subtitle-1 filters__button--primary font-weight-regular">
                {{ $t('folders.moveToFolder') }}
              </span>
            </v-btn>

            <v-tooltip :disabled="isBulkExtendSupported" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn class="px-3" :disabled="!isBulkExtendSupported" text @click="openModalWithCheckedDocuments('extendExpiration')">
                    <v-icon color="#B4C1D6" left>mdi-lock-clock</v-icon>
                    <span class="subtitle-1 filters__button--primary font-weight-regular">
                      {{ $t('docs.pending_expiration.extend') }}
                    </span>
                  </v-btn>
                </div>
              </template>

              <span>{{ $t('docs.bulk_action.extend.not_supported') }}</span>
            </v-tooltip>

            <v-tooltip :disabled="isBulkDeleteSupported" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn class="px-3" :disabled="!isBulkDeleteSupported" text @click="showBulkDocumentsDeleteDialog = true">
                    <v-icon color="#B4C1D6" left>mdi-trash-can-outline</v-icon>
                    <span class="subtitle-1 filters__button--primary font-weight-regular">
                      {{ $t('general.delete') }}
                    </span>
                  </v-btn>
                </div>
              </template>

              <span>{{ $t('docs.bulk_action.delete.not_supported') }}</span>
            </v-tooltip>

            <v-tooltip :disabled="isBulkDeletePdfSupported" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn class="px-3" :disabled="!isBulkDeletePdfSupported" text @click="showBulkDocumentsPdfsDeleteDialog = true">
                    <v-icon color="#B4C1D6" left>mdi-trash-can-outline</v-icon>
                    <span class="subtitle-1 filters__button--primary font-weight-regular">
                      {{ $t('general.delete_pdf') }}
                    </span>
                  </v-btn>
                </div>
              </template>

              <span>{{ $t('docs.bulk_action.delete_pdf.not_supported') }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>

      <BulkDocumentsDeleteDialog
        v-model="showBulkDocumentsDeleteDialog"
        @refresh-table="reloadCurrentData"
      />

      <BulkDocumentsPdfsDeleteDialog
        v-model="showBulkDocumentsPdfsDeleteDialog"
        @refresh-table="reloadCurrentData"
      />

      <DuplicateContract
        v-if="dashboardModal && dashboardModal.type === 'duplicate'"
        v-model="dashboardModal.type"
        :contract="dashboardModal.contracts[0]"
      />

      <ExtendExpiration
        v-else-if="dashboardModal && dashboardModal.type === 'extendExpiration'"
        v-model="dashboardModal.type"
        :contracts="modalEditor.bulkContracts.enabled"
        @reloadAfterExtend="fetchData"
      />

      <Modal v-else-if="dashboardModal && dashboardModal.type" :enableClose="true" @close="closeModal">
        <div v-if="dashboardModal.type === 'move'">
          <form class="modal-move" @submit.prevent="addDocsToFolder()">
            <Select placeholder="Složka" :value="modalEditor.selectedFolder ? modalEditor.selectedFolder.name : ''" @select="selectFolderInModal" :options="workspaceData.folders"></Select>
            <div class="profile-buttons dashboard-save-button">
              <Button buttonType="submit" type="primary" :disabled="!modalEditor.selectedFolder">
                {{ $t('folders.move') }}
              </Button>
            </div>
          </form>
          <div class="separator-hr dashboard-mb-20"></div>
          <div class="dashboard-edit-button">
            <Button @click="openModalWithCheckedDocuments('manageFolders')">
              {{ $t('folders.manage') }}
            </Button>
          </div>
        </div>
        <div v-if="dashboardModal.type === 'manageFolders'">
          <form @submit.prevent="createNewFolder()">
            <Input v-model="modalEditor.newName" type="text" :placeholder="$t('placeholder.name')" :label="$t('folders.new')" />
            <div class="profile-buttons dashboard-save-button">
              <Button buttonType="submit" type="primary" :disabled="modalEditor.newName.length < 2">
                {{ $t('folders.create') }}
              </Button>
            </div>
          </form>
        </div>
        <div v-if="dashboardModal.type === 'editTags'">
          <form @submit.prevent="editDocsTags()">
            <MultiSelect
              :placeholder="`${$t('tags')}:`"
              :options="modalEditor.contractTags"
            />
            <div class="profile-buttons dashboard-save-button">
              <Button buttonType="submit" type="primary">
                {{ $t('tags.save') }}
              </Button>
            </div>
          </form>
          <div class="separator-hr dashboard-mb-20"></div>
          <div class="dashboard-edit-button">
            <Button @click="openModalWithCheckedDocuments('manageTags')">
              {{ $t('tags.manage') }}
            </Button>
          </div>
        </div>
        <div v-if="dashboardModal.type === 'manageTags'">
          <form @submit.prevent="createNewTag()">
            <Input v-model="modalEditor.newName" type="text" :placeholder="$t('placeholder.name')" :label="$t('tags.new')" />
            <div class="profile-buttons dashboard-save-button">
              <Button buttonType="submit" type="primary" :disabled="modalEditor.newName.length < 2">
                {{ $t('tags.create') }}
              </Button>
            </div>
          </form>
        </div>
        <div v-if="dashboardModal.type === 'delete'" class="dashboard-documents__modal-bulk">
          <div class="dashboard-documents__modal-bulk-heading">
            {{ $tc('docs.bulkDelete', dashboardModal.contracts.length, { count: dashboardModal.contracts.length }) }}
          </div>
          <div v-if="modalEditor.bulkContracts.disabled.length" class="dashboard-documents__modal-bulk-disabled">
            <strong>{{ $t('docs.bulkError.deleteTitle') }}</strong>
            {{ $t('docs.bulkError.deleteDescription') }}
            <ul class="dashboard-documents__modal-bulk-list">
              <li v-for="contract in modalEditor.bulkContracts.disabled">
                <router-link :to="{ name: 'documentsDetail', params: { workspace_id: filters.workspace_id, contract_id: contract.id } }">
                  <IcoMenuPage /> {{ contract.title }} <span class="dashboard-documents__modal-bulk-list-link-icon"><IcoArrowBack/></span>
                </router-link>
              </li>
            </ul>
          </div>
          <ul class="dashboard-documents__modal-bulk-list">
            <li v-for="contract in modalEditor.bulkContracts.enabled">
              <router-link :to="{ name: 'documentsDetail', params: { workspace_id: filters.workspace_id, contract_id: contract.id } }">
                <IcoMenuPage /> {{ contract.title }} <span class="dashboard-documents__modal-bulk-list-link-icon"><IcoArrowBack/></span>
              </router-link>
            </li>
          </ul>
        </div>

        <div v-if="dashboardModal.type === 'sort'" class="sort-documents-modal">
          <div>
            <div>{{ $t('general.title') }}:</div>

            <div>
              <div @click="onSortChange('title', 'ASC')">
                <v-icon
                  v-if="isSortedBy('title', 'ASC')"
                  color="primary"
                  small
                >mdi-check</v-icon>
                {{ $t('table.order.alphabetDesc') }}
              </div>

              <div @click="onSortChange('title', 'DSC')">
                <v-icon
                  v-if="isSortedBy('title', 'DSC')"
                  color="primary"
                  small
                >mdi-check</v-icon>
                {{ $t('table.order.alphabetAsc') }}
              </div>
            </div>
          </div>

          <div>
            <div>{{ $t('general.created_at') }}:</div>

            <div>
              <div @click="onSortChange('created_at', 'DSC')">
                <v-icon
                  v-if="isSortedBy('created_at', 'DSC')"
                  color="primary"
                  small
                >mdi-check</v-icon>
                {{ $t('table.order.latest') }}
              </div>

              <div @click="onSortChange('created_at', 'ASC')">
                <v-icon
                  v-if="isSortedBy('created_at', 'ASC')"
                  color="primary"
                  small
                >mdi-check</v-icon>
                {{ $t('table.order.oldest') }}
              </div>
            </div>
          </div>

          <div>
            <div>{{ $t('general.updated_at') }}:</div>
            <div>
              <div @click="onSortChange('updated_at', 'DSC')">
                <v-icon
                  v-if="isSortedBy('updated_at', 'DSC')"
                  color="primary"
                  small
                >mdi-check</v-icon>
                {{ $t('table.order.latest') }}
              </div>

              <div @click="onSortChange('updated_at', 'ASC')">
                <v-icon
                  v-if="isSortedBy('updated_at', 'ASC')"
                  color="primary"
                  small
                >mdi-check</v-icon>
                {{ $t('table.order.oldest') }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="dashboardModal.type === 'renameFolder'">
          <form @submit.prevent="renameFolder()">
            <Input v-model="modalEditor.newName" type="text" :placeholder="$t('placeholder.name')" :label="$t('general.title')" />
            <div class="profile-buttons dashboard-save-button">
              <Button buttonType="submit" type="primary" :disabled="modalEditor.newName.length < 2">
                {{ $t('general.rename') }}
              </Button>
            </div>
          </form>
        </div>
        <div v-if="dashboardModal.type === 'deleteFolder'" class="dashboard-documents__modal-bulk">
          <div
            class="dashboard-documents__modal-bulk-heading"
            v-html="$t('folders.delete_confirmHtml', { folderName: modalEditor.selectedFolder.name })"
          ></div>
          <Button buttonType="submit" type="secondary" @click="deleteFolder()">
            {{ $t('general.delete') }}
          </Button>
        </div>
      </Modal>
    </div>

    <LoadMore
      v-if="hasNextPage"
      :isLoadingTableRows="isLoadingDocuments"
      @onLoadMore="onLoadMore"
    />
  </Data>
</template>

<script>
import { mapGetters } from "vuex";
import { DataService } from '@/services/DataService.js';
import { parseDate } from '@/services/helpers/parseDate.js';
import { hasFeatureFlag } from '@/common/reusable/featureFlagsChecker';
import moment from 'moment';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import DocumentTableRow from "./components/DocumentTableRow";
import DuplicateContract from "./components/DuplicateContract.vue";
import ExtendExpiration from "@/views/dashboard/components/ExtendExpiration.vue";
import Modal from '@/components/Modal.vue';
import IcoCheckPurple from '@/components/svg/IcoCheckPurple.vue';
import IcoMenuPage from '@/components/svg/menu/IcoMenuPage.vue';
import IcoArrowBack from '@/components/svg/IcoArrowBack';
import IcoArrowDownGrey from '@/components/svg/IcoArrowDownGrey.vue';
import DateRangePicker from 'vue2-daterange-picker'
import LoadMore from "@/views/dashboard/components/LoadMore";
import IcoEdit from "@/views/dashboard/components/icons/IcoEdit";
import IcoTrash from "@/views/dashboard/components/icons/IcoTrash";
import BulkDocumentsDeleteDialog from "@dashboard/components/BulkDocumentsDeleteDialog";
import BulkDocumentsPdfsDeleteDialog from "@dashboard/components/BulkDocumentsPdfsDeleteDialog";

  export default {
    name: 'Documents',
    components: {
      BulkDocumentsDeleteDialog,
      BulkDocumentsPdfsDeleteDialog,
      DocumentTableRow,
      Modal,
      DuplicateContract,
      ExtendExpiration,
      DateRangePicker,
      LoadMore,
      IcoCheckPurple,
      IcoMenuPage,
      IcoArrowBack,
      IcoArrowDownGrey,
      IcoEdit,
      IcoTrash,
    },
    filters: {
      formatDate: (jsDate) => jsDate ? parseDate(moment(jsDate).format('YYYY-MM-DD')) : '',
    },
    data() {
      return {
        showBulkDocumentsDeleteDialog: false,
        showBulkDocumentsPdfsDeleteDialog: false,
        isLoadingFirstRequest: true,
        isLoadingDocuments: true,
        debounceTimeout: false,
        documents: [],
        isDatepickerOpened: false,
        datepickerCreatedModel: {
          startDate: null,
          endDate: null,
        },
        datepickerLocale: {
          firstDay: 1,
          format: 'DD. MM. YYYY', // TODO: i18n - move date format to locale + use it in parseDate
          daysOfWeek: [
            this.$t('calendar.shortDays.sun'),
            this.$t('calendar.shortDays.mon'),
            this.$t('calendar.shortDays.tue'),
            this.$t('calendar.shortDays.wed'),
            this.$t('calendar.shortDays.thu'),
            this.$t('calendar.shortDays.fri'),
            this.$t('calendar.shortDays.sat'),
          ],
          monthNames: [
            this.$t('calendar.months.jan'),
            this.$t('calendar.months.feb'),
            this.$t('calendar.months.mar'),
            this.$t('calendar.months.apr'),
            this.$t('calendar.months.may'),
            this.$t('calendar.months.jun'),
            this.$t('calendar.months.jul'),
            this.$t('calendar.months.aug'),
            this.$t('calendar.months.sep'),
            this.$t('calendar.months.oct'),
            this.$t('calendar.months.nov'),
            this.$t('calendar.months.dec'),
          ],
        },
        workspaceData: {
          isLoaded: false,
          folders: [],
          tags: [],
          states: this.getStateFilters(),
        },
        filters: {
          workspace_id: this.$route.params.workspace_id,
          states: this.getActiveStatesFromUrl(),
          tags: [],
          folders: this.getActiveFoldersFromUrl(),
          searchQuery: this.$route.query.search || '',
          createdAt: {
            from: null,
            to: null,
          },
        },
        sortSettings: {
          column: 'created_at',
          isDescending: true,
        },
        page: 0,
        limit: 20,
        hasNextPage: false,
        openFilters: false,
        screenWidth: 0,
        modalEditor: {
          newName: '',
          selectedFolder: null,
          contractTags: [],
          bulkContracts: {
            enabled: [],
            disabled: [],
          },
        },
      }
    },
    computed: {
      ...mapGetters({
        workspaceById: "workspaceById",
        profile: "profile",
        dashboardCheckedDocuments: "dashboardCheckedDocuments",
        dashboardModal: "dashboardModal",
        isMobileDevice: "window/isMobileDevice",
      }),
      filtersCount() {
        const countableFilters = [
          this.filters.tags.length,
          this.filters.folders.length,
          this.filters.states.length,
          this.filters.createdAt.from,
        ];
        return countableFilters.filter(isTruthy => isTruthy).length;
      },
      areCheckedAll() {
        return this.documents.length && this.dashboardCheckedDocuments.length === this.documents.length;
      },
      oneSelectedFolder() {
        const selectedFolders = this.workspaceData.folders.filter((folder) => {
          return folder.isSelected;
        });
        return selectedFolders.length == 1 ? selectedFolders[0] : null;
      },
      isBulkDeleteSupported() {
        for (let document of this.dashboardCheckedDocuments) {
          if (document.state === 'completed') {
            return false;
          }
        }

        return true;
      },
      isBulkDeletePdfSupported() {
        for (let document of this.dashboardCheckedDocuments) {
          if (document.state === 'completed' && document?.feature_flags?.canDeleteDocuments) {
            return true;
          }
        }

        return false;
      },
      isBulkExtendSupported() {
        for (let document of this.dashboardCheckedDocuments) {
          if (!hasFeatureFlag(document, 'isExpirationExtendable')) {
            return false;
          }
        }

        return true;
      },
    },
    async created() {
      this.fetchData();
      this.$store.dispatch('resetDashboard');
    },
    methods: {
      checkOrUncheckAll() {
        if (this.areCheckedAll) {
          this.$store.commit('dashboardDeselectAllDocuments');
          return;
        }
        this.$store.commit('dashboardSelectAllDocuments', this.documents);
      },
      reloadCheckedDocuments() {
        const checkedIds = this.dashboardCheckedDocuments.map(c => c.id);
        const checkedRows = this.documents.filter(c => checkedIds.indexOf(c.id) !== -1);
        this.$store.commit('dashboardSelectAllDocuments', checkedRows);
      },
      onSortChange(category, direction = null) {
        if (category !== this.sortSettings.column) {
          this.sortSettings.column = category;
          this.sortSettings.isDescending = direction ? (direction == 'DSC') : this.sortSettings.isDescending;
        } else {
          this.sortSettings.column = category;
          this.sortSettings.isDescending = direction ? (direction == 'DSC') : !this.sortSettings.isDescending;
        }
        this.reloadCurrentData();
      },
      isSortedBy(category, direction) {
        return this.sortSettings.column === category
          && (this.sortSettings.isDescending === (direction === 'DSC'));
      },
      reloadCurrentData() {
        this.page = 0;
        this.fetchData();
      },
      fetchData() {
        this.isLoadingDocuments = true;
        const areMetadataReturned = !this.workspaceData.isLoaded;
        this.workspaceData.isLoaded = true; // load available tags/folders only in first request
        const normalizeDate = jsDate => jsDate ? moment(jsDate).format('YYYY-MM-DD') : null;
        DataService.getContracts({
            workspace_id: this.filters.workspace_id,
            states: this.filters.states,
            tags_ids: this.filters.tags,
            folder_ids: this.filters.folders,
            search_query: this.filters.searchQuery,
            created_at: {
              from: normalizeDate(this.filters.createdAt.from),
              to: normalizeDate(this.filters.createdAt.to),
            },
            sort: {
              column: this.sortSettings.column,
              isDescending: this.sortSettings.isDescending,
            },
            pagination: {
              limit: this.limit,
              offset: this.page * this.limit,
            },
            load_metadata: areMetadataReturned,
        }).then(
          response => {
            if (areMetadataReturned) {
              this.loadAvailableTagsAndFolders(response.meta);
            }
            const newDocuments = response.data;
            if (this.page) {
              this.documents = this.documents.concat(newDocuments);
            } else {
              this.documents = newDocuments;
            }
            this.hasNextPage = newDocuments.length == this.limit;
            this.reloadCheckedDocuments();
            this.isLoadingDocuments = false;
            this.isLoadingFirstRequest = false;
          },
          () => {
            this.isLoadingDocuments = false;
            this.isLoadingFirstRequest = false;
          }
        );
      },
      createNewFolder() {
        this.createNewTag(true);
      },
      renameFolder() {
        this.manageTagsOrFolders({
          action: 'rename',
          workspace_id: this.filters.workspace_id,
          id: this.modalEditor.selectedFolder.id,
          name: this.modalEditor.newName,
        });
      },
      deleteFolder() {
        this.manageTagsOrFolders({
          action: 'delete',
          workspace_id: this.filters.workspace_id,
          id: this.modalEditor.selectedFolder.id,
        });
      },
      createNewTag(isFolder) {
        this.manageTagsOrFolders({
          action: 'create',
          workspace_id: this.filters.workspace_id,
          name: this.modalEditor.newName,
          is_folder: !!isFolder,
        });
      },
      manageTagsOrFolders(request) {
        DataService.manageTagsOrFolders(request).then(
          (response) => {
            this.loadAvailableTagsAndFolders(response);
            if (request.action == 'delete') {
              this.updateFilters('folders', []);
            }
            this.closeModal();
          },
          () => {
            this.$notification.error(this.$t('folders.createError'));
          }
        );
      },
      editDocsTags() {
        DataService.assignTagsOrFolders({
          workspace_id: this.filters.workspace_id,
          tags_ids: this.modalEditor.contractTags.filter(t => t.isSelected).map(t => t.key),
          docs_ids: this.dashboardModal.contracts.map(contract => contract.docs_id),
        }).then(
          () => {
            this.fetchData();
            this.closeModal();
          },
          () => {
            this.$notification.error(this.$t('tags.editError'));
          }
        );
      },
      addDocsToFolder() {
        DataService.assignTagsOrFolders({
          workspace_id: this.filters.workspace_id,
          folder_id: this.modalEditor.selectedFolder.id,
          docs_ids: this.dashboardModal.contracts.map(contract => contract.docs_id),
        }).then(
          (response) => {
            this.updateFilters('folders', [response.folder_id]);
            this.closeModal();
          },
          () => {
            this.$notification.error(this.$t('folders.moveError'));;
          }
        );
      },
      loadAvailableTagsAndFolders(transformedTags) {
        this.workspaceData.tags = (transformedTags.tags || []).map(tag => ({
          name: tag.name,
          key: tag.id,
          isSelected: this.filters.tags.indexOf(tag.id) != -1,
        }));

        this.workspaceData.tags.push({
          name: this.$t('no_tag'),
          key: 'no_tag',
          root: true,
          isSelected: this.filters.tags.indexOf('no_tag') != -1,
        });

        this.workspaceData.folders = (transformedTags.folders || []).map(tag => ({
          name: tag.name,
          key: tag.id,
          id: tag.id, // fallback for folder (used in move modal + addDocsToFolder, maybe in other places too)
          isSelected: this.filters.folders.indexOf(tag.id) != -1,
        }));
        this.reloadMenuLinks();
      },
      openModalWithCheckedDocuments(action) {
        this.$store.commit('dashboardOpenModal', {
          type: action,
          contracts: this.dashboardCheckedDocuments,
        });
      },
      selectFolderInModal(selected) {
        this.modalEditor.selectedFolder = selected;
      },
      openModalWithSelectedFolder(action) {
        this.modalEditor.newName = this.oneSelectedFolder.name;
        this.modalEditor.selectedFolder = this.oneSelectedFolder;
        this.$store.commit('dashboardOpenModal', {
          type: action,
        });
      },
      closeModal() {
        this.modalEditor = {
          newName: '',
          selectedFolder: null,
          contractTags: [],
          bulkContracts: {
            enabled: [],
            disabled: [],
          },
        };
        this.$store.commit('dashboardCloseModal');
      },
      onLoadMore() {
        this.page += 1;
        this.fetchData();
      },
      onSearchQueryChange(value) {
        this.updateFilters('searchQuery', value);
      },
      toggleCreatedDatepicker() {
        this.isDatepickerOpened = !this.isDatepickerOpened;
      },
      clearCreatedAt() {
        this.datepickerCreatedModel = {
          startDate: null,
          endDate: null,
        };
        this.updateCreatedAt(this.datepickerCreatedModel);
      },
      updateCreatedAt(newDatepickerModel) {
        this.updateFilters('createdAt', {
          from: newDatepickerModel.startDate,
          to: newDatepickerModel.endDate,
        });
      },
      updateFilters(type, newValue) {
        if (JSON.stringify(this.filters[type]) == JSON.stringify(newValue)) {
          return;
        }
        this.filters[type] = newValue;
        const debounceDuration = 500;
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(
          () => {
            resetPagination(this);
            this.fetchData();
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = false;
            updateQueryParams(this.filters, this.workspaceData, this);
          },
          debounceDuration
        );

        function resetPagination(vue) {
          vue.page = 0;
        }

        function updateQueryParams(filters, workspaceData, vue) {
          setTimeout(() => {
            const query = {};
            if (filters.states.length && workspaceData.states.length != filters.states.length) {
              query.state = filters.states.join(',');
            }
            if (filters.folders && filters.folders.length) {
              // avoids NavigationDuplicated for string/integer conversion...
              query.folder = filters.folders.length == 1 ? filters.folders[0] : filters.folders.join(',');
            }
            if (JSON.stringify(query) == JSON.stringify(vue.$route.query)) {
              return;
            }
            vue.$router.replace({ query });
            vue.reloadMenuLinks(query);
          }, 500);
        }
      },
      getActiveFoldersFromUrl() {
        if (!this.$route.query || !this.$route.query.folder) {
          return [];
        }
        return String(this.$route.query.folder).split(',').filter(id => parseInt(id)).map(id => parseInt(id));
      },
      getStateFilters() {
        const activeStates = this.getActiveStatesFromUrl();
        const isReviewDisabled = this.isReviewDisabled(activeStates);
        const allStates = [
          {name: this.$t('docs.drafts'), key: 'draft'},
          {name: this.$t('review.pending_generic'), key: 'review', isDisabled: isReviewDisabled },
          {name: this.$t('review.pending_me'), key: 'review.me', isDisabled: isReviewDisabled },
          {name: this.$t('review.pending_other'), key: 'review.not_me', isDisabled: isReviewDisabled },
          {name: this.$t('docs.pending.generic'), key: 'pending' },
          {name: this.$t('docs.pending.me'), key: 'pending.me' },
          {name: this.$t('docs.pending.not_me'), key: 'pending.not_me' },
          {name: this.$t('docs.pending.expiring'), key: 'pending.expiring' },
          {name: this.$t('docs.rejected'), key: 'rejected' },
          {name: this.$t('docs.expired'), key: 'expired' },
          {name: this.$t('docs.completed'), key: 'completed' },
          {name: this.$t('docs.completed.expiring'), key: 'completed.expiring' },
          {name: this.$t('docs.archive.trash'), key: 'archive.trashed' },
        ];
        return allStates
          .filter(f => !f.isDisabled)
          .map(
            ({ name, key }) => ({
              name,
              key,
              isSelected: activeStates.indexOf(key) !== -1,
            })
          );
      },
      getActiveStatesFromUrl() {
        if (!this.$route.query || !this.$route.query.state) {
          return [];
        }
        return this.$route.query.state.split(',').filter(state => state && state.length);
      },
      reloadMenuLinks(dynamicQuery) {
        const changeQueryString = ({ state, folder }) => () => {
          this.$router.replace({
            name: 'documentsTable',
            query: { state, folder },
          });
        };
        // TODO: UI - extract csv query string helpers (copy-pasted code in getActiveGroupsFromUrl, getActiveStatesFromUrl, getActiveFoldersFromUrl)
        const queryString = dynamicQuery || this.$route.query || {};
        const activeStates = this.getActiveStatesFromUrl();
        const activeFoldersFromUrl = String(queryString.folder).split(',').filter(id => parseInt(id)).map(id => parseInt(id))
        const routes = [
          {
            title: this.$t('docs.all'),
            action: changeQueryString({}),
            isActive: !queryString.state && !activeFoldersFromUrl.length,
          },
          {
            title: this.$t('review.pending_generic'),
            action: changeQueryString({ state: 'review' }),
            isActive: queryString.state === 'review' && !activeFoldersFromUrl.length,
            isDisabled: this.isReviewDisabled(activeStates),
          },
          {
            title: this.$t('docs.pending.generic'),
            action: changeQueryString({ state: 'pending' }),
            isActive: queryString.state === 'pending' && !activeFoldersFromUrl.length,
          },
          {
            title: this.$t('docs.drafts'),
            action: changeQueryString({ state: 'draft' }),
            isActive: queryString.state === 'draft' && !activeFoldersFromUrl.length,
          },
          {
            title: this.$t('docs.completed'),
            action: changeQueryString({ state: 'completed' }),
            isActive: queryString.state === 'completed' && !activeFoldersFromUrl.length,
          },
          {
            title: this.$t('docs.expiring'),
            action: changeQueryString({ state: 'pending.expiring,completed.expiring' }),
            isActive: queryString.state === 'pending.expiring,completed.expiring' && !activeFoldersFromUrl.length,
          },
          {
            title: this.$t('docs.archive.trash'),
            action: changeQueryString({ state: 'archive.trashed' }),
            isActive: queryString.state === 'archive.trashed' && !activeFoldersFromUrl.length,
          },
          {
            title: 'SidebarFolders',
          },
          ...this.workspaceData.folders.map(folder => ({
            title: folder.name,
            action: changeQueryString({ folder: folder.key }),
            isActive: activeFoldersFromUrl.indexOf(folder.key) !== -1,
          })),
          {
            title: `+ ${this.$t('folders.new')}`,
            extraCss: 'sidemenu-workplace__a-new-folder',
            action: () => {
              this.$store.commit('dashboardOpenModal', { type: 'manageFolders' });
            }
          },
        ];
        this.$store.commit('dashboardSetDocumentLinks', routes.filter(r => !r.isDisabled));
      },
      isReviewDisabled(activeStates) {
        const activeWorkspace = this.workspaceById ? this.workspaceById(this.$route.params.workspace_id) : false;
        const isEnabledInWorkspace = activeWorkspace;
        const isFilteredInUrl = this.$route.query.state && String(this.$route.query.state).indexOf('review') !== -1;
        return !(isEnabledInWorkspace || isFilteredInUrl);
      },
    },
    watch: {
      '$route.query.state'() {
        const activeStates = this.getActiveStatesFromUrl();
        _.each(this.workspaceData.states, selectOption => {
          selectOption.isSelected = activeStates.indexOf(selectOption.key) !== -1;
        });
        this.reloadMenuLinks();
        this.updateFilters('states', activeStates);
      },
      '$route.query.folder'() {
        const activeFolders = this.getActiveFoldersFromUrl();
        _.each(this.workspaceData.folders, selectOption => {
          selectOption.isSelected = activeFolders.indexOf(selectOption.key) !== -1;
        });
        this.reloadMenuLinks();
        this.updateFilters('folders', activeFolders);
      },
      dashboardModal() {
        // edit tags
        if (!this.dashboardModal || this.dashboardModal.type != 'editTags' || this.dashboardModal.contracts.length != 1) {
          this.modalEditor.contractTags = [];
        } else {
          const activeTags = this.dashboardModal.contracts[0].tags.map(tag => tag.id);
          this.modalEditor.contractTags = this.workspaceData?.tags
            ?.filter((wt) => !wt.root)
            ?.map((workspaceTag) => ({
              name: workspaceTag.name,
              key: workspaceTag.key,
              isSelected: activeTags.indexOf(workspaceTag.key) != -1,
            })) || [];
        }
        // bulk actions
        const flags = {
          delete: 'isCompletelyDeletable',
          extendExpiration: 'isExpirationExtendable',
        };
        const flag = this.dashboardModal ? flags[this.dashboardModal.type] : null;
        if (flag) {
          this.modalEditor.bulkContracts.enabled = this.dashboardModal.contracts.filter(c => hasFeatureFlag(c, flag));
          this.modalEditor.bulkContracts.disabled = this.dashboardModal.contracts.filter(c => !hasFeatureFlag(c, flag));
        }
      },
      profile: function () {
        if (this.profile && this.workspaceData) {
          // TODO: workflow - delete watcher and isReviewDisabled when review is enabled for all workspaces
          this.workspaceData.states = this.getStateFilters();
        }
      },
    },
  }
</script>

<style lang="scss">
.filters__button--primary {
  color: var(--v-primary-base);
  text-decoration: underline;
}

  .dashboard-documents__header {
    margin-top: -35px;
    margin-bottom: 20px;
    @include md {
      margin-top: 0;
      margin-bottom: unset;
    }

    &-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .dashboard-documents__header-wrapper-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: unset;
        text-align: left;
      }

      .dashboard-documents__header-wrapper-actions {
        display: flex;
        flex-direction: row;
        a {
          margin: 0 5px;
          display: flex;
          align-items: center;
          text-align: left;
          color: #9fa2a6;
        }
      }
    }
  }

  .dashboard-documents__heading {
    font-weight: bold;
    text-align: left;
  }
  .dashboard-documents__desktop-panel {
    padding: 20px;
    background: white;
    border-radius: 6px;
    font-size: 16px;
    display: flex;
    color: black;
    position: sticky;
    margin-bottom: 20px;
    top: 70px;
    z-index: 1;
    box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
    align-items: center;
    & > div:first-child {
      margin-right: 15px;
    }
    @include md {
      display: none;
    }
  }
  .dashboard-documents__mobile-panel {
    position: sticky;
    bottom: 0;
    z-index: 1;
    background: white;
    width: 100%;
    font-size: 16px;
    display: none;
    padding: 20px 20px 5px 20px;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
    & > div:first-child {
      width: 100px;
      margin-right: 10px;
      color: black;
    }
    & >div:nth-child(2) {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    @include md {
      display: flex;
    }
  }
  .dashboard-documents__panel-link {
    display: flex;
    align-items: center;
    & > span {
      display: flex;
      align-items: center;
      color: #7443AD;
      text-decoration: underline;
      margin-right: 20px;
      cursor: pointer;
      user-select: none;
      .dashboard-documents__panel-link-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      a {
        color: #7443AD;
        font-weight: normal;
      }
    }
    @include md {
      justify-content: flex-start;
      & > span {
        margin-bottom: 15px;
        display: flex;
        align-self: end;
      }
    }
  }
  .dashboard-documents__panel-link-icon {
    &.icon::before {
      text-decoration: none;
      display: inline-block;
    }
  }
  .dashboard-documents__wrapper {
    .datetime-picker__button {
      color: gray;
    }
    .input__data::placeholder {
      color: gray;
      font-style: normal;
    }
    .modal-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      .modal-container {
        max-width: 350px;
        max-height: 80vh;
        overflow: scroll;
        height: unset;
        &:before {
          display: none;
        }
      }
    }
  }
  .dashboard-documents__filters {
    .icon-search {
      position: absolute;
      right: 10px;
      top: 20px;
      height: 20px;
      width: 20px;
      font-size: 20px;
      &:before {
        color: #B9C6D9;
      }
    }
  }
  .dashboard-documents__filters-desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    & > div {
      width: 16%;
    }
    & > div:nth-child(2) {
      width: 18%;
    }
    & > div:nth-child(3) {
      width: 18%;
    }
    & > div:first-child {
      width: 30%;
    }
    .input {
      margin: 0;
      width: 100%;
    }
    .calendar-label {
      display: none;
    }
    .date-picker {
      left: -320px;
      border-radius: 6px 0 0 6px;
    }
    .date-time-picker-table {
      left: 0;
    }
    .select-wrap {
      position: relative;
    }
    .select__data {
      width: 300px;
      left: 0;
      transform: none;
      border-radius: 0 6px 6px 6px;
      top: 50px;
    }
    .daterangepicker {
      right: 0;
    }
  }
  .dashboard-documents__filters-desktop {
    @include lg {
      display: none;
    }
  }
  .filter-documents-open {
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #7443AD;
      text-decoration: underline;
      cursor: pointer;
      font-size: 16px;
    }
  }
  .active-filters-wrapper.filter-documents-open {
    justify-content: space-between;
  }
  .dashboard-documents__filters-mobile {
    display: none;
    @include lg {
      display: block;
      .multiselect {
        width: 100%;
      }
      .multiselect__data {
        width: 100%;
      }
      .select, .select__data {
        width: 100%;
      }
      .select-wrap {
        position: relative;
      }
      & > div {
        & > div {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
  .active-filters {
    color: #949DA9 !important;
    text-decoration: none !important;
  }

  .sort-documents-modal {
    padding-top: 30px;
    & > div {
      font-size: 14px;
      display: flex;
      & > div:first-child {
        width: 50%;
        text-align: left;
        color: black;
      }
      & > div:last-child {
        width: 50%;
        & > div {
          padding-left: 20px;
          text-align: left;
          color: #6831A5;
          margin-bottom: 10px;
          position: relative;
          & > span {
            position: absolute;
            left: 10px;
            svg {
              width: 8px;
            }
          }
        }
      }
    }
  }
  .funnel-ico {
    width: 12px;
    height: 12px;
  }
  .funnel-wrapper {
    display: flex;
    align-items: center;
  }

  .dashboard-documents__modal-bulk {
    text-align: left;
    font-size: 14px;
    .dashboard-documents__modal-bulk-heading {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 18px;
      color: black;
    }
    .dashboard-documents__modal-bulk-list {
      margin: 1ex 0;
      li {
        list-style-type: none;
        line-height: 1.5;
        padding-left: 30px;
        position: relative;
        a {
          color: #772cb3;
          text-decoration: underline;
          .dashboard-documents__modal-bulk-list-link-icon {
            width: 10px;
            transform: rotate(140deg);
          }
          & > svg {
            vertical-align: sub;
            position: absolute;
            left: 0;
          }
        }
      }
    }
    .dashboard-documents__modal-bulk-disabled {
      background: #FFFAD0;
      padding: 20px;
      border-radius: 1ex;
      border: 1px solid #F4EEB7;
      color: #8C5729;
      line-height: 20px;
      font-size: 15px;
    }
  }
  .modal-move {
    .select, .select__data {
      width: 290px;
    }
  }
  .dashboard-documents__modal-bulk-list-link-icon {
    svg {
      width: 10px;
      transform: rotate(140deg);
    }
  }
  .dashboard-datepicker {
    cursor: pointer;
    position: relative;
    width: 310px;
    outline: none;
    border: 1px solid #B1BBCB;
    border-radius: 6px;
    height: 56px;
    font-size: 16px;
    letter-spacing: -0.03px;
    line-height: 54px;
    font-weight: 300;
    .dashboard-datepicker-icon {
      pointer-events: none;
    }
    &-icon {
      background: url(/images/calendar.svg);
      width: 20px;
      position: absolute;
      right: 20px;
      background-size: contain;
      top: -2px;
      background-repeat: no-repeat;
      height: 100%;
      background-position: center center;
      z-index: 99;
    }
    &-clear-icon {
      background: url(/images/cross.svg);
      width: 15px;
      position: absolute;
      right: 20px;
      background-size: contain;
      background-repeat: no-repeat;
      height: 100%;
      background-position: center center;
      z-index: 99;
    }
    .row {
      margin: 0;
    }
    .in-range {
      background-color: #712cbd26 !important;
    }
    .start-date, .end-date {
      background-color: #712cbd !important;
    }
    .vue-daterange-picker {
      height: 56px;
      width: 100%;
      & > div {
        border: 0;
        width: 100%;
        background: none;
        padding: 0 50px 0 20px;
        color: gray;
        font-weight: 400;
        font-size: 16px;
      }
      .daterangepicker {
        top: 60px;
        right: -1px;
        box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
      }
      .calendars-container {
        width: 100%;
        background: white;
        @include md {
          & > div:first-child {
            width: 100%;
            max-width: unset;
            padding: 10px;
          }
          & > div:nth-child(2) {
            display: none;
          }
        }
      }
    }
    .form-control {
      background: white !important;
      border-radius: 6px;
    }
  }
  .dashboard-datepicker-open {
    border-color: #5E239E;
    border-radius: 6px 6px 0 0;
    .daterangepicker {
      margin: 0;
      top: 54px !important;
      border: 1px solid #5E239E !important;
      border-radius: 6px 0 6px 6px;
      overflow: hidden;
      &:before, &:after {
        display: none;
      }
      @include md {
        width: calc(100% + 2px) !important;
        border-radius: 0 0 6px 6px;
      }
    }
  }
  .dashboard-mb-20 {
    margin-bottom: 20px;
  }

  body #app .dashboard-edit-button {
    button {
      padding: 0;
      width: fit-content;
      height: fit-content;
      background: none;
    }
  }
  body #app .dashboard-save-button {
    width: 100%;
    margin-top: 10px;
    button {
      width: 100%;
      height: 50px;
    }
  }

  .document-table-header {
    position: relative;
    display: grid;
    grid-template-columns: 40px 1fr 20% 15% 15% 40px;
    grid-template-rows: auto;
    font-size: 15px;
    background: #F8FBFF;
    border-radius: 6px 6px 0 0;
    border: 1px solid #E1E4E8;
    height: 55px;
    .document-table-item__checkbox-mobile-label {
      display: none;
    }
    & > div:first-child {
      .checkbox-wrap {
        height: unset;
        margin-bottom: 0;
        .checkbox {
          margin: -4px auto;
          z-index: 0;
          position: relative;
          top: 0;
        }
      }
    }
    & > div {
      padding: 0 15px 0 15px;
      display: flex;
      align-items: center;
    }

    @include md {

      .all-checked-label {
        width: 400px;
        display: flex !important;
        align-items: center;
        justify-content: flex-start;
        .checkbox-wrap {
          width: 30px;
        }
      }
      .document-table-item__checkbox {
        width: 200px;
      }
      .document-table-item__name-header, .document-table-item__state-header, .document-table-item__created-header, .document-table-item__changed-header, .document-table-item__actions-header {
        display: none;
      }
      .document-table-item__checkbox-mobile-label {
        display: block;
      }
    }
  }
  #app .document-table-item__select-filters-mobile {
    display: none;
    position: absolute;
    top: 18px;
    right: 0;
    text-decoration: underline;
    @include md {
      display: block;
    }
  }
  .document-table-item__arrow-up {
    transform: rotate(-180deg);
    width: 12px;
    height: 8px;
    display: flex;
    position: relative;
    left: 2px;
  }
  .document-table-item__arrow-down {
    width: 12px;
    height: 8px;
    display: flex;
    position: relative;
    left: 2px;
  }
  .document-table-empty {
    width: 100%;
    height: 200px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: black;
    font-weight: bold;
    border: 1px solid #E1E4E8;
    border-top: 0;
    border-radius: 0 0 6px 6px;
  }
  .document-table-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .document-table-header > div > span {
    cursor: pointer;
    text-decoration: underline;
  }

 .document-table-item:last-child .document-table-row {
   border-radius: 0 0 6px 6px;
  }

  .document-table-row__name-created-secondary {
    display: none;
  }

  .document-table-row {
    width: 100%;
    display: grid;
    grid-template-columns: 40px 1fr 20% 15% 15% 40px;
    grid-template-rows: auto;
    background: #F8FBFF;
    border: 1px solid #E1E4E8;
    border-top: 0;
    font-size: 17px;
    position: relative;

    & > div:first-child {
      align-items: start;
      .checkbox-wrap {
        height: unset;
        .checkbox {
          margin: -4px auto;
          z-index: 0;
        }
      }
    }
    & > div:nth-child(2) {
      display: block;
    }
    & > div {
      padding: 15px;
      display: flex;
      align-items: center;
      color: black;
    }
    .document-table-row__name-heading {
      color: black;
      padding-bottom: 5px;
      cursor: pointer;
      a.link-unstyled {
        // reset css from App.vue
        text-align: left;
        text-decoration: none;
        font-size: unset;
        font-weight: unset;
        transition: unset;
        letter-spacing: unset;
        color: unset;
      }
    }
    .document-table-row__name-attachments {
      color: #B3C2D6;
      font-size: 15px;
      svg {
        width: 15px;
        height: 15px;
        path {
          fill: #B3C2D6;
        }
      }
    }
    .document-table-row__name-author, .document-table-row__name-signers, .document-table-row__name-tags {
      font-size: 15px;
      color: #75797E;
      padding-bottom: 5px;
    }
    .document-table-row__name-tags .document-table-row__name-tag {
      color: #6DECAF;
      border: 1px solid #6DECAF;
      border-radius: 5px;
      padding: 2px;
      margin-right: 2px;
    }
    .document-table-row__actions {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .document-table-row__actions-dots {
      transform: rotate(90deg);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 25px;
      cursor: pointer;
      color: #7C8693;
    }

    @include md {
      display: block;
      .document-table-item__checkbox {
        position: absolute;
        top: 4px;
        z-index: 1;
      }
      .document-table-row__name {
        padding-bottom: 0;
        margin-bottom: 0;
        .document-table-row__name-heading {
          padding-left: 30px;
          padding-right: 30px;
          margin-bottom: 10px;
        }
        .document-table-row__name-author, .document-table-row__name-signers {
          margin-bottom: 10px;
        }
      }
      .document-table-row__created {
        display: none;
      }
      .document-table-row__changed {
        display: none;
      }
      .document-table-row__state {
        width: fit-content;
        div {
          padding: 10px;
          font-size: 14px;
        }
      }
      .document-table-row__actions {
        position: absolute;
        top: 0;
        right: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
      }
      .document-table-item__actions-item {
        top: 50px;
      }
      .document-table-row__name-created-secondary {
        font-size: 15px;
        display: block;
        color: #75797E;
        padding-bottom: 5px;
      }
    }
  }

  .document-table-item__state-item {
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 6px;
    font-weight: bold;
    padding: 5px;
  }
  .item-state-text {
    background: #e6e6e6;
    color: black;
  }
  .item-state-red {
    background: #FEE2E2;
    color: #D9403A;
  }
  .item-state-yellow {
    background: #FFF0B5;
    color: #A5803E;
  }
  .item-state-purple {
    background: #F4EAFE;
    color: #7C49B1;

  }
  .item-state-green {
    background: #6DECAF;
    color: #297F53;
  }
  .item-state-hidden {
    display: none;
  }

 .document-table-row__name-signers {
   display: flex;
   & > div {
     display: flex;
     align-items: center;
   }
   & > div:first-child {
     margin-right: 10px;
   }
 }
 .document-table-row__name-signers-ruler {
   opacity: 1;
 }
 .document-table-row__name-signers-items {
   flex: 1;
   & > div {
     width: fit-content;
   }
 }

 .document-table-item__name {
   position: relative;
   overflow: hidden;
 }

 .document-table-row__name-signers-ruler {
   //opacity: 0;
   //position: absolute;
   width: calc(100% - 30px);
   .document-table-row__name-signers-items-inner {
     width: 10000px;
   }
 }
 .document-table-row__name-signers-items-inner {
   display: flex;
   width: 10000px !important;
   align-items: center;
   & > div {
     padding-right: 7px;
     display: flex;
     align-items: center;
     justify-content: center;
   }
 }
 .document-table-row__name-signers-trimmed {
   border: 1px solid #C5D0E0;
   border-radius: 20px;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0 4px 0 4px;
   color: #9BA2AE;
   cursor: pointer;
 }
 .document-table-row__name-signers-unsigned {
   color: #B3C2D6;
   margin-right: 2px;
   display: flex;
   svg {
     width: 8px;
     height: 8px;
   }
 }
 .document-table-row__name-signers-signed {
   color: #B3C2D6;
   margin-right: 2px;
   display: flex;
   svg {
     width: 8px;
     height: 8px;
   }
 }

 .document-table-item__actions-item {
   position: absolute;
   background: white;
   padding: 20px !important;
   display: flex;
   border-radius: 6px;
   flex-direction: column;
   right: 0;
   box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
   top: 75px;
   align-items: self-end !important;
   z-index: 99;
   div {
     margin: 0 20px 20px;
     width: 90%;
     cursor: pointer;
     text-align: left;
     display: flex;
     align-items: center;
     color: #7443AD;
     text-decoration: underline;
     a {
      color: #7443AD;
      text-decoration: underline;
      font-weight: normal;
     }
     img {
       width: 18px;
       height: 18px;
       margin-right: 10px;
     }
   }
   div:last-child {
     margin-bottom: 0;
   }
 }
 .separator-hr {
   height: 1px;
   width: 100%;
   border-top: 1px solid #E9EAEE;
 }
</style>
