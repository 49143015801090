import Authentication from '@verification/views/Authentication';

const routes = [
  {
    path: '/auth/:authToken',
    name: 'authentication',
    component: Authentication,
  },
  {
    path: '/auth/bank-identity/:authToken',
    name: 'authentication-bank-identity',
    component: () =>
      import(/* webpackChunkName: "auth-bank-identity" */ '@verification/views/BankIdentity'),
  },
  {
    path: '/auth/questionnaire/:authToken',
    name: 'authentication-questionnaire',
    component: () =>
      import(/* webpackChunkName: "auth-questionnaire" */ '@verification/views/Questionnaire'),
  },
  {
    path: '/auth/liveliness/:authToken',
    name: 'authentication-liveliness',
    component: () =>
      import(/* webpackChunkName: "auth-liveliness" */ '@verification/views/Liveliness'),
  },
  {
    path: '/auth/identity/:authToken',
    name: 'authentication-identity',
    component: () => import(/* webpackChunkName: "auth-identity" */ '@verification/views/Identity'),
  },
  {
    path: '/auth/sms/:authToken',
    name: 'authentication-sms',
    component: () => import(/* webpackChunkName: "auth-sms" */ '@verification/views/Sms'),
  },
  {
    path: '/auth/micropayment/:authToken',
    name: 'authentication-micropayment',
    component: () =>
      import(/* webpackChunkName: "auth-micropayment" */ '@verification/views/Micropayment'),
  },
  {
    path: '/auth/summary/:authToken',
    name: 'authentication-summary',
    component: () => import(/* webpackChunkName: "auth-summary" */ '@verification/views/Summary'),
  },
  {
    path: '/auth/done/:authToken',
    name: 'authentication-done',
    component: () => import(/* webpackChunkName: "auth-done" */ '@verification/views/Done'),
  },
];

routes.forEach((route) => {
  route.meta = {
    authRequired: false,
    layout: () =>
      import(/* webpackChunkName: "verification-layout" */ '@/layouts/VerificationLayout'),
  };
});

export default routes;
