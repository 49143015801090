<template>
  <v-container style="margin-top: -2rem">
    <v-row justify="center">
      <v-col class="pa-0 pt-0 pt-md-3" cols="12">
        <ContractTitleEdit />
      </v-col>

      <v-col cols="12">
        <v-row v-if="isMainDocument" align="center" justify="center">
          <v-col cols="auto" class="pa-0">
            <v-btn
              color="#5F6369"
              class="font-weight-medium"
              text
              style="font-size: 1rem; text-decoration: underline"
              @click="showDocumentNumberField = !showDocumentNumberField"
            >
              {{ $t('contract.special_settings') }}
            </v-btn>
          </v-col>

          <v-col cols="auto" class="pa-0">
            <v-row align="center" justify="center" no-gutters>
              <v-col cols="auto" class="pa-0 pt-1">
                <span style="color: #8a919e"> {{ $t('contract.create.locale') }}: </span>
              </v-col>

              <v-col cols="auto" class="pa-0 pt-1">
                <LanguageToggle
                  :locale="contract.locale"
                  :border="false"
                  transparent
                  @onLocaleChanged="saveUpdatedContract({ locale: $event })"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-expand-transition>
        <v-col v-if="showDocumentNumberField" cols="12">
          <v-row align="center" justify="center">
            <v-col class="pb-0" cols="12">
              <v-text-field
                autofocus
                type="text"
                name="contract-number"
                class="mx-auto"
                v-model="contractNumber"
                :label="$t('contract.enter_number')"
                clearable
                outlined
                style="max-width: 25rem"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="pb-0" cols="12">
              <v-textarea
                class="mx-auto"
                v-model="note"
                :label="$t('contract.reject.note')"
                clearable
                outlined
                style="max-width: 25rem"
                hide-details
              ></v-textarea>
            </v-col>

            <v-col class="pb-0" cols="12">
              <v-text-field
                type="text"
                class="mx-auto"
                v-model="noteLink"
                :label="$t('general.url_link')"
                clearable
                outlined
                style="max-width: 25rem"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-expand-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ContractTitleEdit from '@contract/components/ContractTitleEdit';
import LanguageToggle from '@/plugins/i18n/components/LanguageToggle';

export default {
  name: 'ContractCreateHeader',
  components: {
    LanguageToggle,
    ContractTitleEdit,
  },
  data() {
    return {
      contractNumberDialog: false,
      showDocumentNumberField: false,
      note: '',
      noteLink: '',
    };
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
    }),
    contractNumber: {
      get() {
        return this.contract.number;
      },
      set(value) {
        this.updateContractNumber(value);
      },
    },
    contractLocale() {
      return this.contract?.locale || this.$i18n.fallbackLocale;
    },
    isMainDocument() {
      return !this.contract.is_attachment;
    },
    contractNote() {
      let noteLink = this.noteLink;
      const httpPrefix = 'http://';
      const httpsPrefix = 'https://';

      if (
        noteLink !== '' &&
        noteLink.substr(0, httpPrefix.length) !== httpPrefix &&
        noteLink.substr(0, httpsPrefix.length) !== httpsPrefix
      ) {
        noteLink = httpPrefix + noteLink;
      }

      return (
        `${this.note}\n\n${
          noteLink ? `[${this.$t('general.open_attached_link')}](${noteLink})` : ''
        }` || null
      );
    },
  },
  watch: {
    contractNote: {
      handler: function (value) {
        this.updateContractNote(value);
      },
    },
  },
  mounted() {
    const noteData = this.contract?.note?.split('\n\n');

    if (noteData) {
      this.note = noteData[0];
      this.noteLink = noteData[1]
        ?.replace(`[${this.$t('general.open_attached_link')}](`, '')
        ?.slice(0, -1);
    }
  },
  methods: {
    ...mapActions({
      updateContractNote: 'updateContractNote',
      updateContractNumber: 'updateContractNumber',
      updateContractLanguage: 'updateContractLanguage',
    }),
    saveUpdatedContract(e) {
      this.contractNumberDialog = false;
      this.updateContractLanguage(e.locale);
    },
  },
};
</script>

<style lang="scss" scoped></style>
