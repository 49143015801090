<template>
  <v-dialog
    v-model="show"
    :max-width="maxWidth"
    :persistent="isPersistent"
    :fullscreen="isFullscreen || isFullscreenOnMobile"
    @keydown.esc="closeModal"
  >
    <template v-slot:default>
      <v-card class="signi-modal green-corner">
        <v-toolbar dense flat>
          <v-spacer></v-spacer>
          <v-btn v-if="!isPersistent" icon @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card class="pa-5 pa-md-12 pt-0 pt-md-0" flat>
          <v-card-title v-if="hasTitleSlotContent">
            <h1 class="mx-auto signi-modal__title">
              <slot name="title" />
            </h1>
          </v-card-title>

          <v-card-text v-if="hasContentSlotContent">
            <slot name="default" />
          </v-card-text>
        </v-card>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: 'SigniModal',
  props: {
    value: Boolean,
    fullscreen: {
      type: Boolean,
      default: false
    },
    fullscreenOnMobile: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: true
    },
    maxWidth: {
      type: String,
      default: '700px'
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    hasTitleSlotContent () {
      return !!this.$slots.title
    },
    hasContentSlotContent () {
      return !!this.$slots.default
    },
    isFullscreen () {
      return this.fullscreen
    },
    isFullscreenOnMobile () {
      return this.fullscreenOnMobile && this.$vuetify.breakpoint.mobile
    },
    isPersistent () {
      return !this.persistent
    }
  },
  methods: {
    closeModal () {
      this.$emit('close')
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.signi-modal {
  &__title {
    margin: 0;
    text-align: left;
    word-break: normal;
    line-height: 120%;
    font-weight: bolder;
  }
}
</style>
