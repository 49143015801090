import { authorizedApiRequest } from '@/services/ApiService';

export const cancelVerification = ({ contactId, verificationTypes }) => {
  if (0 === verificationTypes.length) {
    throw new Error('Verification types cannot be empty');
  }

  return authorizedApiRequest({
    method: 'PATCH',
    endpoint: `/api/v1/contacts/${contactId}/invalidateAuths`,
    data: verificationTypes,
  });
};
