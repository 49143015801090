<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ico-N/ico-24-poslat-dokument.gray</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-24-poslat-dokument.gray" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M4,15.6796741 C4,18.4027325 4,19.9412634 4,20.2952669 C4,20.8262722 4.40545259,21 4.87777523,21 C5.35009787,21 14.8136914,21 15.3302414,21 C15.8467914,21 16,20.6487424 16,20.0652492 C16,19.4817559 16,4.23816732 16,3.77908225 C16,3.31999718 15.7227855,3 15.1761864,3 C14.8117869,3 12.5133758,3 8.28095297,3 L4,7.64479795 L4,8.78026314" id="Path-10" stroke="#B4C1D6" stroke-width="2" fill-rule="nonzero" transform="translate(10.000000, 12.000000) scale(-1, 1) translate(-10.000000, -12.000000) "></path>
      <path d="M11,9 C13.5522135,9 15.026748,9 15.4236035,9 C16.0188867,9 15.9999321,8.29587898 15.9999321,7.37479044 C15.9999321,6.76073141 15.9999321,5.63580127 15.9999321,4" id="Path-16" stroke="#B4C1D6" stroke-width="2" fill-rule="nonzero" transform="translate(13.500000, 6.500000) scale(-1, 1) translate(-13.500000, -6.500000) "></path>
      <path d="M8.87648774,13 L21.1466187,13" id="Path-60" stroke="#B4C1D6" stroke-width="2"></path>
      <polyline id="Path-68" stroke="#B4C1D6" stroke-width="2" points="18 10 21 13 18 16"></polyline>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IcoSendDocument",
};
</script>
