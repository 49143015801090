import * as types from './mutation_types';

export default {
  [types.SET_APP_NEWS](state, messages) {
    state.messages = messages;
  },
  [types.SET_APP_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications;
  },
};
