const staticRoutes = [
  {
    path: '/contract/expiredLink',
    name: 'contractExpiredLink',
    component: () =>
      import(
        /* webpackChunkName: "contract-expired-link" */ '@/views/contract/ContractExpiredLink'
      ),
  },
  {
    path: '/error/not-found',
    alias: '/404',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound'),
  },
  {
    path: '/success/sign/sms',
    alias: '/sms-sign-success',
    name: 'success-sign-sms',
    component: () => import(/* webpackChunkName: "success-sign-sms" */ '@/views/SuccessSignSms'),
  },
];

staticRoutes.forEach((route) => {
  route.meta = {
    authRequired: false,
    layout: () => import(/* webpackChunkName: "static-layout" */ '@/layouts/StaticLayout'),
  };
});

export default staticRoutes;
