<template lang="html">
    <svg width="7px" height="12px" viewBox="0 0 7 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Desktop---vícepráce" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
            <g id="SYN001---DSTP---Vzory-smluv---kategorie" transform="translate(-496.000000, -135.000000)" fill-rule="nonzero" stroke="#5E239E" stroke-width="2">
                <g id="Group-8" transform="translate(497.000000, 131.000000)">
                    <g id="arr/arr-10-2/down" transform="translate(3.000000, 10.000000) rotate(-270.000000) translate(-3.000000, -10.000000) translate(-2.000000, 7.000000)">
                        <polyline points="0 1 4.753258 5.753258 9.506516 1"></polyline>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'IcoBack'
    }
</script>
