<template lang="html">
  <ContractLayout
    :background-image="contract.settings && contract.settings.background ? contract.settings.background : `/images/contract-desktop-bg.20200622.jpg`"
    :contract="contract"
    :is-loading="isLoading"
  >
    <template v-slot:content>
      <div class="contract">
        <div class="contract__data contract__data--main">
          <div class="contract__logo">
            <Logo :logo="contract.settings ? contract.settings.logo : null" :white="false" />
          </div>

          <template v-if="!loaded">
            <Loader class="register-loader" style="text-align: center; padding-left: 0" />
          </template>

          <template v-if="loaded">
            <h1 class="contract-title">{{ $t('contract.create.header') }}</h1>

            <div class="contract__view contract__veiw--data">
              <tabs style="margin-top: 0;" v-bind:class="{'select-clicked': selectClicked}" @changed="tabChanged" :options="tabsOption">
                <tab id="nature" :name="$t('workspace.type.fo')">
                  <form @submit.prevent="" @change="changedState = true">
                    <Input class="workspace-input" :validation="!$v.user_private.firstname.$error" v-model="user_private.firstname" :label="`${$t('general.name')}*`" type="text" />
                    <Input class="workspace-input" :validation="!$v.user_private.lastname.$error" v-model="user_private.lastname" :label="`${$t('general.surname')}*`" type="text" />

                    <div class="workspace-input mt-6 mx-auto" style="max-width: 320px">
                      <div
                        class="birth-picker__title text-left">
                        {{ $t('general.birth_date')}}
                      </div>
                      <SBirthDayPicker
                        v-model="user_private.number"
                        class="mx-auto mb-8"
                        backgroundColor="white"
                        :label="''"
                        :locale="$i18n.locale"
                      />
                    </div>

                    <Input class="workspace-input" v-model="user_private.organization_street" :label="$t('company.street_and_number')" type="text" />
                    <Input class="workspace-input" v-model="user_private.organization_city" :label="$t('company.city')" type="text" />
                    <Input class="workspace-input" v-model="user_private.organization_zip" :label="$t('company.zip')" type="text" />
                  </form>
                </tab>
                <tab id="citizen" :name="$t('workspace.type.osvc')">
                  <form @submit.prevent="" @change="changedState = true">
                    <Input class="workspace-input" :validation="!$v.citizen.firstname.$error" v-model="citizen.firstname" :label="`${$t('general.name')}*`" type="text" />
                    <Input class="workspace-input" :validation="!$v.citizen.lastname.$error" v-model="citizen.lastname" :label="`${$t('general.surname')}*`" type="text" />
                    <InputICO
                      class="identity-main-data"
                      :label="`${$t('company.ico')}*`"
                      placeholder=""
                      :model="citizen"
                      @processExternalCompany="processExternalCompany(citizen, $event)"
                      :unauthorizedToken="$route.params.hash"
                      :color="contract.settings.primary_color || 'primary'"
                    />
                    <Input class="workspace-input" v-model="citizen.organization_dic" :label="$t('company.dic')" type="text" />
                    <Input class="workspace-input" v-model="citizen.organization_street" :label="$t('company.street_and_number')" type="text" />
                    <Input class="workspace-input" v-model="citizen.organization_city" :label="$t('company.city')" type="text" />
                    <Input class="workspace-input" v-model="citizen.organization_zip" :label="$t('company.zip')" type="text" />
                  </form>
                </tab>
                <tab id="legal" :name="$t('workspace.type.po')">
                  <form @submit.prevent="" @change="changedState = true">
                    <Input class="workspace-input" :validation="!$v.legal.firstname.$error" v-model="legal.firstname" :label="`${$t('general.name')}*`" type="text" />
                    <Input class="workspace-input" :validation="!$v.legal.lastname.$error" v-model="legal.lastname" :label="`${$t('general.surname')}*`" type="text" />
                    <Input :validation="!$v.legal.organization_name.$error" class="workspace-input" v-model="legal.organization_name" :label="`${$t('company.title')}*`" type="text" />
                    <InputICO
                      class="identity-main-data"
                      :label="`${$t('company.ico')}*`"
                      placeholder=""
                      :model="legal"
                      @processExternalCompany="processExternalCompany(legal, $event)"
                      :unauthorizedToken="$route.params.hash"
                      :color="contract.settings.primary_color || 'primary'"
                    />
                    <Input class="workspace-input" v-model="legal.organization_dic" :label="$t('company.dic')" type="text" />
                    <Input class="workspace-input" v-model="legal.organization_street" :label="$t('company.street_and_number')" type="text" />
                    <Input class="workspace-input" v-model="legal.organization_city" :label="$t('company.city')" type="text" />
                    <Input class="workspace-input" v-model="legal.organization_zip" :label="$t('company.zip')" type="text" />
                  </form>
                </tab>
              </tabs>

              <v-row align="center" justify="center" no-gutters>
                <v-col cols="12" class="text-center pt-0 pb-10">
                  <v-btn
                    :color="contract.settings.primary_color || 'primary'"
                    :loading="loader"
                    max-width="15rem"
                    width="100%"
                    x-large
                    dark
                    @click="saveIdentity()"
                  >
                    {{ $t('contract.save_header') }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </template>
        </div>
      </div>
    </template>
  </ContractLayout>
</template>


<script>
import ContractLayout from "@/views/contract/components/ContractLayout";
import { required, minLength } from 'vuelidate/lib/validators';
import { ContractService } from '@/services/ContractService.js';
import { mapGetters } from 'vuex';
import Loader from '@/components/Loader.vue';
import Tab from '@/components/Tab.vue';
import Tabs from '@/components/Tabs.vue';
import InputICO from '@/components/InputICO.vue';
import SBirthDayPicker from "@/common/components/SBirthDayPicker";

export default {
  name: 'contractData',
  components: {
    SBirthDayPicker,
    ContractLayout,
    Tabs,
    Tab,
    Loader,
    InputICO,
  },
  data() {
    return {
      loaded: false,
      loader: false,
      type: 'nature',
      errorMessage: [],
      legal: {
        email: '',
        firstname: '',
        lastname: '',
        organization_name: '',
        organization_ico: '',
        organization_dic: '',
        organization_street: '',
        organization_city: '',
        organization_zip: '',
        type: 'legal',
      },

      user_private: {
        email: '',
        firstname: '',
        lastname: '',
        number: '',
        organization_street: '',
        organization_city: '',
        organization_zip: '',
        type: 'nature',
      },

      citizen: {
        email: '',
        firstname: '',
        lastname: '',
        organization_ico: '',
        organization_dic: '',
        organization_street: '',
        organization_city: '',
        organization_zip: '',
        type: 'citizen',
      },
      selectedTab: '',
      tabsOption: {
        defaultTabHash: 'fo'
      },
      workspaceLoader: false,
      searchedValue: '',
      selectClicked: false,
      validForm: true,
      changedState: false
    }
  },
  validations: {
    user_private: {
      firstname: {
        required,
        minLength: minLength(2),
      },
      lastname: {
        required,
        minLength: minLength(2),
      }
    },
    citizen: {
      firstname: {
        required,
        minLength: minLength(2)
      },
      lastname: {
        required,
        minLength: minLength(2)
      },
      organization_ico: {
        required,
        minLength: minLength(6),
      },
    },
    legal: {
      firstname: {
        required,
        minLength: minLength(2)
      },
      lastname: {
        required,
        minLength: minLength(2)
      },
      organization_name: {
        required,
        minLength: minLength(2)
      },
      organization_ico: {
        required,
        minLength: minLength(6),
      },
    }
  },
  created() {
    ContractService.contractFromApiToken({
      component: this,
      onSuccess: (response) => {
        this.$store.commit('setContract', response);

        this.loaded = true;

        this.tabsOption.defaultTabHash = this.contract.sign_identity.type;
        this.selectedTab = this.contract.sign_identity.type;

        this.type = this.contract.sign_identity.type;
        window.location.hash = this.type;

        this.setFormData();
      },
    });
  },
  computed: {
    ...mapGetters([
      'contract'
    ]),
    isLoading() {
      return !this.loaded;
    }
  },
  methods: {

    processExternalCompany(editedWorkspace, response) {
      editedWorkspace.organization_city = response.city;
      editedWorkspace.organization_street = response.street;

      if (response.number) {
        if (response.street) {
          editedWorkspace.organization_street += " ";
        }
        editedWorkspace.organization_street += response.number;
      }

      editedWorkspace.organization_dic = response.dic;
      editedWorkspace.organization_ico = response.ico;
      editedWorkspace.organization_zip = response.zip;
      if (editedWorkspace === this.legal) {
        editedWorkspace.organization_name = response.organization_name;
      }
    },

    tabChangedName(tabName) {
      this.errorMessage = [];
      this.selectedTab = tabName;
    },

    tabChanged (selectedTab) {
      this.errorMessage = [];
      this.selectedTab = selectedTab.tab.name;
      this.type = selectedTab.tab.id;
    },

    setFormData() {

      switch( this.type ) {
        case 'nature':
          this.type = 'nature';
          this.user_private = this.contract.sign_identity;
          this.citizen.email = this.contract.sign_identity.email;
          this.legal.email = this.contract.sign_identity.email;
          break;

        case 'citizen':
          this.type = 'citizen';
          this.citizen = this.contract.sign_identity;
          this.user_private.email = this.contract.sign_identity.email;
          this.legal.email = this.contract.sign_identity.email;
          break;

        case 'legal':
          this.type = 'legal';
          this.legal = this.contract.sign_identity;
          this.user_private.email = this.contract.sign_identity.email;
          this.citizen.email = this.contract.sign_identity.email;
          break;
      }

      this.changedState = false
    },

    getBirthDate(date) {
      var month = date.getMonth() + 1;
      this.user_private.number = date.getFullYear() +"-"+ month + "-" + date.getDate() + " 00:00:00";
    },

    saveIdentity() {
      switch (this.type) {
        case 'nature':
          return this.editCounterparty(this.$v.user_private, this.user_private);
        case 'citizen':
          return this.editCounterparty(this.$v.citizen, this.citizen);
        case 'legal':
          return this.editCounterparty(this.$v.legal, this.legal);
      }
    },
    editCounterparty(validation, identity)  {
      validation.$touch();
      if (validation.$error) {
        this.errorMessage.push(this.$t('general.fill_required'));
        this.$notification.error(this.$t('general.fill_required'));;
        return;
      }

      if (!this.changedState) {
        return this.$router.push({
          name: 'contractView',
          params: {
            token: this.$route.params.hash
          }
        })
      }

      this.confirmBeforeEdit(identity, () => {
        ContractService.editCounterpartyUnregistered({...identity}, this.$route.params.hash, this.contract.contract_id, this.contract.sign_identity.id).then(() => {
          this.$router.push({name: 'contractView', params: { token: this.$route.params.hash }});
        }, error => {
          this.$notification.error(this.$t('contract.errors.general_save'));
        });
      });
    },
    confirmBeforeEdit(user, onSuccess) {
      const request = {
        ...user,
        isDryRun: true,
      };
      const vm = this;
      ContractService.editCounterpartyUnregistered(request, this.$route.params.hash, this.contract.contract_id, this.contract.sign_identity.id).then(
        (response) => {
          if (response.has_removable_signatures) {
            vm.$dialog.confirm(vm.$t('contract.edit_confirm_delete')).then(onSuccess);
          } else {
            onSuccess();
          }
        },
        error => {
          this.$notification.error(this.$t('general.data_not_loaded'));
        }
      );
    },
  }
}
</script>


<style lang="scss">
.contact-type{
  font-size: 16px;
  color: #616161;
  letter-spacing: -0.03px;
  font-weight: 300;
  margin-right: 5px;
}
.select-type{
  position: absolute;
  left: 0px;
  width: 100%;
  height: 60px;
  top: 0px;
  border-bottom: solid 1px #E8EDF5;
}

.select-type__box{
  position: relative;
  width: 270px;
  margin-left: auto;
  top: 25px;
  margin-right: auto;
}

.contract__view {
  .tabs-component {
    margin-bottom: 1rem;
  }

  .tabs-component-panels {
    box-shadow: none !important;
    background: none !important;
  }
}

.contract__veiw--data .identity-main-data {
  background: none;
  text-align: center;
  margin: 15px -20px;
  .workspace-input {
    width: 100%;
  }
  .country-radio {
    display: block;
    margin: 15px auto 0;
    .radio-wrap {
      width: 48%;
      text-align: center;
      @include md {
        width: 45%;
      }
    }
  }
}
</style>
