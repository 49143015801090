import { submitPolitical } from '@verification/services/authService';

export const namespaced = true;

export const state = {
  political: undefined,
};

export const mutations = {
  setPolitical(state, payload) {
    state.political = payload;
  },
};

export const actions = {
  setPolitical: (context, payload) => {
    context.commit('setPolitical', payload);
  },
  submitPolitical: (context, payload) => {
    return submitPolitical(payload.authToken, payload.data).then(
      (resp) => {
        context.commit('setPolitical', resp);
      },
      (err) => {
        throw err;
      },
    );
  },
};

export const getters = {
  political: (state) => {
    return state.political;
  },
  isPoliticalDone: (state) => {
    return 'submitted' === state.political?.status || 'ok' === state.political?.status;
  },
  isWithoutPoliticalVerification: (state) => {
    return 'undefined' === typeof state.political;
  },
};
