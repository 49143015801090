import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import routes from '@/router/routes/routes';
import NProgress from 'nprogress';
import { isRoutePublicPage } from '@/services/helpers';

import accessRoutes from '@access/routes';
import accountRoutes from '@account/routes';
import apiRoutes from '@apiDoc/routes';
import contactsRoutes from '@contacts/routes';
import contractRoutes from '@contract/routes';
import publicSignRoutes from '@publicSign/routes';
import subscriptionRoutes from '@subscription/routes';
import verificationRoutes from '@verification/routes';
import workspaceRoutes from '@workspace/routes';
import staticRoutes from '@/router/routes/staticRoutes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...accessRoutes,
    ...accountRoutes,
    ...apiRoutes,
    ...contactsRoutes,
    ...contractRoutes,
    ...publicSignRoutes,
    ...routes,
    ...subscriptionRoutes,
    ...verificationRoutes,
    ...workspaceRoutes,
    ...staticRoutes,
    {
      path: '*',
      redirect: '/',
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  NProgress.start();

  const token = localStorage.getItem('token');
  const authRequired =
    'undefined' === typeof to.meta.authRequired
      ? !isRoutePublicPage(to.name)
      : to.meta.authRequired;

  if (authRequired) {
    if (null === token) {
      next('/login?redirect_to');
    } else {
      store.dispatch('getUserProfile', to.params.workspace_id);
      next();
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
