<template>
  <v-alert
    class="mx-auto px-3 py-1"
    :color="updatedNotification.background"
    :style="`border: 1px solid ${updatedNotification.border}`"
    max-width="845px"
    width="100%"
    dense
    dark
  >
    <template v-slot:prepend>
      <v-icon
        class="pr-2 py-1 align-self-start"
        :style="`color: ${updatedNotification.colors.title}`"
      >
        {{ updatedNotification.icon }}
      </v-icon>
    </template>

    <div
      v-if="hasTitle"
      class="subtitle-1 notification-title font-weight-bold"
      :style="`color: ${updatedNotification.colors.title}`"
      v-html="updatedNotification.title"
    ></div>

    <span
      v-if="hasMessage"
      class="subtitle-2 notification-message font-weight-medium"
      :style="`color: ${updatedNotification.colors.text}`"
      v-html="updatedNotification.message"
    ></span>
  </v-alert>
</template>

<script>
export default {
  name: 'DashboardNotification',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      types: {
        info: {
          background: '#FFFAD1',
          border: '#E8DBB6',
          colors: {
            title: '#8b572a',
            text: '#B58E64',
          },
          icon: 'mdi-information-outline',
        },
        warning: {
          background: '#FFEFEF',
          border: '#ffd6d6',
          colors: {
            title: '#cd0062',
            text: '#d1719e',
          },
          icon: 'mdi-alert-outline',
        },
        success: {
          background: '#E9FBF4',
          border: '#D9E7E4',
          colors: {
            title: '#064e2b',
            text: '#5E9578',
          },
          icon: 'mdi-check-circle-outline',
        },
      },
    };
  },
  computed: {
    updatedNotification() {
      return Object.assign({}, this.notification, this.types[`${this.notification.type}`]);
    },
    hasTitle() {
      return !!this.updatedNotification.title;
    },
    hasMessage() {
      return !!this.updatedNotification.message;
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-title {
  font-size: 15px !important;
  line-height: 1.2rem !important;
  padding-top: 0.2rem;
}

.notification-message::v-deep {
  font-size: 14px !important;

  a {
    text-decoration: underline;
    font-size: inherit;
    font-weight: inherit;
    color: inherit !important;
  }

  a:hover {
    color: var(--v-primary-base) !important;
  }
}
</style>
