import Vue from 'vue';
import router from '@/router';
import environment from '@/config/environment';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

export const loggerInit = () => {
  let watchedDomain = false;

  const sentryConfig = {
    Vue,
    tracesSampleRate: 1.0,
    ignoreErrors: [/^ResizeObserver loop limit exceeded$/, 'UnhandledRejection'],
    beforeBreadcrumb(breadcrumb) {
      if (
        'xhr' === breadcrumb.category &&
        (401 === breadcrumb?.data?.status_code || 404 === breadcrumb?.data?.status_code)
      ) {
        return null;
      }

      return breadcrumb;
    },
  };

  if (environment.getAppUrl()?.includes('app.signi.com')) {
    watchedDomain = true;

    Object.assign(sentryConfig, {
      dsn: 'https://051ab72f91a84c48b548cccab8001ab3@o1125453.ingest.sentry.io/6165651',
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['app.signi.com', /^\//],
        }),
      ],
    });
  }

  if (environment.getAppUrl()?.includes('app.pre.signi.tech')) {
    watchedDomain = true;

    Object.assign(sentryConfig, {
      dsn: 'https://d20316e4c633483b93fb1f73f985c364@o1125453.ingest.sentry.io/6165621',
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['app.pre.signi.tech', /^\//],
        }),
      ],
    });
  }

  if (watchedDomain) {
    Sentry.init(sentryConfig);
  }
};
