<template lang="html">
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="40" height="27">
    <rect width="40" height="27" fill="#d7141a" />
    <rect width="40" height="13" fill="#fff" />
    <path d="M 20,13 0,0 V 27 z" fill="#11457e" />
  </svg>
</template>

<script>
export default {
  name: 'IcoFlagCS',
};
</script>
