
export const Colors = {
  // https://stackoverflow.com/a/3012987
  lightenDarkenHex(rawColor, amount) {
    if (!rawColor) {
      return null;
    }
    const color = rawColor[0] == "#" ? rawColor.slice(1) : rawColor;
    var num = parseInt(color, 16);

    var r = (num >> 16) + amount;
    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    var b = ((num >> 8) & 0x00FF) + amount;
    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    var g = (num & 0x0000FF) + amount;
    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  },
  // https://stackoverflow.com/a/5624139, https://stackoverflow.com/a/3012987
  hexToRgba(hexColor, opacity) {
    if (!hexColor) {
      return null;
    }
    const normalizedHex = hexColor.replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      (m, r, g, b) => r + r + g + g + b + b
    );
    const parsedColor = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(normalizedHex);
    if (!parsedColor) {
      return null;
    }
    const rgb = {
      r: parseInt(parsedColor[1], 16),
      g: parseInt(parsedColor[2], 16),
      b: parseInt(parsedColor[3], 16)
    };
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
  },
}
