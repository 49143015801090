<template>
  <div class="contract-signature__content" :style="{ 'display: none': isSignable }">
    <div class="signature-detail">
      <span v-if="signIdentity.is_signed" class="signature-detail__date signed">
        {{ signHeader }}
      </span>

      <span v-else class="signature-detail__date unsigned">
        {{ $t('signing.not_signed_yet') }}
      </span>
    </div>

    <div
      v-if="isSignImageVisible"
      class="signature-img"
      :style="{ 'background-image': `url('${signatureImage}')` }"
    ></div>
    <div v-else class="signature-img signature-img--empty">
      <v-btn
        v-if="isSignable"
        :color="customColor"
        :disabled="disabled"
        :key="$vuetify.breakpoint.width"
        class="contract-sign-button"
        dark
        x-large
        @click="sign()"
      >
        {{ $t('contract.sign') }}
      </v-btn>
    </div>

    <div class="signature-line">
      <span class="signature-line__path"></span>
      <span class="icon" :class="`icon-round-${signIdentity.is_signed ? 'check' : 'cross'}`"></span>
    </div>

    <div class="signature-detail">
      <span class="signature-detail__name">
        {{ signIdentity.firstname }}
        {{ signIdentity.lastname }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions';
import { i18n } from '@/plugins/i18n';

export default {
  name: 'DetailSignature',
  props: {
    signIdentity: {
      type: Object,
      required: true,
    },
    customColor: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: Boolean,
    },
  },
  methods: {
    sign() {
      this.$emit('sign');
    },
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
    }),
    signatureDate() {
      return getFormattedDateByLocale({
        date: this.signIdentity?.signature_date,
        locale: this.contract?.locale || i18n.locale,
      });
    },
    signHeader() {
      return this.isSigned
        ? this.$t('signature.header.place_day', this.contract.locale, {
            place: this.signIdentity.signature_place,
            day: this.signatureDate,
          })
        : this.$t('signing.not_signed_yet');
    },
    signatureImage() {
      return (
        this.signIdentity?.signature_image ||
        this.contract?.sign_identities.find((identity) => {
          return `${identity.id}` === `${this.signIdentity.id}`;
        })?.signature_image ||
        null
      );
    },
    isSigned() {
      return this.signIdentity?.is_signed || false;
    },
    isSignable() {
      return this.signIdentity?.is_signable_by_current_user;
    },
    isSignImageVisible() {
      return this.isSigned && !!this.signatureImage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.signature__stamp {
  &--clear {
    padding: 0;
    top: 0;
  }

  &--sign {
    font-family: 'Dancing Script', cursive;
    font-size: 150%;
  }
}

.signature-detail {
  text-align: center;
}

.signature-detail__name {
  font-size: 18px;
  color: #000000;
  text-align: center;
}
.signature-detail__date {
  font-weight: 300;
  font-size: 15px;
  color: #4a4a4a;
  text-align: center;
}

.contract-signature__content {
  width: 50%;
  float: left;
  padding: 0 20px 20px;
  @include sm {
    width: 100%;
    margin-bottom: 15px;
  }
  .signature-img {
    margin-bottom: 0;
    height: 4rem;
    @include md {
      height: 30px;
    }
  }
}

.contract-signature {
  width: 100%;
  display: inline-block;
  margin-top: 50px;
  @include md {
    z-index: 100;
  }
}

.signature-line {
  position: relative;
  height: 18px;
  margin-bottom: 10px;
  padding: 0 10px;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}
.signature-line .icon {
  font-size: 18px;
  position: absolute;
  right: -18px;
}

.signature-line__path {
  padding: 0 10px;
  border-bottom: dashed 1px #b4c1d6;
  width: auto;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  right: 15px;
  left: 15px;
  max-width: 100%;
}

.signature-img {
  background-image: unset;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -16px;
}

.signature-img--empty {
  margin-bottom: 0 !important;
  height: 55px !important;

  button {
    width: 100% !important;
    max-width: 140px;
    margin: 0 auto;
    display: block;
  }
}

con-sign {
  width: 100%;
  display: table;
  clear: both;
  margin-top: 80px;
  margin-bottom: 80px;
}

.sign-placement {
  width: 30%;
  position: absolute;
  border-radius: 6px;
  border: 1px solid #b5c1d7;
  color: black;
  font-size: 14px;
  background: #fffffff2;
  overflow: hidden;

  &__inner {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #000;
    position: relative;
    align-items: center;
    justify-content: center;
    &:before {
      content: '';
      display: inline-block;
      padding-bottom: 45%;

      @media (max-width: 767px) {
        padding-bottom: 39%;
      }
    }

    @media (max-width: 1200px) {
      font-size: 12px;
    }

    @media (max-width: 767px) {
      font-size: 9px;
    }

    @media (max-width: 550px) {
      width: calc(100% - 8px);
    }
  }

  &__header {
    position: relative;
    display: block;
    background: #f5f5f5;
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid #b5c1d7;
    text-align: center;
    padding: 5px;
    color: gray;
    font-size: 14px;
    width: 100%;
  }

  &__dots {
    position: absolute;
    height: 2px;
    width: 80%;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    bottom: 25%;
    border-top: 2px dashed #5e239e;
  }

  &__title {
    overflow: hidden;
    position: absolute;
    width: 100%;
  }

  &__name {
    font-size: 12px;
    color: gray;
    font-style: italic;
    position: absolute;
    @media (max-width: 550px) {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
    }
  }

  &__link {
    background-image: linear-gradient(-90deg, var(--color, #934fdd) 0%, var(--color, #6226a3) 100%);
    -webkit-transition: background-image 0.5s linear, -webkit-box-shadow 0.2s linear;
    transition: background-image 0.5s linear, box-shadow 0.2s linear;
    color: #fff;
    text-decoration: none;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 13px 19px 0 rgba(94, 35, 158, 0.22);
    margin-bottom: 5px;
    position: absolute;
    width: 90%;

    &:hover {
      color: #fff !important;
      text-decoration: none;
    }

    @media (max-width: 767px) {
      font-size: 12px;
      padding: 3px;
      margin-bottom: 0;
    }

    svg {
      width: 16px;
      margin-right: 5px;
      position: relative;
      top: 2px;

      @media (max-width: 767px) {
        width: 12px;
        margin-right: 4px;
        top: 3px;
      }
    }
  }

  .signature-img {
    height: 100% !important;
    margin-top: 0;
    margin-bottom: -5px;

    &:before {
      content: '';
      display: inline-block;
      padding-bottom: 45%;

      @media (max-width: 767px) {
        padding-bottom: 39%;
      }
    }

    @media (max-width: 767px) {
      margin-top: -5px;
      margin-bottom: -5px;
    }
  }
}
</style>
