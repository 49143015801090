<template>
  <ContractLayout
    :background-image="contract.settings && contract.settings.background ? contract.settings.background : `/images/contract-desktop-bg.20200622.jpg`"
    :contract="contract"
    :is-loading="isLoading"
  >
    <template v-slot:content>
      <div class="contract">
        <div class="contract__data contract__data--thin">
          <div class="contract__logo">
            <Logo :logo="contract.settings ? contract.settings.logo : null" :white="false" larger />
          </div>

          <h1 class="contract-title">{{ $t('two_factor_auth.two_factor_auth_heading') }}</h1>

          <div class="contract__message contract__message--margin">
            <p class="p-bigger p-title">{{ $t('placeholder.phone') }}</p>
            <p>
              <small>{{ $t('two_factor_auth.enter_phone_number_text') }}</small>
            </p>
          </div>

          <v-form @submit.prevent="sendMobile()">
            <SPhoneNumberInput
              v-model="phoneNumber"
              autofocus
              required
              @on-validity-change="phoneNumberValid = $event"
            />

            <v-btn class="mt-10" type="submit" color="primary" :loading="isSendingMobile" x-large block>
              {{ $t('two_factor_auth.send_pin') }}
            </v-btn>
          </v-form>
        </div>
      </div>
    </template>
  </ContractLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { ContractService } from '@/services/ContractService.js';
import FeatureFlags from '@/common/reusable/featureFlagsChecker';
import ContractLayout from "@/views/contract/components/ContractLayout";
import SPhoneNumberInput from "@/common/components/SPhoneNumberInput";

export default {
  name: 'Contract2FAStart',
  components: {
    SPhoneNumberInput,
    ContractLayout
  },
  data() {
    return {
      loaded: false,
      isSendingMobile: false,
      phoneNumberValid: true,
      phoneNumber: ''
    }
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      myIdentity: 'myIdentity',
    }),
    isLoading() {
      return !this.loaded;
    },
    mobilePhone () {
      return {
        mobile: this.phoneNumber
      }
    }
  },
  created() {
    ContractService.contractFromApiToken({
      component: this,
      onSuccess: (response) => {
        this.$store.commit('setContract', response);

        if (FeatureFlags.cantChangePhoneNumber(response)) {
          this.$router.push({
            name: 'contract2FASubmit',
            params: {
              hash: this.$route.params.hash,
              contract_id: this.$route?.contract_id || response?.id,
            }
          })
        }

        this.loaded = true;
        this.phoneNumber = this.myIdentity.mobile;
      },
    });
  },
  methods: {
    sendMobile() {
      this.isSendingMobile = true;

      if (!this.phoneNumberValid) {
        return (() => {
          this.$notification.error(this.$t('placeholder.phone'));
          this.isSendingMobile = false;
        })();
      }

      const token = this.$route.params.hash;
      const contract_id = this.$route.params.contract_id || this.contract?.contract_id || this.contract?.id;

      let signIdentityId = this.contract.sign_identity?.id;

      if (contract_id !== this.contract.id) {
        for (let index in this.contract.attachments) {
          const attachment = this.contract.attachments[index];

          if (parseInt(attachment.id, 10) === parseInt(contract_id, 10)) {
            signIdentityId = attachment.sign_identity?.id;
            break;
          }
        }
      }

      ContractService
        .sendPhone(this.mobilePhone, token, contract_id, signIdentityId)
        .then(
          (response) => {
            this.$router.push({
              name: 'contract2FASubmit',
              params: {
                hash: this.$route.params.hash,
                receivedPinCode: response.smsPin,
                contract_id: contract_id
              }
            })
            this.isSendingMobile = false
          },
          (errorCode) => {
            this.$notification.error(
              `${errorCode}` === '403'
                ? this.$t('contract.errors.access_2fa')
                : this.$t('contract.errors.send_pin_on_mobile')
            );
            this.isSendingMobile = false;
          }
        )

    }
  },
}
</script>
