import _ from 'lodash';
import {
  createDocumentImage,
  deleteDocumentImage,
  getDocument,
  submitDocument,
} from '@verification/services/authService';

export const namespaced = true;

export const state = {
  document: undefined,
  onlyOneDocumentFeature: false,
  selectedDocumentIds: [4, 3],
  imageTypes: [
    {
      documentTypeId: 4,
      value: 'identity-card',
      side: 'FRONT',
      titleKey: 'identity.identity_card',
      descriptionKey: 'identity.identity_card.front',
      icon: 'mdi-card-account-details-outline',
      image: null,
    },
    {
      documentTypeId: 4,
      value: 'identity-card',
      side: 'BACK',
      titleKey: 'identity.identity_card',
      descriptionKey: 'identity.identity_card.back',
      icon: 'mdi-card-text',
      image: null,
    },
    {
      documentTypeId: 3,
      value: 'driver-card',
      side: 'FRONT',
      titleKey: 'identity.driving_license',
      descriptionKey: 'identity.driving_license.front',
      icon: 'mdi-card-account-details-outline',
      image: null,
    },
    {
      documentTypeId: 3,
      value: 'driver-card',
      side: 'BACK',
      titleKey: 'identity.driving_license',
      descriptionKey: 'identity.driving_license.back',
      icon: 'mdi-card-text',
      image: null,
    },
    {
      documentTypeId: 5,
      value: 'passport',
      side: 'FRONT',
      titleKey: 'identity.passport',
      descriptionKey: 'identity.passport',
      icon: 'mdi-passport',
      image: null,
    },
    {
      documentTypeId: 2,
      value: 'selfie',
      side: 'FRONT',
      titleKey: 'identity.selfie',
      descriptionKey: 'identity.selfie.desc',
      icon: 'mdi-account-outline',
      image: null,
    },
  ],
};

export const mutations = {
  setDocument: (state, payload) => {
    state.document = payload;
  },
  setDocumentImage: (state, payload) => {
    const currentImage = state.imageTypes.find((e) => {
      return (
        e.documentTypeId === payload.auth_document_type_id && e.side === payload.auth_document_side
      );
    });

    currentImage.image = payload;
  },
  setOnlyOneFeature: (state, payload) => {
    state.onlyOneDocumentFeature = payload;
  },
  clearDocumentImage: (state, payload) => {
    const currentImage = state.imageTypes.find((e) => {
      return e.image?.id === payload.imageId;
    });

    currentImage.image = null;
  },
  setSelectedDocumentType: (state, payload) => {
    state.selectedDocumentIds[payload.index] = payload.typeId;
  },
  setSelectedDocumentTypes: (state, payload) => {
    state.selectedDocumentIds = payload;
  },
};

export const actions = {
  fetchDocument: (context, authToken) => {
    return getDocument(authToken).then(
      (resp) => {
        let usedDocumentTypes = [];

        context.commit('setDocument', resp);

        resp?.images?.forEach(async (img) => {
          if (
            !usedDocumentTypes.includes(img.auth_document_type_id) &&
            img.auth_document_type_id !== 2
          ) {
            usedDocumentTypes.push(img.auth_document_type_id);
          }
          await context.dispatch('setDocumentImage', img);
        });

        if (usedDocumentTypes.length <= 1) {
          if (usedDocumentTypes.includes(3)) {
            usedDocumentTypes.push(4);
          } else if (usedDocumentTypes.includes(4)) {
            usedDocumentTypes.push(3);
          } else if (usedDocumentTypes.includes(5)) {
            usedDocumentTypes.push(4);
          } else {
            usedDocumentTypes = [4, 3];
          }
        }

        context.commit('setSelectedDocumentTypes', usedDocumentTypes.slice(0, 2));
      },
      (err) => {
        throw err;
      },
    );
  },
  setOnlyOneFeature: ({ commit }, payload) => {
    commit('setOnlyOneFeature', payload);
  },
  setDocumentImage: (context, payload) => {
    context.commit('setDocumentImage', payload);
  },
  setSelectedDocumentType: (context, payload) => {
    context.commit('setSelectedDocumentType', payload);
  },
  createDocumentImage: (context, payload) => {
    return createDocumentImage(payload.authToken, payload.data).then(
      (resp) => {
        return resp;
      },
      (err) => {
        throw err;
      },
    );
  },
  deleteDocumentImage: (context, payload) => {
    return deleteDocumentImage(payload.authToken, payload.imageId).then(
      (resp) => {
        context.commit('clearDocumentImage', payload);

        return resp;
      },
      (err) => {
        throw err;
      },
    );
  },
  submitDocument: (context, payload) => {
    return submitDocument(payload.authToken).then(
      (resp) => {
        context.commit('setDocument', resp);
      },
      (err) => {
        throw err;
      },
    );
  },
};

export const getters = {
  document: (state) => {
    return state.auths?.document?.images || [];
  },
  documentImages: (state) => {
    return state.imageTypes.map((type) => type.image).filter((image) => image !== null);
  },
  isDocumentDone: (state) => {
    return 'submitted' === state.document?.status || 'ok' === state.document?.status;
  },
  availableDocumentChoices: (state) => {
    return _.uniqBy(state.imageTypes, 'documentTypeId').filter(
      (documentType) => documentType.value !== 'selfie',
    );
  },
  selectedDocumentTypeImages: (state) => (documentTypeId) => {
    return state.imageTypes.filter((imageType) => imageType.documentTypeId === documentTypeId);
  },
  selectedDocumentIds: (state) => {
    return state.selectedDocumentIds;
  },
  currentDocumentImage: (state) => (typeId, side) => {
    return state.imageTypes.find((e) => e.documentTypeId === typeId && e.side === side);
  },
  currentDocumentImagesType: (state) => (typeId) => {
    return state.imageTypes.filter((e) => e.documentTypeId === typeId);
  },
  selfieItems: (state) => {
    return state.imageTypes.filter((e) => 'selfie' === e.value);
  },
  isWithoutDocumentVerification: (state) => {
    return 'undefined' === typeof state.document;
  },
  hasOnlyOneDocumentFeature: (state) => {
    return state.onlyOneDocumentFeature;
  },
};
