<template>
  <div
    class="contract-signature"
    :class="{'contract-signature--light': customColor}"
  >
    <DetailSignature
      v-for="party in indexedParties"
      :key="party.id"
      :sign-type="party.signType"
      :sign-index="party.signIndex"
      :party-order="party.partyOrder"
      :signIdentity="party.signIdentity"
      :customColor="customColor"
      :disabled="checkboxValidatorNotChecked"
      @sign="sign"
    />
  </div>
</template>

<script>
import { setupAgreeCheckbox } from '@/helpers/agreeValidatorRenderer.js'
import { ContractHelper } from '@/helpers/ContractHelper';
import DetailSignature from '@/components/contract/DetailSignature.vue';

export default {
  name: "DetailSignatures",
  components: {
    DetailSignature,
  },
  data() {
    return {
      checkboxValidatorNotChecked: false,
    };
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
    sign: {
      type: Function,
      required: true,
    },
    customColor: {
      type: String,
      required: false,
    },
  },
  computed: {
    indexedParties() {
      const parties = [];
      let proposers = 1;
      let counterparties = 1;

      this.contract.sign_identities.forEach(signIdentity => {

        if (!ContractHelper.isSignatureRequiredForCurrentUser(signIdentity)) {
          return;
        }

        parties.push({
          id: +new Date() + parties.length,
          signType: signIdentity.is_proposer ? 'proposer' : 'signer',
          signIndex: signIdentity.is_proposer ? proposers++ : counterparties++,
          partyOrder: signIdentity.party_order,
          signIdentity: signIdentity,
        });
      });

      return parties;
    },
    size() {
      const sizes = {
        xs:'x-small',
        sm:'small',
        lg:'large',
        xl:'x-large'
      }[this.$vuetify.breakpoint.name];

      return sizes ? { [sizes]: true } : {}
    }
  },
  mounted() {
    const currentSignIdentity = ContractHelper.getCurrentSignIdentity(this.contract);

    if (ContractHelper.isUnsigned(this.contract) && currentSignIdentity && !currentSignIdentity.is_proposer) {
      const checkboxWrapper = document.querySelector('con-agree');

      if (checkboxWrapper) {
        this.checkboxValidatorNotChecked = true;

        setupAgreeCheckbox(checkboxWrapper, () => {
          this.checkboxValidatorNotChecked = !this.checkboxValidatorNotChecked;
          return !this.checkboxValidatorNotChecked;
        });
      }
    }
  },
  methods: {
    render(contractColor) {
      console.log(contractColor || null);
    }
  },
};
</script>

<style lang="scss" scoped></style>
