var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{staticStyle:{"z-index":"999"},attrs:{"close-on-content-click":true,"disabled":_vm.isDisabledSettings,"max-width":"18rem","offset-y":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({staticClass:"workplace-toggle",class:{
        'workplace-toggle--desktop': !_vm.showOnMobile,
        show: _vm.isSideMenuVisible,
      },attrs:{"align":"center","justify":"space-between","no-gutters":""}},'v-row',attrs,false),on),[_c('v-col',{staticClass:"d-block text-truncate"},[_c('span',{staticClass:"subtitle-1 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.activeWorkspace.title)+" ")])]),(_vm.showToggleButton)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(("mdi-chevron-" + (_vm.isWorkspaceMenuActive ? 'up' : 'down')))+" ")])],1):_vm._e()],1)]}}]),model:{value:(_vm.activeWorkspaceMenu),callback:function ($$v) {_vm.activeWorkspaceMenu=$$v},expression:"activeWorkspaceMenu"}},[_c('v-card',{attrs:{"width":"18rem"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"workspace-title"},[_vm._v(" "+_vm._s(_vm.activeWorkspace.title)+" ")])],1)],1)],1),_c('v-divider'),_c('v-list',{staticClass:"py-0"},[(_vm.hasMoreWorkspaces)?_c('v-subheader',{staticClass:"workspace-list__title"},[_vm._v(" "+_vm._s(_vm.$t('workspace.switch'))+" ")]):_vm._e(),_c('div',{staticClass:"workspace-list__wrapper"},_vm._l((_vm.profileWorkspaces),function(workspace){return _c('v-list-item',{key:workspace.id,staticClass:"workspace-list__item",attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.onOpenWorkspace(workspace.id)}}},[_c('v-list-item-icon',{staticClass:"mr-1"},[(_vm.showActiveWorkspace(workspace.id))?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_vm._e()],1),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('v-list-item-title',{staticClass:"subtitle-1 font-weight-regular",class:{
                'font-weight-bold': _vm.showActiveWorkspace(workspace.id) || hover,
              }},[_c('span',{class:hover ? 'primary--text' : '#000000'},[_vm._v(" "+_vm._s(workspace.title)+" ")])])]}}],null,true)}),(workspace.is_owner)?[_c('v-divider',{attrs:{"inset":"","vertical":""}}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-list-item-action',[_c('v-btn',{staticClass:"py-0",attrs:{"icon":""},on:{"click":function($event){return _vm.onOpenWorkspaceSettings(workspace.id)}}},[_c('v-icon',{staticClass:"py-0",attrs:{"color":hover ? 'primary' : '#b3c2d6'}},[_vm._v(" mdi-cog-outline ")])],1)],1)]}}],null,true)})]:_vm._e()],2)}),1)],1),(!_vm.hasMoreWorkspaces)?_c('v-divider'):_vm._e(),(!_vm.hasMoreWorkspaces)?_c('v-card',{staticClass:"ma-3 workspace-card-border",attrs:{"elevation":"0","color":"#FFFBCB"}},[_c('v-card-text',{staticClass:"font-weight-medium workspace-card-message"},[_vm._v(" "+_vm._s(_vm.$t('workspaces.only_one_message'))+" ")])],1):_vm._e(),(!_vm.disabledCreateNewWorkspace)?_c('v-divider'):_vm._e(),(!_vm.disabledCreateNewWorkspace)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"workspace-menu__add-button",attrs:{"link":""},on:{"click":_vm.createWorkspace}},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('workspace.createNew'))+" ")],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }