var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.locale,staticClass:"contract-layout-wrapper",style:(("background-image: url(" + _vm.backgroundImage + ")"))},[_c('div',[(_vm.isLoading)?_c('Loader'):_c('div',{staticClass:"contract-layout"},[(_vm.isPreview)?_c('div',{staticClass:"contract-layout__document-header"},[_c('div',{staticClass:"contract-layout__document-header-logo",style:(("background-color: " + _vm.layerColor))},[(!_vm.isMobileDevice)?_c('div',{staticClass:"contract-layout__document-header-logo-item"}):_vm._e(),_c('Logo',{staticClass:"contract-layout__document-header-logo-item",attrs:{"logo":_vm.contract.settings ? _vm.contract.settings.logo : null,"white":false}}),_c('div',{staticClass:"contract-layout__document-header-logo-item contract__language-select dark text-right"},[_c('LanguageToggle',{attrs:{"locale":_vm.currentLocale},on:{"onLocaleChanged":function($event){return _vm.changeLocale($event)}}})],1)],1),(_vm.isMobileDevice)?_c('div',{staticClass:"contract-layout__document-header-title-mobile"},[_c('DocumentActionsSummarization'),_c('h1',[_vm._v(_vm._s(_vm.contract.contract_title))]),_c('v-row',{staticClass:"contract-layout__document-header-title-mobile-row",attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{staticClass:"additional-questions"},[_c('IcoQuestions'),_c('a',{staticClass:"view-pdf",attrs:{"href":_vm.$t('links.faq'),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('signing.signing_faq')))])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pl-3 pr-1",attrs:{"color":"#5e239e","outlined":"","x-large":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('general.more'))+" "),_c('v-icon',{staticClass:"pa-0 ma-0 pl-1"},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,false,3855826192),model:{value:(_vm.openDotsMenu),callback:function ($$v) {_vm.openDotsMenu=$$v},expression:"openDotsMenu"}},[_c('v-list',{attrs:{"max-width":"17rem"}},[_c('v-list-item',{staticClass:"font-weight-medium",attrs:{"link":""},on:{"click":function($event){return _vm.download()}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('IcoDownload')],1),_vm._v(" "+_vm._s(_vm.$t('contract.download_all'))+" ")],1),_c('v-divider'),_c('v-list-item',{staticClass:"font-weight-medium",class:{
                      'd-none': _vm.contract.current_user && !_vm.contract.current_user.can_reject_some_contract
                    },attrs:{"link":""},on:{"click":function($event){_vm.rejectRequest = true}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('IcoRejectRed')],1),_vm._v(" "+_vm._s(_vm.$t('general.decline'))+" ")],1)],1)],1)],1)],1)],1):_c('div',{staticClass:"contract-layout__document-header-title"},[_c('v-row',{staticClass:"contract-layout__document-header-title-row",attrs:{"align":"center","justify":"space-between"}},[_c('v-col',[_c('h1',[_vm._v(_vm._s(_vm.contract.contract_title))])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pl-3 pr-1",attrs:{"color":"#5e239e","outlined":"","x-large":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('general.more'))+" "),_c('v-icon',{staticClass:"pa-0 ma-0 pl-1"},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,false,3855826192),model:{value:(_vm.openDotsMenu),callback:function ($$v) {_vm.openDotsMenu=$$v},expression:"openDotsMenu"}},[_c('v-list',{attrs:{"max-width":"17rem"}},[_c('v-list-item',{staticClass:"font-weight-medium",attrs:{"link":""},on:{"click":function($event){return _vm.download()}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('IcoDownload')],1),_vm._v(" "+_vm._s(_vm.$t('contract.download_all'))+" ")],1),_c('v-divider'),_c('v-list-item',{staticClass:"font-weight-medium",class:{
                      'd-none': _vm.contract.current_user && !_vm.contract.current_user.can_reject_some_contract
                    },attrs:{"link":""},on:{"click":function($event){_vm.rejectRequest = true}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('IcoRejectRed')],1),_vm._v(" "+_vm._s(_vm.$t('general.decline'))+" ")],1)],1)],1)],1)],1),_c('div',{staticClass:"contract-layout__document-header-title-info",staticStyle:{"display":"flex"}},[_c('DocumentActionsSummarization'),_c('div',{staticClass:"additional-questions"},[_c('IcoQuestions'),_c('a',{staticClass:"view-pdf",attrs:{"href":_vm.$t('links.faq'),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('signing.signing_faq')))])],1)],1)],1)]):_vm._e(),_c('div',{staticClass:"contract-layout__content-page",class:{'turn-off-padding': _vm.isMobileDevice && _vm.isPreview }},[(!_vm.isPreview)?_c('div',{staticClass:"contract__language-select text-right"},[_c('LanguageToggle',{attrs:{"locale":_vm.currentLocale},on:{"onLocaleChanged":function($event){return _vm.changeLocale($event)}}})],1):_vm._e(),_c('div',{staticClass:"contract-layout__content-page-content"},[_vm._t("content")],2),_c('v-toolbar',{staticClass:"signing-layout__footer mx-auto",attrs:{"width":"100%","dense":"","flat":"","dark":""}},[_c('v-spacer'),_c('span',[_vm._v("Powered by")]),_c('img',{attrs:{"src":"/images/signi_cz_rgb_color_neg_transparent.png","width":"auto","height":"25px","alt":""}}),_c('v-spacer')],1)],1)])],1),_c('RejectContractDialog',{model:{value:(_vm.rejectRequest),callback:function ($$v) {_vm.rejectRequest=$$v},expression:"rejectRequest"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }