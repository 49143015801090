<template>
  <v-menu
    v-model="open"
    transition="slide-x-transition"
    :nudge-width="nudgeWidth"
    :nudge-top="nudgeTop"
    :nudge-right="nudgeRight"
    :nudge-bottom="nudgeBottom"
    :nudge-left="nudgeLeft"
    :offset-x="offsetX"
    :offset-y="offsetY"
  >
    <template v-slot:activator="{ on, attrs }" class="signi-help__activator--button">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon color="#B3C0D5" class="text-decoration-none">mdi-help-circle-outline</v-icon>
      </v-btn>

      <slot v-if="hasActivatorSlotContent" name="activator"></slot>
    </template>

    <v-card class="signi-help__content" :max-width="maxContentWidth">
      <v-card-text v-if="hasContentSlotContent" class="signi-help__content--message">
        <slot name="content"></slot>
      </v-card-text>

      <v-card-text v-else class="signi-help__content--message">
        <span v-html="message"></span>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'SHelp',
  data() {
    return {
      open: false,
    };
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    nudgeWidth: {
      type: Number,
      default: 0,
    },
    nudgeTop: {
      type: String,
      default: '50%',
    },
    nudgeRight: {
      type: String,
      default: '',
    },
    nudgeBottom: {
      type: String,
      default: '',
    },
    nudgeLeft: {
      type: String,
      default: '',
    },
    offsetX: {
      type: Boolean,
      default: true,
    },
    offsetY: {
      type: Boolean,
      default: false,
    },
    maxContentWidth: {
      type: String,
      default: '300px',
    },
  },
  computed: {
    hasActivatorSlotContent() {
      return !!this.$slots.activator;
    },
    hasContentSlotContent() {
      return !!this.$slots.content;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn--icon.v-size--default {
  height: auto;
  width: fit-content;
  max-height: 24px;
  max-width: 24px;
}

.signi-help {
  &__content {
    background-color: #fefad1;

    &--message::v-deep * {
      color: #8f5b2e;
      font-size: 15px;
      font-weight: 500;
    }
  }
}
</style>
