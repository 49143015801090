import { apiAuthorizedRequest } from '@/services/ApiService';

/**
 * Method to get available authentications an verifications for contract by workspace
 *
 * @param workspaceId
 * @returns {Promise<any | void>}
 */
export function getSignSettings(workspaceId) {
  return apiAuthorizedRequest('GET', `/api/v1/enum/workspace/${workspaceId}/list`);
}
