import { submitMicroTransaction } from '@verification/services/authService';

export const namespaced = true;

export const state = {
  microTransaction: undefined,
};

export const mutations = {
  setMicroTransaction(state, payload) {
    state.microTransaction = payload;
  },
};

export const actions = {
  setMicroTransaction: (context, payload) => {
    context.commit('setMicroTransaction', payload);
  },
  submitMicroTransaction: (context, payload) => {
    return submitMicroTransaction(payload.authToken, payload.data).then(
      (resp) => {
        context.commit('setMicroTransaction', resp);
      },
      (err) => {
        throw err;
      },
    );
  },
};

export const getters = {
  microTransaction: (state) => {
    return state.microTransaction;
  },
  isMicroTransactionDone: (state) => {
    return (
      'submitted' === state.microTransaction?.status || 'ok' === state.microTransaction?.status
    );
  },
  isWithoutMicroTransactionVerification: (state) => {
    return 'undefined' === typeof state.microTransaction;
  },
};
