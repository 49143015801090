<template>
  <div class="progress-line" :class="{ 'progress-line-null': progress === 0 }">
    <div class="progress-line__wrapper">
      <div class="progress-line__part" :style="`width: ${percentage}%`">
        <div class="progress-line__status">
          <div class="progress-line__content">
            <span>{{ progress }}/{{ total }}</span>
            <span class="progress-line__content-text">
              {{ $t('general.filled') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressLine',
  props: {
    progress: Number,
    total: Number,
  },
  data() {
    return {
      valueToPercentage: 0,
      percentage: '',
    }
  },
  watch:{
    progress: function() {
      this.valueToPercentage = 100 / this.total;
      this.percentage = this.progress * this.valueToPercentage;
    },
    total: function() {
      this.valueToPercentage = 100 / this.total;
      this.percentage = this.progress * this.valueToPercentage;
    },
  },
  created() {
    this.valueToPercentage = 100 / this.total;
    this.percentage = this.progress * this.valueToPercentage;
  },
}
</script>

<style lang="scss">
.progress-line__part {
  width: 0;
  float: left;
  display: block;
  height: 6px;
  position:relative;
  background-color: #6DECAF;

  &:first-child{
    border-top-left-radius: 6px;
  }

  &:last-child{
    border-top-right-radius: 6px;
  }
}

.progress-line {
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  z-index: 4;

  @include display(960px) {
    display: none;
  }
}

.progress-line__wrapper {
  position: relative;
  width: 100%;
  height: 6px;
  background-color: #E2E7EF;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
}

.progress-line__current {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20%;
  background-color: #6DECAF;
  @include animation;
}

.progress-line__status {
  box-sizing: border-box;
  padding: 0 12px;
  position: absolute;
  top: -35px;
  height: 27px;
  line-height: 29px;
  border-radius: 11px;
  background-color: #fff;
  font-weight: 800;
  font-size: 12px;
  color: #99A2B1;
  letter-spacing: -0.03px;
  text-transform: uppercase;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.10);
  right: 0;
  min-width: 125px;
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 7px solid #fff;
    transform: translateX(-50%) rotate(180deg);
  }

  span {
    color: #000000;
  }
}

span.progress-line__content-text {
  font-size: 12px;
  color: #99A2B1;
}

.done.progress-line__part {
  background: #6DECAF;
}

.current.progress-line__part {
  background: #6DECAF;
}

.progress-line-null {
  .progress-line__status {
    right: unset;
    left: 0;
  }

  .progress-line__content {
    display: inline-flex;
  }

  .progress-line__content-text {
    padding-left: 5px;
  }
}
</style>
