<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
    <title>ico-N/ico-24-lab.gray</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-24-lab.gray" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group" transform="translate(12.546197, 14.546197) rotate(45.000000) translate(-12.546197, -14.546197) translate(3.546197, 2.974768)" stroke="currentColor" stroke-width="2">
        <path d="M2.54912103,1.28571429 C2.54912103,10.1040221 2.54912103,16.0684632 2.54912103,19.1790377 C2.54912103,23.8448994 8.37296257,24.1731542 8.37296257,19.1790377 C8.37296257,15.8496266 8.37296257,9.88518549 8.37296257,1.28571429" id="Path-58"></path>
        <path d="M0.601709005,1.16509322 L9.88459927,1.16509322" id="Path-88"></path>
        <path d="M2.50948518,15.5226856 L7.95864754,10.152576" id="Path-90"></path>
        <path d="M13.8214286,7.71428571 C15.0640693,7.71428571 16.0714286,6.7069264 16.0714286,5.46428571 C16.0714286,4.63585859 15.3214286,3.45728716 13.8214286,1.92857143 C12.3214286,3.45728716 11.5714286,4.63585859 11.5714286,5.46428571 C11.5714286,6.7069264 12.5787879,7.71428571 13.8214286,7.71428571 Z" id="Oval" transform="translate(13.821429, 4.821429) rotate(-45.000000) translate(-13.821429, -4.821429) "></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IcoExperimentalFunctions",
}
</script>

<style lang="scss" scoped></style>
