<template lang="html">
  <div class="proposer__item" :class="{ 'proposer__item-draggable': !isDragndropDisabled }">
    <div class="proposer__item-name-wrapper">
      <div class="proposer__item-name-wrapper-content">
        <VerificationBadge :avatar-size="30" :contact="signatory" />

        <div class="proposer__item-name">
          <span class="signatory-search--signers-result-name">
            {{ signatory.firstname }}
            {{ signatory.lastname }}
          </span>
          <p
            v-if="signatory.organization_name"
            class="signatory-search--signers-result-organization color-gray"
          >
            {{ signatory.organization_name }}
          </p>
        </div>
      </div>

      <div class="proposer__item-name-wrapper-actions">
        <v-btn v-if="editable" icon @click="onShowModal()">
          <IcoEdit />
        </v-btn>

        <v-btn v-if="deselectable" icon @click="onDeselect()">
          <IcoTrash />
        </v-btn>
      </div>
    </div>

    <v-row class="pl-9 pr-3" no-gutters>
      <v-col cols="12">
        <v-select
          class="mt-2 font-weight-regular"
          v-model="signatory.contract_role"
          :items="availableRoles"
          item-value="value"
          item-text="name"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
    </v-row>

    <v-row v-if="isCounterParty && hasVerificationEnabled" class="mt-3 pl-9 pr-3" no-gutters>
      <!--      <v-col class="col-12 col-md-auto mb-2">-->
      <!--        <VerificationsSelect v-model="signatory.requested_verifications" />-->
      <!--      </v-col>-->

      <v-col class="col-12 col-md-auto">
        <AuthenticationsSelect v-model="signatory.required_authentications" />
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-row v-if="isAmlVerification" class="mt-3 ml-9 mr-3" style="border-top: 1px solid #e7eaeb">
        <v-col
          cols="12"
          class="pa-0 pt-2 grey--text font-weight-bold"
          style="min-height: 36px; font-size: 0.9rem"
        >
          {{ $t('contract.reject.note') }}:
          <template
            v-if="
              typeof signatory.auths_data.microTransaction !== 'undefined' &&
              typeof signatory.auths_data.microTransaction.variable_symbol !== 'undefined'
            "
          >
            <v-dialog v-model="noteDialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text class="pl-1 grey--text" v-bind="attrs" v-on="on">
                  {{ signatory.auths_data.microTransaction.note }}
                  <v-icon class="pl-2" small>mdi-pencil-outline</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="headline grey lighten-2">
                  {{ $t('contract.reject.note') }}:
                </v-card-title>

                <v-card-text class="pa-5 pb-0 info-box">
                  <v-alert
                    type="warning"
                    icon="mdi-information-outline"
                    border="left"
                    color="#9D6F42"
                    outlined
                  >
                    {{ $t('aml.micropayment.note_info') }}
                  </v-alert>
                </v-card-text>

                <v-card-text class="pa-5">
                  <v-text-field
                    ref="noteField"
                    type="text"
                    name="micropayment-note"
                    v-model="signatoryNote"
                    :rules="signatoryNoteRules"
                    :label="$t('contract.reject.note')"
                    outlined
                    counter
                    maxlength="60"
                  ></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn color="primary" text :disabled="noteFieldHasError" @click="saveNewNote()">
                    {{ $t('general.save') }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="noteDialog = false">
                    {{ $t('general.cancel') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-col>
      </v-row>
    </v-expand-transition>

    <div v-if="!isDragndropDisabled" class="create-owner-wrap-handle">
      <IcoBars />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IcoBars from '@/components/svg/IcoBars.vue';
import IcoTrash from '@/views/dashboard/components/icons/IcoTrash';
import IcoEdit from '@/views/dashboard/components/icons/IcoEdit';
import AuthenticationsSelect from '@signIdentity/components/AuthenticationsSelect';
// import VerificationsSelect from '@signIdentity/components/VerificationsSelect';
import VerificationBadge from '@contacts/components/VerificationBadge';
import featureFlagsChecker from '@/common/reusable/featureFlagsChecker';
import FeatureFlagsChecker from '@/common/reusable/featureFlagsChecker';

export default {
  name: 'SignIdentityCard',
  components: {
    AuthenticationsSelect,
    // VerificationsSelect,
    VerificationBadge,
    IcoEdit,
    IcoTrash,
    IcoBars,
  },
  props: {
    value: Object,
    deselectable: {
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    isDragndropDisabled: {
      type: Boolean,
      required: true,
    },
    hasAdvancedWorkflowSelects: {
      required: true,
    },
  },
  data() {
    const buildOption = (key, name) => {
      return {
        name,
        key,
        isSelected: this.signatory?.required_verifications?.indexOf(key) !== -1 || false,
      };
    };
    return {
      signatoryVariableSymbol: '',
      variableSymbolDialog: false,
      signatoryNote: '',
      // eslint-disable-next-line no-useless-escape
      signatoryNoteRules: [(v) => v.match(/^[a-zA-Z 0-9\$\%\*\+\-\.\/\:\,]{0,60}$/m) !== null],
      noteDialog: false,
      allVerifications: [buildOption('sms_pin', this.$t('verification.type.sms_pin'))],
    };
  },
  computed: {
    ...mapGetters({
      bankAccount: 'workspace/activeWorkspaceBankAccount',
      contract: 'contract',
    }),
    isContractForm() {
      return 'form' === this.contract?.type;
    },
    signatory: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    availableRoles() {
      return this.allRoles.filter((role) => {
        const isOnlyForSigners = role.isOnlyForSigners || false;

        if (this.signatory.is_proposer) {
          return !isOnlyForSigners;
        }

        return true;
      });
    },
    allRoles() {
      const defaultRoles = [
        {
          name: this.$t('contract.signs.role_sign'),
          value: 'sign',
        },
        {
          name: this.$t('contract.signs.role_approve'),
          value: 'approve',
        },
        {
          name: this.$t('contract.signs.role_notice'),
          value: 'notice',
        },
      ];

      defaultRoles.push({
        name: this.$t('contract.signs.role_stamp'),
        value: 'stamp',
        isOnlyForSigners: true,
      });

      if (featureFlagsChecker.enableSignWithCertificate(this.contract)) {
        defaultRoles.push({
          name: this.$t('qualified_signature'),
          value: 'sign_certificate',
          disabled: this.isContractForm,
        });
      }

      if (featureFlagsChecker.enableSignWithBankId(this.contract)) {
        defaultRoles.push({
          name: this.$t('bank_id_sign'),
          value: 'sign_bank_id_sign',
          disabled: this.isContractForm,
        });
      }

      return defaultRoles;
    },
    hasVerificationEnabled() {
      return this.hasEnabledAmlVerification || this.hasEnabledBankIdVerification;
    },
    hasEnabledBankIdVerification() {
      return FeatureFlagsChecker.hasEnabledBankIdVerification(this.contract);
    },

    hasEnabledAmlVerification() {
      return FeatureFlagsChecker.hasEnabledAmlVerification(this.contract);
    },
    selectedVerificationsCsv() {
      return this.allVerifications
        .filter((v) => v.isSelected)
        .map((v) => v.name)
        .join(', ');
    },
    userAvatar() {
      if (this.signatory?.avatar_url) {
        return this.signatory.avatar_url;
      }

      return null;
    },
    isCounterParty() {
      return !this.signatory.is_proposer;
    },
    isAmlVerification() {
      return this.isCounterParty && this.signatory.required_authentications.includes('aml');
    },
    noteFieldHasError() {
      return (
        // eslint-disable-next-line no-useless-escape
        null === this.signatoryNote.match(/^[a-zA-Z 0-9\$\%\*\+\-\.\/\:\,]{0,60}$/m) ||
        '' === this.signatoryNote
      );
    },
  },
  watch: {
    'signatory.required_authentications': {
      handler: function (value, oldValue) {
        if (value.includes('aml')) {
          this.signatory.auths_data = {
            microTransaction: {
              bank_id: this.bankAccount?.id,
              variable_symbol: this.contract?.id,
              note: `AML-${this.contract?.id}`,
            },
          };
        } else if (oldValue.includes('aml') && !value.includes('aml')) {
          this.signatory.auths_data = {};
        } else {
          this.signatory.auths_data = {};
        }
      },
    },
    bankAccount: {
      handler: function () {
        if (this.signatory?.required_authentications?.includes('aml')) {
          this.signatory.auths_data = {
            microTransaction: {
              bank_id: this.bankAccount?.id,
              variable_symbol: this.contract?.id,
              note: `AML-${this.contract?.id}`,
            },
          };
        }
      },
    },
    variableSymbolDialog: {
      handler: function (val) {
        if (true === val) {
          this.signatoryVariableSymbol = this.signatory.auths_data.microTransaction.variable_symbol;
        }
      },
    },
    noteDialog: {
      handler: function (val) {
        if (true === val) {
          this.signatoryNote = this.signatory.auths_data.microTransaction.note;
        }
      },
    },
  },
  created() {
    if (true === this.signatory?.signature_stamp) {
      this.signatory.contract_role = 'sign';
    }

    if (this.signatory?.required_authentications.includes('aml')) {
      this.signatory.auths_data = {
        microTransaction: {
          bank_id: this.bankAccount?.id,
          variable_symbol: this.contract?.id,
          note: `AML-${this.contract?.id}`,
        },
      };
    }
  },
  methods: {
    onDeselect() {
      this.$emit('onDeselect', this.signatory);
    },
    onShowModal() {
      this.$emit('onShowModal', this.signatory);
    },
    saveNewNote() {
      this.signatory.auths_data.microTransaction.note = this.signatoryNote;
      this.noteDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-box {
  .v-alert {
    background-color: #fffad1 !important;
    border: unset !important;
    font-weight: bolder;
    text-align: left;
    color: #926033;
  }
}
</style>

<style lang="scss">
.proposer__item {
  border: 1px solid #c5ccd8;
  border-radius: 6px;
  padding: 1rem 1.2rem 1.5rem;
  margin-bottom: 15px;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;

  .create-owner-wrap-handle {
    top: 22px !important;
    left: 12px !important;
  }
}
.proposer__item-selects-wrapper {
  .select-wrap,
  .multiselect {
    float: left;
    width: 160px;
    display: inline-block;
    position: relative;
  }
  .select-wrap:first-child {
    margin: 0 1ex 1ex 0;
  }
  .select,
  .multiselect__placeholder {
    font-weight: 500;
    height: 40px;
    line-height: 40px;
  }
  .multiselect {
    height: 40px;
  }
  .multiselect__placeholder,
  .multiselect-placeholdertext {
    color: black;
  }
  .multiselect__data {
    top: 40px;
    left: 0;
    border-top: solid 1px #5e239e !important;
    width: 320px !important;
    padding: 1em 0 1ex;
  }
  .select__data,
  .multiselect__data {
    background: white;
    width: 160px;
    margin-left: unset;
    margin-right: unset;
    border: solid 1px #5e239e;
    border-top: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    max-height: 235px;
    position: absolute;
    z-index: 10;
    right: 0;
    transform: translate(px, 1px);
  }
  .select-disabled .select__arrow {
    display: none;
  }
}

.proposer__item .proposer__item-avatar {
  background-image: unset;
  width: 32px;
  height: 32px;
  background: #d4dff0;
  line-height: 32px;
  color: white;
  font-weight: bold;
  border-radius: 100%;
  font-size: 14px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.proposer__item-name-wrapper {
  display: flex;
  justify-content: space-between;
  .proposer__item-name-wrapper-content {
    flex: 1;
    display: flex;
    margin-bottom: 10px;
    .proposer__item-name {
      padding: 0 8px;
      .signatory-search--signers-result-name {
        font-weight: bold;
      }
      p {
        margin: 0;
      }
    }
  }
  .proposer__item-name-wrapper-actions {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .icon-pen1,
    .icon-bin {
      font-weight: normal;
      color: #8e97a4;
      padding: 0;
      &:before {
        color: #8e97a4 !important;
        padding: 0;
        font-size: unset;
      }
    }
    .icon-pen1 {
      margin-right: 15px;
    }
  }
}
.proposer__item-draggable {
  padding-left: 44px;
}
</style>
