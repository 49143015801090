import { i18n } from '@/plugins/i18n';
import { ContractService } from '@/services/ContractService';
import { getContractDoc } from '@/common/reusable/contractFunctions';
import Vue from 'vue';

const contractData = {
  namespaced: true,
  state: () => ({
    contract: null,
    contractImages: null,
    comments: [],
    defaultBackground: require('@/layouts/assets/public_sign_background.jpg'),
  }),
  mutations: {
    SET_CONTRACT(state, contract) {
      state.contract = contract;

      return state.comments;
    },
    SET_CONTRACT_IMAGES(state, contractImages) {
      state.contract = contractImages;

      return state.comments;
    },
    SET_COMMENTS(state, comments) {
      state.comments = comments;

      return state.comments;
    },
  },
  actions: {
    fetchContract: ({ commit }, contractId) => {
      return ContractService.detailWithSignsAndLinks(
        contractId,
        this.$route.params.workspace_id,
      ).then(
        async (response) => {
          const contract = await commit('SET_CONTRACT', response);

          if ('doc' === String(response.type) || 'wysiwyg' === String(response.type)) {
            commit('SET_CONTRACT_IMAGES', await getContractDoc(response));
          }

          return contract;
        },
        () => {
          Vue.prototype.$notification.error(i18n.$t('contract.not_loaded'));
        },
      );
    },
    fetchComments: ({ commit, getters }, contractId) => {
      return ContractService.getComments(contractId, getters.commentsUsage).then(
        (response) => {
          return commit('SET_COMMENTS', response);
        },
        () => {
          Vue.prototype.$notification.error(i18n.$t('general.error'));
        },
      );
    },
  },
  getters: {
    background: (state) => {
      if (state.contract && state.contract.settings) {
        return state.contract.settings.background || state.defaultBackground;
      }

      return state.defaultBackground;
    },
    contract: (state) => {
      return state.contract;
    },
    contractImages: (state) => {
      return state.contractImages;
    },
    comments: (state) => {
      return state.comments;
    },
    commentsUsage: (state) => {
      if (state.contract) {
        return state.contract.comments_usage || '';
      }

      return '';
    },
    language: (state) => {
      // TODO - return contract locale
      return state;
    },
  },
};

export default contractData;
