<template>
  <v-expand-transition>
    <v-card
      v-if="canShow"
      class="contract-document-preview my-2"
      :class="`contract-document-preview__${isUploaded ? 'success' : 'error'}`"
      width="100%"
      outlined
    >
      <div style="flex: 10%">
        <img class="ma-2 mb-0" src="/images/try-sign.png" width="auto" height="55px" alt="" />
      </div>

      <div class="px-2 py-2" style="flex: 84%; overflow: hidden">
        <span class="subtitle-2 font-weight-bold">
          {{ document.title }}
        </span>
        <template v-if="hasErrorMessage">
          <br />
          <span
            class="subtitle-2 error--text text-center font-weight-bold"
            v-html="errorMessage"
          ></span>
        </template>
      </div>

      <div style="flex: 6%">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="primary">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list class="py-0" width="17rem">
            <v-list-item v-if="document.type === 'doc'" link @click="uploadNewFile">
              <v-list-item-icon class="mr-3">
                <v-icon color="#AEBACE">mdi-upload</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                class="font-weight-medium text-decoration-underline text-left py-2"
                style="color: #666b71 !important"
              >
                {{ $t('upload.retry') }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="isUploaded" link @click="getTemplatePDF">
              <v-list-item-icon class="mr-3">
                <v-icon color="#AEBACE">mdi-download</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                class="font-weight-medium text-decoration-underline text-left py-2"
                style="color: #666b71 !important"
              >
                {{ $t('contract.download_pdf') }}
              </v-list-item-content>
            </v-list-item>

            <v-divider class="mx-2"></v-divider>

            <v-list-item @click="deleteDocument">
              <v-list-item-icon class="mr-3">
                <v-icon color="error">mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                class="font-weight-medium text-decoration-underline text-left py-2"
                style="color: #666b71 !important"
              >
                {{ $t('contract.remove_document') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-card>
  </v-expand-transition>
</template>

<script>
import { ContractService } from '@/services/ContractService';
import { circularFetchDocumentWithLink } from '@contract/services/contractService';

export default {
  name: 'ContractDocumentPreview',
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      documentInfo: {},
      loaded: false,
      error: null,
      errorMessage: '',
    };
  },
  computed: {
    canShow() {
      return this.loaded && (!this.$vuetify.breakpoint.mobile || this.isUnsuccessful);
    },
    isUploaded() {
      return !this.isUnsuccessful;
    },
    isUnsuccessful() {
      return !!this.error;
    },
    hasErrorMessage() {
      return !!this.errorMessage;
    },
  },
  created() {
    this.loaded = false;

    if ('form' === this.document?.type) {
      setTimeout(() => {
        this.loaded = true;
      }, 800);

      return;
    }

    circularFetchDocumentWithLink({
      contractId: this.document?.id,
      timeout: 2500,
      next: (resp) => {
        this.documentInfo = resp;
        this.loaded = true;
      },
      catchError: (err) => {
        this.error = err;
        this.$emit('on-document-failed');

        let reason = this.$t('general.error');

        if (err?.message?.toLowerCase().includes('file is locked.')) {
          reason = this.$t('document.error.locked');
        } else if (err?.message?.toLowerCase()?.includes('file is sealed.')) {
          reason = this.$t('document.error.sealed');
        }

        this.errorMessage = this.$t('document.error.message', {
          title: this.document.title,
          reason,
        });

        this.$notification.error(reason);
        this.loaded = true;
      },
    });
  },
  methods: {
    uploadNewFile() {
      this.$router.push({
        name: 'createUpload',
        params: {
          contract_id: this.document.id,
          workspace_id: this.$route.params.workspace_id,
        },
        query: {
          replace: true,
        },
      });
    },
    getTemplatePDF() {
      ContractService.getPDF(this.document.id)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.document.title}.pdf`);

          document.body.appendChild(link);

          link.click();
          link.parentNode.removeChild(link);
        });
    },
    deleteDocument() {
      this.$emit('on-document-delete', this.document.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-document-preview {
  border: 1px solid #d4dff0;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__success {
    background-color: #fffad1;
  }

  &__error {
    background-color: #ffe2e3;
  }

  &__download--link {
    color: var(--v-primary-base);
    text-decoration: underline;
  }
}
</style>
