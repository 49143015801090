import axios from 'axios';
import environment from '@/config/environment';
import { authHeader } from '@/config/authHeader';
import { fetchContractSignIdentities } from '@contract/services/contractService';

const mutationsTypes = {
  SET_CURRENT_SIGN_IDENTITY: 'SET_CURRENT_SIGN_IDENTITY',
  SET_SIGN_IDENTITIES: 'SET_SIGN_IDENTITIES',
};

export const namespaced = true;

export const state = {
  currentSignIdentity: null,
  signIdentities: [],
};

export const mutations = {
  [mutationsTypes.SET_CURRENT_SIGN_IDENTITY]: (state, payload) => {
    state.currentSignIdentity = payload;
  },
  [mutationsTypes.SET_SIGN_IDENTITIES]: (state, payload) => {
    state.signIdentities = payload;
  },
};

export const actions = {
  fetchCurrentSignIdentity: ({ commit }, contractId) => {
    return axios
      .get(`${environment.getApiUrl()}/api/v2/contract/${contractId}/signIdentity/current`, {
        headers: authHeader(),
      })
      .then((resp) => {
        commit(mutationsTypes.SET_CURRENT_SIGN_IDENTITY, resp.data);

        return resp.data;
      })
      .catch(() => {
        commit(mutationsTypes.SET_CURRENT_SIGN_IDENTITY, null);

        return false;
      });
  },
  resetCurrentSignIdentity: ({ commit }) => {
    commit(mutationsTypes.SET_CURRENT_SIGN_IDENTITY, null);
  },
  fetchSignIdentities: ({ commit }, contractId) => {
    return new Promise((resolve, reject) => {
      fetchContractSignIdentities({
        contractId,
        attempt: 5,
        timeout: 3000,
        next: (resp) => {
          commit(mutationsTypes.SET_SIGN_IDENTITIES, resp);

          resolve(resp);
        },
        catchError: (err) => {
          commit(mutationsTypes.SET_SIGN_IDENTITIES, []);

          reject(err);
        },
      });
    });
  },
  resetSignIdentities: ({ commit }) => {
    commit(mutationsTypes.SET_SIGN_IDENTITIES, []);
  },
};

export const getters = {
  currentSignIdentity: (state) => {
    return state.currentSignIdentity;
  },
  signIdentities: (state) => {
    return state.signIdentities;
  },
};
