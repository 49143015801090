<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="1000px"
    height="600px"
    viewBox="0 0 1000 600"
  >
    <rect fill="#FFFFFF" width="1000" height="600" />
    <rect y="200" fill="#009900" width="1000" height="400" />
    <rect y="400" fill="#CC0000" width="1000" height="200" />
  </svg>
</template>

<script>
export default {
  name: 'IcoFlagBG',
};
</script>
