<template>
  <v-menu
    v-model="open"
    class="input-color"
    transition="slide-y-transition"
    :close-on-content-click="false"
    offset-y
    bottom
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="color"
        :label="label"
        class="mb-5"
        outlined
        v-on="on"
        hide-details
        readonly
      >
        <template v-slot:append>
          <v-avatar :color="color" size="25" rounded></v-avatar>
        </template>
      </v-text-field>
    </template>

    <v-card class="pa-1 pt-0 mb-2" elevation="0">
      <v-color-picker v-model="color" type="hex" mode="hexa" class="mx-auto" elevation="5"></v-color-picker>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'ColorInput',
  data () {
    return {
      open: false
    }
  },
  props: {
    value: [Object, String],
    label: {
      type: String,
      required: false
    },
    default: {
      type: String,
      default: '#FFFFFF'
    }
  },
  computed: {
    color: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value.hex || value)
      }
    }
  },
  created () {
    if (typeof this.color === 'undefined') {
      this.color = this.default
    }
  },
  watch: {
    open: {
      handler: function (value) {
        if (value === false) {
          this.$emit('save')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-menu__content {
  box-shadow: unset !important;
}
</style>
