import {
  authHeader,
  apiTokenBasedFormHeader,
  apiTokenBasedHeader,
  generateAuthHeader,
} from '@/config/authHeader';
import { automaticLogout } from '@/middlewares/autoLogout';
import axios from 'axios';
import router from '@/router';
import environment from '@/config/environment';

/**
 *
 * @param method
 * @param endpoint
 * @param headers
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const apiRequest = ({ method, endpoint, headers, data }) => {
  const apiUrl = environment.getApiUrl();
  const endpointPath = endpoint.startsWith('/') ? endpoint : `/${endpoint}`;

  return axios
    .request({
      method: method,
      headers: headers,
      url: `${apiUrl}${endpointPath}`,
      data,
    })
    .then((resp) => {
      return resp.data;
    })
    .catch(async (err) => {
      if (401 === err?.response?.status) {
        await automaticLogout();

        throw { errorCode: 'Authentication.Expired' };
      }

      throw err?.response?.data || err;
    });
};

/**
 * Universal api request method
 *
 * @param authorization
 * @param authToken
 * @param method
 * @param endpoint
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const authorizedRequest = ({
  authorization = 'Bearer',
  authToken = null,
  method,
  endpoint,
  data = null,
}) => {
  const config = {
    method,
    endpoint,
    headers: generateAuthHeader({ authToken, authorization }),
  };

  if (null !== data) {
    config.data = JSON.stringify(data);
  }

  return apiRequest(config);
};

/**
 * Api request method for logged user in application
 *
 * @param method
 * @param endpoint
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 */
export const authorizedApiRequest = ({ method, endpoint, data = null }) => {
  const config = {
    method,
    endpoint,
    headers: authHeader(),
  };

  if (null !== data) {
    config.data = JSON.stringify(data);
  }

  return apiRequest(config);
};

/**
 * Api request method for public part of application
 *
 * @param authorization
 * @param authToken
 * @param method
 * @param endpoint
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 */
export const publicApiRequest = ({
  authorization = 'Bearer',
  authToken = null,
  method,
  endpoint,
  data = null,
}) => {
  const config = {
    method,
    endpoint,
    headers: generateAuthHeader({ authToken, authorization }),
  };

  if (null !== data) {
    config.data = JSON.stringify(data);
  }

  return apiRequest(config);
};

// TODO - methods to refactoring or delete

const request = (method, endpoint, headers, body) => {
  const apiUrl = environment.getApiUrl();
  const endpointPath = endpoint.startsWith('/') ? endpoint : `/${endpoint}`;

  return fetch(`${apiUrl}${endpointPath}`, { method, headers, body }).then((response) => {
    return response
      .json()
      .then((data) => {
        if (401 === response.status && 'Token expired' === data.message) {
          return router.push({ name: 'contractExpiredLink' });
        }

        if (
          406 === response.status &&
          'Signi.Exceptions.Auth.AuthTokenExpiredException' === data.errorCode
        ) {
          return router.push({ name: 'contractExpiredLink' });
        }

        if (401 === response.status) {
          return automaticLogout();
        }

        if (!response.ok) {
          throw data.message || response.statusText;
        }

        return data;
      })
      .catch((err) => {
        throw err.message || response.statusText || err.response || err;
      });
  });
};

export const apiAuthorizedRequest = (method, endpoint, data = null) => {
  const body = data !== null ? JSON.stringify(data) : null;

  return request(method, endpoint, authHeader(), body);
};

export const apiTokenBasedRequest = (method, endpoint, token, data = null) => {
  const body = data !== null ? JSON.stringify(data) : null;

  return request(method, endpoint, apiTokenBasedHeader(token), body);
};

export const apiTokenBasedFormRequest = (method, endpoint, token, data = null) => {
  return request(method, endpoint, apiTokenBasedFormHeader(token), data);
};
