<template>
  <SigniModal :value="true" width="fit-content" max-width="35rem" persistent @close="$emit('close')">
    <div :class="`import_contacts import_contacts-${currentStep} text-center`">
      <div class="import_contacts-step import_contacts-step--1">
        <h1>{{ $t('contacts.import') }}</h1>
        <p>{{ $t('contacts.import.help') }}</p>

        <pre><code>{{ $t('general.name') }};{{ $t('general.surname') }};{{ $t('general.email_simple') }};{{ $t('general.phone') }};{{ $t('contacts.contact_owner_email') }}</code></pre>

        <file-upload
          class="import_contacts-upload"
          :post-action="uploadConfig.url"
          :headers="uploadConfig.headers"
          extensions="csv"
          accept="text/csv"
          :drop="false"
          :multiple="false"
          v-model="files"
          :size="1024 * 1024"
          ref="upload">
          {{ $t('contacts.import') }}
        </file-upload>
        <div v-if="uploadedFile.error" class="import_contacts-error">
          {{ $t('upload.failed') }}
        </div>
      </div>

      <div class="import_contacts-step import_contacts-step--2">
        <h1>{{ $t('general.uploading') }}...</h1>
        <Loader class="register-loader" style="text-align: center; padding-left: 0" />
      </div>

      <div class="import_contacts-step import_contacts-step--3">
        <h1>{{ $t('general.done') }}!</h1>
        <p>
          {{ $t('contacts.import.stats_created') }}: <strong>{{ stats.created }}</strong><br />
          {{ $t('contacts.import.stats_updated') }}: <strong>{{ stats.updated }}</strong>
        </p>
      </div>
    </div>
  </SigniModal>
</template>

<script>
import { generateAuthHeader } from "@/config/authHeader";
import environment from "@/config/environment";
import FileUpload from 'vue-upload-component';
import Loader from '@/components/Loader.vue';
import SigniModal from "@/components/SigniModal";

export default {
  name: 'ImportContacts',
  components: {
    SigniModal,
    FileUpload,
    Loader,
  },
  props: ['groupsIdsCsv'],
  data() {
    return {
      currentStep: 1,
      uploadConfig: {
        url: `${environment.getApiUrl()}/api/v1/workspaces/${this.$route.params.workspace_id}/contacts/import?groups=${this.groupsIdsCsv || ''}`,
        headers: generateAuthHeader({
          authorization: 'Bearer',
          isForm: true,
        }),
      },
      files: [],
      stats: {},
    };
  },
  computed: {
    uploadedFile() {
      return this.files.length ? this.files[0] : {};
    },
  },
  watch: {
    uploadedFile() {
      const isUploading = this.currentStep === 2;
      if (!isUploading && !this.uploadedFile.active) {
        this.currentStep = 2;
        this.$refs.upload.update(this.files[0], {active: true});
      } else if (isUploading && !this.uploadedFile.active) {
        if (this.uploadedFile.success) {
          this.currentStep = 3;
          this.stats = this.uploadedFile.response;
          this.$emit('reloadContacts');
        } else {
          this.currentStep = 1;
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.import_contacts {
  pre {
    font-size: .8rem;
    background-color: #eee;
    margin: 1em 0;
    padding: 10px 1ex;
    overflow: auto;
  }
  .import_contacts-upload {
    opacity: 1;
  }
  .import_contacts-error {
    padding: 1ex;
    font-size: 20px;
    color: red;
  }
  // watcher stopped working, if html contains v-if
  .import_contacts-step {
    display: none;
  }
  &.import_contacts-1 .import_contacts-step--1,
  &.import_contacts-2 .import_contacts-step--2,
  &.import_contacts-3 .import_contacts-step--3 {
    display: block;
  }
}
</style>
