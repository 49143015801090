const routes = [
  {
    path: '/subscription',
    name: 'subscription',
    component: () =>
      import(/* webpackChunkName: "subscription" */ '@subscription/views/Subscription'),
  },
  {
    path: '/subscription/packages',
    name: 'subscriptionPackages',
    redirect: {
      name: 'subscription',
      hash: '#subscriptions',
    },
  },
  {
    path: '/subscription/credits',
    name: 'subscriptionCredits',
    redirect: {
      name: 'subscription',
      hash: '#credits',
    },
  },
  {
    path: '/subscription/billing-data',
    name: 'subscriptionBillingData',
    redirect: {
      name: 'subscription',
      hash: '#billing',
    },
  },
  {
    path: '/subscription/payment/:paymentId/done',
    name: 'subscriptionPaymentDone',
    redirect: (to) => {
      return {
        name: 'subscription',
        params: {
          paymentId: to.params.paymentId,
        },
      };
    },
  },
];

routes.forEach((route) => {
  route.meta = {
    ...route.meta,
    layout: () => import('@/layouts/EmptyLayout'),
    authRequired: true,
  };
});

export default routes;
