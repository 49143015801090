<template>
  <Data :showMenu="false" :shadow="false">
    <div class="pa-5 pt-0 text-center">
      <IcoContractCreated />
    </div>

    <v-card class="pa-2 pa-md-5 mx-auto" max-width="800px" elevation="2">
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-menu v-if="apiTokenHash && apiTokenHash.length" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="primary">mdi-share-variant-outline</v-icon>
            </v-btn>
          </template>
          <v-list>
            <div class="pa-3">
              <ShareLinks
                :links="apiTokenHash"
                :is-aml-links="isAmlVerification"
                containerClass="input contract-link-container"
                inputClass="input__data contract-link-share-input"
              />
            </div>
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-row class="px-5" align="center" justify="center" no-gutters>
        <v-col cols="12" class="pb-5">
          <h1 class="font-weight-medium mb-0">
            {{ $t('general.congratulations') }}
          </h1>
        </v-col>

        <template v-if="contract_flow.duplicatedContacts.length">
          <v-col cols="12">
            <div class="content-finish__box">
              <span class="subtitle-1 font-weight-regular">
                {{ $t('contract.completed.send_to_contacts') }}
              </span>

              <ul class="content-finish__box-people">
                <li v-for="sent in contract_flow.duplicatedContacts">
                  <router-link target="_blank" :to="{
                      name: 'documentsDetail',
                      params: {
                          workspace_id: $route.params.workspace_id,
                          contract_id: sent.contract_id
                      }
                    }"
                  >
                    {{ sent.contact_fullname }}
                  </router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </template>

        <template v-else>
          <v-col cols="12" class="pt-0 pb-5 text-center">
            <span
              v-if="contract.has_authentication === true && contract.is_verification_done !== true"
              class="subtitle-1 font-weight-regular"
            >
              {{ $t('contract.create.aml_done') }}
            </span>

            <span
              v-else-if="contract.has_sign_with_certificate || contract.has_sign_with_bank_id_sign"
              class="subtitle-1 font-weight-regular"
            >
              {{ $t('document.created') }}
            </span>

            <span
              v-else-if="contract_flow.isStateCompleted"
              class="subtitle-1 font-weight-regular"
            >
              {{ $t('contract.completed.by_all') }}
            </span>

            <span
              v-else-if="contract_flow.isStateReview"
              class="subtitle-1 font-weight-regular"
            >
              {{ $t('review.sent_to_reviewer') }}<br />

              <span v-for="(identity, index) in contract_flow.activeReviewers" :key="index">
                <strong>{{ identity }}</strong><br />
              </span>
            </span>

            <span
              v-else-if="contract_flow.sentEmails.length"
              class="subtitle-1 font-weight-regular"
            >
              {{ $t('contract.completed.send_to_next') }}<br />

              <span v-for="(identity, index) in contract_flow.sentEmails" :key="index">
                <strong>{{ identity }}</strong><br />
              </span>
            </span>

            <span
              v-else-if="contract_flow.nextIdentities.length"
              class="subtitle-1 font-weight-regular"
            >
              {{ $t('contract.completed.waiting_for_next') }}<br />

              <span v-for="(identity, index) in contract_flow.nextIdentities" :key="index">
                <strong>{{ identity }}</strong><br />
              </span>
            </span>

            <span
              v-else
              class="subtitle-1 font-weight-regular"
            >
              {{ $t('contract.completed.waiting_for_missing') }}
            </span>
          </v-col>

          <v-col cols="12" class="pt-2 pb-5 text-center">
            <v-btn
              color="primary"
              x-large
              @click="onShowContract"
            >
              {{ $t('contract.show_contract') }}
            </v-btn>
          </v-col>

          <v-col cols="12" class="pt-2">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" class="text-center py-3">
            <span class="font-weight-bold">
              {{ $t('contract.waiting.undone_documents') }}
            </span>
          </v-col>

          <v-col v-if="isLoadingUndoneDocuments" cols="12" class="text-center">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </v-col>

          <v-col
            v-else-if="!undoneDocuments.length"
            cols="10"
            class="text-center text-truncate"
          >{{ $t('no_next_contracts_to_sign') }}</v-col>

          <v-col
            v-else
            v-for="document in undoneDocuments"
            :key="document.id"
            cols="10"
            class="text-center text-truncate"
          >
            <v-icon color="#C6CFE2" left>mdi-file-document-outline</v-icon>
            <a href="#" class="text-decoration-none" @click.prevent="onOpenUndoneDocument(document.id)">
              <span class="primary--text text-decoration-underline font-weight-medium">{{ document.title }}</span>
            </a>
          </v-col>

          <v-col cols="12" class="pt-3">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" class="pt-2 pb-3 text-center">
            <v-btn text class="text-decoration-underline" @click="onBackToDashboard">
              {{ $t('contract.back_to_dashboard') }}
            </v-btn>
          </v-col>
        </template>
      </v-row>
    </v-card>
  </Data>
</template>

<script>
import { mapGetters } from 'vuex';
import { apiAuthorizedRequest } from "@/services/ApiService";
import { ContractService } from '@/services/ContractService.js';
import IcoContractCreated from '@/components/svg/IcoContractCreated.vue';
import ShareLinks from '@/components/ShareLinks.vue';
import { DataService } from "@/services/DataService";

export default {
  name: 'CreateCompleted',
  components: {
    IcoContractCreated,
    ShareLinks,
  },
  data() {
    return {
      loadedUndoneDocuments: false,
      shareLinkPopupOpen: false,
      sentEmails: [],
      contract_flow: {
        isStateCompleted: false,
        isStateReview: false,
        sentEmails: [],
        nextIdentities: [],
        duplicatedContacts: [],
        activeReviewers: [],
      },
      apiTokenHash: [],
      isSampleContract: false,
      isAmlVerification: false,
      undoneDocuments: [],
    }
  },
  computed: {
    ...mapGetters([
      'contract'
    ]),
    isLoadingUndoneDocuments() {
      return !this.loadedUndoneDocuments;
    },
    workspaceId() {
      return this.$route.params.workspace_id
    }
  },
  created() {
    this.loadContractFlow(this.contract);

    ContractService
      .detailWithLinks(this.$route.params.contract_id, this.$route.params.workspace_id)
      .then((resp) => {
        this.$store.dispatch('setContract', resp)
        this.loadContractFlow(resp);

        if (resp.has_authentication) {
          apiAuthorizedRequest('GET', `/api/v1/contracts/${this.$route.params.contract_id}/links`).then((resp) => {
            this.isAmlVerification = true
            this.apiTokenHash = resp.links || []
          })
        } else {
          this.apiTokenHash = resp.contract_flow && resp.contract_flow.shareLinks
            ? resp.contract_flow.shareLinks
            : []
        }

        const sampleContractId = localStorage.getItem('createdSampleContract');

        if (String(sampleContractId) === String(this.$route.params.contract_id)) {
          this.isSampleContract = true;
          localStorage.removeItem('createdSampleContract');
        }
      })
      .catch(() => {
        this.$notification.error(this.$t('contract.not_loaded'));
      });
  },
  mounted() {
    this.loadedUndoneDocuments = false;

    DataService
      .getContracts({
        workspace_id: this.workspaceId,
        states: [
          'pending.me' ],
      })
      .then((resp) => {
        this.undoneDocuments = resp.data;
      })
      .catch(() => {
        this.undoneDocuments = [];
      })
      .finally(() => {
        this.loadedUndoneDocuments = true;
      });
  },
  methods: {
    loadContractFlow(contract) {
      const safeIdentities = (field) =>
        contract && contract.contract_flow && contract.contract_flow[field] && contract.contract_flow[field].length
          ? contract.contract_flow[field] : [];
      const activeReviewers = contract && contract.contract_flow && contract.contract_flow.reviewers
        ? contract.contract_flow.reviewers.active
        : [];

      this.contract_flow.isStateReview = contract && contract.state === 'review';
      this.contract_flow.isStateCompleted = contract && contract.state === 'completed';

      if (activeReviewers && activeReviewers.length) {
        this.contract_flow.activeReviewers = activeReviewers.map(r => r.fullname);
      }

      if (this.contract_flow.sentEmails.length) {
        return;
      }

      this.contract_flow.sentEmails = safeIdentities('sentEmails');
      this.contract_flow.nextIdentities = safeIdentities('nextIdentities');
      this.contract_flow.duplicatedContacts = safeIdentities('duplicatedContacts');
    },
    onBackToDashboard() {
      return this.$router.push({
        name:  'dashboard',
        params: {
          'workspace_id': this.$route.params.workspace_id
        }
      })
    },
    onShowContract() {
      return this.$router.push({
        name: 'documentsDetail',
        params: {
          workspace_id: this.$route.params.workspace_id,
          contract_id: this.$route.params.contract_id
        },
        query: {
          isSampleContract: this.isSampleContract ? 'true' : 'false',
          showFull: 'true',
        }
      });
    },
    onOpenUndoneDocument(documentId) {
      this.$router.push({
        name: 'documentsDetail',
        params: {
          workspace_id: `${this.workspaceId}`,
          contract_id: `${documentId}`,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content-finish__box {
  position: relative;
}

ul.content-finish__box-people {
  list-style-type: none;
}
</style>
