<template>
  <v-dialog v-model="dialog" max-width="750px" width="100%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="customColor"
        :block="$vuetify.breakpoint.mobile"
        outlined
        x-large
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('signing.sign_on_mobile') }}
      </v-btn>
    </template>

    <v-card color="#fffad1">
      <v-btn
        icon
        style="position: absolute; top: 0.5rem; right: 0.5rem; z-index: 1"
        @click="onCloseDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-container class="px-5 pb-5 pt-5">
        <v-card
          v-if="canSendRequestToSignOnPhone"
          color="#fffad1"
          class="pa-5"
          elevation="0"
          rounded="lg"
        >
          <v-card-title>
            <h2 class="headline font-weight-bold mb-3">
              {{ $t('signing.sign_on_mobile') }}
            </h2>
          </v-card-title>

          <v-card-subtitle>
            <p class="subtitle-2 font-weight-medium mb-0">
              {{ $t('signing.signing_description') }}
            </p>
          </v-card-subtitle>

          <v-card-text>
            <v-row align="center" justify="space-between" no-gutters>
              <v-col cols="auto">
                <SPhoneNumberInput
                  v-model="phoneNumber"
                  autofocus
                  required
                  @on-validity-change="phoneNumberValid = $event"
                />
              </v-col>

              <v-col>
                <v-btn
                  :color="customColor"
                  class="mx-5"
                  max-width="12rem"
                  width="100%"
                  :loading="sendingToPhone"
                  :disabled="!isMobileValid"
                  x-large
                  style="color: #ffffff"
                  @click="onSignOnPhoneRequest"
                >
                  {{ $t('general.send') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card v-else color="#fffad1" class="pa-5" elevation="0" rounded="lg">
          <v-card-title>
            <h2 class="headline font-weight-bold mb-3">
              {{ $t('signing.waiting_for_singing_on_device') }} ({{ formattedTimeLeft }})
            </h2>
          </v-card-title>

          <v-card-subtitle>
            <p class="subtitle-2 font-weight-medium mb-0">
              {{ $t('signing.waiting_for_singing_on_device_description') }}
            </p>
          </v-card-subtitle>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import SPhoneNumberInput from '@/common/components/SPhoneNumberInput';
import { ContractService } from '@/services/ContractService';
import { mapGetters } from 'vuex';

export default {
  name: 'SignOnPhoneDialog',
  components: {
    SPhoneNumberInput,
  },
  props: {
    value: Boolean,
    signIdentity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      phoneNumber: '',
      phoneNumberValid: false,
      sendingToPhone: false,
      waitingChecker: null,
      contractChecker: null,
      timeOfWaitingToSignOnMobile: 0,
    };
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
    }),
    canSendRequestToSignOnPhone() {
      return !this.isWantingForSign;
    },
    customColor() {
      return this.contract?.settings?.primary_color || 'primary';
    },
    isMobileValid() {
      return this.phoneNumberValid;
    },
    isWantingForSign() {
      return this.waitingChecker !== null;
    },
    formattedTimeLeft() {
      const timeLeft = this.timeOfWaitingToSignOnMobile;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },
  },
  mounted() {
    this.phoneNumber = this.signIdentity?.mobile;
  },
  methods: {
    onCloseDialog() {
      this.closeRequestToSignOnPhone();
      this.closeContractChecker();
      this.dialog = false;
    },
    onSignOnPhoneRequest() {
      if (this.isMobileValid) {
        this.sendRequestToPhoneSign();
      } else {
        this.$notification.error(this.$t('contract.errors.mobile'));
      }
    },
    sendRequestToPhoneSign() {
      this.sendingToPhone = true;
      this.timeOfWaitingToSignOnMobile = 120;

      if (this.$route.params.hash) {
        ContractService.sendSignOnMobileLinkUnauthorized(
          this.signIdentity.id,
          {
            mobile: this.phoneNumber,
          },
          this.$route.params.hash,
        )
          .then(() => {
            this.setWaitingForSign();
          })
          .catch(() => {
            this.$notification.error(this.$t('contract.errors.send_sign_on_mobile'));
          })
          .finally(() => {
            this.sendingToPhone = false;
          });
      } else {
        ContractService.sendSignOnMobileLink(this.signIdentity.id, {
          mobile: this.phoneNumber,
        })
          .then(() => {
            this.setWaitingForSign();
          })
          .catch(() => {
            this.$notification.error(this.$t('contract.errors.send_sign_on_mobile'));
          })
          .finally(() => {
            this.sendingToPhone = false;
          });
      }
    },
    closeRequestToSignOnPhone() {
      if (this.waitingChecker) {
        clearInterval(this.waitingChecker);
        this.waitingChecker = null;
      }
    },
    closeContractChecker() {
      if (this.contractChecker) {
        clearInterval(this.contractChecker);
        this.contractChecker = null;
      }
    },
    setWaitingForSign() {
      this.waitingChecker = setInterval(() => {
        if (0 === this.timeOfWaitingToSignOnMobile) {
          this.closeRequestToSignOnPhone();
        } else {
          this.timeOfWaitingToSignOnMobile -= 1;
        }
      }, 1000);

      this.contractChecker = setInterval(() => {
        if (null === this.waitingChecker) {
          this.closeContractChecker();
        } else {
          this.checkContractState();
        }
      }, 5 * 1000);
    },
    checkContractState() {
      if (this.$route.params.hash) {
        ContractService.detailUnauthorized(
          this.contract.id,
          this.contract.workspace_id,
          null,
          this.$route.params.hash,
        ).then((resp) => {
          const signIdentity = resp.sign_identities.find((signIdentity) => {
            return signIdentity.id === this.signIdentity.id;
          });

          if (signIdentity?.is_signed) {
            this.$emit('contract-signed-on-remote-device', resp);
            this.onCloseDialog();
          }
        });
      } else {
        const workspaceId = this.contract?.one_device
          ? this.contract.workspace_id
          : this.signIdentity.workspace_id;

        ContractService.detail(this.contract.id, workspaceId).then((resp) => {
          const signIdentity = resp.sign_identities.find((signIdentity) => {
            return signIdentity.id === this.signIdentity.id;
          });

          if (signIdentity?.is_signed) {
            this.$emit('contract-signed-on-remote-device', resp);
            this.onCloseDialog();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep {
  .v-dialog {
    overflow-y: visible;
  }
}
</style>
