<template lang="html">
  <div
    v-if="areTemplatesLoaded"
    class="dashboard-documents__wrapper dashboard-templates"
    v-bind:class="{'dashboard-templates-favourites': !isSearchable}"
  >
    <component :is="headingTag" class="dashboard-documents__heading">
      {{ title }}
    </component>
    <div class="dashboard-documents__table-wrapper">
      <div class="dashboard-documents__filters" v-if="isSearchable">
        <div class="dashboard-documents__filters-desktop">
          <v-text-field
            type="text"
            v-model="searchQuery"
            :placeholder="$t('general.try_secrecy')"
            prepend-inner-icon="mdi-magnify"
            class="filters__search--input"
            hide-details
            clearable
            outlined
            @input="loadVisibleTemplates"
          ></v-text-field>
        </div>
      </div>
      <div v-if="rows.length" :class="{
        'document-table': true,
      }">
        <div class="document-table-header" v-if="isSearchable">
          <div class="document-table-item__name document-table-item__name-header">
            {{ $t('general.title') }}
          </div>
          <div class="document-table-item__created document-table-item__created-header">
            {{ $t('general.price') }}
          </div>
          <div class="document-table-item__changed document-table-item__changed-header">
          </div>
        </div>
        <div v-if="rows.length">
          <div class="document-table-item" v-for="item in rows" :key="item.id">
            <div class="document-table-row">
              <div class="document-table-row__name-author">
                <IcoFilePrev />
                {{ item.title }}
              </div>
              <div class="document-table-item__created document-table-row__created">
                <div v-if="parseInt(item.credit_cost) > 0">
                  {{ $t('contract.buy_short.text') }}
                  {{ $tc('contract.buy_short.x_credits', item.credit_cost, { count: item.credit_cost }) }}
                </div>
                <div v-else class="document-table-item__credits-amount-positive">
                  {{ $t('contract.template_free') }}
                </div>
              </div>
              <div :class="{
                'document-table-item__changed': true,
                'document-table-row__changed': true,
              }">
                <Button type="primary-small" @click="createContract(item)">{{ $t('general.create') }}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="searchQuery && searchQuery.length" class="mt-5">
        <div class="not-found">
          <div class="not-found__ico">
            <IcoContractRejected />
          </div>
          <div class="not-found__text">
            <p>{{ $t('general.nothing_found') }}</p>
          </div>
        </div>
      </div>
      <div v-else class="favourite-templates__empty">
        <div class="favourite-templates__empty__ico">
          <IcoFile />
        </div>
        <div class="favourite-templates__empty__content">
          <p>{{ $t('dashboard.any_favourite_templates') }}</p>
        </div>
      </div>

      <div v-if="isLinkDisplayed" class="center mt-5 more">
        <a @click="showAllTemplates()" class="more__link" href="#">
          <strong>{{ $t('dashboard.show_contract_patterns') }}</strong>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import IcoFile from '@/components/svg/IcoFile.vue';
import IcoFilePrev from '@/components/svg/IcoFilePrev.vue';
import IcoContractRejected from '@/components/svg/IcoContractRejected.vue';
import { mapGetters } from 'vuex';
import { ContractService } from '@/services/ContractService';

export default {
  name: 'TemplatesTable',
  props: ['title', 'templates', 'noSearch', 'isLinkHidden'],
  components: {
    IcoFile,
    IcoFilePrev,
    IcoContractRejected,
  },
  data() {
    return {
      searchQuery: '',
      rows: null,
    };
  },
  computed: {
    ...mapGetters([
      'profile',
      'workspaceById',
    ]),
    areTemplatesLoaded() {
      return this.rows !== null;
    },
    activeWorkspace() {
      return this.workspaceById(this.$route.params.workspace_id);
    },
    headingTag() {
      return this.isSearchable ? 'h1' : 'h2';
    },
    isSearchable() {
      return !this.noSearch;
    },
    isLinkDisplayed() {
      return this.$route.name != 'template' && !this.isLinkHidden;
    },
  },
  created() {
    this.loadVisibleTemplates();
  },
  methods: {
    loadVisibleTemplates() {
      if (!this.templates) {
        return;
      }
      if (this.searchQuery) {
        this.rows = this.templates.filter(t => t.title.toLowerCase().includes(this.searchQuery.toLowerCase()));
      } else {
        this.rows = this.templates;
      }
    },
    showAllTemplates() {
      if (this.$listeners && this.$listeners.showAllTemplates) {
        this.$emit('showAllTemplates');
      } else {
        this.$router.push({
          name: 'template',
          params: {
            workspace_id: this.$route.params.workspace_id,
          },
        });
      }
    },
    async createContract( item ) {
      if (this.$route.params.contract_id) {
        const payload = {
          title: item.title,
          type: 'form',
          number: '',
          tvid: item.id,
          workspace_id: this.$route.params.workspace_id,
          contract_id: this.$route.params.contract_id,
        };

        try {
          const { id } = await ContractService.addAttachment(payload, this.$route.params.contract_id);

          this.$router.push({
            name: 'createContent',
            params: {
              workspace_id: this.$route.params.workspace_id,
              contract_id: id,
            },
          });

        } catch (e) {
          this.loaded = true;

          if (e === 'Uživatel nemá dostatek kreditů') {
            this.$notification.error(this.$t('upload.error_credits'));

            return this.$router.push({ name: 'subscription' });
          }

          this.$notification.error(this.$t('upload.error_general'));
          return;
        }

      } else {
        const payload = {
          title: item.title,
          tvid: item.id,
          type: 'form',
        };
        try {
          const { id } = await ContractService.create(payload, this.activeWorkspace);
          this.$router.push({
            name: 'createContractNew',
            params: {
              workspace_id: this.$route.params.workspace_id,
            },
            query: {
              contract_id: id,
            }
          });
        } catch (e) {
          this.loaded = true;
          this.$notification.error(this.$t('upload.error_general'));
          return;
        }
      }
    }
  },
  watch: {
    'templates': 'loadVisibleTemplates',
  },
}
</script>


<style lang="scss">
// hotfix css from Documents - override grid-template-columns, unify widths...
.dashboard-templates.dashboard-documents__wrapper {
  text-align: left;
  .dashboard-documents__filters-desktop, .document-table-header, .document-table-row {
    display: flex;
    justify-content: space-between;
    align-items: left;
    & > div {
      display: block;
    }
    & > div:first-child {
      width: 50%;
    }
    & > div:nth-child(2) {
      width: 20%;
    }
    & > div:nth-child(3) {
      width: 30%;
      text-align: right;
    }
    @include md {
      display: block;
      & > div {
        display: block;
        text-align: left;
        width: 100% !important;
      }
    }
  }
  .document-table-header > div {
    line-height: 53px;
  }
  .document-table-row > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: #fff;
    line-height: 30px;
    font-size: 15px;
    @include md {
      line-height: normal;
    }
  }
  .document-table-row__name-author svg {
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }
  .document-table-item__changed .button {
    min-width: unset;
    height: 30px;
    margin-bottom: 0;
    @include md {
      width: 100%;
    }
  }
  &.dashboard-templates-favourites {
    margin: 15px auto;
    .dashboard-documents__heading {
      text-align: center;
      margin: 30px 0;
    }
    .document-table-item:first-child .document-table-row {
      border-top: 1px solid #E1E4E8;
    }
  }
}

.more {
  padding-top: 28px;
  text-align: center;
}
.more__link {
  font-weight: 400;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  strong {
    font-weight: 500;
  }
}

.favourite-templates__empty{
  text-align: center;
}
.favourite-templates__empty__ico{
  margin-top: 36px;
}
.favourite-templates__empty__content{
  margin-top: 19px;
}

.not-found__ico {
  text-align: center;
}
.not-found__text {
  text-align: center;
  margin-top: 30px;
}
.not-found__text p {
  font-weight: 500;
}
</style>
