<template>
  <div
    class="multiselect-wrap"
    :class="{'multiselect-clicked':selectOpened}"
    v-click-outside="closeSelect"
  >
    <div class="multiselect-wrap-under">
      <span v-if="label" class="multiselect__label" v-line-clamp="1">
        {{placeholder}}
        {{ label }}
      </span>

      <div class="multiselect" tabindex="0" @click="toggleSelect">
        <span v-if="selectedOptions.length" class="multiselect__placeholder">
          <span class="multiselect-placeholdertext">{{placeholder}}</span>
          {{ selectText }}
        </span>

        <span v-else-if="placeholder" class="multiselect__placeholder">{{placeholder}}</span>

        <span v-if="selectOpened" class="multiselect-mask"></span>

        <span class="multiselect__arrow">
          <span class="icon-bottom"></span>
        </span>
      </div>

      <div v-if="selectOpened" class="multiselect__data">
        <ul style="padding-left: 0">
          <li
            v-if="isSelectAllEnabled"
            :class="{ 'select-all': !showNoTagsOption }"
            @click.stop="selectAll"
          >
            <Checkbox :value="areCheckedAll" :disabled="!options.length" />
            {{ $t("general.all") }}
          </li>

          <li
            v-if="showNoTagsOption"
            class="select-all"
            @click.stop="selectNoTags"
          >
            <Checkbox :value="isNoTagSelected" :disabled="!options.length" />
            {{ $t("no_tag") }}
          </li>

          <li
            v-for="(option, index) in selectOptions"
            :key="index"
            @click.stop="selectOption(option)"
          >
            <Checkbox :value="option.isSelected" />
            {{option.name}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSelect',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    isSelectAllDisabled: {
      type: Boolean,
      default: false,
    },
    showNoTagsOption: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selectOpened: false,
      isNoTagSelected: false,
    }
  },
  computed: {
    selectOptions() {
      return this.options.filter((option) => !option?.root)
    },
    selectText() {
      if (this.areCheckedAll && this.isSelectAllEnabled) {
        return this.$t("general.all");
      }
      return this.selectedOptions.map(item => item.name).join(', ');
    },
    isSelectAllEnabled() {
      return !this.isSelectAllDisabled;
    },
    areCheckedAll() {
      return this.options.length && this.selectedOptions.length === this.options.length;
    },
    selectedOptions() {
      return this.options.filter(item => item.isSelected);
    },
  },
  methods: {
    toggleSelect() {
      this.selectOpened = !this.selectOpened;
    },
    openSelect() {
      this.selectOpened = true;
    },
    selectOption(option) {
      option.isSelected = !option.isSelected;
      this.emitChangeInSelected();
    },
    selectAll() {
      const areCheckedAll = this.areCheckedAll;
      this.options.forEach(option => {
        option.isSelected = !areCheckedAll;
      })
      this.emitChangeInSelected();
    },
    selectNoTags() {
      const noTagOption = this.options?.find((option) => option.key === 'no_tag');

      this.isNoTagSelected = !this.isNoTagSelected;
      noTagOption.isSelected = this.isNoTagSelected

      this.emitChangeInSelected();
    },
    emitChangeInSelected() {
      const selectedKeys = this.selectedOptions.map(item => item.key);
      this.$emit('saveSelected', selectedKeys);
    },
    closeSelect() {
      this.selectOpened = false;
    },
    onBlur() {
      setTimeout(() => {
        this.selectOpened = false;
      }, 250);
    },
  },
}
</script>

<style lang="scss">
.multiselect-wrap {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

.multiselect-wrap .select:focus, .multiselect-wrap .select:active {
  display: block;
  border: solid 1px #5E239E;
}

.multiselect-clicked .tabs-component-tabs{
  z-index: 9;
}

.multiselect {
  cursor: pointer;
  display: block;
  position: relative;
  width: 310px;
  outline: none;
  background: #FFFFFF;
  border: 1px solid #B1BBCB;
  border-radius: 6px;
  height: 56px;
  font-size: 16px;
  color: black;
  letter-spacing: -0.03px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 54px;
  font-weight: 300;
  padding: 0 20px;
  padding-right: 55px !important;
  @include md {
    width: 100%;
  }
}

.multiselect__placeholder {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 100%;
  color: gray;
  font-weight: 400;
  font-size: 16px;
}

.multiselect__arrow{
  font-size: 10px;
  position: absolute;
  right: 20px;
  transition: 0.2s;
  line-height: 10px;
  top: 50%;
  line-height: 54px;
  display: block;
}

.multiselect__arrow .icon-bottom {
  display: block;
  top: 0px;
  transform: translateY(-50%);
  position: absolute;
  right: 0px;
  line-height: 54px;
}

.multiselect__data{
  display:none;
  background: white;
  width: 310px;
  margin-left: auto;
  margin-right: auto;
  border: solid 1px #5E239E;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  max-height: 235px;
  overflow-y: scroll !important;
  position: absolute;
  z-index: 10;
  left: 0;

  @include md {
    width: 100%;
  }

  ul {
    padding-bottom: 5px;
    margin-left: 7px;

    li {
      display: flex;
      align-items: center;
      list-style-type: none;
      padding: .5rem 1.3rem;
      font-size: 15px;
      color: #000000;
      font-weight: 300;
      transition: 0.2s;
      cursor:pointer;

      &:hover{
        background: #F2F6FC;
        transition: 0.2s;
      }
    }

    .select-all {
      border-bottom: 1px solid #C6D1E0;
      margin-bottom: .8rem;
      padding: .5rem 1.3rem .8rem;
      display: flex;
      align-items: center;
    }

    .checkbox-wrap {
      margin: 0;
    }
  }
}


.multiselect-clicked{
  .multiselect__data{
    display:block;
    z-index: 1001;
  }
  .multiselect{
    border: 1px solid #5E239E;
    border-bottom:0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .icon-bottom{
    display: block;
    top: 0px;
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
    position: absolute;
    right: 0px;
    line-height: 54px;
  }
}

.content-finish__box .multiselect-wrap label{
  width: 180px;
  display: block;
}

.content-finish__box .multiselect{
  width: 320px;
}


.content-finish__box .multiselect{
  text-align: left;
  width: 180px;
  margin-left: 0;
}

.content-finish__box .multiselect-wrap{
  width: 320px;
  margin-left: auto;
  margin-top: 15px;
  margin-right: auto;
}
.content-finish__box .input{
  width: 320px;
}

.content-finish__box .multiselect__data{
  text-align: left;
  width: 100%;
}

.content-finish__box .multiselect-wrap-under{
  width: 180px;
  position: relative;
}

.multiselect__label{
  font-weight: normal;
  font-size: 15px;
  color: black;
  letter-spacing: -0.03px;
  margin-bottom: 5px;
  text-align: left;
  display: block;
}
.multiselect-wrap-under {
  position: relative;
}
.multiselect-mask {
  width: 100%;
  position: absolute;
  z-index: 1999;
  height: 4px;
  left: 0;
  bottom: -2px;
  background: white;
}
.multiselect-placeholdertext {
  color: gray;
}
</style>
