<template>
  <v-dialog
    v-model="signWithCertificate"
    transition="scale-transition"
    max-width="750px"
    width="100%"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card class="px-2 py-3">
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-btn icon @click="signWithCertificate = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card class="px-md-13" elevation="0">
        <v-card-title>
          <h2 class="display-1 font-weight-bold mb-2 mb-md-4">
            {{ $t('qualified_signature') }}
          </h2>
        </v-card-title>

        <v-card-subtitle
          class="black--text mb-2 mb-md-4"
          style="font-size: 1rem; line-height: 1.5rem"
        >
          {{ $t('sign.certificate.info') }}
        </v-card-subtitle>

        <v-card-text class="mb-0">
          <v-card class="sign-type pa-1 pa-md-5" elevation="0">
            <v-card-title>
              <h2 class="headline font-weight-bold mb-2 mb-md-4">
                {{ $t('app') }}
              </h2>
            </v-card-title>

            <v-card-subtitle class="sign-type__subtitle black--text">
              <span v-html="$t('signing.signi_app.info')"></span>&nbsp;<a
                href="#"
                class="link"
                @click="onOpenSigniAppDownloadLink"
                >{{ $t('signing.signi_app.info_end') }}</a
              >.
            </v-card-subtitle>

            <v-card-text>
              <v-row align="center" justify="start">
                <v-col cols="auto">
                  <a
                    href="https://apps.apple.com/cz/app/signi-com/id1596763917?mt=12"
                    target="_blank"
                  >
                    <img
                      :src="appleStoreDownloadImage"
                      height="35px"
                      width="auto"
                      alt=""
                      style="border-radius: 6px"
                    />
                  </a>
                </v-col>

                <v-col cols="auto">
                  <a href="https://www.microsoft.com/store/productId/9NZZX3VRZ2SK" target="_blank">
                    <img
                      :src="msDownloadImage"
                      height="35px"
                      width="auto"
                      alt=""
                      style="border-radius: 6px"
                    />
                  </a>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-btn
                class="d-block"
                :width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
                color="primary"
                x-large
                @click="openSigniDesktopApp"
              >
                <span class="text-truncate">{{ $t('signing.open_app_and_sign') }}</span>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-text class="pb-md-15">
          <v-card class="sign-type pa-1 pa-md-5" elevation="0">
            <v-card-title>
              <h2 class="headline font-weight-bold mb-2 mb-md-4">
                {{ $t('signing.sign_outside') }}
              </h2>
            </v-card-title>

            <v-card-subtitle class="sign-type__subtitle black--text" style="line-height: 1.5rem">
              <b>{{ $t('how_does_it_work') }}</b>
              <v-icon small>mdi-paperclip</v-icon>
              <a href="#" class="link" @click.prevent="downloadDocument">{{
                $t('download_document')
              }}</a
              >,
              {{ $t('signing.outside.info') }}
            </v-card-subtitle>

            <v-card-text class="text-center">
              <div class="" v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                <v-file-input
                  v-model="file"
                  color="primary"
                  :label="$t('signing.drag_signed_document')"
                  outlined
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="primary" dark label small>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </div>

              <v-btn
                color="primary"
                :block="$vuetify.breakpoint.mobile"
                :disabled="file === null"
                :loading="uploading"
                max-width="12rem"
                width="100%"
                x-large
                @click="uploadDocument"
              >
                {{ $t('general.send') }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { authorizedApiRequest, authorizedRequest } from '@/services/ApiService';
import { buildQuery } from '@/common/reusable/query';
import { goToLinkWithTarget } from '@/common/reusable/routing';
import download from 'downloadjs';
import axios from 'axios';
import environment from '@/config/environment';
import { mapGetters } from 'vuex';

export default {
  name: 'SignWithCertificate',
  props: {
    value: Boolean,
    contractTitle: {
      type: String,
      default: 'document.pdf',
    },
    contractId: {
      type: [Number, String],
      required: true,
    },
    signIdentityId: {
      type: [Number, String],
      required: true,
    },
    activeContractId: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      document: null,
      file: null,
      uploading: false,
    };
  },
  computed: {
    ...mapGetters({
      workspace: 'workspace/activeWorkspace',
      workspaceById: 'workspaceById',
    }),
    activeWorkspace() {
      return (
        this.workspace?.title ||
        this.workspaceById(this.$route.params.workspace_id)?.title ||
        'Signi'
      );
    },
    signWithCertificate: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    documentData() {
      return this.document;
    },
    parameters() {
      return buildQuery({
        id_podpisu: this.document?.anchor,
        organizace: '', // this.activeWorkspace,
        url: this.documentUrl,
        url_podpisu: `${environment.getAppUrl()}/images/logo.png`,
        url_upload: this.document?.url_file_upload,
        url_callback: this.document?.url_callback,
      });
    },
    documentUrl() {
      return this.document?.url_file_download;
    },
    appleStoreDownloadImage() {
      return require('../assets/apple-store-badge.svg');
    },
    msDownloadImage() {
      return require('../assets/ms-store-badge.svg');
    },
  },
  watch: {
    signWithCertificate: {
      handler: async function (value) {
        if (!value) {
          return (this.document = null);
        }

        if (this.$route?.params?.hash) {
          authorizedRequest({
            authorization: 'ApiToken',
            authToken: this.$route?.params?.hash,
            method: 'GET',
            endpoint: `api/client/v1/contract/${this.activeContractId}/signaturesWithCertificate/signIdentity/${this.signIdentityId}/info`,
          }).then((resp) => {
            this.document = resp;
          });
        } else {
          authorizedApiRequest({
            method: 'GET',
            endpoint: `api/v2/contract/${this.activeContractId}/signaturesWithCertificate/signIdentity/${this.signIdentityId}/info`,
          }).then((resp) => {
            this.document = resp;
          });
        }
      },
    },
  },
  methods: {
    addDropFile(e) {
      this.file = e.dataTransfer.files[0];
    },
    getOS() {
      const platform = window.navigator.platform;

      if (['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'].indexOf(platform) !== -1) {
        return 'Mac OS';
      } else if (['iPhone', 'iPad', 'iPod'].indexOf(platform) !== -1) {
        return 'iOS';
      } else if (['Win32', 'Win64', 'Windows', 'WinCE'].indexOf(platform) !== -1) {
        return 'Windows';
      } else if (/Android/.test(window.navigator.userAgent)) {
        return 'Android';
      } else if (/Linux/.test(platform)) {
        return 'Linux';
      }

      return 'Windows';
    },
    openSigniDesktopApp() {
      goToLinkWithTarget(`signi-app://sign-pdf?${this.parameters}`, '_blank');
    },
    onOpenSigniAppDownloadLink() {
      return goToLinkWithTarget(
        'Mac OS' === this.getOS()
          ? 'https://apps.apple.com/cz/app/signi-com/id1596763917?mt=12'
          : 'https://www.microsoft.com/store/productId/9NZZX3VRZ2SK',
      );
    },
    async downloadDocument() {
      download(`${this.documentUrl}`);
    },
    async uploadDocument() {
      this.uploading = true;

      const data = new FormData();
      data.append('document', this.file);

      if (this.$route?.params?.hash) {
        await axios.post(
          `${environment.getApiUrl()}/api/client/v1/contract/${
            this.contractId
          }/signaturesWithCertificate/signIdentity/${this.signIdentityId}/upload`,
          data,
          {
            headers: {
              Authorization: `ApiToken ${this.$route?.params?.hash}`,
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        return this.$router.push({
          name: 'contractComplete',
          params: {
            hash: this.$route.params.hash,
          },
        });
      } else {
        await axios.post(
          `${environment.getApiUrl()}/api/v2/contract/${
            this.contractId
          }/signaturesWithCertificate/signIdentity/${this.signIdentityId}/upload`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        window.location.reload();
      }

      this.uploading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-type {
  border: 1px solid #dee7f4;
  border-radius: 6px;

  &__subtitle {
    font-size: 0.95rem;
  }
}

.link {
  color: var(--v-primary-base) !important;
  font-weight: inherit;
  font-size: inherit;
}
</style>
