<template>
  <div class="px-5 px-md-10">
    <v-menu v-model="unpackedMenu" :disabled="isDisabled" nudge-bottom="4rem" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <h2
          class="mb-0 headline font-weight-bold"
          style="width: fit-content"
          :style="!isDisabled ? 'border-bottom: 1px dashed #b1b1b1' : ''"
          v-bind="attrs"
          v-on="on"
        >
          {{ templateTitle }}
          <v-icon v-if="!isDisabled" color="primary" class="ml-2" large>
            {{ `mdi-chevron-${unpackedMenu ? 'up' : 'down'}` }}
          </v-icon>
        </h2>
      </template>

      <v-card style="border: 1px solid #979fab; border-radius: 8px">
        <v-list class="py-0">
          <v-list-item link @click="onSelectTemplate(null)">
            <v-list-item-title class="subtitle-1 font-weight-regular">
              {{ $t('contract.create.header') }}
            </v-list-item-title>
          </v-list-item>

          <v-divider color="#DEE7F4"></v-divider>

          <article v-for="(item, index) in templatesListPredefinedSettings" :key="item.id">
            <v-list-item class="py-0 my-0" link>
              <v-list-item-title
                class="subtitle-1 font-weight-regular"
                @click="onSelectTemplate(item)"
              >
                {{ item.name }}
              </v-list-item-title>

              <v-list-item-action class="my-0">
                <v-btn color="#8E97A4" icon @click="onDeleteTemplate(item.id)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider
              v-if="index !== templatesListPredefinedSettings.length - 1"
              color="#DEE7F4"
            ></v-divider>
          </article>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PredefinedWorkflowSelect',
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      selectedTemplate: null,
      unpackedMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      templatesListPredefinedSettings: 'predefinedWorkflow/list',
    }),
    isDisabled() {
      return 0 === this.templatesListPredefinedSettings?.length;
    },
    isSelectedWorkflow() {
      return !!this.selectedTemplate;
    },
    templateTitle() {
      return this.isSelectedWorkflow
        ? this.selectedTemplate?.name
        : this.$t('contract.create.header');
    },
  },
  methods: {
    ...mapActions({
      deletePredefinedSettingsTemplate: 'predefinedWorkflow/deleteSettingsTemplate',
    }),
    onSelectTemplate(template) {
      this.selectedTemplate = template;
      this.$emit('on-select-template', template);
    },
    onDeleteTemplate(templateId) {
      this.deletePredefinedSettingsTemplate({
        workspaceId: this.$route.params.workspace_id,
        templateId,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
