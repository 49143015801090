<template>
  <div>
    <v-badge v-if="hasVerification" avatar overlap>
      <template v-slot:badge>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-avatar
              v-on="on"
              slot="activator"
              :color="hasVerificationExpired ? 'error' : 'success'"
              :size="avatarSize"
            >
              <v-icon>
                {{ `mdi-${hasVerificationExpired ? 'close' : 'check'}` }}
              </v-icon>
            </v-avatar>
          </template>

          <span>{{ description }}</span>
        </v-tooltip>
      </template>

      <SAvatar
        :firstname="contact.firstname"
        :lastname="contact.lastname"
        :position="contact.variable_position"
        :size="avatarSize"
      />
    </v-badge>

    <SAvatar
      v-else
      :firstname="contact.firstname"
      :lastname="contact.lastname"
      :position="contact.variable_position"
      :size="avatarSize"
    />
  </div>
</template>

<script>
import { getFormattedDate } from '@/common/reusable/dateFunctions';
import SAvatar from '@/common/components/SAvatar';

export default {
  name: 'VerificationBadge',
  components: {
    SAvatar,
  },
  props: {
    avatarSize: {
      type: Number,
      default: 50,
    },
    contact: {
      type: Object,
      required: true,
    },
  },
  computed: {
    verification() {
      return this.contact?.valid_auths[0] || false;
    },
    description() {
      return `
        ${this.$t(`${this.verification.key}.verification`)} -
        ${
          this.$t('general.valid_until').charAt(0).toLowerCase() +
          this.$t('general.valid_until').slice(1)
        }
        ${getFormattedDate({
          date: this.verification.valid_to,
          format: 'DD.MM.YYYY',
        })}
      `;
    },
    hasVerification() {
      return this.contact?.valid_auths?.length > 0;
    },
    hasVerificationExpired() {
      return new Date(this.verification.valid_to) < new Date();
    },
  },
};
</script>

<style lang="scss" scoped></style>
