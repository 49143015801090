<template>
  <div
    class="contract-document-tab pa-5"
    :class="{
      'contract-document-tab__active': canShowHighlighting,
      'pl-4': isMobileDevice,
      'pl-6': !isMobileDevice,
    }"
  >
    <v-row align="center" justify="start" no-gutters>
      <v-col v-if="isMobileDevice" cols="1">
        <v-btn icon class="pt-0 mt-0" @click="changeContract(contract.id)">
          <v-icon v-if="isDocumentOpen" class="pt-0 mt-0" color="primary" large>
            mdi-chevron-up
          </v-icon>
          <v-icon v-else color="primary" large> mdi-chevron-down </v-icon>
        </v-btn>
      </v-col>

      <v-col
        :cols="isMobileDevice ? 10 : 11"
        class="text-left pr-0"
        :class="{ 'pl-3': isMobileDevice }"
      >
        <v-hover v-slot="{ hover }">
          <a
            href="#"
            class="text-decoration-none text-left pl-0 pr-auto primary--text"
            :class="{ 'text-decoration-underline': hover }"
            @click.prevent="changeContract(contract.id)"
          >
            <span style="word-wrap: break-word; overflow: hidden">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-if="isUnfinished"
                    size="20"
                    class="mr-2"
                    rounded
                    v-bind="attrs"
                    v-on="on"
                    style="float: left"
                  >
                    <v-icon color="#F8E71D">mdi-alert</v-icon>
                  </v-avatar>
                </template>
                <span>{{ $t('contract.info.undone') }}</span>
              </v-tooltip>
              {{ contract.title }}
            </span>
          </a>
        </v-hover>
      </v-col>

      <v-col cols="1" class="text-left pr-0">
        <v-menu transition="slide-y-transition" bottom offset-y style="z-index: 1001 !important">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list class="py-0" max-width="17rem" width="17rem">
            <v-list-item v-if="canChangeContract" link @click="replaceContractDocument">
              <v-list-item-icon class="mr-3">
                <v-icon color="#AEBACE">mdi-file-document-edit-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                class="font-weight-medium text-decoration-underline text-left py-2"
                style="color: #666b71 !important"
              >
                {{ $t('contract.replace_document') }}
              </v-list-item-content>
            </v-list-item>

            <v-divider v-if="canChangeContract && canDeleteContract" class="mx-2"></v-divider>

            <v-list-item
              v-if="canDeleteContract"
              link
              @click.prevent="showDeleteContractDocumentDialog = true"
            >
              <v-list-item-icon class="mr-3">
                <v-icon color="error">mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                class="font-weight-medium text-decoration-underline text-left py-2"
                style="color: #666b71 !important"
              >
                {{ $t('contract.remove_document') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-divider v-if="isDocumentOpen && isMobileDevice" class="mt-4 ml-2 mr-1 mb-2"></v-divider>

    <v-row v-if="isDocumentOpen && isMobileDevice">
      <v-col cols="12" :class="{ 'pa-0 mt-1': contract.type !== 'form' }">
        <slot></slot>
      </v-col>
    </v-row>
    <SConfirmDialog
      v-model="showDeleteContractDocumentDialog"
      :message="`${$t('docs.delete.confirm')}`"
      @confirmation-callback="deleteContractDocument"
    />
  </div>
</template>

<script>
import { ContractService } from '@/services/ContractService';
import SConfirmDialog from '@/common/components/SConfirmDialog';

export default {
  name: 'CreateContractDocumentTab',
  components: {
    SConfirmDialog,
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
    contracts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDeleteContractDocumentDialog: false,
      open: false,
    };
  },
  computed: {
    canShowHighlighting() {
      return this.isDocumentOpen && !this.isMobileDevice;
    },
    canChangeContract() {
      return 'doc' === this.contract.type;
    },
    canDeleteContract() {
      return this.contracts?.length > 1;
    },
    isDocumentOpen() {
      return parseInt(this.$route.params.contract_id) === parseInt(this.contract.id) && this.open;
    },
    isUnfinished() {
      const isAmlVerification = this.contract?.has_authentication || false;
      const isAmlVerificationDone = this.contract?.is_send_auths || false;

      return !(this.contract.state !== 'draft' || (isAmlVerification && isAmlVerificationDone));
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {
    this.open = true;
  },
  methods: {
    changeContract(contractId = null) {
      if (
        parseInt(this.contract.id) === parseInt(this.$route.params.contract_id) ||
        null === contractId
      ) {
        this.open = this.isMobileDevice ? !this.open : this.open;

        return;
      }

      this.open = true;
      this.$router.push({
        name: 'createContent',
        params: {
          workspace_id: this.$route.params.workspace_id,
          contract_id: contractId,
        },
      });
    },
    replaceContractDocument() {
      const self = this;
      setTimeout(() => {
        self.$router.push({
          name: 'createUpload',
          query: {
            replace: 'true',
          },
        });
      }, 450);
    },
    deleteContractDocument() {
      const self = this;
      const contractId = this.contract.id;

      ContractService.delete(contractId).then(
        () => {
          const nextContract = self.contracts.find(
            (contract) =>
              parseInt(contract.id) !== parseInt(self.$route.params.contract_id) &&
              'draft' === contract.state,
          );
          const nextNonDraftContract = self.contracts.find(
            (contract) => parseInt(contract.id) !== parseInt(self.$route.params.contract_id),
          );

          if ('undefined' === typeof nextContract?.id) {
            self.$router.push({
              name: 'documentsDetail',
              params: {
                contract_id: self.contract?.main_contract_id || nextNonDraftContract.id,
                workspace_id: self.$route.params.workspace_id,
              },
            });
          }

          this.$notification.success(this.$t('docs.delete.ok'));

          if (parseInt(nextContract.id) !== parseInt(contractId)) {
            return self.$router.push({
              name: 'createContent',
              params: {
                workspace_id: self.$route.params.workspace_id,
                contract_id: nextContract.id,
              },
            });
          }

          location.reload();
        },
        () => {
          this.$notification.error(this.$t('docs.delete_draft.failed'));
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-document-tab {
  border-bottom: 1px solid #d8d8d8;
  background-color: #ffffff;
  position: relative;

  &__active {
    border-left: 3px solid #7240ab;
  }

  &__active::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -22px;
    z-index: 1;
    border-bottom: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    background-color: #ffffff;
    transform: rotate(-45deg) translateY(-50%);
    height: 25px;
    width: 25px;
  }
}
</style>
