import router from '@/router'
import store from '@/store';

// auto logout if 401 response returned from api
export function autologoutIfUnauthorized (response) {
  if (response.status === 401) {
    if (!store.getters['profile']) {
      return router.push({ name: 'dashboard' });
    }

    localStorage.removeItem('token')
    const urlBeforeLogin = window.location.href

    if (urlBeforeLogin.indexOf('redirect_to') !== -1) {
      // there might be multiple parallel API requests, redirect only first one
      return
    }

    if (isRoutePublicPage(router.currentRoute.name)) {
      return
    }

    return router.push({
      name: 'login',
      query: {
        redirect_to: urlBeforeLogin
      }
    })
  }
}

export function isRoutePublicPage (route) {
  return [
    'login',
    'passwordReset',
    'contractReceived',
    'public-sign-reject',
    'contractExpiredLink',
    'contractView',
    'contractData',
    'contractVerifyIdentity',
    'contract2FAStart',
    'contract2FASubmit',
    'contractConfirm',
    'contractComplete',
    'contractSign',
    'register',
    'registerEmail',
    'passwordForgotten',
    'registerToConfirm',
    'registrationConfirm',
    'notFound',
    'success-sign-sms'
  ].includes(route);
}
