<template>
  <div
    class="tutorial-tab__wrapper"
    :class="`tutorial-tab__wrapper-type-${borderStyle} ${isOpen ? 'tutorial-tab-open' : 'tutorial-tab-closed' }`"
    v-bind:style="{ background: background }"
  >
    <template v-if="isOpen">
      <div class="tutorial-tab__inner">
        <div class="tutorial-tab__inner-content">
          <h2 class="tutorial-tab__heading" v-html="heading" @click="onToggleClick"></h2>
          <p class="tutorial-tab__content" v-html="content"></p>
          <v-btn
            v-if="button"
            color="primary"
            class="mb-2"
            x-large
            @click="button.action"
          >
            {{ button.text }}
          </v-btn>

          <a class="tutorial-tab__link-secondary" v-if="linkSecondary" type="secondary" :href="linkSecondary.link" target="_blank" v-html="linkSecondary.text"></a>
        </div>
        <div class="tutorial-tab__inner-icon">
          <img v-if="icon" :src="icon">
        </div>
      </div>
    </template>
    <template
      v-else
    >
      <h2 class="tutorial-tab__heading" v-html="heading" @click="onToggleClick"></h2>
    </template>
    <div @click="onToggleClick"
         class="toggle">
      <IcoArrowTop
        v-if="type === 'collapsable'"
      />
      <IcoClose
        v-else-if="type !== 'banner'"
      />
    </div>
  </div>
</template>
<script>
  import IcoArrowTop from '@/components/svg/IcoArrowTop.vue';
  import IcoClose from '@/components/svg/IcoClose.vue';

  export default {
    name: 'TutorialTab',
    components: {
      IcoArrowTop,
      IcoClose,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      heading: {
        type: String,
        required: true,
      },
      content: {
        type: String,
        required: true,
      },
      button: {
        type: Object,
        required: false,
      },
      linkSecondary: {
        type: Object,
        required: false,
      },
      icon: {
        type: String,
        required: false,
      },
      collapsed: {
        type: Boolean,
        required: true,
      },
      borderStyle: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
      background: {
        type: String,
        required: true,
      },
    },
    computed: {
      isOpen() {
        return !this.collapsed;
      },
    },
    methods: {
      onToggleClick() {
        this.$emit('toggle', this.id);
      },
    },
  }
</script>
<style lang="scss">
  .tutorial-tab__wrapper {
    position: relative;
    border-radius: 10px;
    padding: 50px;
    margin-bottom: 20px;

    @include md {
      padding: 10px;
    }

    .tutorial-tab__heading {
      font-weight: bold;
      font-size: 24px;
    }

    .tutorial-tab__content {
      font-size: 17px;
      color: black;
      font-weight: 400;
      span {
        float: left;
        a {
          float: none;
          display: inline;
          margin: 0;
          line-height: inherit;
          padding: 0;
        }
      }
    }
    .tutorial-tab__inner {
      display: flex;
      align-items: center;
      justify-content: left;
      @include md {
        flex-direction: column-reverse;
      }
      .tutorial-tab__inner-content {
        width: 70%;
        @include md {
          width: 90%;
        }
        button {
          width: fit-content;
          max-width: 100%;
          min-width: 290px;

          @include md {
            min-width: unset;
          }
        }
        a {
          color: #666;
          font-weight: normal;
        }
      }
      .tutorial-tab__inner-icon {
        width: 30%;
        @include md {
          width: 50%;
          margin: 20px;
          max-width: 120px;
        }
        img {
          margin: 0 auto;
          display: block;
          width: 70%;
        }
      }
    }
  }
  .tutorial-tab__wrapper-type-shadow {
    box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.1);
  }
  .tutorial-tab__wrapper-type-solid {
    border: 1px solid #d8d8d8;
  }
  .tutorial-tab-closed {
    padding: 30px;
    br {
      display: none;
    }
    .tutorial-tab__heading {
      font-size: 20px;
      margin: 0;
    }
  }
  .toggle {
    position: absolute;
    cursor: pointer;
    top: 30px;
    right: 30px;
    width: 15px;
    height: 15px;
    transform: rotate(180deg);
  }
  .tutorial-tab-open .toggle {
    transform: rotate(0deg);
  }
  .tutorial-tab__content {
    span {
      float: left !important;
    }
    a {
      color: black !important;
      font-size: 17px;
    }
  }
  .tutorial-tab__link-secondary {
    display: block;
    text-align: left;
  }
</style>
