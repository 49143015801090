<template lang="html">
    <div>
        <div class="input" v-bind:class="{'invalid': !validation, 'line': inputClass == 'line', 'disabled': disabled}">
            <label>
                <div class="input-label-wrap">
                    <span v-if="label" class="input__label" v-bind:class="{'input__label--value' : name}">{{ label }}</span>
                </div>

                <input
                    v-on:blur="$emit('blur')"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :type="type"
                    v-bind:class="{'psc': psc}"
                    :value="name"
                    @input="updateSelf($event.target.value)"
                    class="input__data"
                    :min="min"
                    :max="max"
                >
                <span v-if="search" class="icon-search"></span>
                <span v-if="fileIco" class="icon-file1"></span>
                <p v-if="!validation" class="input__error">{{error}}</p>
            </label>

            <div class="tip" v-if="tip">{{ $t('general.tip') }}: {{tip}}</div>
        </div>

    </div>
</template>


<script>
    export default {
        name: 'InputNumber',
        model: {
            prop: "name",
            event: "input"
        },
        props: {
            value: String,
            label: String,
            type: String,
            inputClass: String,
            validation: {
                type: Boolean,
                default: true
            },
            placeholder: String,
            disabled: Boolean,
            error: String,
            search: Boolean,
            psc: Boolean,
            tip: String,
            fileIco: Boolean,
            name: String,
            min: Number,
            max: Number,
        },
        components: {

        },
        data() {
            return {
                content: this.value
            }
        },
        created() {

        },
        computed: {

        },
        methods: {
            updateSelf(name) {
                this.$emit("input", name);
            }
        },
        watch: {

        }
    }
</script>


<style lang="scss">
    .workspace-input-edit .input__data{
        padding-right: 70px;
    }

    .disabled .input__data{
        color: #8E97A4;
        background: #F2F6FC;
        &:active, &:focus{
            border:solid 1px #B1BBCB;

        }
    }

    .disabled .input__label{
        color: #8E97A4;
    }

    .profile-input.input{
        width:auto;

    }

    .input__data::placeholder{
        font-weight: 300;
        color:#8E97A4;
        font-style:italic;
    }

    textarea::placeholder{
        font-weight: 300;
        color:#8E97A4;
        font-style:italic;
    }

    .input__error{
        font-size: 13px;
        color: #CD0000;
        position: absolute;
        bottom: -13px;
        font-weight: 300;

    }

    .password-wrap{
        position: relative;

    }
    .password-wrap .input__data{

        padding-right: 35px !important;
    }

    .input__label {
        display: block;
    }


    .textarea-small{
        font-weight: normal;
        font-size: 15px;
        color: #FFFFFF;
        letter-spacing: -0.03px;
        line-height: 22px;
    }

    .input__data{
        width: 100%;
        outline: none;
        background: #FFFFFF;
        border: 1px solid #B1BBCB;
        border-radius: 6px;
        height: 56px;
        max-width: 230px;
        padding: 0px 20px;
        font-size: 17px;
        font-weight: 300;
        color:black;
        letter-spacing: -0.03px;
        max-width: 100%;
        &:focus, &:active{
            border:solid 1px #5E239E;
        }
    }

    .input.invalid .input__data{
        border-color: red;
    }

    .input{
        max-width: 100%;
        text-align: left;
        margin-top: 20px;
        width: 273px;
        margin-left: auto;
        margin-right: auto;
        position:relative;

        @include sm{
            width: 260px;
        }
    }

    .input__label{
        font-weight: normal;
        font-size: 15px;
        color:black;
        letter-spacing: -0.03px;
        margin-bottom: 5px;

    }

    .white-input .input__label{
        color:white;
    }

    .input-disabled{
        cursor: default;
        pointer-events: none;

    }

    .textarea{
        width: 240px;
        height: 110px;
        border-radius: 6px;
        padding: 10px;
        resize: none;
        outline: none;
        margin-bottom: 10px;
    }


    .input-disabled .input__label{
        color: #8E97A4;
    }
    .input-disabled .input__data{
        color: #8E97A4;
    }



    .short-input .input__label{
        width: 70px;
    }

    .input-search .input{
        margin-top:0px;
        width: 100%;
        /*padding-right: 20px;*/
    }
    .input-search label{
        position:relative;

    }
    .input-search .icon-search{
        position: absolute;
        right: 20px;
        bottom: 0px;
        font-size: 20px;
        &:before{
            color: #B1BBCB;
        }
    }
    .input-search .input__data{
        padding-right: 60px;
    }
    .input-search .input__data:focus ~ .icon-search:before{
        color:#5e239e;
    }

    .psc.input__data{
        max-width:115px;
    }

    .tip{
        border-radius: 6px;
        //border: 1px solid #F1EAAF;
        background: #FFFAD1;
        padding: 10px 30px;
        font-size: 15px;
        color: #73560B;
        text-align: left;
        line-height: 21px;
        font-weight: 300;
        margin-top: 15px;
        position:relative;
        margin-bottom: 25px;
        box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
        &:before{
            content: "";
            width: 8px;
            height: 8px;
            //border: 1px solid #F1EAAF;
            background: #FFFAD1;
            position: absolute;
            top: -6px;
            transform: rotate(45deg);
            border-bottom: none;
            border-right: 0px;
            left: 25px;
            @include md{
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
            }
        }
        @include md{
            padding: 10px 15px;
        }
    }

    .tip--normal{

        p{
            margin-bottom: 0;
            padding-bottom: 0;
        }

        &:after{
            display: none !important;
        }
    }

    .input-file-ico .input__data{
        padding-left: 50px;
        &:before{
            content:"";

        }
    }


    .input-file-ico .input{
        margin-top:0px;
        width: 100%;
        padding-right: 0px;
    }
    .input-file-ico label{
        position:relative;

    }
    .input-file-ico .icon-file1{
        position: absolute;
        left: 20px;
        bottom: 0px;
        font-size: 20px;
        &:before{
            color: #B1BBCB;
        }
    }

    .input-file-ico .input__data:focus ~ .icon-file1:before{
        color:#5e239e;
    }

     .input input:-internal-autofill-selected {
         background-color: transparent!important;
         color: #5E239E !important;
     }

     input:-webkit-autofill,
     input:-webkit-autofill:hover,
     input:-webkit-autofill:focus,
     textarea:-webkit-autofill,
     textarea:-webkit-autofill:hover,
     textarea:-webkit-autofill:focus,
     select:-webkit-autofill,
     select:-webkit-autofill:hover,
     select:-webkit-autofill:focus {

         background-color: transparent!important;
         color: #5E239E !important;
         -webkit-text-fill-color: #5E239E;
        -webkit-box-shadow: 0 0 0px 1000px white inset;
     }

</style>
