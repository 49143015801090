<template>
  <div class="contract-tabs">
    <a v-if="showMenu || (isMobile && hasAttachments)"
       href="#"
       ref="menu"
       class="contract-tab contract-tab-menu"
       :class="{'open': openMenu && showMenu}"
       @click.prevent="toggleTabMenu"
    >
      <IcoArrowDownGrey />
    </a>
    <div v-if="openMenu"
         class="contract-menu"
    >
      <a v-for="contract in contractsWithoutActive"
         :key="contract.contract_id"
         class="contract-menu__item"
         @click="contractChange(contract)"
         :class="{'active': selectedContractId === contract.contract_id, 'hidden': childVisible[contract.id]}"
      >
        <span class="contract-tab__title">{{contract.contract_title}}</span>
        <span class="contract-tab__signed" v-if="isUnsigned(contract)" :title="$t('signing.not_signed_yet')">
          <IcoUnsignedAlert />
        </span>
      </a>
    </div>
    <div class="contract-tabs__scroller"
         :class="{'contract-tabs__scroller--with-menu': showMenu}"
         ref="scroller"
    >
      <a v-for="contract in filteredContracts"
         :key="contract.id"
         @click.prevent="contractChange(contract)"
         class="contract-tab"
         :data-id="contract.id"
         :class="{'active': selectedContractId === contract.contract_id}"
      >
        <span class="contract-tab__title">{{contract.contract_title}}</span>
        <span class="contract-tab__signed" v-if="isUnsigned(contract)" :title="$t('signing.not_signed_yet')">
          <IcoUnsignedAlert />
        </span>
      </a>
    </div>
    <a class="contract-tab contract-tab--add-attachment hidden"></a>
  </div>
</template>

<script>
import IcoPlus from '@/components/svg/IcoPlus';
import IcoUnsignedAlert from '@/components/svg/IcoUnsignedAlert';
import IcoArrowDownGrey from "@/components/svg/IcoArrowDownGrey";
import { ContractHelper } from '@/helpers/ContractHelper';

export default {
  name: 'ContractTabsClient',
  components: {
    IcoArrowDownGrey,
    IcoPlus,
    IcoUnsignedAlert,
  },
  props: {
    mainContractId: {
      required: true,
    },
    contracts: {
      required: false,
      default: null,
    },
    selectedContractId: {
      required: false,
      default: null,
    },
    workspace_id: {
      required: true,
    },
  },
  data() {
    return {
      showMenu: false,
      openMenu: false,
      childVisible: [],
      isMobile: false,
    };
  },
  mounted() {
    this.checkDeviceType();
    window.addEventListener('resize', this.onResize);
    this.$refs.scroller.addEventListener('scroll', this.toggleMenuHandler);
    this.toggleMenuHandler();
  },
  destroyed() {
    window.removeEventListener('resize', this.toggleMenuHandler);
    if (this.$refs.scroller) {
      this.$refs.scroller.removeEventListener('scroll', this.toggleMenuHandler);
    }
  },
  computed: {
    hasAttachments() {
      return this.contracts && this.contracts.length > 1;
    },
    _selectedContractId() {
      return this.selectedContractId || this.mainContractId;
    },
    filteredContracts() {
      return !this.isMobile ? this.contracts : this.contracts.filter((contract) => (contract.id === Number(this._selectedContractId)));
    },
    contractsWithoutActive() {
      return !this.isMobile ? this.contracts : this.contracts.filter((contract) => (contract.id !== Number(this._selectedContractId)));
    },
  },
  methods: {
    isUnsigned(contract) {
      return ContractHelper.isUnsigned(contract);
    },
    onResize() {
      this.checkDeviceType();
      this.toggleMenuHandler();
    },
    checkDeviceType() {
      this.isMobile = window.innerWidth < 993;
    },
    contractChange(contract) {
      if (contract.id === this.selectedContractId) {
        return ;
      }
      this.$emit('click', contract);
      this.openMenu = false;
    },

    toggleTabMenu() {
      this.openMenu = !this.openMenu;
    },

    toggleMenuHandler() {
      if (!this.isMobile) {
        if (!this.tabsFitsResolution()) {
          this.showMenu = true;
          this.childVisible = {};
          _.each(this.$refs.scroller.childNodes, (tab) => {
            this.childVisible[tab.getAttribute('data-id')] = this.tabInViewport(tab);
          });
        } else {
          this.showMenu = false;
          this.openMenu = false;
        }
      }
    },
    tabsFitsResolution() {
      if (!this.isMobile) {
        const scrollerWidth = this.$refs.scroller.offsetWidth + 1;
        let tabsWidth = 0;
        _.each(this.$refs.scroller.childNodes, (tab) => {
          tabsWidth += tab.offsetWidth;
        });

        return scrollerWidth >= tabsWidth;
      }
    },

    tabInViewport(elem) {
      const $scroller = this.$refs.scroller;
      const bounding = elem.getBoundingClientRect();
      const scrollerBounding = $scroller.getBoundingClientRect();

      return (scrollerBounding.left + scrollerBounding.width >= bounding.left + bounding.width) && (scrollerBounding.left <= bounding.left);
    },
  },
}
</script>
