<template>
  <div
    v-if="total && !closed"
    class="tip tip--normal tip--without-arrow tip--auto-width google-drive-upload"
  >
    <div v-if="completed" class="tip-flex-content">
      <div class="google-drive-upload__message">
        {{ $t('drive.uploaded') }}
      </div>
      <div class="tip__closer" @click="closeGoogleUpload">
        <IcoClose />
      </div>
    </div>
    <div v-else class="google-drive-upload__alert-box">
      <div class="google-drive-upload__icon-badge">
        +{{ total }}
      </div>
      <div class="google-drive-upload__icon">
        <IcoInvoices />
      </div>
      <div class="google-drive-upload__alert-content">
        <div class="google-drive-upload__alert-message">
          <strong>{{ $tc('drive.alert', total, { count: total }) }}.</strong>
          {{ $t('drive.confirm') }}
        </div>
        <div class="google-drive-upload__alert-button">
          <div class="google-drive-upload__action">
            <v-btn color="primary" outlined :loading="uploading" @click="uploadOnGoogleDrive">
              <v-row class="px-3" align="center" justify="space-between" no-gutters>
                <v-col class="px-1">
                  <IcoGoogleDrive />
                </v-col>
                <v-col class="px-1">
                  {{ $t('drive.upload') }}
                </v-col>
              </v-row>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="tip__closer" :class="{'tip__closer--hidden': uploading}" @click="closeGoogleUpload">
        <IcoClose />
      </div>
    </div>
  </div>
</template>

<script>
import { AccountService } from '@/services/AccountService';
import GoogleDrive from "@/config/GoogleDrive";
import IcoClose from '@/components/svg/IcoClose';
import IcoGoogleDrive from '@/components/svg/IcoGoogleDrive';
import IcoInvoices from "@/views/dashboard/components/icons/IcoInvoices";

export default {
  name: "GoogleDriveUpload",
  components: {
    IcoClose,
    IcoGoogleDrive,
    IcoInvoices,
  },
  data() {
    return {
      closed: false,
      uploading: false,
      completed: false,
      processed: 0,
      toProcess: 0,
      developerKey: null,
      clientId: null,
      scope: "https://www.googleapis.com/auth/drive",
      oauthToken: null,
    };
  },
  props: {
    total: {
      type: Number,
      required: false,
      default: 0,
    },
    workspaceId: {
      type: [Number, String],
      required: false,
    },
    inProgressAfterLoad: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const googleDrive = new GoogleDrive()

    this.clientId = googleDrive.getClientId()
    this.developerKey = googleDrive.getDeveloperKey()

    if (this.inProgressAfterLoad) {
      this.uploading = true;
      this.checkState();
    }
  },
  methods: {
    async uploadOnGoogleDrive() {
      this.uploading = true;

      await gapi.load("auth2", () => {
        gapi.auth2.authorize(
          {
            client_id: this.clientId,
            scope: this.scope,
            immediate: false
          },
          this.handleAuthResult
        );
      });
    },

    async handleAuthResult(authResult) {
      this.oauthToken = authResult.access_token;

      if (this.oauthToken) {
        const data = {
          api_key: this.oauthToken,
          client_id: this.clientId,
        };

        await AccountService.saveGD(data, this.workspaceId ).then(() => {

        }, (error) => {
          this.uploading = false;
          this.$notification.error(this.$t('drive.load_error'));
        });

        AccountService.googleUploadFiles(this.workspaceId).then(() => {
          this.checkState();
        }, (error) => {
          this.uploading = false;
          this.$notification.error(this.$t('drive.load_error'));
        });
      }
    },

    closeGoogleUpload() {
      this.closed = true;
    },

    checkState() {
      AccountService
        .checkUploadFileState(this.workspaceId)
        .then(
          (response) => {
            this.completed = response.completed;
            this.toProcess = response.to_process;

            if (!this.completed) {
              setTimeout(() => {
                this.checkState();
              }, 60 * 1000);
            } else {
              this.uploading = false;
            }
          },
          () => {
            this.uploading = false;
          });
    },
  },

  watch: {
    inProgressAfterLoad() {
      if (this.inProgressAfterLoad) {
        this.uploading = true;
        this.checkState();
      }
    },
  },
}
</script>

<style lang="scss" scoped>

  .tip {
    width: 100%;
    max-width: 845px;
    color: #97673c;
    margin-bottom: 15px !important;

    &--without-arrow {
      padding: 15px 20px;

      &:before {
        display: none;
      }
    }

    &--auto-width {
      display: table;
      margin: auto;
    }

    .tip-flex-content {
      display: flex;
      align-items: center;

      @media (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__closer {
      position: absolute;
      top: 10px;
      right: 6px;
      cursor: pointer;

      &--hidden {
        visibility: hidden;
      }

      svg {
        height: 15px;
        display: inline-block;
        vertical-align: top
      }
    }
  }

  .google-drive-upload {
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }

    &__icon {
      padding-right: 20px;

      @media (max-width: 600px) {
        margin-top: 5px;
      }
    }

    &__icon-badge {
      position: absolute;
      top: 13px;
      left: 40px;
      display: inline-block;
      min-width: 10px;
      padding: 0 2px;
      border-radius: 50%;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      background: #de3e3f;
      color: #fad3d5;
    }

    &__action {
      &__send {
        border: 1px solid #5E239E;
        padding: 10px 10px;
        color: #5E239E;
        text-decoration: none;
        border-radius: 6px;
        cursor: pointer;
        white-space: nowrap;

        @media (max-width: 600px) {
          display: block;
          margin-top: 10px;
        }

        &:hover {
          color: lighten(#5E239E, 10%);
          border-color: lighten(#5E239E, 10%);
          text-decoration: none;
        }

        svg {
          height: 20px;
          display: inline-block;
          vertical-align: top
        }
      }

      .button-loading {
        display: inline-block;
        margin-right: 10px;
        position: relative;
        top: 3px;
        left: 0;
        right: auto;
      }
    }
  }

  .google-drive-upload__alert-box {
    display:flex;
    flex-direction: row;
    padding-right: 10px;

    @media (max-width: 600px) {
      padding: 0;
    }
  }

  .google-drive-upload__alert-content {
    display: flex;
    flex-direction: row;
  }

  .google-drive-upload__alert-message {
    margin: auto;
    flex: 65%;
  }

  .google-drive-upload__alert-button {
    margin: auto;
    width: 100%;
    flex: 35%;
    text-align: right;
  }

  @media (max-width: 600px) {
    .google-drive-upload__alert-content {
      flex-direction: column;
      margin-right: 10px;
    }
  }
</style>
