<template lang="html">
  <ContractLayout
    :background-image="contract.settings && contract.settings.background ? contract.settings.background : `/images/contract-desktop-bg.20200622.jpg`"
    :contract="contract"
    :is-loading="isLoading"
  >
    <template v-slot:content>
      <div class="contract">
        <div class="contract__data contract__data--thin">
          <div class="contract__logo">
            <Logo :logo="contract.settings ? contract.settings.logo : null" :white="false" />
          </div>

          <h1 class="contract-title">Ověření identity</h1>
          <div class="contract__message contract__message--margin">
            <p>Náhled principu ověření (neimplementovaného) ověření identity</p>
            <p>Při odeslání prázdného inputu dojde k chybě. Identifikace projde po zadání jakéhokoliv textu.</p>
          </div>
          <form @submit.prevent="sendIdentification()">
            <Input v-model="dummyId" type="text" :label="'ID občanky'" />
            <div class="contract__buttons contract__buttons--closer">
              <Button :loading="isSending" type="primary" buttonType="submit">Ověřit identitu</Button>
            </div>
          </form>
        </div>
      </div>
    </template>
  </ContractLayout>
</template>

<script>
  import ContractLayout from "@/views/contract/components/ContractLayout";
  import { mapGetters } from 'vuex';
  import { ContractService } from '@/services/ContractService.js';
  import { required, minLength } from 'vuelidate/lib/validators';
  import { WorkflowHelper } from '@/helpers/WorkflowHelper.js';

  export default {
    name: 'contractVerifyIdentity',
    components: {
      ContractLayout,
    },
    data() {
      return {
        isLoading: true,
        isSending: false,
        dummyId: '',
      };
    },
    created() {
      this.fetchData();
    },
    computed: {
      ...mapGetters([
        'contract',
      ]),
    },
    methods: {
      fetchData() {
        this.isLoading = true;
        ContractService.contractFromApiToken({
          component: this,
          onSuccess: (response) => {
            this.$store.commit('setContract', response);
            this.isLoading = false;
          },
        });
      },
      sendIdentification() {
        if (this.isSending) {
          return; // Prevent double sending
        }
        this.isSending = true;
        const token = this.$route.params.hash;
        const selectedContract = WorkflowHelper.getSelectedContractForUnregistredUser(this.contract, this.$route.params.contract_id);
        ContractService.sendIdentification(token, selectedContract.id, selectedContract.current_sign_identity.id, { dummy_id: this.dummyId }).then(
          (response) => {
            this.$router.push({name: 'contractView', params: { hash: this.$route.params.hash }, query: { contract_id: selectedContract.id }});
            this.isSending = false;
          },
          () => {
            this.isSending = false;
            this.$notification.error(this.$t('contract.fill.error'));
          }
        );
      },
    },
  }
</script>
