
<template lang="html">
  <div class="overview">
    <div class="overview__column">
      <div class="overview__waiting-for-me" @click="$router.push({ name: `documentsTable`, query: { state: `pending.me` }})">
        <div class="overview__badge">
          <span v-if="pendingMe">{{ pendingMe }}</span>
          <span v-else class="empty"></span>
        </div>

        <div class="overview__icon">
          <IcoDocsPendingMe />
        </div>

        <div class="overview__name">
          {{ $t('docs.pending.me') }}
        </div>
      </div>
    </div>

    <div class="overview__column">
      <div class="overview__waiting-for-other" @click="$router.push({ name: `documentsTable`, query: { state: `pending.not_me` }})">
        <div class="overview__badge">
          <span v-if="pendingOther">{{ pendingOther }}</span>
          <span v-else class="empty"></span>
        </div>

        <div class="overview__icon">
          <IcoDocsPendingOther />
        </div>

        <div class="overview__name">
          {{ $t('docs.pending.not_me') }}
        </div>
      </div>
    </div>

    <div class="overview__column">
      <div class="overview__concepts" @click="$router.push({ name: `documentsTable`, query: { state: `draft` }})">
        <div class="overview__badge">
          <span v-if="draft">{{ draft }}</span>
          <span v-else class="empty"></span>
        </div>

        <div class="overview__icon">
          <IcoDocsDrafts />
        </div>

        <div class="overview__name">
          {{ $t('docs.drafts.contracts') }}
        </div>
      </div>
    </div>

    <div class="overview__column">
      <div class="overview__done" @click="$router.push({ name: `documentsTable`, query: { state: `completed` }})">
        <div class="overview__badge">
          <span v-if="completed">{{ completed }}</span>
          <span v-else class="empty"></span>
        </div>

        <div class="overview__icon">
          <IcoDocsCompleted />
        </div>

        <div class="overview__name">
          {{ $t('docs.completed.contracts') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcoDocsPendingMe from "./icons/IcoDocsPendingMe";
import IcoDocsPendingOther from "./icons/IcoDocsPendingOther";
import IcoDocsDrafts from "./icons/IcoDocsDrafts";
import IcoDocsCompleted from "./icons/IcoDocsCompleted";

export default {
  name: 'Overview',
  components: {
    IcoDocsPendingMe,
    IcoDocsPendingOther,
    IcoDocsDrafts,
    IcoDocsCompleted,
  },
  props: {
    workspace: {
      type: Object,
      required: true,
    },
  },
  computed: {
    pendingMe() {
      return this.getCount('pending.me');
    },
    pendingOther() {
      return this.getCount('pending.not_me');
    },
    draft() {
      return this.getCount('draft');
    },
    completed() {
      return this.getCount('completed');
    },
    trashArchive() {
      return this.getCount('archive.trashed')
    }
  },
  methods: {
    getCount(state) {
      if (!this.workspace || !this.workspace.contract_summaries) {
        return 0;
      }
      return parseInt(this.workspace.contract_summaries[state]) || 0;
    },
  }
};
</script>

<style lang="scss" scoped>
.overview {
  margin: 0 -10px;
  @include clearfix();
}

.overview__column {
  float: left;
  width: 25%;
  padding: 0 10px;
  margin-bottom: 20px;
}

@media screen and (max-width: 991px) {
  .overview__column {
    width: 50%;
    display: block;
  }
}

@mixin overview__card-item($backgroundColor, $color) {
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #ffffff;
  border-top: 8px solid $backgroundColor;
  border-radius: 6px;

  .overview__badge span {
    background-color: $backgroundColor;
    color: $color;
  }
}

.overview__waiting-for-me {
  @include overview__card-item(#fde2e3, #e05353);
}

.overview__waiting-for-other {
  @include overview__card-item(#fdefb5, #845609);
}

.overview__concepts {
  @include overview__card-item(#e8edf5, #8d949d);
}

.overview__done {
  @include overview__card-item(#6decaf, #13613b);
}

.overview__archive-trash {
  @include overview__card-item(#F35353, #fde2e3);
}

.overview__badge {
  margin-top: 10px;
  position: relative;
  top: 50%;
  left: 17%;

  span {
    display: inline-block;
    height: 20px;
    padding: 0 7px;
    border-radius: 30px;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    letter-spacing: -0.03px;
    line-height: 20px;
    color: grey;
  }

  span.empty {
    display: inline;
    background: #ffffff;
  }
}

.overview__name {
  margin-top: 18px;
  color: #5e239e;
  display: inline-block;
  font-size: 16px;
  text-decoration: underline;
  line-height: 1.125;
  min-height: 40px;
}
</style>
