<template>
  <v-container>
    <CreateInvitation @reload-workspace="onReloadWorkspace" />

    <h2 v-if="workspace.is_owner" class="subtitle-2 my-2">
      {{ $t('workspace.team.add_member.help') }}
    </h2>

    <v-divider class="mt-3" color="#d4dff0"></v-divider>

    <v-row class="mt-3" align="center" justify="center">
      <v-col class="col-12 col-md-8">
        <v-text-field
          v-model="searchTerm"
          class="mx-auto"
          prepend-inner-icon="mdi-magnify"
          :label="$t('general.find_by.name_or_email')"
          hide-details
          outlined
        ></v-text-field>
      </v-col>

      <v-col class="col-12 col-md-8">
        <WorkspaceMemberRow
          v-for="member in filteredWorkspaceAllowedUsers"
          :key="member.email"
          :member="member"
          :is-owner="workspace.is_owner"
          @reload-workspace="onReloadWorkspace"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import CreateInvitation from '@workspace/components/CreateInvitation';
import WorkspaceMemberRow from '@workspace/components/WorkspaceMemberRow';

export default {
  name: 'WorkspaceSettingsTeam',
  components: { CreateInvitation, WorkspaceMemberRow },
  props: {
    workspace: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchTerm: '',
    };
  },
  computed: {
    filteredWorkspaceAllowedUsers() {
      return (
        this.workspace.allowed_users?.filter(
          (user) =>
            `${user.firstname} ${user.lastname}`
              ?.toLowerCase()
              ?.includes(this.searchTerm?.toLowerCase()) ||
            `${user.email}`?.includes(this.searchTerm?.toLowerCase()),
        ) || []
      );
    },
  },
  mounted() {
    this.fetchWorkspaceMembersPositions(this.$route.params.workspace_id);
  },
  methods: {
    ...mapActions({
      fetchWorkspaceMembersPositions: 'workspace/fetchWorkspaceMembersPositions',
    }),
    onReloadWorkspace() {
      this.$emit('reload-workspace');
    },
  },
};
</script>

<style lang="scss" scoped></style>
