<template>
  <v-snackbar v-model="show" :color="type" :timeout="3000" :right="!$vuetify.breakpoint.mobile" top>
    <span class="font-weight-medium">
      {{ message }}
    </span>

    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="show = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { i18n } from '@/plugins/i18n';

export default {
  name: 'AlertMessage',
  props: {
    value: Boolean,
    type: {
      type: String,
      default: 'error',
    },
    message: {
      type: String,
      default: i18n.t('general.error'),
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
