import { hostname } from '@/services/helpers/variables.js';
import { authHeader } from '@/config/authHeader';
import { parseJSON } from '@/services/helpers/json.js';

export const KatastrService = {
  getContract,
  getKatastralniUzemi,
};

async function getContract({ contractId, listVlastnictvi, katastralniUzemi }) {
  const response = await fetch(
    `${hostname}/api/v1/katastr/${contractId}?listVlastnictvi=${listVlastnictvi}&katastralniUzemi=${katastralniUzemi}`,
    {
      method: 'GET',
      headers: { ...authHeader() },
    }
  );
  const text = await response.text();
  const json = parseJSON(text);
  if (response.status !== 200 || !json || !json.katastralniUzemi) {
    throw new Error('katastr not found');
  }
  return json;
}

async function getKatastralniUzemi(katastralniUzemi) {
  const response = await fetch(
    `${hostname}/api/v1/katastr/uzemi?katastralniUzemi=${katastralniUzemi}`,
    {
      method: 'GET',
      headers: { ...authHeader() },
    }
  );
  const text = await response.text();
  const json = parseJSON(text);
  if (response.status !== 200 || !json) {
    return {
      selected: null,
      options: [],
    };
  }
  return json;
}
