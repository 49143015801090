import { getBankIdLink } from '@verification/services/authService';

export const namespaced = true;

export const state = {
  bankId: null,
  bankIdLink: null,
};

export const mutations = {
  setBankId(state, payload) {
    state.bankId = payload;
  },
  setBankIdLink(state, payload) {
    state.bankIdLink = payload;
  },
};

export const actions = {
  setBankId: (context, payload) => {
    context.commit('setBankId', payload);
  },
  fetchBankIdLink: (context, authToken) => {
    return getBankIdLink({ authToken }).then((resp) => {
      context.commit('setBankIdLink', resp.url);
    });
  },
};

export const getters = {
  bankId: (state) => {
    return state.bankId;
  },
  bankIdLink: (state) => {
    return state.bankIdLink;
  },
  authsBankIdDone: (state) => {
    return 'submitted' === state.bankId?.status || 'ok' === state.bankId?.status;
  },
};
