<template>
  <div v-bind:class="{'opened': true}" class="sidemenu-workplace">
    <div class="sidemenu-workplace__data">
      <ul v-if="$route.name === 'workspaceSettings'">
        <li v-for="link in workspaceSettingsLinks" :key="link.id" :class="{ 'active-menu-item': workspaceSelectedTab === link.id }">
          <a
            href="#"
            class="sidemenu-workplace__a"
            @click.prevent="selectTab(link.id)"
          >
            <div class="sidemenu-workplace__icon sidemmenu-workspace__icon">
              <component :is="link.icon"></component>
            </div>
            <div class="sidemenu-workplace__link">
              {{ link.title }}
            </div>
          </a>
        </li>
      </ul>

      <ul v-else-if="$route.name === 'account'">
        <li v-for="link in accountSettingsLinks" :key="link.id" :class="{ 'active-menu-item': accountSelectedTab === link.id }">
          <a
            href="#"
            class="sidemenu-workplace__a"
            @click.prevent="selectAccountTab(link.id)"
          >
            <div class="sidemenu-workplace__icon sidemmenu-workspace__icon">
              <component :is="link.icon"></component>
            </div>
            <div class="sidemenu-workplace__link">
              {{ link.title }}
            </div>
          </a>
        </li>
      </ul>

      <ul v-else>
        <li :class="{ 'active-menu-item': isActive('dashboard') }">
          <router-link class="sidemenu-workplace__a" :to="{ name: 'dashboard', params: { workspace_id }}">
            <div class="sidemenu-workplace__icon sidemenu-workplace__icon-fill">
              <IcoMenuHome />
            </div>
            <div class="sidemenu-workplace__link">
              {{ $t('overview') }}
            </div>
          </router-link>
        </li>

        <li :class="{ 'active-menu-item': isActive('documentsTable') }">
          <router-link class="sidemenu-workplace__a" :to="{ name: 'documentsTable', params: { workspace_id }, query: {} }">
            <div class="sidemenu-workplace__icon sidemenu-workplace__icon-stroke">
              <IcoMenuPage />
            </div>
            <div class="sidemenu-workplace__link">
              {{ $t('docs') }}
            </div>
          </router-link>

          <ul v-if="isActive('documentsTable') && dashboardDocumentLinks.length">
            <li
              v-for="link in dashboardDocumentLinks"
              :key="link.title"
              :class="{ 'active-menu-item': link.isActive }"
            >
              <a v-if="link.action" href="#" @click.prevent="link.action" :class="`sidemenu-workplace__a ${link.extraCss}`">
                {{ link.title }}
              </a>
              <div v-else class="separator-hr"></div>
            </li>
          </ul>
        </li>

        <li :class="{ 'active-menu-item': isActive('templates') }">
          <router-link class="sidemenu-workplace__a" :to="{ name: 'template', params: { workspace_id} }">
            <div class="sidemenu-workplace__icon sidemenu-workplace__icon-stroke">
              <IcoMenuTemplate />
            </div>
            <div class="sidemenu-workplace__link">
              {{ $t('templates') }}
            </div>
          </router-link>
          <ul v-if="isActive('templates') && dashboardTemplateLinks.length">
            <li
              v-for="link in dashboardTemplateLinks"
              :key="link.title"
              :class="{ 'active-menu-item': link.isActive }"
            >
              <a href="#" @click.prevent="link.action" class="sidemenu-workplace__a">
                {{ link.title }}
              </a>
            </li>
          </ul>
        </li>

        <li :class="{ 'active-menu-item': isActive('contacts') }">
          <router-link class="sidemenu-workplace__a" :to="{ name: 'contacts', params: { workspace_id} }">
            <div class="sidemenu-workplace__icon sidemenu-workplace__icon-stroke">
              <IcoContact style="width: auto; height: 100%" />
            </div>
            <div class="sidemenu-workplace__link">
              {{ $t('contacts.title') }}
            </div>
          </router-link>
          <ul v-if="isActive('contacts')">
            <li
              v-for="link in contactsLinks"
              :key="link.title"
              :class="{ 'active-menu-item': link.isActive }"
            >
              <a v-if="link.action" href="#" @click.prevent="link.action" :class="`sidemenu-workplace__a ${link.extraCss}`">
                {{ link.title }}
              </a>
              <div v-else class="separator-hr"></div>
            </li>
          </ul>
        </li>

        <li v-if="areReportsVisible" :class="{ 'active-menu-item': isActive('reports') }">
          <router-link class="sidemenu-workplace__a" :to="{ name: 'reports', params: { workspace_id} }">
            <div class="sidemenu-workplace__icon sidemenu-workplace__icon-stroke">
              <span class="icon-dashboard"></span>
            </div>
            <div class="sidemenu-workplace__link">
              {{ $t('reports') }}
            </div>
          </router-link>
          <ul v-if="isActive('reports')">
            <li
              v-for="link in reportLinks"
              :key="link.title"
              :class="{ 'active-menu-item': link.isActive }"
            >
              <a v-if="link.action" href="#" @click.prevent="link.action" :class="`sidemenu-workplace__a ${link.extraCss}`">
                {{ link.title }}
              </a>
              <div v-else class="separator-hr"></div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DataService } from "@/services/DataService";
import IcoMenuHome from '@/components/svg/menu/IcoMenuHome.vue';
import IcoMenuPage from '@/components/svg/menu/IcoMenuPage.vue';
import IcoMenuTemplate from '@/components/svg/menu/IcoMenuTemplate.vue';
import IcoSignatureIdentity from "@/components/svg/menu/IcoSignatureIdentity";
import IcoTeam from "@/components/svg/menu/IcoTeam";
import IcoAppearance from "@/components/svg/menu/IcoAppearance";
import IcoEmails from "@/components/svg/menu/IcoEmails";
import IcoSettings from "@/components/svg/menu/IcoSettings";
import IcoExperimentalFunctions from "@/components/svg/menu/IcoExperimentalFunctions";
import IcoContact from "@/views/dashboard/components/icons/IcoContact";
import FeatureFlags from '@/common/reusable/featureFlagsChecker';

const menuRoutes = {
  'dashboard': ['dashboard'],
  'documentsTable': ['documentsTable'],
  'templates': ['template'],
  'reports': ['reports'],
  'contacts': ['contacts', 'contactDetail'],
};
const findActiveMenuItem = (routeName) => {
  for (let menuItem in menuRoutes) {
    if (menuRoutes[menuItem].indexOf(routeName) != -1) {
      return menuItem;
    }
  }
  return '';
};

export default {
  name: 'SideMenuWorkplace',
  props: ['currentWorkspace'],
  components: {
    IcoContact,
    IcoExperimentalFunctions,
    IcoSettings,
    IcoEmails,
    IcoAppearance,
    IcoTeam,
    IcoSignatureIdentity,
    IcoMenuHome,
    IcoMenuPage,
    IcoMenuTemplate,
  },
  data() {
    return {
      activeMenuItem: findActiveMenuItem(this.$route.name),
      queryString: this.$route.query || {},
      documentsCount: {},
    };
  },
  computed: {
    ...mapGetters({
      accountSelectedTab: 'userSettings/accountSelectedTab',
      activeWorkspace: 'workspaceById',
      dashboardDocumentLinks: "dashboardDocumentLinks",
      dashboardAvailableGroups: "dashboardAvailableGroups",
      dashboardTemplateLinks: "dashboardTemplateLinks",
      workspaceSelectedTab: "userSettings/workspaceSelectedTab",
    }),
    workspace_id() {
      return this.currentWorkspace ? this.currentWorkspace.id : null;
    },
    contactsLinks() {
      if (!this.isActive('contacts')) {
        return [];
      }
      const changeQueryString = (groupId) => () => {
        this.$router.replace({
          name: 'contacts',
          query: {
            groups: groupId,
          },
        });
      };
      const routes = [
        {
          title: this.$t('docs.all'),
          action: changeQueryString(),
          isActive: !this.queryString.groups,
        },
        {
          title: 'SidebarGroups',
        },
        ...this.dashboardAvailableGroups.map(group => ({
          title: group.name,
          action: changeQueryString(group.key),
          isActive: this.queryString.groups == group.key,
        })),
        {
          title: `+ ${this.$t('contacts.group_create')}`,
          extraCss: 'sidemenu-workplace__a-new-folder',
          action: () => {
            this.$store.commit('dashboardOpenModal', { contactAction: 'createGroup' });
          },
          isDisabled: this.$route.name !== 'contacts',
        },
      ];
      return routes.filter(r => !r.isDisabled);
    },
    areReportsVisible() {
      return this.currentWorkspace.is_owner;
    },
    reportLinks() {
      return [
        {
          title: this.$t('reports.credits'),
          action: () => null,
          isActive: this.isActive('reports'),
        },
      ];
    },
    accountSettingsLinks() {
      if (this.$route.name === 'account') {
        return [
          {
            id: "personal-information",
            title: this.$t('account.personal_information'),
            icon: () => import('@/components/svg/IcoPersonalData'),
          },
          {
            id: "phone",
            title: this.$t('general.phone'),
            icon: () => import('@/components/svg/IcoPhone'),
          },
          {
            id: "change-password",
            title: this.$t('password.edit2'),
            icon: () => import('@/components/svg/IcoPassword'),
          },
          {
            id: "signature",
            title: this.$t('general.signature'),
            icon: () => import('@/components/svg/IcoSignature'),
          },
        ];
      }

      return [];
    },
    workspaceSettingsLinks() {
      if (this.$route.name === 'workspaceSettings') {
        let workspaceSettingsTabs = [
          {
            id: "sign-identity",
            title: this.$t("workspace.identity"),
            icon: IcoSignatureIdentity,
          },
          {
            id: "team",
            isDisabled: this.currentWorkspace.type != 'legal',
            title: this.$t("workspace.team.title"),
            icon: IcoTeam,
          },
          {
            id: "appearance",
            title: this.$t("workspace.branding.title"),
            icon: IcoAppearance,
          },
          {
            id: "messages",
            title: this.$t("workspace.notifications.title"),
            icon: IcoEmails,
          },
          // {
          //   id: 'bank-account',
          //   isDisabled: !FeatureFlags.hasEnabledAmlAuthentication(this.currentWorkspace),
          //   title: this.$t('workspace.bank_account'),
          //   icon: IcoExperimentalFunctions
          // },
          {
            id: 'verification',
            isDisabled: !FeatureFlags.hasEnabledAmlAuthentication(this.currentWorkspace),
            title: this.$t('general.persons_verification'),
            icon: IcoExperimentalFunctions
          },
          {
            id: "settings",
            title: this.$t("general.settings"),
            icon: IcoSettings,
          },
        ];
        return workspaceSettingsTabs.filter((workspaceSettingsTab) => {
          return !workspaceSettingsTab.isDisabled;
        });
      }
      return [];
    },
  },
  watch: {
    '$route.query'() {
      this.queryString = this.$route.query || {};
    },
    // dashboardDocumentLinks: {
    //   handler: function (value) {
    //     if (value) {
    //       this.reloadCounts();
    //     }
    //   }
    // }
  },
  // created() {
  //   this.reloadCounts();
  // },
  methods: {
    ...mapActions({
      setAccountSelectedTab: 'userSettings/setAccountSelectedTab',
      setWorkspaceSelectedTab: "userSettings/setWorkspaceSelectedTab",
    }),
    formatTitles(linkKey) {
      const selects = {
        [`${this.$t('review.pending_generic')}`]: {
          states: ['review']
        },
        [`${this.$t('docs.pending.generic')}`]: {
          states: ['pending'],
        },
        [`${this.$t('docs.drafts')}`]: {
          states: ['draft'],
        },
        [`${this.$t('docs.completed')}`]: {
          states: ['completed'],
        },
        [`${this.$t('docs.expiring')}`]: {
          states: ['pending.expiring', 'completed.expiring'],
        },
        [`${this.$t('docs.archive.trash')}`]: {
          states: ['archive.trashed'],
        },
      };

      if (Object.keys(selects)?.includes(linkKey)) {
        const currentItem = this.dashboardDocumentLinks?.find((item) => item.title === linkKey);

        DataService
          .getContracts({
            workspace_id: this.workspace_id,
            states: selects[`${linkKey}`]?.states || []
          })
          .then((response) => {
            const documentsCount = response.data?.length || '';

            if (typeof documentsCount === 'string') {
              return;
            }

            if (currentItem?.title) {
              currentItem.title = `${linkKey} (${documentsCount < 20 ? `${documentsCount}` : '20+'})`;
            }
          });
      }
    },
    reloadCounts() {
      for (let item of [
        this.$t('review.pending_generic'),
        this.$t('docs.pending.generic'),
        this.$t('docs.drafts'),
        this.$t('docs.completed'),
        this.$t('docs.expiring'),
        this.$t('docs.archive.trash'),
      ]) {
        this.formatTitles(item);
      }
    },
    isActive(type) {
      return this.activeMenuItem == type;
    },
    selectTab(tabId) {
      window.location.hash = `#${tabId}`
      this.setWorkspaceSelectedTab(tabId);
    },
    selectAccountTab (tabId) {
      window.location.hash = `#${tabId}`
      this.setAccountSelectedTab(tabId)
    },
  },
}
</script>

<style lang="scss">
.sidemenu-workplace__icon {
  padding-right: 20px;
  width: 40px;
  height: 24px;
  span {
    vertical-align: super;
  }
  .icon-dashboard{
    font-size: 18px;
    vertical-align: super;
  }
}

.sidemenu-workplace__data {
  background: white;
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
  border-radius: 6px;

  ul {
    padding: 0;
  }

  & > ul > li {
    padding: 20px;
    border-bottom: 1px solid #DEE7F4;
    list-style-type: none;
    font-weight: 400;

    &:last-child {
      border-bottom: 0;
    }
    .sidemenu-workplace__a {
      display: flex;
      text-decoration: none;
      .sidemenu-workplace__link {
        text-align: left;
      }
      &.sidemenu-workplace__a-new-folder {
        color: #999;
      }
    }

    & > ul {
      padding: 30px 20px 0 40px;
      list-style: none;
      & > li {
        padding-bottom: 15px;
        cursor: pointer;
        color: #383838;
        a {
          color: #505050;
          font-weight: 400;
        }
      }
      & > li.active-menu-item {
        font-weight: bold;
        color: black;
        cursor: pointer;
        a {
          color: black;
          font-weight: bold;
        }
      }
    }
  }
}

.sidemenu-workplace__link {
  display: flex;
  align-items: center;
}

.active-menu-item .sidemenu-workplace__link {
  color: #6026A0;
  font-size: 17px;
}
.active-menu-item .sidemenu-workplace__icon-fill svg path {
  fill: #5E239E;
}
.active-menu-item .sidemenu-workplace__icon-stroke svg g {
  stroke: #5E239E;
}
.active-menu-item .icon-dashboard:before {
  color: #5E239E;
}
.sidemenu-workplace__link {
  font-weight: 400;
}
.active-menu-item .sidemenu-workplace__link {
  font-weight: bold;
}

.sidemenu-workplace__icon {
  color: #B4C1D6;
}

.sidemenu-workplace__a:hover {
  color: #5E239E;

  .sidemenu-workplace__icon {
    color: #5E239E;
  }
}

.active-menu-item {
  .sidemenu-workplace__icon {
    color: #5E239E;
  }
}
</style>
