<template lang="html">
    <Data :showMenu="false" :shadow="false">

        <template v-slot:header></template>

        <div class="content-finish">
            <div class="content-ico">
                <IcoContractRejected />
            </div>

           <h1>{{ $t('contract.was_rejected') }}!</h1>

            <div class="content-finish__box">
                <p>{{ $t('signing.comment_sent') }}</p>
                <Button @click="$router.push({name:  'dashboard', params: {'workspace_id': $route.params.workspace_id}})" type="primary">{{ $t('general.done') }}</Button>
            </div>
        </div>

    </Data>
</template>


<script>
    import IcoContractRejected from '@/components/svg/IcoContractRejected.vue';

    export default {
        name: 'CreateRejected',
        components: {
            IcoContractRejected
        },
        data() {
            return {

            }
        },
        created() {

        },
        computed: {

        },
        methods: {

        }
    }
</script>


<style lang="scss">


    .content-ico{
        padding: 0 0 35px 0;
    }
</style>
