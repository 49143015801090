<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="ico-N/ico-24-faq.gray" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M4,14.7736903 C4,16.8181373 4.85292076,19 7.94359294,19 C8.56775222,19 8.71565687,19 8.38730692,19 L11.9176103,22.1356355 L11.9176103,19 C15.7712071,19 18.2110213,19 19.237053,19 C20.7761004,19 22,18.0596209 22,15.7259779 C22,13.3923349 22,10.8932173 22,8.55170863 C22,6.21020001 21.3524603,5 18.9549918,5 C16.5575233,5 9.93670221,5 7.42755671,5 C4.91841121,5 4,6.29566649 4,8.12803751 C4,9.96040852 4,12.7292433 4,14.7736903 Z" id="Path-35" stroke="#B4C1D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" transform="translate(13.000000, 13.567818) scale(-1, 1) translate(-13.000000, -13.567818) "></path>
      <path d="M3.45132657,12.7018892 C3.26138765,12.7018892 2.76509193,12.7018892 1.96243941,12.7018892 C0.758460634,12.7018892 1.02513213,11.0509346 1.02513213,10.4981504 C1.02513213,9.9453662 1.02513213,3.91704204 1.02513213,2.46376843 C1.02513213,1.01049481 3.34930322,1 4.1493177,1 C4.94933218,1 14.4755261,1 15.9153801,1 C16.8752828,1 17.3456569,2.14554045 17.3265024,4.43662134" id="Path-63" stroke="#B4C1D6" stroke-width="2"></path>
      <path d="M11.2644334,9.94451259 C11.2644334,9.13728414 11.8461127,8.3769699 13.0043431,8.3769699 C14.1625735,8.3769699 14.5872706,8.95722471 14.5872706,10.285163 C14.5872706,11.6131013 13,11.9573605 13,13.0329687 C13,13.7500408 13,14.0723846 13,14" id="Path-101" stroke="#B4C1D6" stroke-width="2"></path>
      <path d="M13,15 L13,17" id="Path-102" stroke="#B4C1D6" stroke-width="2"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IcoQuestions"
}
</script>

<style lang="scss" scoped></style>
