import { apiAuthorizedRequest } from '@/services/ApiService';
import { internalApiRequest } from '@/shared/services/apiService';

export const deleteWorkspace = (workspaceId) => {
  return internalApiRequest({
    method: 'DELETE',
    path: `api/v1/workspaces/${workspaceId}`,
  });
};

export const deleteBrandingImage = (workspaceId, imageAttribute) => {
  return apiAuthorizedRequest(
    'DELETE',
    `/api/v1/workspaces/${workspaceId}/upload/delete/${imageAttribute}`,
  );
};

export const manageSubjects = (workspaceId, locale, data = null) => {
  return apiAuthorizedRequest(
    `${null === data ? 'GET' : 'PUT'}`,
    `/api/v2/workspaces/${workspaceId}/settings/mailSubjects/${locale}`,
    data,
  );
};
