import axios from 'axios';
import environment from '@/config/environment';
import * as types from './mutation_types';
import { publicSignRequest } from '@publicSign/services/contractService';

export const fetchActiveContract = (context, payload) => {
  context.commit(types.SET_ACTIVE_CONTRACT, payload);
};

export const setLoading = (context) => {
  context.commit(types.SET_LOADING, true);
};

export const setReady = (context) => {
  context.commit(types.SET_LOADING, false);
};

export const fetchContract = (context, authToken) => {
  context.commit(types.SET_LOADING, true);

  return publicSignRequest({
    authToken,
    method: 'GET',
    endpoint: 'api/v1/invitecontract',
  })
    .then((resp) => {
      if (!localStorage.getItem('sign_app_locale')) {
        localStorage.setItem('sign_app_locale', resp.locale);
      }

      context.commit(types.SET_CONTRACT, resp);
      context.commit(types.SET_LOADING, false);

      return resp;
    })
    .catch((err) => {
      context.commit(types.SET_ERROR, err);

      throw err;
    });
};

export const fetchContractCurrentSignIdentity = (context, payload) => {
  return axios
    .request({
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `ApiToken ${payload.authToken}`,
        'Content-Type': 'application/json',
      },
      url: `${environment.getApiUrl()}/api/client/v1/contract/${
        payload.contractId
      }/signIdentity/current`,
    })
    .then((resp) => {
      context.commit(types.SET_CURRENT_SIGN_IDENTITY, resp.data);
    })
    .catch((err) => {
      context.commit(types.SET_CURRENT_SIGN_IDENTITY, null);

      throw err;
    });
};

export const resetContractCurrentSignIdentity = ({ commit }) => {
  commit(types.SET_CURRENT_SIGN_IDENTITY, null);
};

export const fetchContractSignIdentities = (context, payload) => {
  return axios
    .request({
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `ApiToken ${payload.authToken}`,
        'Content-Type': 'application/json',
      },
      url: `${environment.getApiUrl()}/api/client/v1/contract/${
        payload.contractId
      }/signIdentity/list`,
    })
    .then((resp) => {
      context.commit(types.SET_SIGN_IDENTITIES, resp.data);
    })
    .catch((err) => {
      context.commit(types.SET_SIGN_IDENTITIES, []);

      throw err;
    });
};

export const resetContractSignIdentities = ({ commit }) => {
  commit(types.SET_SIGN_IDENTITIES, []);
};

export const fetchHistory = (context, payload) => {
  return publicSignRequest({
    authToken: payload.authToken,
    method: 'GET',
    endpoint: `api/v2/contract/${payload.idContract}/history${
      payload.idCurrentSignIdentity ? `/${payload.idCurrentSignIdentity}` : ''
    }`,
  })
    .then((resp) => {
      context.commit(types.SET_DOCUMENT_HISTORY, resp);

      return resp;
    })
    .catch((err) => {
      context.commit(types.SET_ERROR, err);

      throw err;
    });
};

export const reject = (context, payload) => {
  return publicSignRequest({
    authToken: payload.authToken,
    method: 'POST',
    endpoint: `api/v1/contracts/${context.getters['contract'].contract_id}/decline`,
    data: payload.data,
  });
};

export const setError = (context, payload) => {
  context.commit(types.SET_ERROR, payload);
};
