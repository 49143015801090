<template>
  <div class="dashboard-state">
    <div class="px-3" :class="`dashboard-state-item__color-${currentState.color}`">
      {{ currentState.title }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { publicApiRequest } from '@/services/ApiService';

export default {
  name: 'DocumentActionsSummarization',
  data() {
    return {
      contractState: 'pending',
    };
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
    }),
    currentState() {
      const undoneDocuments = [];
      const contracts = [this.contract, ...this.contract.attachments];
      contracts.forEach((document) => {
        const contractCurrentSignIdentity = document?.current_sign_identity;

        if (
          typeof contractCurrentSignIdentity !== 'undefined' &&
          'approve' === String(contractCurrentSignIdentity?.contract_role)
        ) {
          if (!contractCurrentSignIdentity?.is_approved && !undoneDocuments.includes(document.id)) {
            undoneDocuments.push(document.id);
          }
        }

        if (
          typeof contractCurrentSignIdentity !== 'undefined' &&
          'sign' === String(contractCurrentSignIdentity?.contract_role)
        ) {
          if (!contractCurrentSignIdentity?.is_signed && !undoneDocuments.includes(document.id)) {
            undoneDocuments.push(document.id);
          }
        }

        if (
          typeof contractCurrentSignIdentity !== 'undefined' &&
          'stamp' === String(contractCurrentSignIdentity?.contract_role)
        ) {
          if (!contractCurrentSignIdentity?.is_signed && !undoneDocuments.includes(document.id)) {
            undoneDocuments.push(document.id);
          }
        }
      });

      return {
        color:
          0 === undoneDocuments.length
            ? 'completed' === this.contractState?.state
              ? 'green'
              : 'red'
            : 'red',
        title:
          0 === undoneDocuments.length
            ? 'completed' === this.contractState?.state
              ? this.$t('general.done')
              : this.$t('general.waiting_for_completion')
            : this.$tc('contract.action_required.count', undoneDocuments.length),
      };
    },
  },
  mounted() {
    publicApiRequest({
      authorization: 'ApiToken',
      authToken: this.$route.params.hash,
      method: 'GET',
      endpoint: `api/client/v1/contract/${this.contract?.id}/state/virtual`,
    }).then((resp) => {
      this.contractState = resp;
    });
  },
};
</script>

<style lang="scss" scoped>
.dashboard-state {
  padding-bottom: 10px;
  width: fit-content;
  font-size: small;

  .dashboard-state-item__root {
    padding: 6px;
    border-radius: 6px;
  }

  .dashboard-state-item__color-red {
    @extend .dashboard-state-item__root;
    background-color: #fde2e3;
    color: #d21717;
  }
  .dashboard-state-item__color-green {
    @extend .dashboard-state-item__root;
    background-color: #6decaf;
    color: #0f522f;
  }
}
</style>
