<template>
  <v-stepper class="progress-bar" :value="currentStep" alt-labels>
    <v-stepper-header>
      <v-stepper-step
        step="1"
        :complete="isSignIdentitiesFilled && currentStep > 1"
        complete-icon="mdi-check"
        editable
        edit-icon="mdi-check"
        @click="selectCreateNewContractPage"
      >
        {{ $t('contract.create.header') }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        step="2"
        :complete="isContentFilled && currentStep > 2"
        complete-icon="mdi-check"
        :editable="isSignIdentitiesFilled"
        edit-icon="mdi-check"
        @click="selectEditContentPage"
      >
        {{ $t('contract.contract_content') }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">
        {{ $t('contract.signature_and_sending') }}
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
import { mapGetters } from 'vuex'
import { ContractHelper } from '@/helpers/ContractHelper'

export default {
  name: 'ProgressBar',
  props: {
    currentStep: {
      type: [Number, String],
      required: true
    },
    someDocumentFailed: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      create: 'create'
    }),
    isContentFilled () {
      if (!this.isSignIdentitiesFilled) {
        return false
      }

      if (this.create.isContentFilled) {
        return true
      }

      return (this.contract.type !== 'form' && this.contract.document && this.contract.document.state === 'completed') ||
        (this.contract.type === 'form' && this.contract.state === 'pending')
    },
    isSignIdentitiesFilled () {
      if (this.isNewUnsavedContract) {
        return false
      }

      return ContractHelper.arePartiesFilled(this.contract) && !this.someDocumentFailed
    },
    isNewUnsavedContract () {
      return (this.currentStep == 1 && !this.$route.query.contract_id) ||
        !this.contract ||
        !this.contract.id
    }
  },
  methods: {
    selectCreateNewContractPage () {
      if (this.$route.name === 'createContractNew') {
        return
      }

      this.$router.push({
        name: 'createContractNew',
        params: {
          workspace_id: this.$route.params.workspace_id
        },
        query: { contract_id: this.contract.id }
      })
    },
    selectEditContentPage () {
      if (this.$route.name === 'createContent' || this.someDocumentFailed) {
        return
      }

      this.$router.push({
        name: 'createContent',
        params: {
          workspace_id: this.$route.params.workspace_id,
          contract_id: this.contract.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-bar {
  margin: 0 auto 2rem;

  @include display(960px) {
    margin: 1rem auto 1rem;
    max-width: 100%;
  }
}
</style>
