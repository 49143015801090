<template>
  <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ico-N/ico-50-kontakty</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-50-kontakty" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-6" transform="translate(8.000000, 6.000000)" stroke="#B4C1D6" stroke-width="2">
        <path d="M0.261963362,37.2435897 C6.27389097,36.0410787 9.81685816,35.091533 10.8908649,34.3949526 C11.9648717,33.6983723 12.2403959,31.8060377 11.7174377,28.7179487" id="Path-25"></path>
        <path d="M23.1465787,37.2435897 C29.1585064,36.0410787 32.7014735,35.091533 33.7754803,34.3949526 C34.849487,33.6983723 35.1250113,31.8060377 34.6020531,28.7179487" id="Path-25-Copy" transform="translate(29.002756, 32.980769) scale(-1, 1) translate(-29.002756, -32.980769) "></path>
        <path d="M14.3589744,30.0641026 C11.5696647,29.3270861 9.53401247,27.2843645 8.25201777,23.9359378 C6.32902571,18.9132977 5.38770093,10.5982766 6.53746191,6.73076923 C7.6872229,2.86326191 11.7828822,-2.89495037e-14 17.6944018,0 C23.6059215,2.55066623e-14 26.729775,4.05786231 28.2031724,6.73076923 C29.6765698,9.40367615 28.7903798,20.0467273 26.4124141,24.6794872 C24.8271036,27.7679938 22.8592866,29.5628656 20.5089629,30.0641026" id="Path-32"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IcoContactLarge",
};
</script>
