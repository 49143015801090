/*
 * Find svg flag on wikipedia, e.g. https://en.wikipedia.org/wiki/Flag_of_Vietnam
 * (country flags are too big: https://www.countryflags.com/en/vietnam-flag-vector.html)
 *
 * TODO: i18n - better flags definitions without duplication
 */

import IcoFlagCS from './flags/IcoFlagCS';
import IcoFlagEN from './flags/IcoFlagEN';
import IcoFlagDE from './flags/IcoFlagDE';
import IcoFlagSK from './flags/IcoFlagSK';
import IcoFlagPL from './flags/IcoFlagPL';
import IcoFlagHU from './flags/IcoFlagHU';
import IcoFlagNL from './flags/IcoFlagNL';
import IcoFlagRU from './flags/IcoFlagRU';
import IcoFlagVI from './flags/IcoFlagVI';
import IcoFlagUA from './flags/IcoFlagUA';
import IcoFlagBG from './flags/IcoFlagBG';

const DEFAULT_LOCALE = 'cs';
const FALLBACK_LOCALE = 'en';
const appLocales = [
  { locale: 'cs', flag: 'IcoFlagCS', isFullyLocalized: true, code: 'cs-CZ' },
  { locale: 'en', flag: 'IcoFlagEN', isFullyLocalized: true, code: 'en-US' },
  { locale: 'de', flag: 'IcoFlagDE', isFullyLocalized: true, code: 'de-DE' },
  { locale: 'sk', flag: 'IcoFlagSK', isFullyLocalized: true, code: 'sk-SK' },
  { locale: 'pl', flag: 'IcoFlagPL', isFullyLocalized: true, code: 'pl-PL' },
  { locale: 'hu', flag: 'IcoFlagHU', isFullyLocalized: true, code: 'hu-HU' },
  { locale: 'nl', flag: 'IcoFlagNL', isFullyLocalized: true, code: 'nl-NL' },
  { locale: 'ua', flag: 'IcoFlagUA', isFullyLocalized: true, code: 'uk-UA' },
  { locale: 'bg', flag: 'IcoFlagBG', isFullyLocalized: true, code: 'bg-BG' },
  { locale: 'ru', flag: 'IcoFlagRU', alternate: 'en', code: 'ru-RU' },
  { locale: 'vi', flag: 'IcoFlagVI', alternate: 'en', code: 'vi-VN' },
];

export const appLocalesFlags = appLocales?.map((l) => l.flag);

export const availableLocales = appLocales.sort((a, b) =>
  a.locale > b.locale ? 1 : b.locale > a.locale ? -1 : 0,
);

export const fullyLocalizedLocales = appLocales.filter((l) => l.isFullyLocalized || false);

export const getAlternateLocale = (lang) => {
  const currentLocale = appLocales?.find((l) => l.locale === lang);

  return currentLocale?.alternate || currentLocale?.locale || 'en';
};

export const getLocales = (isFullyLocalized = true) =>
  isFullyLocalized ? fullyLocalizedLocales : availableLocales;

export const getNormalizeLocaleCode = (lang) =>
  appLocales?.find((l) => l.locale === lang)?.code || 'en-US';

export const isFullyLocalized = (lang) =>
  appLocales.find((l) => l.locale === lang).isFullyLocalized || false;

export const isLocaleSupported = (lang) =>
  availableLocales.findIndex((l) => l.locale === lang) !== -1;

export const Locales = {
  enum: appLocales?.map((locale) => locale.locale),
  DEFAULT_LOCALE,
  FALLBACK_LOCALE,
  flagComponents: {
    IcoFlagCS,
    IcoFlagSK,
    IcoFlagEN,
    IcoFlagPL,
    IcoFlagHU,
    IcoFlagNL,
    IcoFlagDE,
    IcoFlagRU,
    IcoFlagVI,
    IcoFlagUA,
    IcoFlagBG,
  },
};
