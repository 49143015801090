import Vue from 'vue';
import { ContractService } from '@/services/ContractService';
import { i18n } from '@/plugins/i18n';

/**
 * Method return html of contract type doc
 *
 * @param contract
 * @returns {Promise<T>}
 */
export const getContractDoc = (contract) => {
  return ContractService.getDoc(contract, 'html').then(
    (response) => {
      return response.preview_html;
    },
    () => {
      Vue.prototype.$notification.error(i18n.$t('contract.not_loaded'));
    },
  );
};

/**
 * Method return array of images from contract type doc html or empty array
 *
 * @param documentHtml
 * @returns {*[]}
 */
export const getDocumentPreviewImages = (documentHtml) => {
  if (!documentHtml) {
    return [];
  }

  let match;
  const images = [];
  const rex = /<img[^>]+src="?([^"\s]+)"?\s*\/>/g;

  // eslint-disable-next-line no-cond-assign
  while ((match = rex.exec(documentHtml))) {
    images.push(match[1]);
  }

  return images;
};
