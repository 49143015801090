const config = {
  'packeta.com': {
    links: [
      {
        api: 'https://api.signi.packeta.com',
        app: 'https://app.signi.packeta.com',
      },
    ],
    featureFlags: {
      signOnPhone: false,
    },
    translationsPrefix: 'app-packeta',
  },
  'sab.cz': {
    links: [
      {
        api: 'https://api.smlouvy.sab.cz',
        app: 'https://smlouvy.sab.cz',
      },
      {
        api: 'https://api.contracts.prostream.com',
        app: 'https://contracts.prostream.com',
      },
    ],
    featureFlags: {
      restrictedMode: true,
    },
    translationsPrefix: 'app-sab',
  },
};

const findConfig = () => {
  for (let solution of Object.keys(config)) {
    for (let couple of config[`${solution}`]?.links) {
      if (window.location.origin?.includes(couple.app)) {
        return config[`${solution}`];
      }
    }
  }

  return null;
};

const getFeatureFlagValue = (flag, defaultValue) => {
  const currentConfig = findConfig()?.featureFlags[`${flag}`];

  if ('undefined' === typeof currentConfig) {
    return defaultValue;
  }

  return findConfig()?.featureFlags[`${flag}`];
};

const urlsBuilder = () => {
  const currentAppOrigin = window.location.origin;
  const entryPoints = {
    api: process.env['VUE_APP_BACKEND_URL'] || currentAppOrigin.replace('app', 'api'),
    app: process.env['VUE_APP_FRONTEND_URL'] || currentAppOrigin,
    translationsPrefix: process.env['VUE_APP_TRANSLATIONS_PREFIX'],
  };

  for (let solution of Object.keys(config)) {
    for (let couple of config[`${solution}`]?.links) {
      if (entryPoints.app?.includes(couple.app)) {
        return Object.assign({}, couple, {
          translationsPrefix: config[`${solution}`]?.translationsPrefix,
        });
      }
    }
  }

  if (!process.env['VUE_APP_BACKEND_URL'] && 'http://localhost:8081' === entryPoints?.app) {
    entryPoints.api = 'http://localhost:8080';
  }

  return entryPoints;
};

export default {
  getAppUrl() {
    return urlsBuilder()?.app;
  },

  getApiUrl() {
    return urlsBuilder()?.api;
  },

  getAppTranslationsPrefix() {
    return urlsBuilder()?.translationsPrefix || 'signi';
  },

  isDevelopment() {
    const appUrl = this.getAppUrl();

    return !!(
      'development' === process.env.NODE_ENV ||
      (!appUrl.includes('pre.signi.tech') && appUrl.includes('.signi.tech'))
    );
  },

  isSigniDomain() {
    return window.location.origin?.includes('app.signi.com');
  },

  canSignOnPhone() {
    return getFeatureFlagValue('signOnPhone', true);
  },

  isRestrictedMode() {
    return getFeatureFlagValue('restrictedMode', false);
  },
};
