import { i18n } from '@/plugins/i18n';
import _ from 'lodash';

// TODO - add translate keys
const errors = {
  Authentication: {
    Expired: 'auths.timeout.title',
  },
  FOS: {
    RestBundle: {
      Exception: {
        InvalidParameterException: 'error.unsupported_chars',
      },
    },
  },
  Signi: {
    Exceptions: {
      Auth: {
        AuthNotSubmittedException: 'auths.not_all_submitted',
      },
      BankIdSignException: 'contract.bank_id_sign.not_allowed',
      Contract: {
        ContractCreditException: 'general.error',
        ContractLackCreditException: 'upload.error_credits',
      },
      DocsPerson: {
        DocsPersonCannotChangeMobileException: 'contract.parties.party.missing_phone_number',
      },
      NotFoundException: 'general.data_loading_error',
      Sms: {
        SmsPin: {
          SmsPinExpiredException: 'auth.sms.expired_pin',
          SmsPinMismatchException: 'auth.sms.wrong_pin',
          SmsPinOverflowAttemptsException: 'auth.sms.overflow_attempt',
        },
      },
    },
  },
};

export const getErrorMessage = ({ errorCode, errorValues = null, locale = null }) => {
  return i18n.t(
    _.get(errors, errorCode) || 'general.error',
    null === locale ? i18n.locale : locale,
    null === errorValues ? {} : errorValues,
  );
};
