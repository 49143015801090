import { apiTokenBasedRequest, apiTokenBasedFormRequest } from '@/services/ApiService';
import { unauthorizedApi } from '@/services/ContractService';

export function getAuthsStatus(token) {
  return apiTokenBasedRequest('GET', '/api/v1/auth/status', token);
}

export const getSignpostChoices = ({ authToken }) => {
  return apiTokenBasedRequest('GET', '/api/v1/auth/choices', authToken);
};

export const setSignpostChoice = ({ authToken, choice }) => {
  return apiTokenBasedRequest('POST', '/api/v1/auth/choices', authToken, {
    type: choice,
  });
};

export const getBankIdLink = ({ authToken }) => {
  return apiTokenBasedRequest('GET', '/api/v1/auth/bankId/authUri', authToken);
};

export const rejectAuth = (token, data, contractId) => {
  return unauthorizedApi({
    method: 'POST',
    url: `/contracts/${contractId}/decline`,
    token: token,
    body: JSON.stringify(data),
  });
};

export function submitPolitical(token, data) {
  return apiTokenBasedRequest('PATCH', '/api/v1/auth/political', token, data);
}

export function submitDocument(token, data) {
  return apiTokenBasedRequest('PATCH', '/api/v1/auth/document', token, data);
}

export function submitSms(token, data) {
  return apiTokenBasedRequest('PATCH', '/api/v1/auth/sms', token, data);
}

export function getDocument(token) {
  return apiTokenBasedRequest('GET', '/api/v1/auth/document', token);
}

export function createDocumentImage(token, data) {
  return apiTokenBasedFormRequest('POST', '/api/v1/auth/document/images', token, data);
}

export function getDocumentImage(token, imageId) {
  return apiTokenBasedRequest('GET', `/api/v1/auth/document/images/${imageId}`, token);
}

export function deleteDocumentImage(token, imageId) {
  return apiTokenBasedRequest('DELETE', `/api/v1/auth/document/images/${imageId}`, token);
}

export function submitMicroTransaction(token, data) {
  return apiTokenBasedRequest('PATCH', '/api/v1/auth/microTransaction', token, data);
}

export function submitAmlVerification(token) {
  return apiTokenBasedRequest('PATCH', '/api/v1/auth/submit', token);
}
