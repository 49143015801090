<template>
  <v-container class="proposer-party fluid ma-0 pa-0 text-center">
    <v-row v-if="activeAdding" class="pa-7 mx-auto proposer-party__new-role" no-gutters>
      <v-col cols="12" class="d-flex pb-4">
        <span>{{ $t('general.select') }}:</span>
        <a
          href="#"
          class="pl-2 text-decoration-none"
          :class="{ active: selectedType.signer }"
          @click.prevent="
            selectedType.signer = true;
            selectedType.group = false;
          "
        >
          {{ $t('contract.signer') }}
        </a>

        <a
          v-if="enableVariableUsers"
          href="#"
          class="pl-2 text-decoration-none"
          :class="{ active: selectedType.group }"
          @click.prevent="
            selectedType.group = true;
            selectedType.signer = false;
          "
        >
          {{ $t('contract.role') }}
        </a>
      </v-col>

      <v-col v-if="selectedType.signer">
        <slot name="signatorySearch"></slot>
      </v-col>

      <v-col v-if="selectedType.group && enableVariableUsers" cols="12">
        <v-select
          v-model="selectedGroup"
          :items="proposerRoles"
          :item-disabled="(val) => 0 === val.numberOfPeopleWithThatPosition"
          background-color="white"
          item-text="name"
          item-value="id"
          hide-details
          outlined
          return-object
          @change="onSelectRole"
        ></v-select>
      </v-col>
    </v-row>

    <v-row v-else no-gutters>
      <v-col cols="12" class="text-left">
        <v-btn color="primary" class="px-1 py-0" text x-large @click="activeAdding = true">
          <v-icon class="pr-1">mdi-plus</v-icon>
          {{ $t('contract.create.add_signer') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AddProposerParty',
  props: {
    computedAvailableRoles: {
      type: Array,
      default: () => [],
    },
    enableVariableUsers: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeAdding: false,
      selectedGroup: null,
      selectedType: {
        signer: true,
        group: false,
      },
    };
  },
  computed: {
    proposerRoles() {
      return [
        {
          name: this.$t('contract.signs.role_placeholder'),
          id: null,
        },
        ...this.computedAvailableRoles,
      ]
        ?.map((item, index) => {
          if (!item?.id) {
            return {
              id: index,
              ...item,
            };
          }
        })
        ?.map((item) => {
          if (item?.number_of_people_with_that_position <= 0) {
            return {
              ...item,
              disabled: true,
            };
          }

          return item;
        });
    },
  },
  methods: {
    onSelectRole(event) {
      if (null === event.id) {
        return;
      }

      event.number_of_people_with_that_position -= 1;

      this.onDeactivate();
      this.$emit('on-signer-select', {
        variable_position: event.name,
      });
    },
    onDeactivate() {
      this.activeAdding = false;
      this.selectedGroup = null;
      this.selectedType = {
        signer: true,
        group: false,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.proposer-party__new-role {
  background-color: #f2f6fb;
  border-radius: 6px;
  width: 100%;
}

.active {
  color: var(--v-primary-base) !important;
  text-decoration: underline !important;
}
</style>
