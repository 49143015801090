<template>
  <div class="contract-document-add-attachment pa-5">
    <v-row align="start" justify="start" no-gutters>
      <v-col cols="12" class="text-left pr-0">
        <v-hover v-slot="{ hover }">
          <v-btn color="primary" class="text-left pl-0 pr-auto" text block @click="addAttachment">
            <v-avatar class="mr-2" color="#f0f3fb" size="35" rounded>
              <v-icon color="primary">mdi-plus</v-icon>
            </v-avatar>

            <span
              class="primary--text"
              :class="{ 'text-decoration-underline': hover }"
              style="word-wrap: break-word; overflow: hidden"
            >
              {{ $t('contract.attach_document') }}
            </span>

            <v-spacer></v-spacer>
          </v-btn>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'CreateContractDocumentAddTab',
  methods: {
    addAttachment() {
      this.$emit('add-attachment');
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-document-add-attachment {
  border-bottom: 1px solid #d8d8d8;
  background-color: #ffffff;
}
</style>
