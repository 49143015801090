// complicated code because of weird issues for one-device, check IS-191
// TODO: clicking on label works only for onedevice (DataContractCounterparty) without page reload
export function setupAgreeCheckbox(element, toggleCheckboxState) {
  let checkbox;
  const renderCheckbox = (checked = false) => {
    checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.checked = checked;
    checkbox.className = 'option-input checkbox checkbox__data';
  };
  renderCheckbox();

  const label = document.createElement('label');
  label.appendChild(checkbox);
  label.append(element.innerText);
  label.style.display = 'flex';

  element.innerText = '';
  element.appendChild(label);
  element.style.display = 'flex';

  const handler = () => {
    const isChecked = toggleCheckboxState();
    checkbox.parentNode.removeChild(checkbox);
    renderCheckbox(isChecked);
    label.insertBefore(checkbox, label.firstChild);
  };

  element.addEventListener('click', handler);
}
