<template>
  <div class="document-view">
    <DocumentPageLoader v-if="isLoading" />

    <template v-else>
      <div :class="{ 'document-view__comments--active': canEditComments }" @click="createComment">
        <shadow-root v-if="isContractTemplate" class="document-view__template">
          <DocumentTemplateView :source="contractTemplate" />
        </shadow-root>

        <DocumentPages
          v-if="canShowPdfDocument"
          class="showImages"
          :document-link="documentLink"
          :is-pdf-loading="isPdfLoading"
          :can-show-signatures="canShowSignatures"
          :sign="sign"
        />
      </div>

      <div v-if="canContinueToSigning" class="pt-10 px-0">
        <v-row align="center" justify="center">
          <v-col class="col-12 col-md-auto">
            <v-btn
              color="primary"
              x-large
              min-width="14rem"
              :block="$vuetify.breakpoint.mobile"
              :loading="isSubmitted"
              @click="submit"
            >
              {{ $t('general.continue_to_signing') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <template>
        <CommentsPoint
          v-for="comment in contractComments"
          :key="comment.id || `comment-${new Date().getTime()}`"
          :comment="comment"
          :contract-id="contract.id"
          :can-edit-comment="canEditComments && !hasCommentModeClosed"
          @reload-comments="reload"
          @destroyUncreatedComment="destroyUncreatedComment"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ContractService } from '@/services/ContractService';
import CommentsPoint from '@/views/dashboard/components/CommentsPoint';
import DocumentPageLoader from '@/common/skeletons/DocumentPageLoader';
import DocumentPages from '@contract/components/DocumentPages';
import DocumentTemplateView from '@contract/components/DocumentTemplateView';
import { apiAuthorizedRequest } from '@/services/ApiService';

export default {
  name: 'DocumentView',
  components: {
    DocumentTemplateView,
    CommentsPoint,
    DocumentPageLoader,
    DocumentPages,
  },
  props: {
    contractTemplate: {
      type: String,
      default: null,
    },
    canEditComments: {
      type: Boolean,
      default: false,
    },
    canShowSignatures: {
      type: Boolean,
      default: true,
    },
    selectedContractId: {
      type: [String, Number],
      default: undefined,
    },
    sign: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      pdfLoaded: false,
      commentsLoaded: false,
      comments: [],
      submitted: false,
    };
  },
  computed: {
    ...mapGetters({
      auths: 'verification/auths',
      isAllAuthsDone: 'verification/isAllAuthsDone',
      contract: 'contract',
      documentInfo: 'pdfDocument/documentInfo',
      documentLink: 'pdfDocument/documentLink',
      isLoadingDocument: 'pdfDocument/isLoadingDocument',
    }),
    canContinueToSigning() {
      return 'draft' === this.contract?.state && this.isAllAuthsDone;
    },
    isSubmitted() {
      return this.submitted;
    },
    isLoading() {
      if (this.contractTemplate !== null) {
        return this.isPdfLoading;
      }

      return this.isPdfLoading || this.isLoadingDocument;
    },
    isPdfLoading() {
      return !this.pdfLoaded;
    },
    isContractTemplate() {
      return this.contractTemplate !== null;
    },
    canShowPdfDocument() {
      return !this.isContractTemplate && !this.isPdfLoading;
    },
    hasCommentMode() {
      return 'comments' === this.contract?.comments_usage;
    },
    hasCommentModeClosed() {
      return 'completed' === this.contract?.state;
    },
    contractComments() {
      return this.comments || [];
    },
    isContractCompleted() {
      return 'completed' === this.contract?.state;
    },
  },
  watch: {
    documentInfo: {
      handler: function (value) {
        if (typeof value !== 'undefined') {
          this.load(this.documentLink).then(() => {
            this.pdfLoaded = true;
          });
        }
      },
    },
  },
  mounted() {
    this.pdfLoaded = false;

    if (this.contractTemplate !== null) {
      setTimeout(() => {
        this.pdfLoaded = true;
      }, 1400);
    } else {
      const authToken = this.$route?.params?.hash;

      if ('undefined' === typeof authToken) {
        this.fetchDocumentWithLink();
      } else {
        this.fetchDocumentWithLinkAsUnregistered({
          authToken: this.$route?.params?.hash,
          contractId: this.selectedContractId,
        });
      }
    }

    if (this.hasCommentMode && this.$route.name !== 'contractView') {
      this.fetchComments(this.contract?.id || this.contract?.contract_id).then((resp) => {
        this.comments = resp || [];
        this.commentsLoaded = true;
        this.$store.commit('setContract', Object.assign(this.contract, { comments: resp || [] }));
      });
    }
  },
  beforeDestroy() {
    this.reset();
  },
  methods: {
    ...mapActions({
      fetchAuthState: 'verification/fetchAuthState',
      fetchDocumentWithLink: 'pdfDocument/fetchDocumentWithLink',
      fetchDocumentWithLinkAsUnregistered: 'pdfDocument/fetchDocumentWithLinkAsUnregistered',
      load: 'pdfDocument/load',
      reset: 'pdfDocument/reset',
    }),
    fetchComments(contractId, mode = 'comments') {
      return ContractService.getComments(contractId, mode).then(
        (response) => {
          return response;
        },
        () => {
          this.$notification.error(this.$t('document.error.comments_not_loaded'));
        },
      );
    },
    searchNewTitle(commentsTitles) {
      let newTitle = 1;
      let checkNext = true;

      while (checkNext) {
        if (!commentsTitles.includes(newTitle)) {
          checkNext = false;
        } else {
          newTitle++;
        }
      }

      return newTitle;
    },
    createComment(e) {
      if (!this.canEditComments || !this.commentsLoaded || this.isContractCompleted) {
        return;
      }

      let openedComments =
        this.comments?.filter((comment) => {
          return comment.openDialog;
        }) || [];

      if (openedComments.length > 0) {
        this.comments.pop();
      }

      const rect = e.currentTarget.getBoundingClientRect();
      const newTitle = this.searchNewTitle(this.comments.map((c) => c.anchor.title));

      this.comments.push({
        anchor: {
          title: newTitle || this.comments?.length + 1,
          offset_top: ((e.clientY - rect.top - 16) / rect.height) * 100,
          offset_left: ((e.clientX - rect.left - 16) / rect.width) * 100,
        },
        content: '',
        openDialog: true,
        parent_comment_id: null,
      });
    },
    destroyUncreatedComment() {
      this.comments?.pop();
    },
    reload() {
      this.fetchComments(this.contract?.id || this.contract?.contract_id).then((resp) => {
        this.comments = resp;
        this.commentsLoaded = true;
        this.$store.commit('setContract', Object.assign(this.contract, { comments: resp }));
      });
    },
    submit() {
      this.submitted = true;

      apiAuthorizedRequest(
        'PATCH',
        `/api/v1/auths/contracts/${this.$route.params.contract_id}/submit`,
      )
        .then(() => {
          this.$router.push({
            name: 'createContent',
            params: {
              contract_id: this.$route.params.contract_id,
              workspace_id: this.$route.params.workspace_id,
            },
          });
        })
        .catch(() => {
          this.$notification.error(this.$t('general.error'));
          this.submitted = false;
        });
    },
  },
};
</script>

<style lang="scss">
.document-view {
  position: relative;
  z-index: 0;

  &__template {
    padding: 1rem 3rem;

    @include display(960px) {
      padding: 0.5rem;
    }
  }

  &__comments--active {
    cursor: crosshair;
    border: 1px solid red;
  }
}
</style>
