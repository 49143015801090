<template lang="html">
    <div class="checkbox-wrap">
        <label class="checkbox-label">
            <input
                @change="$emit('click')"
                type="checkbox"
                :checked="value"
                class="option-input checkbox checkbox__data"
                :style="cssVars"
                :disabled="disabled"
            >
            <span class="checkbox__label" v-html="label"></span>
        </label>
    </div>
</template>

<script>
    export default {
        name: 'Checkbox',
        // TODO: ui - it should emit click if label is clicked (now it's handled in every parent view)
        // - find usage: <Checkbox :value=".." OR <Checkbox v-model=".."
        props: ['label', 'value', 'customPrimaryColor', 'disabled'],
        methods: {
            lightenDarkenColor(col, amt) {
                var usePound = false;

                if (col[0] == "#") {
                    col = col.slice(1);
                    usePound = true;
                }

                var num = parseInt(col, 16);

                var r = (num >> 16) + amt;

                if (r > 255) r = 255;
                else if (r < 0) r = 0;

                var b = ((num >> 8) & 0x00FF) + amt;

                if (b > 255) b = 255;
                else if (b < 0) b = 0;

                var g = (num & 0x0000FF) + amt;

                if (g > 255) g = 255;
                else if (g < 0) g = 0;

                return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
            },
        },
        computed: {
            cssVars() {
                return {
                    '--color': this.customPrimaryColor,
                    '--color-lighten': (this.customPrimaryColor ? this.lightenDarkenColor(this.customPrimaryColor, 20) : null),
                }
            }
        },
    }
</script>


<style lang="scss">
    .checkbox-template__line{
        text-align: left;
        .ico-info {
            margin-left: 1ex;
            vertical-align: sub;
        }
    }

    .profile-checkbox{
        .checkbox__label{
            line-height: 45px;
            font-size: 16px;
            font-weight: 300;
        }
    }

    .checkbox {
        @include clearfix;
        display: block;
        border-radius: 4px;
    }


    .checkbox__label {
        // float: left;
        font-weight: normal;
        font-size: 16px;
        color: #000000;
        cursor:pointer;
        line-height: 35px;
        transition: 0.2s;
        &:hover{
            color:#5E239E;
            transition: 0.2s;
        }

        @include md{
            float: none;
            text-align: left;
            display: block;
            padding-left: 40px;
            line-height: 1.5;
        }
    }

    .profile-checkbox .checkbox__label{
        line-height: 45px;
    }

    .checkbox__data{
        float: left;
    }

    .profile-checkbox .checkbox__data{
        float: right;
    }

    .checkbox-wrap{
        width: auto;
        display: inline-block;
        height: 35px;
        margin-bottom: 5px;

        @include md{
            height: auto;
        }
    }
    .profile-checkbox{
        margin-top: 15px;
        height: 45px;
        margin-bottom: 10px;
        width: 100%;
        display: block;
        border-bottom: solid 1px #D4DFF0;
    }

    .option-input {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      position: relative;
      top: 3px;
      right: 0;
      bottom: 0;
      left: 0;
      height: 22px;
      width: 22px;
      transition: all 0.15s ease-out 0s;
      background: white;
      border: none;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      margin-right: 10px;
      outline: none;
      position: relative;
      padding: 0px;
      margin-right: 10px;
      margin-bottom: 0px;
      min-width: 20px;
      max-width: unset;
      min-height: 20px;

      @include md{
          top: -2px;
      }
    }
    .option-input:hover {
        border:solid 1px #5E239E;
    }
    .option-input:hover ~ .checkbox__label{
        color:#5E239E;
    }
    .option-input.checkbox:checked {
      background-color: var(--color, #5E239E);
      background-position: 100% 0;
      background-size: 200% 200%;
      transition: 0.2s;
      border: solid 1px var(--color, #5E239E);
    }
    .option-input.checkbox:checked::before {
      height: 25px;
      width: 25px;
      position: absolute;
      content: url(/images/check.svg);
      display: inline-block;
      text-align: center;
      line-height: 24px;
      margin-left: -2.5px;
      margin-top: 0.5px;
    }
    .option-input.checkbox:checked::after {

      background: var(--color, #5E239E);
      content: '';
      display: block;
      position: relative;
    }
    .option-input.radio {
      border-radius: 50%;
    }
    .option-input.radio::after {
      border-radius: 50%;
    }


    .option-input-radio:hover{
      background:#5E239E;
    }

    .option-input.radio:checked {
      background-color: #5E239E;
      border: solid 1px #5E239E;
      transition: 0.2s;
    }
    .option-input.radio:checked::before {
      position: absolute;
      content: '';
      display: inline-block;
      font-size: 13px;
      text-align: center;
      line-height: 20px;
      width: 12px;
      height: 12px;
      left: 3px;
      top: 3px;

      background-color: #5E239E;
      background-position: 100% 0;
      background-size: 200% 200%;
      border-radius: 50%;
    }
    .option-input.radio:checked::after {

      background: #ffffff;
      content: "";
      display: block;
      position: relative;
      width: 8px;
      height: 8px;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
    }

    .checkbox{
        border: solid 1px #B4C1D6;

    }
    .radio{
        border: solid 1px #B4C1D6;
    }



    .register-checkbox{
      position: relative;
      max-width: 5px 0px;
      .option-input{
        top: 7px;
      }
      .checkbox-label{
        font-size: 11px;
        position: relative;
      }
    }


    .disabled{
        .checkbox__label{
            color: #8E97A4;
            &:hover{
                color: #8E97A4;
            }
        }
        .option-input:hover ~ .checkbox__label{
            color: #8E97A4;
        }
        .option-input{
            background: #F2F6FC;
        }
        .option-input.checkbox:checked{
            background: #F2F6FC;
            border: solid 1px #B4C1D6;
        }
        .option-input.checkbox:checked::before {
          content: url(/images/check-color.svg);
        }
    }

    .profile-checkbox{
        .option-input{
            margin-right: 0px;
        }
    }

    .content-box__controll-wrap{
        .checkbox-wrap{
            margin-bottom: 0px;
        }
    }


</style>
