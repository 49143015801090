<template>
  <div class="workspace-member-row pt-2 pb-4">
    <v-row align="center" justify="start" no-gutters>
      <v-col class="col-auto pr-2">
        <s-avatar
          :size="40"
          :profile-image="member.avatar_url"
          :firstname="member.firstname"
          :lastname="member.lastname"
          background-color="#6DECB0"
        />
      </v-col>

      <v-col>
        <div class="workspace-member-row__mail">
          {{ memberName }}
        </div>

        <div class="workspace-member-row__info">
          {{ memberDescription }}
        </div>
      </v-col>

      <v-col class="col-auto">
        <WorkspaceMemberSettingsDialog
          v-if="isOwner"
          v-model="memberSettings"
          :default-config="member"
          :is-submitted="submitted"
          @update-settings="onUpdateMemberSettings"
        />

        <v-btn
          v-if="isOwner"
          color="error"
          :disabled="!canDeleteUser"
          icon
          small
          @click="showDeleteContactPreviewItemDialog = true"
        >
          <v-icon small>mdi-trash-can-outline</v-icon>
        </v-btn>
      </v-col>

      <v-col v-if="canResendInvitation" cols="12" class="pt-2 text-center">
        <v-btn
          v-if="invitationId && isOwner"
          color="primary"
          :loading="resendingInvitation"
          outlined
          @click="onResendInvitation"
        >
          {{ $t('workspace.invitation.resend') }}
        </v-btn>
      </v-col>
    </v-row>

    <SConfirmDialog
      v-model="showDeleteContactPreviewItemDialog"
      :message="`${$t('workspace.team.delete.confirm')}`"
      @confirmation-callback="onDeleteMemberFromWorkspace"
    />
  </div>
</template>

<script>
import SAvatar from '@/common/components/SAvatar';
import {
  deleteWorkspaceMemberInvitation,
  deleteWorkspaceMember,
  resendMemberInvitation,
  updateWorkspaceMemberInvitationSettings,
  updateWorkspaceMemberSettings,
} from '@workspace/services/workspaceMembersService';
import { email, required } from 'vuelidate/lib/validators';
import WorkspaceMemberSettingsDialog from '@workspace/components/WorkspaceMemberSettingsDialog';
import SConfirmDialog from '@/common/components/SConfirmDialog';

export default {
  name: 'WorkspaceMemberRow',
  components: {
    SAvatar,
    WorkspaceMemberSettingsDialog,
    SConfirmDialog,
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteMode: false,
      editMode: false,
      memberSettings: {},
      submitted: false,
      resendingInvitation: false,
      showDeleteContactPreviewItemDialog: false,
    };
  },
  validations: {
    invitation: {
      email: {
        required,
        email,
        minLength: 5,
      },
    },
  },
  computed: {
    canDeleteUser() {
      return 'owner' !== this.member.rights;
    },
    invitationId() {
      return this.member?.invitation_id || false;
    },
    canResendInvitation() {
      return this.invitationId && this.isOwner;
    },
    memberName() {
      if (this.invitationId) {
        return `${this.member.email}`;
      }

      const memberName = `${this.member.firstname} ${this.member.lastname}`;

      return this.member?.position ? `${memberName} / ${this.member.position}` : `${memberName}`;
    },
    memberDescription() {
      return this.invitationId
        ? this.$t('workspace.invitation.not_confirmed')
        : `${this.member.email}`;
    },
  },
  watch: {
    member: {
      deep: true,
      handler: function (val) {
        this.memberSettings = Object.assign({}, val);
      },
    },
  },
  mounted() {
    this.memberSettings = Object.assign({}, this.member);
  },
  methods: {
    onResendInvitation() {
      this.resendingInvitation = true;

      resendMemberInvitation(this.invitationId)
        .then(() => {
          this.$notification.success(this.$t('workspace.invitation.resend.ok'));
        })
        .catch(() => {
          this.$notification.error(this.$t('workspace.invitation.resend.failed'));
        })
        .finally(() => {
          this.resendingInvitation = false;
        });
    },
    onUpdateMemberSettings() {
      this.submitted = true;

      if (this.invitationId) {
        updateWorkspaceMemberInvitationSettings(
          this.invitationId,
          Object.assign({}, this.memberSettings, {
            all_contracts: this.memberSettings.all_contracts ? 1 : 0,
            all_contacts: this.memberSettings.all_contacts ? 1 : 0,
          }),
        )
          .then(() => {
            this.$notification.success(this.$t('workspace.team.role.saved'));
          })
          .catch(() => {
            this.$notification.error(this.$t('workspace.team.role.error'));
          })
          .finally(() => {
            this.$emit('reload-workspace');
            this.submitted = false;
          });
      } else {
        updateWorkspaceMemberSettings(
          this.$route.params.workspace_id,
          this.memberSettings?.id,
          Object.assign({}, this.memberSettings, {
            all_contracts: this.memberSettings.all_contracts ? 1 : 0,
            all_contacts: this.memberSettings.all_contacts ? 1 : 0,
          }),
        )
          .then(() => {
            this.$notification.success(this.$t('workspace.team.role.saved'));
          })
          .catch(() => {
            this.$notification.error(this.$t('workspace.team.role.error'));
          })
          .finally(() => {
            this.$emit('reload-workspace');
            this.submitted = false;
          });
      }
    },
    onDeleteMemberFromWorkspace() {
      this.submitted = true;

      if (this.invitationId) {
        deleteWorkspaceMemberInvitation(this.invitationId)
          .then(() => {
            this.$notification.success(this.$t('workspace.invitation.deleted'));
          })
          .catch(() => {
            this.$notification.error(this.$t('workspace.team.delete.error'));
          })
          .finally(() => {
            this.$emit('reload-workspace');
            this.submitted = false;
          });
      } else {
        deleteWorkspaceMember(this.$route.params.workspace_id, this.memberSettings?.id)
          .then(() => {
            this.$notification.success(this.$t('workspace.team.delete.ok'));
          })
          .catch(() => {
            this.$notification.error(this.$t('workspace.team.delete.error'));
          })
          .finally(() => {
            this.$emit('reload-workspace');
            this.submitted = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.workspace-member-row__mail {
  font-weight: 300;
  font-size: 16px;
  color: #000000;
  letter-spacing: -0.03px;
}

.workspace-member-row__info {
  font-weight: 300;
  font-size: 13px;
  color: #8e97a4;
  letter-spacing: -0.03px;
}
</style>
