<template>
  <v-alert class="mx-auto px-3 py-2" color="primary" max-width="845px" width="100%" dense dark>
    <template v-slot:prepend>
      <v-icon class="pr-2 py-1 align-self-start">mdi-alert-outline</v-icon>
    </template>

    <v-row align="center" justify="start" no-gutters>
      <v-col cols="12">
        <div class="title font-weight-bold text-decoration-underline pb-2">
          {{ $t('workspace.archive.disabled') }}
        </div>
      </v-col>

      <v-col cols="12">
        <div class="subtitle-2 font-weight-bold">
          {{ archiveMessageCount }}
        </div>
      </v-col>

      <v-col cols="12">
        <v-row align="center" justify="end" class="py-4">
          <v-col cols="auto">
            <span class="d-block font-weight-medium pa-3">{{ $t('general.number_of_years') }}</span>
          </v-col>

          <v-col cols="auto">
            <v-select
              v-model="yearsOfArchiving"
              :items="yearsOffer"
              color="warning"
              light
              solo
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="auto">
            <v-btn
              :href="mailContent"
              color="primary"
              class="white-background"
              height="3rem"
              outlined
            >
              {{ $t('archive.order') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: 'ArchiveNotification',
  props: {
    documentsInTrashCount: {
      type: [Number, String],
      default: 0,
    },
    workspace: {
      type: Object,
    },
  },
  data() {
    return {
      yearsOfArchiving: 5,
      yearsOffer: [5, 10, 20, 30],
    };
  },
  computed: {
    workspaceId() {
      return this.$route.params.workspace_id;
    },
    mailContent() {
      return `mailto:help@signi.com?subject=${this.$t('archive.order.subject')}&body=${this.$t(
        'archive.order.mail_body',
        { years: this.yearsOfArchiving, workspaceId: this.workspaceId },
      )}`;
    },
    archiveMessageCount() {
      return this.$t('workspace.archive.disabled_message', {
        count: this.documentsInTrashCount,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
