// TODO - Dashboard module
import Dashboard from '@/views/dashboard/Dashboard.vue';

// TODO - Reports module
import Reports from '@/views/dashboard/Reports.vue';

// TODO - Contact module
import Contacts from '@/views/dashboard/Contacts.vue';
import ContactDetail from '@/views/dashboard/ContactDetail';

// TODO - Sign app module with components and pages
import ContractView from '@/views/contract/ContractView.vue';
import ContractData from '@/views/contract/ContractData.vue';
import Contract2FAStart from '@/views/contract/Contract2FAStart.vue';
import ContractVerifyIdentity from '@/views/contract/ContractVerifyIdentity.vue';
import Contract2FASubmit from '@/views/contract/Contract2FASubmit.vue';
import ContractConfirm from '@/views/contract/ContractConfirm.vue';
import ContractComplete from '@/views/contract/ContractComplete.vue';
import ContractSign from '@/views/contract/ContractSign.vue';

// TODO - Contract module with components and pages
import Documents from '@/views/dashboard/Documents.vue';
import CreateNew from '@/views/create/CreateNew.vue';
import CreateOwn from '@/views/create/CreateOwn.vue';
import CreateUpload from '@/views/create/CreateUpload.vue';
import CreateContent from '@contract/views/CreateContent';
import CreateSign from '@/views/create/CreateSign.vue';
import CreateCompleted from '@/views/create/CreateCompleted.vue';
import CreateRejected from '@/views/create/CreateRejected.vue';
import ContractCreateForm from '@/views/create/ContractCreateForm.vue';

// TODO - Templates module with components and pages
import Templates from '@/views/dashboard/Templates.vue';

// TODO - Tutorial module
import Tutorial from '@/views/tutorial/Tutorial';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashboard,
  },
  {
    path: '/dashboard/workspace/:workspace_id',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/dashboard/workspace/:workspace_id/documents',
    name: 'documentsTable',
    component: Documents,
  },
  {
    path: '/dashboard/workspace/:workspace_id/reports',
    name: 'reports',
    component: Reports,
  },
  {
    path: '/dashboard/workspace/:workspace_id/contacts',
    name: 'contacts',
    component: Contacts,
  },
  {
    path: '/dashboard/workspace/:workspace_id/contacts/:contact_id',
    name: 'contactDetail',
    component: ContactDetail,
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    component: Tutorial,
  },
  {
    path: '/contract/view/:hash',
    name: 'contractView',
    component: ContractView,
  },
  {
    path: '/contract/data/:hash',
    name: 'contractData',
    component: ContractData,
  },
  {
    path: '/contract/verify/identity/:hash/contract/:contract_id',
    name: 'contractVerifyIdentity',
    component: ContractVerifyIdentity,
  },
  {
    path: '/contract/2fa/start/:hash/contract/:contract_id',
    name: 'contract2FAStart',
    component: Contract2FAStart,
  },
  {
    path: '/contract/2fa/submit/:hash/contract/:contract_id',
    name: 'contract2FASubmit',
    component: Contract2FASubmit,
  },
  {
    path: '/contract/confirm/:email/:hash',
    name: 'contractConfirm',
    component: ContractConfirm,
  },
  {
    path: '/contract/complete/:email/:hash',
    name: 'contractComplete',
    component: ContractComplete,
  },
  {
    path: '/contract/sign/:hash/contract/:contract_id',
    name: 'contractSign',
    component: ContractSign,
  },
  {
    path: '/:workspace_id/create/new',
    name: 'createNew',
    component: CreateNew,
  },
  {
    path: '/:workspace_id/contract/:contract_id/add/attachment',
    name: 'createNewAttachment',
    component: CreateNew,
  },
  {
    path: '/:workspace_id/create-contract',
    name: 'createContractNew',
    component: ContractCreateForm,
    props: (route) => ({
      contract_id: route.query.contract_id,
      is_attachment: route.query.is_attachment,
    }),
  },
  {
    path: '/:workspace_id/create/:contract_id/own',
    name: 'createOwn',
    component: CreateOwn,
  },
  {
    path: '/:workspace_id/:type/create-contract',
    name: 'createContractDeprecated',
    redirect: (to) => ({
      name: 'createContractNew',
      params: {
        workspace_id: to.params.workspace_id,
      },
      query: {
        contract_id: to.query.contract_id,
      },
    }),
  },
  {
    path: '/:workspace_id/create/:contract_id/owner',
    name: 'createOwnerDeprecated',
    redirect: (to) => ({
      name: 'createContractNew',
      params: {
        workspace_id: to.params.workspace_id,
      },
      query: {
        contract_id: to.params.contract_id,
      },
    }),
  },
  {
    path: '/:workspace_id/create/:contract_id/parties',
    name: 'createPartiesDeprecated',
    redirect: (to) => ({
      name: 'createContractNew',
      params: {
        workspace_id: to.params.workspace_id,
      },
      query: {
        contract_id: to.params.contract_id,
      },
    }),
  },
  {
    path: '/:workspace_id/create/:contract_id/upload',
    name: 'createUpload',
    component: CreateUpload,
  },
  {
    path: '/:workspace_id/create/:contract_id/content',
    name: 'createContent',
    component: CreateContent,
  },
  {
    path: '/:workspace_id/create/:contract_id/sign',
    name: 'createSign',
    component: CreateSign,
  },
  {
    path: '/:workspace_id/create/completed/:contract_id',
    name: 'createCompleted',
    component: CreateCompleted,
  },
  {
    path: '/:workspace_id/create/rejected',
    name: 'createRejected',
    component: CreateRejected,
  },
  {
    path: '/overview/workspace/:workspace_id/contracts/active',
    name: 'overviewContractsActive',
    redirect: (to) => ({
      name: 'documentsTable',
      params: {
        workspace_id: to.params.workspace_id,
      },
      query: {
        state: 'draft',
      },
    }),
  },
  {
    path: '/:workspace_id/templates',
    name: 'template',
    component: Templates,
  },
  {
    path: '/templates',
    name: 'templatesGlobalDeprecated',
    redirect: (to) => ({
      name: 'template',
      params: {
        workspace_id: to.params.workspace_id || localStorage.getItem('workspace_id') || 0,
      },
    }),
  },
];

export default routes;
