const routes = [
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '@account/views/Account'),
  },
  {
    path: '/account/signature',
    alias: '/signature',
    name: 'accountSignature',
    component: () =>
      import(/* webpackChunkName: "account__signature" */ '@account/views/AccountSignature'),
  },
];

routes.forEach((route) => {
  route.meta = {
    authRequired: true,
    layout: () => import(/* webpackChunkName: "empty-layout" */ '@/layouts/EmptyLayout'),
  };
});

export default routes;
