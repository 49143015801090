<template lang="html">
  <div id="contract-link">
    <div v-for="(userWithLink, index) of links" :key="index" :class="containerClass">
      <div v-if="links.length > 1" class="share-link-popup-button">
        {{ $t('contract.for')  }}
        <strong>{{ getFullName(userWithLink) }}</strong>

        <div class="share-link-status-icon">
          <IcoCircleCheck v-if="isCompleted(userWithLink)" />
          <IcoCircleCross v-else />
        </div>
      </div>

      <v-text-field
        :value="userWithLink.link"
        :disabled="isDisabled(userWithLink)"
        hide-details
        readonly
        outlined
        dense
      >
        <template v-slot:append>
          <v-icon v-if="isDisabled(userWithLink)" color="#9F9F9F" small style="padding-top: 0.2rem">
            mdi-content-copy
          </v-icon>
          <v-icon v-else color="primary" small style="padding-top: 0.2rem" @click="copyLinkToClipboard(userWithLink, $event)">
            mdi-content-copy
          </v-icon>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
import IcoCircleCheck from '@/components/svg/IcoCircleCheck.vue';
import IcoCircleCross from '@/components/svg/IcoCircleCross.vue';

export default {
  name: 'ShareLinks',
  components: {
    IcoCircleCheck,
    IcoCircleCross,
  },
  props: {
    links: {
      type: Array,
      default: () => {
        return []
      }
    },
    containerClass: {
      type: String,
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    },
    isAmlLinks: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    getFullName (user) {
      return this.isAmlLinks
        ? user.full_name
        : user.fullname
    },
    isCompleted (user) {
      return user.isCompleted || user.is_completed
    },
    isDisabled (user) {
      return this.isAmlLinks ? user.is_disabled : user.isDisabled
    },
    copyLinkToClipboard(userWithLink) {
      if (userWithLink.isDisabled) {
        return
      }

      navigator.clipboard.writeText(userWithLink.link);
    },
  },
}
</script>

<style lang="scss">
@media (max-width: 992px) {
  body .share-link-popup-trigger {
    bottom: -40px;
    width: 15px;
    cursor: pointer;
    left: 50%;
    position: relative;
  }
  body .share-link-popup {
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10px;
  }
}

.contract-link {
  margin: 1em 0 2em 0;
  cursor: pointer;
  padding-bottom: 10px;
}

.contract-link:last-child {
  padding-bottom: 0;
}

.contract-link-input {
  cursor: pointer;
}

body .contract-link-share-input {
  background: url(/images/copy.svg) right 12px center no-repeat;
  padding-right: 35px;
  padding-left: 10px;
  background-size: 15px;
  font-size: 12px;
  height: 40px;
  margin: 0;
  cursor: pointer;
  &:disabled {
    background-image: none;
    color: #ccc;
    pointer-events: none;
  }
}
body .contract-link-share {
  margin: 0 1em 0 0;
  width: 100%;
}

.contract-link-container {
  margin-bottom: 10px;
}

.contract-link-container:first-child {
  margin-top: 10px;
}

.contract-link-container:last-child {
  margin-bottom: 0;
}

.share-link-popup-button {
  margin-bottom: 5px;
}
.share-link-status-icon {
  float: right;
  text-align: right;
  svg {
    height: 16px;
  }
}

body .share-link-popup .input {
  margin: 0;
}

body .contract-link-input {
  background: url(/images/copy.svg) right 12px center no-repeat;
  padding-right: 35px;
  background-size: 15px;
  font-size: 12px;
  height: 40px;
  margin: 0;
  &:disabled {
    background-image: none;
    color: #ccc;
    pointer-events: none;
  }
}

.share-link-popup-trigger {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 15px;
  cursor: pointer;
}
.share-link-popup {
  background: white;
  padding: 20px;
  position: absolute;
  right: 20px;
  top: 45px;
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
  border-radius: 6px;
  border: none;
  z-index: 9;
}

input.input__data.contract-link-share-input {
  font-size: 14px;
  background: url("/images/copy-purple.svg") right 10px center no-repeat;
  background-size: 16px;
  height: 45px;
}
</style>
