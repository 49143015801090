<template>
  <v-dialog v-model="show" max-width="800px">
    <v-card class="mx-auto" elevation="5" rounded="xl">
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-btn icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-title>
        <h1
          class="headline font-weight-bold mx-auto mb-0"
          v-html="$t('signing.tell_reason_to_reject')"
        ></h1>
      </v-card-title>

      <v-row class="pa-10 pt-0" align="stretch" justify="center" no-gutters>
        <v-col
          v-for="(reason, index) in reasons"
          :key="index"
          class="col-12 col-md-5 ma-2 mb-2"
          @click="selectReason(reason)"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              class="reason-choice__button text-center pa-2 pa-md-5"
              :style="
                selectedReason !== null && selectedReason.value === reason.value
                  ? `border: 2px solid ${primaryColor}`
                  : ''
              "
              height="100%"
              rounded="lg"
              :elevation="
                hover
                  ? 5
                  : selectedReason !== null && selectedReason.value === reason.value
                  ? 10
                  : 1
              "
            >
              {{ reason.title }}
            </v-card>
          </v-hover>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row v-show="isSelectedReason" class="pa-10 pt-0" no-gutters>
          <v-col cols="12">
            <v-textarea
              v-model="message"
              :label="reasonLabel"
              :color="primaryColor"
              name="reason"
              outlined
              autofocus
            ></v-textarea>
          </v-col>

          <v-col cols="12" class="text-center">
            <v-btn
              :color="primaryColor"
              :loading="isSending"
              :disabled="message.length <= 2"
              class="white--text"
              min-width="15rem"
              x-large
              @click="onReject"
            >
              {{ $t('general.send') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'RejectContractDialog',
  props: {
    value: Boolean,
  },
  data() {
    return {
      selectedReason: null,
      message: '',
      sending: false,
    };
  },
  computed: {
    ...mapGetters({
      primaryColor: 'publicContract/primaryColor',
    }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    reasons() {
      return [
        {
          title: this.$t('contract.reject.reason.changeMind'),
          value: 'changeMind',
        },
        {
          title: this.$t('contract.reject.reason.notForMe'),
          value: 'notForMe',
        },
        {
          title: this.$t('contract.reject.reason.dontUnderstand'),
          value: 'dontUnderstand',
        },
        {
          title: this.$t('contract.reject.reason.otherReason'),
          value: 'otherReason',
        },
      ];
    },
    reasonLabel() {
      return this.selectedReason !== null && 'otherReason' === this.selectedReason.value
        ? this.$t('contract.reject.typeOther')
        : this.$t('contract.reject.detail');
    },
    isSelectedReason() {
      return this.selectedReason !== null;
    },
    isSending() {
      return this.sending;
    },
  },
  methods: {
    ...mapActions({
      rejectContract: 'publicContract/reject',
    }),
    selectReason(reason) {
      this.selectedReason =
        this.selectedReason !== null
          ? this.selectedReason.value !== reason.value
            ? reason
            : null
          : reason;
    },
    onReject() {
      this.sending = true;
      this.rejectContract({
        authToken: this.$route.params.hash,
        data: {
          reason: `${this.selectedReason.title}. ${this.$t('contract.reject.note')}: ${
            this.message
          }`,
        },
      })
        .then(() => {
          this.$notification.success(this.$t('contract.was_rejected'));
          this.$router.push({
            name: 'public-sign-rejected',
          });
        })
        .catch(() => {
          this.$notification.error(this.$t('contract.reject.failed'));
        })
        .finally(() => {
          this.sending = false;
          this.show = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.reason-choice__button {
  overflow: hidden;
  cursor: pointer;

  &--on-hover,
  &--active {
  }
}
</style>
