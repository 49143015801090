<template>
  <v-container class="create-parties ma-0 pa-0" fluid>
    <div class="create-counterparty contract-create-parties__signatory-cards">
      <div class="create-counterparty__box create-counterparty__box--owner">
        <drop-list
          :items="parties"
          :column="false"
          :row="true"
          @reorder="moveCounterpartiesDnd($event)"
        >
          <template v-slot:item="{ item: party, reorder }">
            <drag
              :data="party"
              :key="party.internalKey"
              :disabled="isDragndropDisabled"
              style="border-bottom: 1px solid #d4dff0"
            >
              <div v-if="!isDragndropDisabled" class="create-owner-wrap-handle">
                <IcoBars />
              </div>

              <div class="create-owner-wrap">
                <div class="proposals proposals-multilateral">
                  <h3
                    class="mb-3 mt-2 mt-md-0"
                    :class="{
                      'ml-md-0': isDragndropDisabled,
                      'ml-md-8': !isDragndropDisabled,
                    }"
                  >
                    {{ getPartyTitle(party) }}
                  </h3>

                  <drop-list
                    mode="cut"
                    :items="party.signers"
                    :accepts-data="
                      (signer) => isDroppableAsSigner(party, signer)
                    "
                    @insert="insertSignerToParty(party, $event)"
                    @reorder="reorderSigners(party, $event)"
                  >
                    <template v-slot:item="{ item: signer, reorder }">
                      <drag
                        :key="signer.internalKey"
                        :data="signer"
                        @cut="removeSigner(signer, true)"
                        :disabled="isDragndropDisabled"
                      >
                        <SignIdentityCard
                          v-if="signer.email"
                          v-model="signer"
                          :deselectable="isSignerDeselectable(party)"
                          :editable="isSignerEditable(party)"
                          :has-advanced-workflow-selects="hasAdvancedWorkflowSelects"
                          :is-dragndrop-disabled="isDragndropDisabled"
                          @onDeselect="removeSigner($event)"
                          @onShowModal="openIdentityModal(party, $event)"
                        />

                        <SignIdentityRoleCard
                          v-else
                          v-model="signer"
                          :deselectable="isSignerDeselectable(party)"
                          :editable="isSignerEditable(party)"
                          :is-draggable="!isDragndropDisabled"
                          @on-remove="removeSigner($event)"
                        />
                      </drag>
                    </template>

                    <template v-slot:feedback="{ data }">
                      <SignIdentityCard
                        v-if="data.email"
                        v-model="data"
                        class="feedback"
                        :key="`feedback-signer-${data.internalKey}`"
                        :deselectable="false"
                        :editable="false"
                        :hasAdvancedWorkflowSelects="hasAdvancedWorkflowSelects"
                        :isDragndropDisabled="true"
                      />

                      <SignIdentityRoleCard
                        v-else
                        v-model="data"
                        :deselectable="false"
                        :editable="false"
                        :is-draggable="!isDragndropDisabled"
                        @on-remove="removeSigner($event)"
                      />
                    </template>

                    <template v-slot:reordering-feedback="{ item }">
                      <SignIdentityCard
                        v-if="item.email"
                        v-model="item"
                        class="feedback"
                        :key="`reordering-signer-${item.internalKey}`"
                        :deselectable="false"
                        :editable="false"
                        :hasAdvancedWorkflowSelects="hasAdvancedWorkflowSelects"
                        :isDragndropDisabled="true"
                      />

                      <SignIdentityRoleCard
                        v-else
                        v-model="item"
                        :deselectable="false"
                        :editable="false"
                        :is-draggable="!isDragndropDisabled"
                        @on-remove="removeSigner($event)"
                      />
                    </template>
                  </drop-list>
                </div>

                <v-row align="center" justify="space-between">
                  <v-col class="col-12 col-md">
                    <SignatorySearch
                      v-if="isSearchVisible(party)"
                      :label="getSearchPlaceholder(party)"
                      :contacts="getSearchOptions(party)"
                      :openSearchId="openSearchId"
                      :currentId="party.internalKey"
                      :isDynamicList="party.isCounterparty"
                      :isLoadingDynamicList="isLoadingSearchOptions(party)"
                      @toggleSearch="toggleOpenSearchId($event)"
                      @onSignerSelect="selectSignerFromSearch(party, $event)"
                      @onSearchTermChange="filterSearchOptions(party, $event)"
                      @onCreateNewUserClick="createNewSigner(party)"
                      class="create-counterparty--signatory-search"
                    ></SignatorySearch>
                  </v-col>

                  <v-col
                    v-if="isCreateButtonVisible(party)"
                    class="col-12 col-md-auto"
                  >
                    <v-btn
                      color="primary"
                      :block="$vuetify.breakpoint.mobile"
                      outlined
                      x-large
                      @click="createNewSigner(party)"
                    >
                      {{ $t("contract.create.new_signer") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <AddProposerParty
                  v-if="canAddAnotherSigner(party) && isProposer(party)"
                  ref="addProposerPartyRef"
                  :computed-available-roles="computedAvailableRoles"
                  :enable-variable-users="enableVariableUsers"
                  @on-signer-select="selectSignerFromSearch(party, $event)"
                >
                  <template v-slot:signatorySearch>
                    <SignatorySearch
                      class="create-counterparty--signatory-search"
                      :label="getSearchPlaceholder(party)"
                      :contacts="getSearchOptions(party)"
                      :openSearchId="openSearchId"
                      :currentId="party.internalKey"
                      :isDynamicList="party.isCounterparty"
                      :isLoadingDynamicList="isLoadingSearchOptions(party)"
                      @toggleSearch="toggleOpenSearchId($event)"
                      @onSignerSelect="selectSignerFromSearch(party, $event)"
                      @onSearchTermChange="filterSearchOptions(party, $event)"
                      @onCreateNewUserClick="createNewSigner(party)"
                    ></SignatorySearch>
                  </template>
                </AddProposerParty>

                <v-row
                  v-else-if="canAddAnotherSigner(party)"
                  class="mt-0 pt-0"
                  :justify="$vuetify.breakpoint.mobile ? 'center' : 'start'"
                  no-gutters
                >
                  <v-col cols="auto">
                    <v-btn
                      color="primary"
                      class="pl-1"
                      text
                      x-large
                      @click="showAddSigner(party)"
                    >
                      <v-icon left>mdi-plus</v-icon>
                      <span class="text-decoration-underline">
                        {{ $t("contract.create.add_signer") }}
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>

                <div class="create-counterparty__proposal"></div>

                <div class="counterparty-actions-wrapper">
                  <button
                    v-if="party.isMovableDown && !isDragndropDisabled"
                    class="button--move-counterparty-down"
                    @click="move(party, 'down')"
                  >
                    <IcoArrowBack />
                  </button>

                  <button
                    v-if="party.isMovableUp && !isDragndropDisabled"
                    class="button--move-counterparty-up"
                    @click="move(party, 'up')"
                  >
                    <IcoArrowBack />
                  </button>

                  <v-btn
                    v-if="
                      !isAttachmentContract &&
                      party.isCounterparty &&
                      isPartyEditable(party)
                    "
                    color="#8E97A4"
                    class="pr-3"
                    text
                    @click="removeCounterparty(party)"
                  >
                    {{ $t("general.remove") }}
                    <v-icon class="pl-1" color="#8E97A4" small>
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </drag>
          </template>

          <template v-slot:reordering-feedback="{ item }">
            <div :key="`reordering-party-${item.internalKey}`">
              <div v-if="!isDragndropDisabled" class="create-owner-wrap-handle">
                <IcoBars />
              </div>
              <div class="create-owner-wrap">
                <span class="title">{{ getPartyTitle(item) }}</span>
              </div>
            </div>
          </template>
        </drop-list>

        <div
          v-if="minCounterpartiesCount === 0 && maxCounterpartiesCount === 0"
          class="create-owner-wrap"
        >
          <h2>
            {{ $t("contract.parties.counterparties") }}
          </h2>

          <div class="create-counterparty__proposal">
            <p class="create-counterparty--info">
              {{ $t("contract.create.unilateral_counterparty") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="canAddAnotherCounterparty"
      class="pa-10 contract-create-parties__add-party text-center text-md-left"
    >
      <v-btn color="primary" outlined x-large @click="addCounterparty">
        {{ $t("contract.create.add_counterparty") }}
        <v-icon class="pl-2" color="primary"> mdi-plus </v-icon>
      </v-btn>
    </div>

    <div class="px-5 px-md-0 pt-8 pb-5 text-center">
      <PredefinedWorkflowTitleInput v-model="settingsTemplateTitle" />

      <v-btn
        color="primary"
        :loading="isUpdatingContract"
        :disabled="isIncomplete || someDocumentFailed"
        x-large
        @click="goToNextStep"
      >
        {{ $t("contract.create.go_to_next") }}
      </v-btn>
    </div>

    <Identity
      v-if="identityModalConfig"
      class="modal-phone-off"
      :modalConfig="identityModalConfig"
      @close="hideSearchAndModalIfOpened()"
      @upsertIdentity="upsertIdentity"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Parties } from "@/helpers/Parties.js";
import { updateContract } from "@contract/services/contractService";
import { getErrorMessage } from "@/common/variables/errors";
import { ContractService } from "@/services/ContractService.js";
import { DropList, Drag } from "vue-easy-dnd";
import featureFlagsChecker from "@/common/reusable/featureFlagsChecker";
import IcoBars from "@/components/svg/IcoBars.vue";
import IcoArrowBack from "@/components/svg/IcoArrowBack.vue";
import IcoInfo from "@/components/svg/IcoInfo.vue";
import PredefinedWorkflowTitleInput from "@contract/components/create/PredefinedWorkflowTitleInput";
import AddProposerParty from "@contract/components/create/AddProposerParty";
import Identity from "./components/Identity.vue";
import SignIdentityCard from "@contract/components/create/SignIdentityCard";
import SignatorySearch from "./components/SignatorySearch.vue";
import SignIdentityRoleCard from "@/features/contract/components/create/SignIdentityRoleCard";

let partiesHelper;

export default {
  name: "CreateParties",
  components: {
    SignIdentityRoleCard,
    AddProposerParty,
    IcoBars,
    IcoArrowBack,
    IcoInfo,
    PredefinedWorkflowTitleInput,
    Identity,
    SignIdentityCard,
    SignatorySearch,
    DropList,
    Drag,
  },
  data() {
    partiesHelper = Parties.build(
      this.isSmsPinRequiredForNewUsers,
      ({ parties, proposerIds, canAddAnotherCounterparty, isIncomplete }) => {
        // As long as new object is created only for new identities, there will be no state issues
        // - previous changed detection issues were caused by messy handling in upsertIdentity (522f65f -> 42ede11a -> 8cce21d)
        this.parties = parties;
        this.partiesAnalysis.proposerIds = proposerIds;
        this.partiesAnalysis.canAddAnotherCounterparty =
          canAddAnotherCounterparty;
        this.partiesAnalysis.isIncomplete = isIncomplete;
        this.filterProposers(); // reloadSearchOptionsForProposers (static list is changed after add/remove signer)
      }
    );
    const transformReviewers = (type, transformReviewer) => {
      return this.contract.contract_flow &&
        this.contract.contract_flow.reviewers[type].length
        ? this.contract.contract_flow.reviewers[type].map(transformReviewer)
        : [];
    };
    return {
      templateTitle: false,
      contractSignIdentities: [],
      isUpdatingContract: false,
      commentsUsage: this.contract.comments_usage,
      reviewersActive: transformReviewers("active", (r) => ({
        isVisible: true,
        ...r,
      })),
      reviewersCompleted: transformReviewers("completed", (r) => r.email),
      // structure is built in Counterparties.buildParties
      parties: [],
      partiesAnalysis: {
        proposerIds: [],
        canAddAnotherCounterparty: false,
        isIncomplete: true,
      },
      minCounterpartiesCount: 1,
      maxCounterpartiesCount: 1,

      identityModalConfig: null,
      identityModalTopPosition: 0,

      openSearchId: null,
      visibleSearches: [],
      allAvailableProposers: [],
      defaultCounterparties: [],
      searchOptionsForProposers: [],
      searchOptionsForCounterparties: [],
      isLoadingSearchOptionsFromApi: false,
      lastSavedPartiesState: [],
      settingsTemplateTitle: "",

      availableProposers: [],
    };
  },
  props: {
    contract: {
      required: true,
    },
    getEditRequest: {
      required: false,
    },
    hasAdvancedWorkflowSelects: {
      required: true,
    },
    hasReviewComments: {
      type: Boolean,
    },
    hasSequentialSigningOrder: {
      type: Boolean,
      required: true,
    },
    isEditingContactsTemplate: {
      type: Boolean,
    },
    isSmsPinRequiredForNewUsers: {
      required: false,
    },
    someDocumentFailed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      profile: "profile",
      bankAccount: "workspace/activeWorkspaceBankAccount",
      workspaceMembersJobPositions: 'workspace/membersJobPositions',
      workspaceSignatureFooterSettings: 'workspace/signatureFooterSettings',
    }),
    computedAvailableProposers() {
      return this.availableProposers
    },
    computedAvailableRoles() {
      const jobPositions = this.workspaceMembersJobPositions.map(a => ({ ...a }));
      const proposersParty = this.parties?.find((party) => party.isCounterparty === false)?.signers || [];

      for (let signer of proposersParty) {
        const position = jobPositions.find((position) => position.name === signer?.workspace_position || position.name === signer?.variable_position);

        if (position) {
          position.numberOfPeopleWithThatPosition -= 1;
        }
      }

      return jobPositions;
    },
    hasEnabledAmlVerification() {
      return featureFlagsChecker.hasEnabledAmlVerification(this.contract);
    },
    canAddAnotherCounterparty() {
      return (
        !this.isAttachmentContract &&
        !this.isEditingContactsTemplate &&
        this.partiesAnalysis.canAddAnotherCounterparty
      );
    },
    isIncomplete() {
      return this.partiesAnalysis.isIncomplete;
    },
    contractId() {
      return this.contract.id;
    },
    isDragndropDisabled() {
      return this.isAttachmentContract || !this.hasSequentialSigningOrder;
    },
    isAttachmentContract() {
      return this.contract.is_attachment;
    },
    enableVariableUsers() {
      return featureFlagsChecker.enableVariableUsers(this.contract);
    },
    isFooterDisplayedByDefault() {
      return Object.keys(this.workspaceSignatureFooterSettings)?.some((e) => this.workspaceSignatureFooterSettings[e] === true);
    },
  },
  watch: {
    contractSignIdentities: {
      handler: function () {
        partiesHelper.loadFromContract(
          this.contractSignIdentities,
          this.allAvailableProposers,
          this.minCounterpartiesCount,
          this.maxCounterpartiesCount
        );
      },
    },
    "contract.sign_identities": {
      deep: true,
      handler: function (value) {
        this.contractSignIdentities = value;
      },
    },
    openSearchId(newValue) {
      if (newValue === null) {
        this.searchOptionsForCounterparties = this.defaultCounterparties;
      }
    },
    parties: {
      deep: true,
      handler: function (value) {
        let newContractRole;
        let somePartySigningWithCertificate = false;
        let somePartySigningWithBankIdSign = false;

        value?.forEach((party, indexParty) => {
          party?.signers?.forEach((signer, indexSigner) => {
            if (signer?.contract_role === "sign_certificate") {
              somePartySigningWithCertificate = true;
            }

            if (signer?.contract_role === "sign_bank_id_sign") {
              somePartySigningWithBankIdSign = true;
            }

            if (
              signer?.contract_role !==
              this.lastSavedPartiesState[indexParty]?.signers[indexSigner]
                ?.contract_role
            ) {
              if (!signer?.contract_role) {
                signer.contract_role = "sign";
              }

              newContractRole = signer.contract_role;
            }
          });
        });

        if (newContractRole) {
          if (newContractRole === "sign_certificate") {
            for (let party of value) {
              for (let signer of party?.signers) {
                if (signer.contract_role !== "approve") {
                  signer.contract_role = "sign_certificate";
                }
              }
            }
          }

          if (newContractRole === "sign_bank_id_sign") {
            for (let party of value) {
              for (let signer of party?.signers) {
                if (signer.contract_role !== "approve") {
                  signer.contract_role = "sign_bank_id_sign";
                }
              }
            }
          }

          if (newContractRole === "sign" && somePartySigningWithCertificate) {
            for (let party of value) {
              for (let signer of party?.signers) {
                if (signer.contract_role !== "approve") {
                  signer.contract_role = "sign";
                }
              }
            }
          }

          if (newContractRole === "sign" && somePartySigningWithBankIdSign) {
            for (let party of value) {
              for (let signer of party?.signers) {
                if (signer.contract_role !== "approve") {
                  signer.contract_role = "sign";
                }
              }
            }
          }
        }

        this.lastSavedPartiesState = JSON.parse(JSON.stringify(value));
      },
    },
  },
  async created() {
    await this.fetchData();
    await this.fetchWorkspaceMembersPositions(this.$route.params.workspace_id);

    if (this.hasEnabledAmlVerification) {
      this.fetchBankAccount(this.$route.params.workspace_id).then((resp) => {
        if (resp === null) {
          this.createBankAccount({
            workspaceId: localStorage.getItem("workspace_id"),
            account: {
              is_primary: true,
              name: "Signi default bank account",
              prefix: "",
              account_number: "000000000",
              bank_code: "0000",
            },
          });
        }
      });
    }
  },
  methods: {
    ...mapActions({
      createBankAccount: "workspace/createBankAccount",
      createNewSettingsTemplate: "predefinedWorkflow/createNewSettingsTemplate",
      fetchBankAccount: "workspace/fetchBankAccount",
      fetchSignIdentities: "contractSignIdentities/fetchSignIdentities",
      fetchWorkspaceMembersPositions:
        "workspace/fetchWorkspaceMembersPositions",
    }),
    // EDIT PARTIES
    getPartyTitle(party) {
      return party.isCounterparty
        ? this.$t("contract.parties.counterparty", {
            partyOrder: party.partyOrder,
          })
        : this.$t("contract.parties.proposer");
    },
    isProposer(party) {
      return !party.isCounterparty;
    },
    isSignerDeselectable(party) {
      return party.isCounterparty
        ? !this.isAttachmentContract && this.isPartyEditable(party)
        : this.allAvailableProposers.length > 1 && !this.isAttachmentContract;
    },
    isSignerEditable(party) {
      return !this.isAttachmentContract && this.isPartyEditable(party);
    },
    removeSigner(removedSigner, isCutBeforeInsert) {
      if (isCutBeforeInsert) {
        partiesHelper.removeSignerBeforeInsert(removedSigner); // reload will be immediately called in insertSigner
      } else {
        partiesHelper.removeSigner(removedSigner);
      }
    },
    insertSignerToParty(party, dndEvent) {
      partiesHelper.insertSigner(party, dndEvent.data, dndEvent.index);
    },
    isDroppableAsSigner(party, signer) {
      return (
        party.isCounterparty ||
        signer.workspace_id == this.contract.workspace_id
      );
    },
    addCounterparty() {
      partiesHelper.addCounterparty();
    },
    removeCounterparty(party) {
      partiesHelper.removeParty(party);
    },
    move(party, direction) {
      partiesHelper.moveParty(party, direction === "up");
    },
    moveCounterpartiesDnd(dndEvent) {
      partiesHelper.reorderParties(dndEvent);
    },
    reorderSigners(party, dndEvent) {
      partiesHelper.reorderSigners(party, dndEvent);
    },
    getSearchPlaceholder(party) {
      return party.isCounterparty
        ? this.$t("contract.typing.signer")
        : this.$t("contract.typing.proposer");
    },
    getSearchOptions(party) {
      if (party.isCounterparty) {
        return this.searchOptionsForCounterparties;
      }

      return this.searchOptionsForProposers?.filter((user) => {
        return !user.workspace_position || this.computedAvailableRoles?.find(
          (role) => role.name === user.workspace_position || role.name === user.variable_position
        )?.numberOfPeopleWithThatPosition > 0;
      });
    },
    isLoadingSearchOptions(party) {
      return party.isCounterparty ? this.isLoadingSearchOptionsFromApi : false;
    },
    filterSearchOptions(party, query) {
      return party.isCounterparty
        ? this.filterCounterparties(query)
        : this.filterProposers(query);
    },
    isSearchVisible(party) {
      if (!party.isCounterparty) {
        return false;
      }

      return (
        !party.signers.length ||
        this.visibleSearches.includes(party.internalKey)
      );
    },
    isCreateButtonVisible(party) {
      return party.isCounterparty ? this.isSearchVisible(party) : false;
    },
    createNewSigner(party) {
      if (party.isCounterparty) {
        this.openSearchId = null;
        this.openIdentityModal(party);
      }
    },
    canAddAnotherSigner(party) {
      if (
        this.isAttachmentContract ||
        this.isSearchVisible(party) ||
        !this.isPartyEditable(party)
      ) {
        return false;
      }

      return party.isCounterparty
        ? party.signers.length > 0
        : this.partiesAnalysis.proposerIds.length <
            this.allAvailableProposers.length;
    },
    isPartyEditable(party) {
      return !(party.isCounterparty && this.isEditingContactsTemplate);
    },
    showAddSigner(party) {
      this.visibleSearches = [...this.visibleSearches, party.internalKey];
    },
    selectSignerFromSearch(party, person) {
      if (partiesHelper.isAlreadySelected(person)) {
        return (() => {
          this.hideSearchAndModalIfOpened();
          this.$notification.error(
            this.$t("contract.create.error.parties_duplication")
          );
        })();
      }

      partiesHelper.addSigner(party, person);

      if (!party.isCounterparty) {
        this.$refs.addProposerPartyRef.onDeactivate();
      }

      this.hideSearchAndModalIfOpened();
      this.visibleSearches = this.visibleSearches.filter((id) => {
        return id !== party.internalKey;
      });
    },
    toggleOpenSearchId(currentId) {
      this.openSearchId = currentId;
    },
    async fetchData() {
      this.contractSignIdentities = await this.fetchSignIdentities(
        this.contract.id
      );

      [this.minCounterpartiesCount, this.maxCounterpartiesCount] =
        guessCounterpartyLimits(this.contract);

      try {
        this.allAvailableProposers =
          this.contract?.contract_flow?.availableProposers || [];
        partiesHelper.loadFromContract(
          this.contractSignIdentities,
          this.allAvailableProposers,
          this.minCounterpartiesCount,
          this.maxCounterpartiesCount
        );
      } catch (e) {
        this.$notification.error(this.$t("contract.create.error.parties_load"));
        return;
      }

      await this.filterCounterparties("");
      this.defaultCounterparties = this.searchOptionsForCounterparties;

      function guessCounterpartyLimits(contract) {
        if (contract.type === "form") {
          return [
            contract.template.analysis.minCounterpartiesCount,
            contract.template.analysis.minCounterpartiesCount,
          ];
        } else {
          return [0, 10];
        }
      }
    },
    async filterCounterparties(query) {
      this.isLoadingSearchOptionsFromApi = true;
      try {
        this.searchOptionsForCounterparties =
          await ContractService.getCounterparty(this.contractId, query);
        this.isLoadingSearchOptionsFromApi = false;
      } catch (e) {
        this.$notification.error(this.$t("contract.create.error.parties_load"));
        this.isLoadingSearchOptionsFromApi = false;
      }
    },
    filterProposers(query) {
      this.searchOptionsForProposers = this.allAvailableProposers
        ?.filter((user) => {
          return (
            this.partiesAnalysis.proposerIds.indexOf(user.user_id) === -1 &&
            (!query || `${user.firstname} ${user.lastname} (${user.email})`.indexOf(query) !== -1)
          );
        });
    },
    openIdentityModal(party, editedSignIdentity) {
      this.identityModalConfig = {
        contractId: this.contractId,
        editedSignIdentity: editedSignIdentity,
        editedParty: party,
      };
    },
    hideSearchAndModalIfOpened() {
      this.openSearchId = null;

      if (!this.identityModalConfig) {
        return;
      }

      this.identityModalConfig = null;
    },
    upsertIdentity({ updatedIdentity, modalConfig }) {
      if (partiesHelper.isAlreadySelected(updatedIdentity)) {
        return this.$notification.error(
          this.$t("contract.create.error.parties_duplication")
        );
      }

      let editedSignIdentity = modalConfig.editedSignIdentity || {};

      editedSignIdentity.firstname = updatedIdentity.firstname;
      editedSignIdentity.lastname = updatedIdentity.lastname;
      editedSignIdentity.initials = `${updatedIdentity.firstname[0]}${updatedIdentity.lastname[0]}`;
      editedSignIdentity.number = updatedIdentity.number;
      editedSignIdentity.organization_name = updatedIdentity.organization_name;
      editedSignIdentity.organization_street = updatedIdentity.organization_street;
      editedSignIdentity.organization_city = updatedIdentity.organization_city;
      editedSignIdentity.organization_zip = updatedIdentity.organization_zip;
      editedSignIdentity.organization_ico = updatedIdentity.organization_ico;
      editedSignIdentity.organization_dic = updatedIdentity.organization_dic;
      editedSignIdentity.organization_position = updatedIdentity.organization_position;
      editedSignIdentity.signature_footer = updatedIdentity.signature_footer;

      if (!editedSignIdentity.workspace_id) {
        editedSignIdentity.type = updatedIdentity.type;
        editedSignIdentity.email = updatedIdentity.email;
        editedSignIdentity.mobile = updatedIdentity.mobile;
        editedSignIdentity.custom_inputs = updatedIdentity.custom_inputs;
      }

      partiesHelper.upsertSigner(modalConfig.editedParty, editedSignIdentity);

      this.hideSearchAndModalIfOpened();
      this.updateContract();
    },
    hasAmlAuthenticationActive() {
      const amlAuthenticationMembers = this.parties.filter((party) => {
        return (
          party.signers?.filter((signer) => {
            return signer.required_authentications.includes("aml");
          }).length !== 0
        );
      });

      return amlAuthenticationMembers.length !== 0;
    },
    goToNextStep() {
      if (this.isIncomplete) {
        return this.$notification.error(
          this.$t("contract.create.error.parties_empty")
        );
      }

      if (this.hasAmlAuthenticationActive() && this.bankAccount === null) {
        return this.$notification.error(
          this.$t("contract.create.missing_bank_account")
        );
      }

      this.isUpdatingContract = true;
      this.updateContract(true);
    },
    updateFooter(source) {
      if (source.signature_footer) {
        return;
      }

      const footerPreview = {
        firstname: this.workspaceSignatureFooterSettings?.enabledName
          ? source?.firstname || ''
          : null,
        lastname: this.workspaceSignatureFooterSettings?.enabledLastname
          ? source?.lastname || ''
          : null,
        organizationName: this.workspaceSignatureFooterSettings?.enabledOrganizationName
          ? source?.organization_name || ''
          : null,
        organizationPosition: this.workspaceSignatureFooterSettings?.enabledOrganizationPosition
          ? source?.organization_position || ''
          : null,
      };

      source.signature_footer = [
        [footerPreview?.firstname || '', footerPreview?.lastname || '']
          ?.filter((item) => item)
          ?.join(' '),
        [footerPreview?.organizationName || '', footerPreview?.organizationPosition || '']
          ?.filter((item) => item)
          ?.join(', ')
      ].join('\n');
    },
    generateGroupParties() {},
    updateContract(isNextStep = false) {
      const contractData = {
        ...this.getEditRequest(),
        parties: partiesHelper.getAllFlat(),
      };

      if (this.isFooterDisplayedByDefault) {
        for (let party of contractData.parties) {
          this.updateFooter(party);
        }
      }

      if (!this.isAttachmentContract && isNextStep) {
        Object.assign(contractData, {
          comments_usage: "comments",
          reviewers: this.reviewersActive
            .filter((r) => r.isVisible)
            .map((r) => r.email),
        });
      }

      updateContract({
        contractId: this.contractId,
        contractData,
      })
        .then((resp) => {
          this.$store.commit("setContract", resp);

          if (isNextStep) {
            if (this.settingsTemplateTitle) {
              this.createNewSettingsTemplate({
                workspaceId: `${this.$route.params.workspace_id}`,
                data: {
                  name: this.settingsTemplateTitle,
                  contractId: this.contractId,
                },
              });
            }

            const params = {
              workspace_id: `${this.$route.params.workspace_id}`,
              contract_id: `${this.contractId}`,
            };

            if (this.contract.type === "wysiwyg" && !this.contract.document) {
              this.$router.push({ name: "createOwn", params });
            } else if (
              this.contract.type === "doc" &&
              !this.contract.document
            ) {
              this.$router.push({
                name: "createUpload",
                params: { ...params, replace: "true" },
              });
            } else {
              this.$router.push({ name: "createContent", params });
            }
          }
        })
        .catch((err) => {
          if (
            "Signi.Exceptions.DocsPerson.DocsPersonCannotChangeMobileException" ===
            err.errorCode
          ) {
            this.$notification.error(
              getErrorMessage({
                errorCode: err.errorCode,
                errorValues: {
                  partyMail: err.message.match(
                    /([a-zA-Z0-9.!#$%&'*+]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
                  ),
                },
              })
            );
          } else {
            this.$notification.error(
              err.message === "User without workspace cannot sign contract"
                ? this.$t("contract.create.error.parties_save_registered")
                : this.$t("contract.create.error.parties_save")
            );
          }
        })
        .finally(() => {
          this.isUpdatingContract = false;
        });
    },
  },
};
</script>

<style lang="scss">
.create-parties {
  .contract-create-parties__add-party {
    border-bottom: 1px solid #d4dff0;
  }
}

.create-owner-wrap {
  position: relative;
  display: flow-root;
  padding: 20px 40px 0;
  &:last-child {
    border-bottom: none;
  }

  @include md {
    padding: 40px 70px;
  }

  @media screen and (max-width: 460px) {
    padding: 40px 20px 0;
  }
}
.create-counterparty__box--owner {
  padding: 0 !important;
}
.create-counterparty__proposal {
  margin-top: 20px;
  @include clearfix;
}
.create-counterparty__continue {
  text-align: center;
  margin-top: 30px;
  @include md {
    text-align: center;
  }
}
.create-counterparty--info {
  text-align: left;
}

.create-counterparty__last {
  padding-top: 35px !important;
  width: 100%;
  margin-top: 40px;
  float: left;
  border-top: solid 1px #b4c1d6;
  h3 {
    text-align: left !important;
  }
}

h2 {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: black;
  margin-bottom: 15px;
}

.icon--preview.icon-pen1.edit-counterparty {
  margin-right: 8px;
  float: left;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;

  position: relative;
  left: 0;
}

.icon--preview {
  font-size: 17px;
  padding: 8px 5px;
  max-width: 25px;
  cursor: pointer;
}

.icon--preview.icon-pen1:hover {
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  opacity: 0.7;
}

.icon--preview.icon-close {
  position: absolute;
  right: 0px;
  transition: opacity 0.2s;
  font-size: 17px;
  line-height: normal;
  padding-left: 5px;
  &:before {
    color: #5e239e;
  }
  &:hover {
    transition: opacity 0.2s;
    opacity: 0.7;
  }
}

// override styles from Search.vue
.input-search {
  margin-left: 0 !important;
}
.search-input-results {
  .search__item-preview {
    float: left;
    margin-right: 10px;
    margin-top: 0 !important;
  }
  p.search__item-text {
    line-height: 30px !important; // same height like in Avatar.vue
    text-align: left;
    margin-bottom: 0 !important;
  }
}

// styles from deprecated 2nd step
.proposals {
  width: 100%;
  outline: none;
  background: #ffffff;
  // border: 1px solid #B1BBCB;
  border: none;
  border-radius: 6px;
  height: 56px;
  // padding: 5px;
  padding: 0;
  font-size: 17px;
  font-weight: 300;
  color: black;
  letter-spacing: -0.03px;
  max-width: 100%;

  @include md {
    background-color: transparent;
  }
}
.proposals.proposals-multilateral {
  height: auto;
  text-align: left;
  .dnd-drop {
    min-height: 15px; // otherwise it's hard to drop signer to empty party
  }
}
.icon--preview.icon-pen1.edit-counterparty {
  margin-right: 8px;
  float: left;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;

  position: relative;
  left: 0;
}
.icon--preview {
  font-size: 17px;
  padding: 8px 5px;
  max-width: 25px;
  cursor: pointer;
}
.icon--preview.icon-pen1:hover {
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  opacity: 0.7;
}
.icon--preview.icon-close {
  position: absolute;
  right: 0px;
  transition: opacity 0.2s;
  font-size: 17px;
  line-height: normal;
  padding-left: 5px;
  &:before {
    color: #5e239e;
  }
  &:hover {
    transition: opacity 0.2s;
    opacity: 0.7;
  }
}

.signatory-search--content {
  overflow: hidden;
}
.add-counterparty-wrap {
  float: left;
  padding: 20px 50px;
  @include md {
    padding: 40px 15px;
    width: 100%;
  }
}
.create-counterparty--signatory-search {
  width: 100%;
}
.create-owner-wrap .button.button--add-person {
  transform: none;
  position: relative;
  padding: 0;
  float: left;
}
.create-owner-wrap .button__ico--left:nth-child(1) {
  display: none;
}
.create-counterparty__continue {
  margin: 40px auto;
}
.create-counterparty__continue button {
  width: auto;
  padding-left: 25px;
  padding-right: 25px;
  clip-path: polygon(0% 0%, 95% 0, 100% 50%, 95% 100%, 0 100%);
}
.create-counterparty__continue button .button__ico {
  display: none;
}
.create-counterparty--signatory-search-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  @include md {
    flex-direction: column;
  }
}
.create-counterparty--signatory-search-wrapper button {
  width: 32%;
  height: 52px;
  margin: 0;
  @include md {
    width: 100%;
  }
}
.create-counterparty {
  .data__title {
    display: none;
  }
  h1 {
    text-align: left;
    padding: 0;
    font-weight: bold;
    margin-bottom: 28px;
  }
}
.create-parties .create-counterparty__box .create-owner-wrap {
  background: #fff;
}
.create-counterparty__box {
  .drop-list {
    .create-owner-wrap-handle {
      position: absolute;
      z-index: 2;
      left: 40px;
      top: 22px;
      width: 20px;
      height: 20px;
      color: #b3c2d6;

      @include md {
        top: 15px;
        left: 22px;
      }
    }
    & > div {
      position: relative;
    }
  }
}

.counterparty-actions-wrapper {
  position: absolute;
  top: 15px;
  right: 25px;
  display: flex;

  @include display(960px) {
    top: 8px;
    right: 10px;
  }

  // override styles from Button.vue
  .button--remove-counterparty {
    border: 0;
    background: none;
    color: #9fa6b1;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon-bin {
      margin-left: 7px;
      &:before {
        color: #8e97a4;
      }
    }
  }

  .button--move-counterparty-down,
  .button--move-counterparty-up {
    padding: 0;
    border: 0;
    background: none;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    color: #9fa6b1;
    width: 15px;
    margin-right: 10px;
    display: flex;
    svg {
      width: 15px;
    }
  }

  .button--move-counterparty-up {
    transform: rotate(90deg);
  }

  .button--move-counterparty-down {
    transform: rotate(-90deg);
  }
}
.checkbox-template__line-order {
  margin: 0 !important;
  padding: 20px 70px !important;
  width: fit-content !important;
  align-items: center;
  display: flex;
  .checkbox-wrap {
    margin: 0;
  }
  svg {
    padding-left: 2px;
  }
}

.create-parties,
.create-counterparty__box,
.create-owner-wrap.create-owner-wrap--review {
  padding: 4em 70px;
  background: transparent; // hotfix for showing shadow
  .input {
    margin: 0;
    margin-top: 10px;
  }
  .radio-wrap {
    display: block;
    padding: 5px;
    padding-left: 0;
    margin: 0;
    max-width: unset;
  }
  .create-owner-wrap--reviewer {
    display: flex;
    .button--remove-reviewer {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      background: none;
      color: #9fa6b1;
      cursor: pointer;
      .icon-bin {
        margin: 7px 0 0 3px;
        &:before {
          color: #8e97a4;
        }
      }
    }
  }
  .create-owner-wrap--reviewers-completed {
    clear: both;
    padding-top: 10px;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.47;
    letter-spacing: -0.03px;
    color: #616161;
    text-align: left;
  }
}
</style>
