const routes = [
  {
    path: '/dashboard/workspace/:workspace_id/documents/:contract_id',
    alias: '/:workspace_id/data/contract/:contract_id/detail',
    name: 'documentsDetail',
    component: () =>
      import(
        /* webpackChunkName: "contract-document-detail" */ '@contract/views/ContractDocumentDetail'
      ),
  },
  {
    path: '/dashboard/workspace/:workspace_id/documents/:contract_id/counterparty',
    alias: '/:workspace_id/data/contract/:contract_id/counterparty',
    name: 'documentsDetailCounterparty',
    component: () =>
      import(
        /* webpackChunkName: "contract-document-detail" */ '@contract/views/ContractDocumentDetail'
      ),
    redirect: (to) => {
      return {
        name: 'documentsDetail',
        params: {
          ...to.params,
        },
        query: {
          ...to.query,
          counterparty: true,
        },
      };
    },
  },
  {
    path: '/dashboard/workspace/:workspace_id/documents/:contract_id/2fa/start',
    alias: '/:workspace_id/create/:contract_id/2fa/start',
    name: 'contractDocumentSign2FAStart',
    component: () =>
      import(
        /* webpackChunkName: "contract-document-sign-2fa-start" */ '@contract/views/ContractDocumentSign2FAStart'
      ),
  },
  {
    path: '/dashboard/workspace/:workspace_id/documents/:contract_id/2fa/submit',
    alias: '/:workspace_id/create/:contract_id/2fa/submit',
    name: 'contractDocumentSign2FASubmit',
    component: () =>
      import(
        /* webpackChunkName: "contract-document-sign-2fa-submit" */ '@contract/views/ContractDocumentSign2FASubmit'
      ),
  },
  {
    path: '/dashboard/workspace/:workspace_id/documents/:contractId/revision-list',
    name: 'contractDocumentRevisionList',
    component: () =>
      import(
        /* webpackChunkName: "contract-document-revision-list" */ '@contract/views/ContractDocumentRevisionList'
      ),
  },
];

routes.forEach((route) => {
  route.meta = {
    authRequired: true,
    layout: () => import(/* webpackChunkName: "empty-layout" */ '@/layouts/EmptyLayout'),
  };
});

export default routes;
