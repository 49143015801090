<template>
  <SigniModal v-model="show" max-width="400px">
    <template v-slot:title>
      {{ $t('docs.pending_expiration.extend_proposal') }}
    </template>

    <v-form>
      <v-text-field
        outlined
        :value="minExpirationDate"
        :label="$t('contract.validity_of_proposal')"
        disabled
      ></v-text-field>

      <v-text-field
        type="number"
        v-model="extendDaysCount"
        :label="$t('docs.pending_expiration.extend')"
        :min="1"
        outlined
      ></v-text-field>

      <v-btn
        color="primary"
        :loading="isExtendingExpiration"
        x-large
        block
        @click="extendExpiration()"
      >
        {{ $t('docs.pending_expiration.extend') }}
      </v-btn>
    </v-form>
  </SigniModal>
</template>

<script>
import { ContractService } from '@/services/ContractService.js'
import { parseDate } from '@/services/helpers/parseDate.js'
import moment from 'moment'
import SigniModal from '@/components/SigniModal'

export default {
  name: 'ExtendExpiration',
  components: {
    SigniModal
  },
  data () {
    return {
      isExtendingExpiration: false,
      extendDaysCount: 7,
      minExpirationDate: null
    }
  },
  props: {
    value: [Boolean, String],
    contracts: {
      type: Array,
      required: true
    }
  },
  computed: {
    show: {
      get () {
        if (typeof this.value === 'string') {
          return this.value !== ''
        }

        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  created () {
    const dates = this.contracts.filter(c => c.pending_expiration_date).map(c => moment(c.pending_expiration_date, 'YYYY-MM-DD'))
    const minDate = moment.min(dates).format('YYYY-MM-DD')
    this.minExpirationDate = parseDate(minDate)
  },
  methods: {
    extendExpiration () {
      this.isExtendingExpiration = true

      ContractService
        .extendExpiration(this.$route.params.workspace_id, this.contracts, this.extendDaysCount)
        .then(
          () => {
            this.$emit('reloadAfterExtend')
            this.show = false
          },
          () => {
            this.isExtendingExpiration = false
            this.$notification.error(this.$t('general.save_error'))
          }
        )
    }
  }
}
</script>
