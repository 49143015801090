<template>
  <div class="tutorial-header-stripe__wrapper">
    <div class="tutorial-header-stripe__inner">
      <div class="tutorial-header-stripe__icon">
        <img :src="signiAvatar" alt="Signi avatar">
      </div>
      <div class="tutorial-header-stripe__content">
        <a href="https://calendly.com/signisales" target="_blank" >
          <h2>{{ $t('help.title') }}</h2>
          <p v-html="$t('tutorial.help.descriptionHtml')"></p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TutorialHeaderStripe',
    computed: {
      signiAvatar() {
        return require('@dashboard/assets/avatar.png');
      }
    }
  }
</script>

<style lang="scss">
  .tutorial-header-stripe__wrapper {
    margin: 0 auto;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 59px;
    height: 70px;
    background: #F4EAFE;
    @include md {
      height: auto;
    }
    .tutorial-header-stripe__inner {
      padding: 13px 0 0 0;
      max-width: 1000px;
      width: fit-content;
      margin: 0 auto;
      display: flex;
      .tutorial-header-stripe__icon {
        height: 40px;
        width: 40px;
        @include md {
          display: none;
        }
        margin-right: 10px;
        img {
          width: 100%;
          border-radius: 50%;
          height: 100%;
        }
      }
      .tutorial-header-stripe__content {
        height: 50px;
        @include md {
          height: auto;
          padding-bottom: 10px;
          padding-left: 5px;
        }
        a {
          text-decoration: none;
          h2 {
            color: #472769;
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 4px;
          }
          p {
            color: #674985;
            margin: 0;
            line-height: 15px;
            font-size: 15px;
            font-weight: 400;
            @include md {
              text-align: left;
            }
            .social-icons-wrapper {
              height: 15px;
              width: auto;
              display: inline-block;
              top: 4px;
              position: relative;
              padding: 0 2px;
              img {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
</style>
