<template lang="html">
    <svg width="39px" height="58px" viewBox="0 0 39 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="ico/ico-uvod-smlouva" transform="translate(-18.000000, -7.000000)" fill-rule="nonzero" stroke-width="2">
                <g transform="translate(37.000000, 37.000000) scale(-1, 1) translate(-37.000000, -37.000000) ">
                    <path d="M18,18.8416032 C18,46.3841883 18,60.7061529 18,61.8074971 C18,63.4595134 19.2501455,64 20.7064736,64 C22.1628018,64 51.3422152,64 52.934911,64 C54.5276068,64 55,62.9071985 55,61.0918862 C55,59.276574 55,11.8520761 55,10.4238115 C55,8.99554679 54.1452554,8 52.4599079,8 C51.336343,8 43.3686229,8 28.5567478,8 L18,18.8416032 Z" id="Path-10" stroke="#6DECAF" fill="#FFFFFF"></path>
                    <path d="M19.5578947,19.6185123 C24.4086783,19.6185123 27.3198781,19.6185123 28.2914941,19.6185123 C29.7489182,19.6185123 29.7025118,18.0371919 29.7025118,15.9686041 C29.7025118,14.5895456 29.7025118,12.0631688 29.7025118,8.38947368" id="Path-16" stroke="#6DECAF"></path>
                    <path d="M26.5127538,28 L31.2947163,28" id="Path-12" stroke="#D4DFF0"></path>
                    <path d="M34.1850161,28 L47.7636054,28" id="Path-12-Copy" stroke="#D4DFF0"></path>
                    <path d="M26.5127538,36 L31.2947163,36" id="Path-12-Copy-5" stroke="#D4DFF0"></path>
                    <path d="M34.1850161,36 L47.7636054,36" id="Path-12-Copy-4" stroke="#D4DFF0"></path>
                    <path d="M26.5127538,44 L31.2947163,44" id="Path-12-Copy-9" stroke="#D4DFF0"></path>
                    <path d="M34.1850161,44 L47.7636054,44" id="Path-12-Copy-8" stroke="#D4DFF0"></path>
                    <path d="M26.5127538,32 L40.1595954,32" id="Path-12-Copy-3" stroke="#D4DFF0"></path>
                    <path d="M42.8753133,32 L47.7636054,32" id="Path-12-Copy-2" stroke="#D4DFF0"></path>
                    <path d="M26.5127538,40 L40.1595954,40" id="Path-12-Copy-7" stroke="#D4DFF0"></path>
                    <path d="M42.8753133,40 L47.7636054,40" id="Path-12-Copy-6" stroke="#D4DFF0"></path>
                    <polyline id="Path-13" stroke="#6DECAF" points="27.4143468 52.7550488 28.7669008 55.0223529 30.5720604 51.2229681 32.768316 55.0223529 34.4951906 51.8024718 36.0372075 55.0223529 40.9027179 52.7550488"></polyline>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'IcoDashboardContract'
    }
</script>
