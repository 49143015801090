<template>
  <div id="contract-template-content" v-html="source" />
</template>

<script>
export default {
  name: 'DocumentTemplateView',
  props: {
    source: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const wrapper = document.getElementById('contract-template-content');
    const css = `
      #contract-template-content {
        color: #4a4a4a;
      }
      #contract-template-content p {
        margin-top: 20px;
      }
      #contract-template-content p.centered {
        text-align: center;
      }
      #contract-template-content h2 {
        text-align: center;
        margin: 30px 0 0 0;
      }
      #contract-template-content h1 {
        display: none;
      }
      #contract-template-content .table {
        width: 100%;
        margin-top: 30px;
        border-spacing: 0;
        font-size: 15px;
        color: #4a4a4a;
        letter-spacing: -0.03px;
        line-height: 1.7;
        font-weight: 300;
      }
      #contract-template-content .table tr:last-of-type td {
        border-bottom-color: #000;
      }
      #contract-template-content .table tr td {
        border: 1px solid #000;
        border-right-color: transparent;
        border-bottom-color: transparent;
        padding: 5px 10px;
      }
      #contract-template-content .table tr td:last-of-type {
        border-right-color: #000;
      }
    `;

    this.$nextTick(() => {
      if (wrapper) {
        wrapper.insertAdjacentHTML('beforebegin', `<style>${css}</style>`);
        document.head.innerHTML += css;
      }
    });
  },
};
</script>

<style lang="scss"></style>
