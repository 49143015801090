import store from '@/store';

export const Parties = {
  build,
};

function build(isSmsPinRequiredForNewUsers, reloadView) {
  const reload = () => reloadView(buildParties());

  let parties = [];
  let uniqueIds = {};
  let counterpartiesLimits = { min: 0, max: 0 };

  return {
    loadFromContract,
    addCounterparty: () => {
      addPartyWithoutReload({ is_proposer: false });
      reload();
    },
    addSigner: (publicParty, signIdentity) => {
      addSignerWithoutReload(publicParty, signIdentity);
      reload();
    },
    upsertSigner,
    removeParty,
    removeSigner: (signIdentity) => {
      removeSignerBeforeInsert(signIdentity);
      reload();
    },
    removeSignerBeforeInsert,
    insertSigner,
    moveParty,
    reorderParties,
    reorderSigners,
    isAlreadySelected,
    getAllFlat,
  };

  function loadFromContract(signIdentities, availableProposers, minCounterpartiesCount, maxCounterpartiesCount) {
    const hasNoProposer = signIdentities.find((s) => {
      return (s?.email || s?.variable_position) && s?.is_proposer;
    }) === undefined;

    parties = [];
    counterpartiesLimits = {
      min: minCounterpartiesCount,
      max: maxCounterpartiesCount,
    };

    // add proposers - proposer party is not editable, it must be preloaded as first party
    if (hasNoProposer) {
      upsertParty({ is_proposer: true })

      if (availableProposers && availableProposers.length === 1) {
        addPartyAndSigner({ is_proposer: true, ...availableProposers[0] })
      } else {
        const currentProposer = store.getters['profile'];
        const preselectedProposer = availableProposers?.find((proposer) => {
          return `${proposer?.email}` === `${currentProposer?.email}`;
        });

        if (preselectedProposer) {
          addPartyAndSigner({ is_proposer: true, ...preselectedProposer });
        }
      }
    }

    // add counterparties
    signIdentities.forEach(signIdentity => {
      if (!signIdentity.email && !signIdentity.variable_position) {
        return;
      }

      addPartyAndSigner(signIdentity);
    });

    reload();

    function addPartyAndSigner(signIdentity) {
      const party = upsertParty(signIdentity)

      addSignerWithoutReload(party, signIdentity)
    }

    function upsertParty(signIdentity) {
      return findPartyBySignIdentity(signIdentity) || addPartyWithoutReload(signIdentity);
    }
  }

  function addPartyWithoutReload(signIdentity) {
    parties.push({
      internalKey: randomId(),
      partyOrder: signIdentity.is_proposer ? null : signIdentity.party_order,
      isCounterparty: !signIdentity.is_proposer,
      signers: [],
    });
    return parties[parties.length - 1];
  }

  function upsertSigner(editedParty, editedContact) {
    if (!editedContact.internalKey) {
      addSignerWithoutReload(editedParty, editedContact);
    }
    reload();
  }

  function addSignerWithoutReload(publicParty, signIdentity) {
    const internalParty = parties[findPartyIndex(publicParty)]

    if (typeof signIdentity.contract_role === 'undefined' || signIdentity.contract_role === null) {
      signIdentity.contract_role = 'sign'
    }

    if (typeof signIdentity.auths_data === 'undefined') {
      signIdentity.auths_data = {}
    }

    if (typeof signIdentity.required_verifications === 'undefined' || signIdentity.required_verifications === null) {
      signIdentity.required_verifications = isSmsPinRequiredForNewUsers || !signIdentity.user_id
        ? ['sms_pin']
        : []
    }

    if (typeof signIdentity.required_authentications === 'undefined' || signIdentity.required_authentications === null) {
      signIdentity.required_authentications = []
    }

    internalParty.signers.push({
      ...signIdentity,
      is_proposer: !internalParty.isCounterparty,
      party_order: internalParty.partyOrder,
      internalKey: randomId()
    })
  }

  function randomId() {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
  }

  function removeParty(publicParty) {
    parties = remove(parties, publicParty);
    reload();
  }

  function removeSignerBeforeInsert(signIdentity) {
    const party = findPartyBySignIdentity(signIdentity);
    party.signers = remove(party.signers, signIdentity);
  }

  function remove(array, deleted) {
    return array.filter(x => x.internalKey !== deleted.internalKey);
  }

  function findPartyBySignIdentity(signIdentity) {
    return parties.find(party =>
      signIdentity.is_proposer != party.isCounterparty &&
      (signIdentity.is_proposer || party.partyOrder == signIdentity.party_order)
    );
  }

  function insertSigner(publicParty, signIdentity, index) {
    // https://github.com/rlemaigre/Easy-DnD/blob/65a19bb8b915dd72a86befba032be2c831ab4e76/src/components/Flex.vue#L55
    const internalParty = parties[findPartyIndex(publicParty)];
    internalParty.signers.splice(normalizeIndex(), 0, signIdentity);
    reload();

    function normalizeIndex() {
      if (index < 0) {
        return 0;
      } else if (index >= internalParty.signers.length) {
        return internalParty.signers.length;
      }
      return index;
    }
  }

  function moveParty(publicParty, isMoveUp) {
    const currentIndex = findPartyIndex(publicParty);
    reorderParties({
      from: currentIndex,
      to: isMoveUp ? currentIndex - 1 : currentIndex + 1,
    });
  }

  function reorderParties(dndEvent) {
    reorderArray(parties, dndEvent.from, dndEvent.to);
  }

  function reorderSigners(publicParty, dndEvent) {
    const internalParty = parties[findPartyIndex(publicParty)];
    reorderArray(internalParty.signers, dndEvent.from, dndEvent.to);
  }

  function reorderArray(array, from, to) {
    // dndEvent.apply = https://github.com/rlemaigre/Easy-DnD/blob/65a19bb8b915dd72a86befba032be2c831ab4e76/lib/src/ts/events.ts#L24
    if (to < 0 || to >= array.length) {
      return;
    }
    let tmp = array[from];
    array.splice(from, 1);
    array.splice(to, 0, tmp);
    reload();
  }

  function findPartyIndex(publicParty) {
    return parties.findIndex(x => x.internalKey == publicParty.internalKey);
  }

  function getAllFlat() {
    return buildParties().signIdentities;
  }

  function buildParties() {
    // this ensures correct order + partyIndex, dynamic flags
    // don't have to care about 'Time complexity' because of small number of parties/people
    // (it still better than repeated analysis in vue methods isMovableUp etc., it's rebuilt only after change)
    const result = {
      parties: [],
      signIdentities: [],
      proposerIds: [],
      counterparties: {},
    };
    uniqueIds = {};
    let counterpartyIndex = 1;

    parties.forEach((party, index) => {
      party.partyOrder = party.isCounterparty ? counterpartyIndex++ : null;
      party.isMovableUp = index > 0;
      party.isMovableDown = index < (parties.length - 1);

      if (party.isCounterparty) {
        result.counterparties[party.partyOrder] = false;
      }

      party.signers.forEach(signer => {
        if (!signer.email && !signer?.variable_position) {
          return;
        }

        signer.partyOrder = party.partyOrder;
        signer.party_order = party.partyOrder;
        signer.is_proposer = !party.isCounterparty;

        result.signIdentities.push(signer);
        uniqueIds[buildUniqueId(signer)] = signer.internalKey;

        if (party.isCounterparty) {
          result.counterparties[party.partyOrder] = true;
        } else {
          result.proposerIds.push(signer.user_id);
        }
      });

      result.parties.push(party);
    });

    return {
      parties: result.parties,
      signIdentities: result.signIdentities,
      proposerIds: result.proposerIds,
      ...analyzeParties(),
    };

    function analyzeParties() {
      const counterpartiesAnalysis = Object.values(result.counterparties);
      const allCounterpartiesCount = counterpartiesAnalysis.length;
      const filledCounterpartiesCount = counterpartiesAnalysis.filter(isFilled => isFilled).length;
      return {
        canAddAnotherCounterparty: allCounterpartiesCount < counterpartiesLimits.max,
        isIncomplete: !result.proposerIds.length
          || filledCounterpartiesCount < counterpartiesLimits.min
          || filledCounterpartiesCount > counterpartiesLimits.max,
      };
    }
  }

  function isAlreadySelected(newSignIdentity) {
    const newId = buildUniqueId(newSignIdentity);

    if (newSignIdentity?.variable_position) {
      return false;
    }

    return !(!uniqueIds[newId] || uniqueIds[newId] == newSignIdentity.internalKey);
  }

  function buildUniqueId(signIdentity) {
    return signIdentity.user_id
      ? `${signIdentity.user_id}_${signIdentity.workspace_id}`
      : `${signIdentity.email}`;
  }
}
