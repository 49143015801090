import { authHeader } from '@/config/authHeader';
import { hostname } from '@/services/helpers/variables.js';
import router from '@/router';

export const ExternalDriveService = {
  onedriveAuthStart,
  onedriveAuthFinish,
  onedriveAuthDelete,
  listOnedriveDirectories,
};

const onedriveAuth = (queryString) => {
  const token = localStorage.getItem('token');
  location.replace(`${hostname}/api/v1/onedrive/auth${queryString}&access_token=${token}`);
};

function onedriveAuthStart(workspaceId) {
  onedriveAuth(`?state=${workspaceId}`);
}

function onedriveAuthFinish(queryString) {
  onedriveAuth(queryString);
}

function onedriveAuthDelete(workspaceid) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader() },
  };
  return fetch(`${hostname}/api/v1/onedrive/auth?workspaceid=${workspaceid}`, requestOptions).then(handleResponse);
}

function listOnedriveDirectories(workspaceid, path = null) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  return fetch(`${hostname}/api/v1/onedrive/folder?workspaceid=${workspaceid}&path=${path}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 404) {
                router.push({name: 'notFound'});
                return;
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleResponseFull(response) {
    return response.text().then(text => {
        let data;
        try {
          data = text && JSON.parse(text);
        } catch (e) {
          data = '';
        }
        return data;
    });
}
