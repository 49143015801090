var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"5","rounded":"xl"}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-title',[_c('h1',{staticClass:"headline font-weight-bold mx-auto mb-0",domProps:{"innerHTML":_vm._s(_vm.$t('signing.tell_reason_to_reject'))}})]),_c('v-row',{staticClass:"pa-10 pt-0",attrs:{"align":"stretch","justify":"center","no-gutters":""}},_vm._l((_vm.reasons),function(reason,index){return _c('v-col',{key:index,staticClass:"col-12 col-md-5 ma-2 mb-2",on:{"click":function($event){return _vm.selectReason(reason)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"reason-choice__button text-center pa-2 pa-md-5",style:(_vm.selectedReason !== null && _vm.selectedReason.value === reason.value
                ? ("border: 2px solid " + _vm.primaryColor)
                : ''),attrs:{"height":"100%","rounded":"lg","elevation":hover
                ? 5
                : _vm.selectedReason !== null && _vm.selectedReason.value === reason.value
                ? 10
                : 1}},[_vm._v(" "+_vm._s(reason.title)+" ")])]}}],null,true)})],1)}),1),_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSelectedReason),expression:"isSelectedReason"}],staticClass:"pa-10 pt-0",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.reasonLabel,"color":_vm.primaryColor,"name":"reason","outlined":"","autofocus":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":_vm.primaryColor,"loading":_vm.isSending,"disabled":_vm.message.length <= 2,"min-width":"15rem","x-large":""},on:{"click":_vm.onReject}},[_vm._v(" "+_vm._s(_vm.$t('general.send'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }