<template lang="html">
  <div class="document-table-row">
    <div class="document-table-item__checkbox">
      <Checkbox @click="checkboxClick" v-model="isChecked" />
    </div>
    <div class="document-table-item__name document-table-row__name">
      <div class="document-table-row__name-heading">
        <router-link
          :to="{ name: 'documentsDetail', params: { workspace_id: workspaceId, contract_id: item.id } }"
          :title="item.title"
          class="link-unstyled"
        >
          <span v-line-clamp="1">
            {{ item.title }}

            <span v-if="item.attachments_count" class="document-table-row__name-attachments">
              <v-icon color="#B3C2D6" small>mdi-paperclip</v-icon>{{ item.attachments_count }}
            </span>

            <span v-if="isPdfDeleted && isCompleted" class="document-table-row__name-attachments">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="#B3C2D6" small>mdi-text-box-remove-outline</v-icon>
                </template>

                <span>{{ $t('contract.deleted.pdf') }}</span>
              </v-tooltip>
            </span>

            <span class="document-table-row__name-attachments">
              <ArchiveState :archive-state="archiveState" small />
            </span>
          </span>
        </router-link>
      </div>
      <div class="document-table-row__name-author">
        {{ $t('general.creator') }}: {{ item.creator }}
      </div>
      <div class="document-table-row__name-signers">
        <div>{{ $t('contract.signers') }}:</div>
        <div class="document-table-row__name-signers-items" ref="identitiesContainer">
          <div v-if="identitiesPrepared" class="document-table-row__name-signers-items-inner">
            <div v-for="(identity, index) of filteredIdentities" :key="identity.id">
              <span v-if="hasRowFeatureFlag(identity, 'isCompleted')" class="document-table-row__name-signers-signed">
                <IcoCheck />
              </span>
              <span class="document-table-row__name-signers-unsigned" v-else>
                <IcoReject />
              </span>
              {{ identity.fullname }}{{ showComa(index, filteredIdentities) }}
            </div>
            <div :title="remainingIdentities" v-if="trimmedIdentitiesCount > 0" class="document-table-row__name-signers-trimmed">
              {{filteredIdentities.length === 0 ? ' ' : '+'}}{{ trimmedIdentitiesCount }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="item.tags.length" class="document-table-row__name-tags">
        {{ $t('tags') }}:
        <span
          v-for="tag in item.tags"
          :key="tag.id"
          class="document-table-row__name-tag"
        >
          {{ tag.name }}
        </span>
      </div>
      <div class="document-table-row__name-created-secondary">
        {{ $t('general.created_at') }}: {{ item.dates.created | formatDate }}
      </div>
      <div v-if="!identitiesPrepared" class="document-table-row__name-signers document-table-row__name-signers-ruler">
        <div>{{ $t('contract.signers') }}:</div>
        <div class="document-table-row__name-signers-items">
          <div class="document-table-row__name-signers-items-inner" ref="ruler">
            <div v-for="(identity, index) of identities" :key="identity.id">
              <span v-if="hasRowFeatureFlag(identity, 'isCompleted')" class="document-table-row__name-signers-signed">
                <IcoCheck />
              </span>
              <span class="document-table-row__name-signers-unsigned" v-else>
                <IcoReject />
              </span>
              {{ identity.fullname }}{{ showComa(index, identities) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="document-table-item__state document-table-row__state">
      <DocumentState :state-type="item.state_dashboard" width="full" />
    </div>
    <div class="document-table-item__created document-table-row__created">
      {{ item.dates.created | formatDate }}
    </div>
    <div class="document-table-item__changed document-table-row__changed">
      {{ item.dates.updated | formatDate }}
    </div>
    <div @click="popupActionsOpen = !popupActionsOpen" class="document-table-item__actions document-table-row__actions">
      <div class="document-table-row__actions-dots">
        ...
      </div>
    </div>
    <div v-if="popupActionsOpen" v-click-outside="onClickOutside" class="document-table-item__actions-item">
      <div v-if="hasRowFeatureFlag(item, 'isDuplicatable')">
        <IcoDuplicate />
        <a href="#" class="pl-2" @click.prevent="openModal('duplicate', item)">
          {{ $t('contract.duplicate') }}
        </a>
      </div>
      <div>
        <IcoMoveToFolder />
        <a href="#" class="pl-2" @click.prevent="openModal('move', item)">
          {{ $t('folders.moveToFolder') }}
        </a>
      </div>
      <div>
        <IcoMoveToFolder />
        <a href="#" class="pl-2" @click.prevent="openModal('editTags', item)">
          {{ $t('tags.edit') }}
        </a>
      </div>
      <div v-if="canChangeExpirationDate">
        <IcoExpiration />
        <a href="#" class="pl-2" @click.prevent="openModal('extendExpiration', item)">
          {{ $t('docs.pending_expiration.extend') }}
        </a>
      </div>
      <div v-if="hasRowFeatureFlag(item, 'isCompletelyDeletable')" class="separator-hr"></div>
      <div v-if="hasRowFeatureFlag(item, 'isCompletelyDeletable')">
        <IcoTrash />
        <a href="#" class="pl-2" @click.prevent="showDeleteContractDialog = true">
          {{ $t('general.delete') }}
        </a>
      </div>
      <div v-if="canDeletePdf">
        <IcoTrash />
        <a href="#" class="pl-2" @click.prevent="showCompletedDocumentDeletePdfDialog = true">
          {{ $t('general.delete_pdf') }}
        </a>
      </div>

      <SConfirmDialog
        v-if="showDeleteContractDialog"
        v-model="showDeleteContractDialog"
        :message="`${$t('docs.delete.confirm')}`"
        @confirmation-callback="deleteContract"
      />

      <SConfirmDialog
        v-if="showCompletedDocumentDeletePdfDialog"
        v-model="showCompletedDocumentDeletePdfDialog"
        :message="`${$t('contract.delete_pdf.info')}`"
        @confirmation-callback="deleteContractPdf"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { parseDate } from '@/services/helpers/parseDate.js';
  import { ContractService } from '@/services/ContractService';
  import { hasFeatureFlag } from '@/common/reusable/featureFlagsChecker';
  import ArchiveState from "@contract/components/ArchiveState";
  import DocumentState from "@/views/dashboard/components/DocumentState";
  import IcoReject from '@/components/svg/IcoReject.vue';
  import IcoCheck from '@/components/svg/IcoCheck.vue';
  import IcoPaperClip from '@/views/dashboard/components/icons/IcoPaperClip.vue';
  import IcoTrash from '@/views/dashboard/components/icons/IcoTrash';
  import IcoExpiration from '@/components/svg/IcoExpiration';
  import IcoMoveToFolder from '@/components/svg/IcoMoveToFolder';
  import IcoDuplicate from '@/components/svg/IcoDuplicate';
  import SConfirmDialog from "@/common/components/SConfirmDialog";

  export default {
    name: 'DocumentTableRow',
    components: {
      ArchiveState,
      DocumentState,
      IcoTrash,
      IcoExpiration,
      IcoMoveToFolder,
      IcoDuplicate,
      IcoReject,
      IcoCheck,
      IcoPaperClip,
      SConfirmDialog,
    },
    props: {
      item: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        showDeleteContractDialog: false,
        identities: [],
        identitiesPrepared: false,
        identitiesFitToContainer: 0,
        popupActionsOpen: false,
        showCompletedDocumentDeletePdfDialog: false,
      };
    },
    filters: {
      formatDate: parseDate,
    },
    computed: {
      ...mapGetters([
        'dashboardCheckedDocuments',
      ]),
      archiveState() {
        return this.item?.state_trusted_archive || null;
      },
      canChangeExpirationDate () {
        const isExpirationExtendable = this.item?.feature_flags?.isExpirationExtendable || false;
        const unsupportedStates = [
          'draft',
          'expired',
          'rejected',
          'completed'
        ];

        return !(unsupportedStates.includes(this.item.state_dashboard)) && isExpirationExtendable;
      },
      canDeletePdf() {
        return this.item?.feature_flags?.canDeleteDocuments || false;
      },
      isPdfDeleted() {
        return this.item?.is_all_deleted_by_client
      },
      isCompleted() {
        return 'completed' === this.item?.state;
      },
      isChecked() {
        return this.dashboardCheckedDocuments.some((document) => (document.id === this.item.id));
      },
      filteredIdentities() {
        return this.identities.slice(0, this.identitiesFitToContainer);
      },
      remainingIdentities() {
        if (this.identitiesPrepared) {
          return [ ...this.identities ].splice(this.identities.length - this.trimmedIdentitiesCount)
            .map(identity => `${this.hasRowFeatureFlag(identity, 'isCompleted') ? '(✔)' : '(✗)'} ${identity.fullname}`)
            .join(', ');
        } else {
          return '';
        }
      },
      trimmedIdentitiesCount() {
        return this.identities.length - this.identitiesFitToContainer;
      },
      workspaceId() {
        return this.$route.params.workspace_id;
      },
    },
    created() {
      this.setupIdentities();
    },
    mounted() {
      this.prepareIdentitiesContainer();
    },
    methods: {
      onClickOutside() {
        this.popupActionsOpen = false;
      },
      deleteContract() {
        ContractService
          .delete(this.item.id, true)
          .then(
            () => {
              this.$store.dispatch('getUserProfile')
              this.$notification.success(this.$t('docs.delete.ok'));
              this.$emit('reload')
            },
            () => {
              this.$notification.error(this.$t('docs.delete_draft.failed'));
            }
          )
      },
      deleteContractPdf() {
        ContractService.deleteCompleted(this.item.id)
          .then((response) => {
            if ('ok' === response.status) {
              return (() => {
                this.$notification.success(this.$t('docs.delete.ok'));
                this.$emit('reload');
              })();
            }

            this.$notification.error(this.$t('docs.delete_draft.failed'));
          })
          .catch(() => {
            this.$notification.error(this.$t('docs.delete_draft.failed'));
          });
      },
      showComa(i, filteredIdentities) {
        return (i < filteredIdentities.length - 1) && (filteredIdentities.length > 0) ? ',' : ''
      },
      prepareIdentitiesContainer() {
        const maximalWidth = this.$refs.identitiesContainer.clientWidth - 30;
        let indexOfLast = -1;
        let totalWidth = 0;

        for (let i = 0; i < this.$refs.ruler.childNodes.length; i++) {
          totalWidth += this.$refs.ruler.childNodes[i].offsetWidth;
          if (totalWidth >= maximalWidth) {
            break;
          }

          indexOfLast = i;
        }

        this.identitiesFitToContainer = indexOfLast + 1;
        this.identitiesPrepared = true;
      },
      setupIdentities() {
        this.identities = this.item.people;
      },
      checkboxClick() {
        this.$store.commit('dashboardCheckDocument', this.item);
      },
      openModal(action, item) {
        this.popupActionsOpen = false;
        this.$store.commit('dashboardOpenModal', {
          type: action,
          contracts: [item],
        });
      },
      hasRowFeatureFlag(item, flag) {
        return hasFeatureFlag(item, flag);
      },
    },
  }
</script>
