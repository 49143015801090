<template>
  <div class="folder-structure-explorer__wrapper">
    <div class="folder-structure-explorer">
      <div class="folder-structure-explorer__dotts" :class="{'folder-structure-explorer__dotts-inactive': parent === '/iSmlouva'}">
        <span @click="navigateBack(parent)">...</span>
        <span v-if="!loading" class="folder-structure-explorer__current-path">/iSmlouva/{{ currentPath }}</span>
      </div>
      <template v-if="!loading">
        <div class="folder-structure-explorer__item"
             v-for="folder in folderItems"
             :key="folder">
        <span
          @click="doubleClickHandler(folder)"
          :class="{ 'selected-folder': folder === selectedFolder }"
        >{{ folder }} <span @click.stop.prevent="navigateToFolder(folder)"><IcoFolderPlus /></span> </span>
        </div>

        <div class="folder-structure-no-results" v-if="folderItems.length === 0">
          <div><IcoFolderOpen /></div>
          <div>{{ $t('contract.onedrive.no_subfolders') }}</div>
        </div>

      </template>
      <template v-else>
        <div class="folder-structure-explorer__loading-wrapper">
          <span class="folder-structure-explorer__loading"></span>
        </div>
      </template>
      <div class="folder-structure-explorer__button-wrapper">
        <span @click="cancel">{{ $t('general.cancel') }}</span>
        <Button class="folder-structure-explorer__button" type="primary" @click="selectFolder">
          {{ selectedFolder ? $t('contract.onedrive.select_checked') : $t('contract.onedrive.select_this') }}
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
  import IcoFolderOpen from '@/components/svg/IcoFolderOpen';
  import IcoFolderPlus from '@/components/svg/IcoFolderPlus';

  export default {
    name: 'FolderStructureExplorer',
    props: {
      loadFunction: {
        required: true,
        type: Function,
      },
      backPath: {
        required: false,
        type: String,
      }
    },
    components: {
      IcoFolderOpen,
      IcoFolderPlus,
    },
    data() {
      return {
        selectedFolder: '',
        timeoutId: null,
        loading: false,
        folderItems: [],
        root: '',
        parent: '',
      };
    },
    computed: {
      splitPath() {
        return this.parent.split('/');
      },
      currentPath() {
        return `${this.splitPath.slice(2).join('/')}`;
      }
    },
    created() {
      this.loadItems('')
    },
    methods: {
      doubleClickHandler(folder) {
        this.highlightFolder(folder);
        if(!this.timeoutId) {
          this.timeoutId = setTimeout(() => {
            this.timeoutId = null
          }, 500);
        } else {
          clearTimeout(this.timeoutId);
          this.timeoutId = null
          this.navigateToFolder(folder);
        }
      },
      async loadItems(path) {
        this.loading = true;
        try {
          const { parent, children } = await this.loadFunction(path);
          this.parent = parent;
          this.folderItems = children;
        } catch (e) {
          this.loading = false;
        }

        this.loading = false;
      },
      highlightFolder(folder) {
        if (this.selectedFolder) {
          this.selectedFolder = null;
          return;
        }

        this.selectedFolder = folder;
      },
      navigateBack(parent) {
        this.selectedFolder = null;

        if (parent === '/iSmlouva') {
          return;
        }

        let parentPath = this.splitPath.slice(2);
        parentPath.pop();
        let path;
        if (parentPath.length > 0) {
          path = `${parentPath.join('/')}`
        } else {
          path = ''
        }
        this.loadItems(path);
      },
      navigateToFolder(folder) {
        this.selectedFolder = null;

        let parentPath = '';
        if (this.splitPath.length > 2) {
          parentPath = `${this.splitPath.slice(2).join('/')}`
        } else {
          parentPath = ''
        }
        const fullPath = `${parentPath}/${folder}`;

        this.loadItems(fullPath);
      },
      selectFolder() {
        let path;
        if (this.selectedFolder) {
          path = `${this.splitPath.slice(2).join('/')}`;
          path = path.length > 0 ? `${path}/${this.selectedFolder}` : this.selectedFolder;
        } else {
          path = this.splitPath.slice(2).join('/');
        }

        path = path.length > 0 ? '/' + path : '';
        this.$emit('selectFolder', path);
      },
      cancel() {
        this.$emit('cancel')
      }
    },
  }
</script>
<style lang="scss">
  .folder-structure-explorer__wrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #000000ba;
    z-index: 1000;

    .folder-structure-explorer {
      width: 300px;
      position: absolute;
      left: 0;
      right: 0;
      margin: 20px auto;
      background: white;
      border-radius: 6px;
      padding: 20px;

      .folder-structure-explorer__dotts {
        font-size: 16px;
        color: black;
        border-bottom: 1px solid #e6e6e6;
        padding: 0 0 10px 0;
        cursor: pointer;
        margin: 0 0 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .folder-structure-explorer__dotts-inactive {
        color: lightgray;
      }

      .folder-structure-explorer__item {
        span {
          font-size: 16px;
          color: black;
          padding: 10px 0;
          display: flex;
          align-items: center;
          justify-content: left;
          cursor: pointer;
          svg {
            width: 20px;
            height: 20px;
            color: #DDDDDD;
            margin-left: 5px;
            cursor: pointer;
          }
        }

        .selected-folder {
          font-weight: bold;
          color: #5E259E;
        }
      }

      .folder-structure-explorer__button-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        button {
          width: 60%;
          height: 40px;
          margin: 0;
        }
        & > span {
          font-size: 14px;
          color: black;
          font-weight: bold;
          cursor: pointer;
        }
      }

      .folder-structure-explorer__loading-wrapper {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        .folder-structure-explorer__loading {
          width: 20px;
          height: 20px;
          background: url(/images/loader.svg);
          background-position: center;
          background-size:contain;
          animation: spin 4s linear infinite;
          background-repeat: no-repeat;
          display: block;
          z-index: 4;
        }

        .button__ico--left {
          display: none !important;
        }
      }
    }
  }

  .folder-structure-no-results {
    height: 100px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    svg {
      width: 40px;
      height: 40px;
      color: #dddddd;
    }
  }

  .folder-structure-explorer__current-path {
    font-size: 12px;
    color: black;
  }
</style>
