<template lang="html">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1280"
    height="800"
    id="Flag of Poland"
    viewBox="0 0 16 10"
  >
    <rect width="16" height="10" fill="#fff" />
    <rect width="16" height="5" fill="#dc143c" y="5" />
  </svg>
</template>

<script>
export default {
  name: 'IcoFlagPL',
};
</script>
