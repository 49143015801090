<template lang="html">
  <!-- POUŽÍT TAKTO: <Select value="" :options="[{'name': 'Možnost 1'},{'name': 'Možnost 2'}]"></Select> -->
  <div class="select-wrap" v-bind:class="{'select-clicked':selectOpened}">
<!--    <label>-->
      <div class="select-wrap-under">
        <span v-if="label" class="select__label" style="width: 100%">{{ label }}</span>
        <div class="select" tabindex="0" @focus.stop.prevent="openSelect" @blur.stop.prevent="onBlur" :class="{'invalid': !validation}">
          <span v-if="value" class="select__placeholder" style="overflow: hidden; width: 140%">{{ value }}</span>
          <span v-else-if="placeholder" class="select__placeholder">{{placeholder}}</span>
          <span v-else>{{ $t('placeholder.workspace') }}</span>
          <span class="select__arrow"><span class="icon-bottom"></span></span>
        </div>

        <div class="select__data">
          <ul>
            <li @click.stop="selectOption(option)" v-for="(option, index) in options" :key="index">{{option.name}}</li>
          </ul>
        </div>
      </div>
<!--    </label>-->
  </div>
</template>


<script>
export default {
  name: 'Select',
  props: {
    placeholder: String,
    value: String,
    label: String,
    options: Array,
    disabled: Boolean,
    validation: {
      required: false,
      default: true,
    },
  },
  data() {
    return {
      selectOpened: false,
    }
  },
  computed: {
    isArrowVisible () {
      return this.options.length > 0
    }
  },
  methods: {
    toggleSelect() {
      if(!this.disabled) {
        this.selectOpened = !this.selectOpened;
      }
    },
    openSelect() {
      if(!this.disabled) {
        this.selectOpened = true;
      }
    },
    selectOption(option) {
      this.$emit('select', option);

      const vm = this;
      setTimeout(function(){
        vm.selectOpened = false;
      }, 100);
    },
    closeSelect() {
      if (this.selectOpened) {
        this.selectOpened = false;
      }
    },
    onBlur() {
      const vm = this;
      setTimeout(function(){
        vm.selectOpened = false;
      }, 250);
    }
  }
}
</script>


<style lang="scss">

.select-wrap .select:focus, .select-wrap .select:active {
  border: solid 1px #5E239E;
}

.select-clicked .tabs-component-tabs{
  z-index: 9;
}

.select{
  cursor: pointer;
  display: block;
  position: relative;
  width: 310px;
  outline: none;
  background: #FFFFFF;
  border: 1px solid #B1BBCB;
  border-radius: 6px;
  height: 56px;
  padding: 0px 20px;
  font-size: 16px;
  color: black;
  letter-spacing: -0.03px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 54px;
  font-weight: 300;
  padding-right: 55px;
}

.select__placeholder {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.select__arrow{
  font-size: 10px;
  position: absolute;
  right: 12px;
  transition: 0.2s;
  top: 50%;
  line-height: 54px;
  display: block;
}

.select__arrow .icon-bottom {
  display: block;
  top: 0;
  transform: translateY(-50%);
  position: absolute;
  right: 0;
  line-height: 54px;
}

.select__data{
  display:none;
  background: white;
  width: 310px;
  margin-left: auto;
  margin-right: auto;
  border: solid 1px #5E239E;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  max-height: 235px;
  overflow-y: scroll;
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  ul {
    padding-left: 0 !important;

    li {
      list-style-type: none;
      border-top: solid 1px #D4DFF0;
      padding: 15px 20px;
      font-size: 15px;
      color: #000000;
      font-weight: 300;
      transition: 0.2s;
      cursor:pointer;
      &:hover{
        background: #F2F6FC;
        transition: 0.2s;
      }
    }
  }
}


.select-clicked{
  .select__data{
    display:block;
    z-index: 1001;
  }
  .select{
    border: 1px solid #5E239E;
    border-bottom:0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .icon-bottom{
    display: block;
    top: 0;
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
    position: absolute;
    right: 0;
    line-height: 54px;
  }
}

.content-finish__box .select-wrap label{
  width: 180px;
  display: block;
}

.content-finish__box .select{
  width: 320px;
}


.content-finish__box .select{
  text-align: left;
  width: 180px;
  margin-left: 0;
}

.content-finish__box .select-wrap{
  width: 320px;
  margin-left: auto;
  margin-top: 15px;
  margin-right: auto;
}
.content-finish__box .input{
  width: 320px;
}

.content-finish__box .select__data{
  text-align: left;
  width: 100%;
}

.content-finish__box .select-wrap-under{
  width: 180px;
  position: relative;
}

.select__label{
  font-weight: normal;
  font-size: 15px;
  color: black;
  letter-spacing: -0.03px;
  margin-bottom: 5px;
  text-align: left;
  display: block;
}

.select.invalid {
  border-color: red;
}
</style>
